import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, lastDayOfMonth } from "date-fns";
import React, { useState } from "react";
import { locale, mask } from "../../../../localisation";
import { colors } from "../../Dashboard/Charts/FormulesChart";

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getDatesOfMonth = (selectedDate) => {
  const totalDaysOfMonth = lastDayOfMonth(selectedDate).getDate();
  const datesOfMonth = Array.from(
    { length: totalDaysOfMonth },
    (_, i) => i + 1
  ).map((day) => {
    const newDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      day
    );
    const monthName = newDate.toLocaleString("fr-FR", { month: "long" });

    return {
      date: newDate,
      month: capitalize(monthName),
      year: newDate.getFullYear(),
      dayOfWeek: newDate.getDay(),
      day: newDate.toLocaleString("fr-FR", { weekday: "short" }),
      dayNumber: newDate.getDate(),
    };
  });
  // Get an array of all dates of month by week
  const datesOfMonthByWeek = datesOfMonth.reduce((acc, date) => {
    const lastWeek = acc[acc.length - 1];
    if (!lastWeek || lastWeek.length === 7) {
      acc.push([date]);
    } else {
      lastWeek.push(date);
    }
    return acc;
  }, []);

  return datesOfMonthByWeek;
};

const Calendar = (props) => {
  const [date, setDate] = useState(new Date());

  return (
    <Grid container>
      <Grid
        item
        marginBottom={3}
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        {props.loading && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}

        <Grid item>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale["fr"]}
          >
            <DatePicker
              mask={mask["fr"]}
              views={["month", "year"]}
              inputFormat="MMMM yyyy"
              value={date}
              onChange={(value) => {
                setDate(value);
                !props.isFilteredOnRecipients &&
                  props.handleChangeSelectedMonth(value);
              }}
              renderInput={(params) => <TextField {...params} disabled />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      {props.isFilteredOnRecipients
        ? getDatesOfMonth(date).map((week, index) => (
            <Grid key={index} item xs={12}>
              <Stack direction="row" flex={1}>
                {Object.values(week).map((day, i) => {
                  const order = props.orders.find(
                    (order) =>
                      format(new Date(order.date), "yyyy-MM-dd") ===
                      format(day.date, "yyyy-MM-dd")
                  );

                  return (
                    <Stack key={i} flex={1} marginBottom={2} height="240px">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          borderBottom: 1,
                          borderBottomColor: "divider",
                        }}
                      >
                        <Typography variant="body2">
                          {day.day} {day.dayNumber}
                        </Typography>
                      </Box>

                      {order ? (
                        <>
                          <Stack
                            padding={2}
                            sx={{
                              height: "100%",
                              overflow: "auto",
                              background:
                                "linear-gradient(rgba(251, 199, 94, 0.7), rgba(255, 166, 0, 0.1))",
                            }}
                          >
                            {order.produitsDejeuner
                              .sort(
                                (a, b) =>
                                  a.categorie.priorite - b.categorie.priorite
                              )
                              .map((produit, i) => (
                                <Typography
                                  key={i}
                                  variant="body2"
                                  textAlign="left"
                                >
                                  • {produit.nom}
                                </Typography>
                              ))}

                            {order.supplementsDejeuner.length > 0 &&
                              order.supplementsDejeuner.map((item, i) => (
                                <Typography
                                  key={i}
                                  variant="body2"
                                  textAlign="left"
                                >
                                  • {item.supplement.nom}{" "}
                                  {item.qte > 1 && `(${item.qte})`}
                                </Typography>
                              ))}
                          </Stack>

                          {order.dinnerMenu && (
                            <>
                              <Divider />

                              <Stack
                                padding={2}
                                sx={{
                                  height: "100%",
                                  overflow: "auto",
                                  background:
                                    "linear-gradient(rgba(4, 69, 131, 0.1), rgba(4, 104, 186, 0.7))",
                                }}
                              >
                                {order.produitsDiner
                                  .sort(
                                    (a, b) =>
                                      a.categorie.priorite -
                                      b.categorie.priorite
                                  )
                                  .map((produit, i) => (
                                    <Typography
                                      key={i}
                                      variant="body2"
                                      textAlign="left"
                                    >
                                      • {produit.nom}
                                    </Typography>
                                  ))}

                                {order.supplementsDiner.length > 0 &&
                                  order.supplementsDiner.map((item, i) => (
                                    <Typography
                                      key={i}
                                      variant="body2"
                                      textAlign="left"
                                    >
                                      • {item.supplement.nom}{" "}
                                      {item.qte > 1 && `(${item.qte})`}
                                    </Typography>
                                  ))}
                              </Stack>
                            </>
                          )}
                        </>
                      ) : (
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                        >
                          <Tooltip title="Aucune commande">
                            <CancelIcon fontSize="medium" color="disabled" />
                          </Tooltip>
                        </Stack>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            </Grid>
          ))
        : props.data.map((week, index) => (
            <Grid key={index} item xs={12}>
              <Stack direction="row" flex={1}>
                {Object.entries(week).map(([day, users]) => {
                  const dayOfMonth = new Date(day);
                  const dayOfWeek = dayOfMonth.toLocaleString("fr-FR", {
                    weekday: "short",
                  });
                  const dayNumber = dayOfMonth.getDate();

                  return (
                    <Stack
                      key={dayNumber}
                      flex={1}
                      marginBottom={2}
                      height="240px"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          borderBottom: 1,
                          borderBottomColor: "divider",
                        }}
                      >
                        <Typography variant="body2">
                          {dayOfWeek} {dayNumber}
                        </Typography>
                      </Box>

                      {users.length === 0 ? (
                        <Box
                          sx={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CancelIcon color="disabled" />
                        </Box>
                      ) : (
                        <Stack
                          padding={2}
                          sx={{
                            height: "100%",
                            overflow: "auto",
                          }}
                          spacing={1}
                        >
                          {Object.entries(users).map(([user, qty], i) => (
                            <Typography
                              key={user}
                              variant="body2"
                              sx={{
                                color: "white",
                                backgroundColor: colors[i],
                                border: `1px solid ${colors[i]}`,
                                borderRadius: 1,
                                padding: 0.5,
                              }}
                            >
                              • {user} : <strong>{qty}</strong>
                            </Typography>
                          ))}
                        </Stack>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            </Grid>
          ))}
    </Grid>
  );
};

export default Calendar;
