import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import {
  Grid,
  Paper,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Card,
  CardHeader,
  Typography,
  Button,
  OutlinedInput,
  Chip,
  ListItemText,
  Input,
  FormHelperText,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SendIcon from "@mui/icons-material/Send";

const Add = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const classes = UseStyle();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <form>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button
          variant={"contained"}
          sx={{ mb: 3 }}
          onClick={props.toggleForm}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Stack>

      <Card className={classes.card} sx={{ mb: 3 }}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'un produit"
        />
      </Card>

      <Paper className={classes.paper} elevation={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={props.errors.nom && props.touched.nom ? true : false}
              {...(props.errors.nom &&
                props.touched.nom && { helperText: props.errors.nom })}
              fullWidth
              name={"nom"}
              label={"Nom du produit"}
              variant="outlined"
              type={"text"}
              value={props.values.nom}
              className={classes.inputField}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={
                props.errors.description && props.touched.description
                  ? true
                  : false
              }
              {...(props.errors.description &&
                props.touched.description && {
                  helperText: props.errors.description,
                })}
              fullWidth
              name={"description"}
              label={"Sous-titre du produit"}
              variant="outlined"
              type={"text"}
              value={props.values.description}
              className={classes.inputField}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel>Catégories</InputLabel>
              <Select
                error={
                  props.errors.categorie && props.touched.categorie
                    ? true
                    : false
                }
                style={{ textAlign: "left", width: "100%" }}
                label="Catégories"
                name={"categorie"}
                value={props.values.categorie}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              >
                {props.categories.length > 0 &&
                  props.categories.map((categorie) => {
                    return (
                      <MenuItem value={categorie.id}>{categorie.nom}</MenuItem>
                    );
                  })}
              </Select>
              {props.errors.categorie && props.touched.categorie && (
                <FormHelperText error>{props.errors.categorie}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormGroup row>
              <FormControlLabel
                onChange={props.handleChange}
                checked={props.values.estMicroOndable}
                name="estMicroOndable"
                onBlur={props.handleBlur}
                control={<Checkbox />}
                label="Micro-ondable"
              />

              <FormControlLabel
                onChange={props.handleChange}
                checked={props.values.estRemplacement}
                name="estRemplacement"
                onBlur={props.handleBlur}
                control={<Checkbox />}
                label="Produit de remplacement"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={props.values.ingredients}
              multiline
              rows={4}
              fullWidth
              name={"ingredients"}
              label={"Ingrédients"}
              variant="outlined"
              type={"text"}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={props.values.preparation}
              multiline
              rows={4}
              fullWidth
              name={"preparation"}
              label={"Préparation"}
              variant="outlined"
              type={"text"}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel>Régimes non adaptés</InputLabel>
              <Select
                multiple
                value={props.values.regimesNonAdaptes}
                onChange={(event) => {
                  props.setFieldValue(`regimesNonAdaptes`, event.target.value);
                }}
                style={{ width: "100%" }}
                input={<OutlinedInput label="Régimes non adaptés" />}
                renderValue={(selected) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((id) => (
                        <Chip
                          key={id}
                          label={
                            props.regimes.find((regime) => regime.id === id).nom
                          }
                        />
                      ))}
                    </Box>
                  );
                }}
                label="Régimes non adaptés au produit"
                name={"regimesNonAdaptes"}
              >
                {props.regimes.map((regime, i) => {
                  return (
                    <MenuItem value={regime.id} key={i}>
                      <Checkbox
                        checked={
                          props.values.regimesNonAdaptes.indexOf(regime.id) > -1
                        }
                      />
                      <ListItemText primary={regime.nom} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel>Allergènes</InputLabel>
              <Select
                multiple
                value={props.values.allergenes}
                onChange={(event) => {
                  props.setFieldValue(`allergenes`, event.target.value);
                }}
                style={{ width: "100%" }}
                input={<OutlinedInput label="Allergènes" />}
                renderValue={(selected) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((id) => (
                        <Chip
                          key={id}
                          label={
                            props.allergenes.find(
                              (allergene) => allergene.id === id
                            ).nom
                          }
                        />
                      ))}
                    </Box>
                  );
                }}
                label="Allergènes du produit"
                name={"allergenes"}
              >
                {props.allergenes.map((allergene, i) => {
                  return (
                    <MenuItem value={allergene.id} key={i}>
                      <Checkbox
                        checked={
                          props.values.allergenes.indexOf(allergene.id) > -1
                        }
                      />
                      <ListItemText primary={allergene.nom} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
              Image du produit
            </Typography>

            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {imageUrl && selectedImage && (
                <img src={imageUrl} alt={selectedImage.name} height="190px" />
              )}

              <label htmlFor="contained-button-file">
                <Input
                  inputProps={{
                    accept: "image/*",
                  }}
                  id="contained-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={(e) => {
                    setSelectedImage(e.target.files[0]);
                    props.setFieldValue("image", e.target.files[0]);
                  }}
                />
                <Button
                  variant="contained"
                  component="span"
                  endIcon={<FileUploadIcon />}
                >
                  Upload un fichier
                </Button>
              </label>
            </Stack>
          </Grid>

          <Grid item container justifyContent={"flex-end"}>
            {props.loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={props.handleSubmit}
                endIcon={<SendIcon />}
              >
                Sauvegarder le produit
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    nom: "",
    description: "",
    preparation: "",
    ingredients: "",
    allergenes: [],
    categorie: "",
    estMicroOndable: true,
    image: "",
    estRemplacement: false,
    regimesNonAdaptes: [],
  }),
  validationSchema: Yup.object().shape({
    nom: Yup.string()
      .min(3)
      .max(100)
      .required("Un nom est nécessaire pour le produit."),
    categorie: Yup.number().required(
      "Une catégorie est nécessaire pour le produit."
    ),
  }),
  handleSubmit: (values, { props }) => {
    props.validation(
      values.nom,
      values.description,
      values.ingredients,
      values.preparation,
      values.allergenes,
      values.estMicroOndable,
      values.categorie,
      values.image,
      values.estRemplacement,
      values.regimesNonAdaptes
    );
  },
})(Add);
