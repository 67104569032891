import { TextField } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

const setPlace = (callback) => (place) => {
  let lat = null;
  let lng = null;
  let data = {
    latitude: lat,
    longitude: lng,
    number: "",
    street: "",
    city: "",
    locality: "",
    country: "",
    postalCode: "",
    unparsed: "",
    notes: "",
    informations: "",
  };

  for (const component of place.address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        data.number = component.short_name;
        break;
      }
      case "route": {
        data.street = component.long_name;
        break;
      }
      case "locality": {
        data.city = component.short_name;
        break;
      }
      case "administrative_area_level_1": {
        data.locality = component.long_name;
        break;
      }
      case "country": {
        data.country = component.long_name;
        break;
      }
      case "postal_code": {
        data.postalCode = component.short_name;
        break;
      }
      default: {
        break;
      }
    }
  }

  data.latitude = place.geometry.location.lat();
  data.longitude = place.geometry.location.lng();
  data.unparsed = place.formatted_address;
  callback(data);
};

const SearchLocationInput = (props) => {
  const [query, setQuery] = useState(props.query ?? "");
  const autoCompleteRef = useRef(null);
  let autoComplete;

  async function handlePlaceSelect(updateQuery, updatePlace) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    updatePlace(addressObject);
  }

  useEffect(() => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "FR" } }
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(setQuery, setPlace(props.callback))
    );
  }, []);

  return (
    <TextField
      {...(props.fullWidth && { fullWidth: true })}
      {...(props.disabled && { disabled: true })}
      inputRef={autoCompleteRef}
      onChange={(event) => {
        setQuery(event.target.value);
      }}
      label={`${props.label}`}
      value={query}
      {...(props.size && { size: props.size })}
      {...(props.error && { error: props.error })}
      {...(props.helpertext && { helperText: props.helpertext })}
    />
  );
};

export default SearchLocationInput;
