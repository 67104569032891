import React, { useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import {
  CardHeader,
  Card,
  Button,
  Paper,
  Stack,
  Autocomplete,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { isAdmin } from "../../../../utils";
import { format } from "date-fns";
import MultipleRecipientsForm from "../MultipleRecipientsForm";

const MultipleAdd = (props) => {
  const [selectedClient, setSelectedClient] = useState(
    !isAdmin(props.userData)
      ? { client: props.userData }
      : props.recipientToDupplicate
      ? { client: props.recipientToDupplicate.organization }
      : null
  );
  const [warnings, setWarnings] = useState({
    active: false,
    message: "",
  });

  const classes = UseStyle();
  const clients =
    props.clients.length > 0 &&
    props.clients
      .filter((c) => c.mode === "diets")
      .map((c) => {
        const filtre = c.name[0].toUpperCase();
        return {
          filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
          client: c,
        };
      });

  return (
    <>
      <form autoComplete="off">
        <Stack spacing={2} marginBottom={2}>
          <Stack direction="row" justifyContent={"flex-end"}>
            <Button
              variant={"contained"}
              onClick={props.toggleList}
              color="secondary"
              startIcon={<ArrowBackIcon />}
            >
              Retour
            </Button>
          </Stack>

          <Card className={classes.card}>
            <CardHeader
              subheader="Ces informations peuvent être modifier."
              title="Création de convives par régimes"
            />
          </Card>

          {isAdmin(props.userData) && (
            <Paper className={classes.paper} elevation={6}>
              <Stack spacing={2}>
                <Autocomplete
                  fullWidth
                  options={clients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.client.id === value.client.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.client) return "";
                    return option.client.id + " - " + option.client.name;
                  }}
                  value={selectedClient}
                  onChange={(event, value) => {
                    setSelectedClient(value);

                    if (value !== null) {
                      props.setFieldValue("client", value.client);

                      if (
                        value.client.packages.length === 0 &&
                        value.client.rounds.length === 0
                      ) {
                        setWarnings({
                          active: true,
                          message:
                            "Ce client ne possède ni formule ni tournée. Veuillez lui en attribuer dans sa fiche client avant de continuer.",
                        });
                      } else if (value.client.packages.length === 0) {
                        setWarnings({
                          active: true,
                          message:
                            "Ce client ne possède pas de formule. Veuillez lui en attribuer dans sa fiche client avant de continuer.",
                        });
                      } else if (value.client.rounds.length === 0) {
                        setWarnings({
                          active: true,
                          message:
                            "Ce client ne possède pas de tournée. Veuillez lui en attribuer dans sa fiche client avant de continuer.",
                        });
                      }
                    } else {
                      props.setFieldValue("client", null);
                      setWarnings({
                        active: false,
                        message: "",
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Client" />
                  )}
                />

                {warnings.active && (
                  <Alert severity="warning">{warnings.message}</Alert>
                )}
              </Stack>
            </Paper>
          )}

          {props.values.client && !warnings.active && (
            <Paper className={classes.paper} elevation={6}>
              <Stack spacing={2}>
                <MultipleRecipientsForm {...props} />

                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={1}
                >
                  {props.loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <Button
                      onClick={props.handleSubmit}
                      variant="contained"
                      startIcon={<SaveRoundedIcon />}
                    >
                      Créer
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Paper>
          )}
        </Stack>
      </form>
    </>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => ({
      client: !isAdmin(props.userData)
        ? { id: props.userData.organization.id }
        : props.recipientToDupplicate
        ? props.recipientToDupplicate.client.id
        : null,
      startedAt: new Date(),
      cancelledAt: "",
      datas: [
        {
          diet:
            props.recipientToDupplicate &&
            props.recipientToDupplicate.diets.length > 0
              ? props.recipientToDupplicate.diets[0].id
              : "none",
          round: props?.recipientToDupplicate?.round.id ?? "",
          recipients: [
            {
              lunchMealDays: props?.recipientToDupplicate?.mealDays ?? [
                { day: 1, qty: 0 },
                { day: 2, qty: 0 },
                { day: 3, qty: 0 },
                { day: 4, qty: 0 },
                { day: 5, qty: 0 },
                { day: 6, qty: 0 },
                { day: 0, qty: 0 },
              ],
              dinnerMealDays: [
                { day: 1, qty: 0 },
                { day: 2, qty: 0 },
                { day: 3, qty: 0 },
                { day: 4, qty: 0 },
                { day: 5, qty: 0 },
                { day: 6, qty: 0 },
                { day: 0, qty: 0 },
              ],
              lunchMenu: props?.recipientToDupplicate?.lunchMenu.id ?? "",
              dinnerMenu:
                props?.recipientToDupplicate?.dinnerMenu?.id ?? "none",
            },
          ],
        },
      ],
      reset: false,
    }),
    validationSchema: Yup.object().shape({
      client: Yup.object()
        .shape({
          id: Yup.number().required(),
        })
        .required("Champs obligatoire"),
      startedAt: Yup.date().required("Champs obligatoire"),
      cancelledAt: Yup.date().nullable(),
      datas: Yup.array().of(
        Yup.object().shape({
          round: Yup.string().required("Champs obligatoire"),
          recipients: Yup.array().of(
            Yup.object().shape({
              lunchMenu: Yup.string().required("Champs obligatoire"),
              dinnerMenu: Yup.string(),
            })
          ),
        })
      ),
    }),
    handleSubmit: (values, { props }) => {
      const data = {
        recipients: values.datas,
        startedAt: format(values.startedAt, "yyyy-MM-dd"),
        cancelledAt: values.cancelledAt
          ? format(values.cancelledAt, "yyyy-MM-dd")
          : null,
      };

      props.validationMacro(values.client.id, data);
    },
  })
)(MultipleAdd);
