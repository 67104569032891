import { green, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
export const adminTheme = createTheme({
  palette: {
    primary: {
      main: "#e84f36",
    },
    secondary: {
      main: "#febd2f",
      contrastText: "rgba(255,255,255,0.87)",
    },
    error: {
      main: red[900],
    },
    success: {
      main: green[700],
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: "Quicksand",
  },
});
