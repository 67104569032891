import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import UseStyle from "../../Common/StyledComponent/UseStyle";
import CreditorForm from "../CreditorForm";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'un créancier"
        />
      </Card>

      <CreditorForm {...props} isEdit={false} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    address: {
      location: [0.0, 0.0],
      address: {
        unparsed: "",
      },
      notes: "",
    },
    capital: 0,
    siren: "",
    tva: "",
    sap: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .min(1)
      .max(100)
      .required("Un nom est obligatoire. Ex: Basil"),
    email: Yup.string()
      .email("L'email est invalide.")
      .required("L'email est obligatoire. Ex: hello@touva.fr"),
    address: Yup.object().nullable().required("Champs obligatoire"),
    capital: Yup.number().required("Le capital est obligatoire. Ex: 1000"),
    siren: Yup.string()
      .min(9, "Un numéro de SIREN est composé d'au minimum 9 caratères.")
      .max(9, "Un numéro de SIREN est composé d'au maximum 9 caratères.")
      .required("Le SIREN est obligatoire. Ex: 123456789"),
    tva: Yup.string()
      .min(11, "Un numéro de TVA est composé d'au minimum 11 caratères.")
      .max(11, "Un numéro de TVA est composé d'au maximum 11 caratères.")
      .required("Le TVA est obligatoire. Ex: 12345678901"),
    sap: Yup.string().required("Le TVA est obligatoire. Ex: 123456789"),
  }),
  handleSubmit: (values, { props }) => {
    const newCreditor = {
      nom: values.name,
      email: values.email,
      adresse: values.address.address.unparsed,
      capital: values.capital,
      siren: values.siren,
      tva: values.tva,
      sap: values.sap,
      users: [`/users/${props.user.id}`],
    };

    props.validation(newCreditor);
  },
})(Add);
