import React, { useMemo, useState } from "react";
import {
  LinearProgress,
  Chip,
  MenuItem,
  Select,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  useGridApiContext,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NotesIcon from "@mui/icons-material/Notes";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import PhoneCallbackOutlinedIcon from "@mui/icons-material/PhoneCallbackOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { RECIPIENT_POSITION, RECIPIENT_STATE } from "../../Common/Enums/Enums";
import { days } from "../../Common/days";

const ContactsDialog = (props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>Contacts</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {props.recipient && props.recipient.contacts.length > 0 ? (
            props.recipient.contacts.map((contact) => (
              <Grid key={contact.nomContact} item xs={12}>
                <DialogContentText>
                  <i>Nom :</i> {contact.nomContact}
                  <br />
                  <i>Tél :</i> {contact.telephoneContact}
                </DialogContentText>
              </Grid>
            ))
          ) : (
            <DialogContentText padding={2}>
              <i>Il n'y a aucun contacts.</i>
            </DialogContentText>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
const ConfirmDeleteDialog = (props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>
        Confirmez-vous la suppression de ce convive ?
        <br />
        <Typography variant="caption" fontSize={16}>
          <i>
            Attention ! Confirmez la suppression revient à supprimer toutes les
            commandes de celui-ci.
          </i>
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Annuler
        </Button>
        <Button
          onClick={() => {
            props.handleDelete(props.id);
            props.handleClose();
          }}
          color="primary"
          variant="contained"
          startIcon={<DeleteRoundedIcon />}
          autoFocus
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RecipientsListDataGrid = (props) => {
  const [recipient, setRecipient] = useState(null);
  const [isContactsOpen, setIsContactsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState({
    isOpen: false,
    id: null,
  });
  const handleOpenContacts = (value) => {
    setRecipient(value);
    setIsContactsOpen(true);
  };
  const handleCloseDialogs = () => {
    setIsContactsOpen(false);
    setIsDeleting(false);
    setRecipient(null);
  };
  const toggleConfirmDialog = (id) => {
    setIsDeleting({
      isOpen: !isDeleting.isOpen,
      id: id,
    });
  };
  const renderMealDays = (params) => {
    let value = "";
    if (params.row["@type"] === "RecipientUnit") {
      value = params.value
        .sort(function (a, b) {
          return a - b;
        })
        .map((value) => days.find((d) => d.value === value).day)
        .join(", ");
    } else {
      value = params.value
        .map((value) => {
          if (value.qty > 0) {
            const day = days.find((d) => d.value === value.day).day;
            return `${day} (x${value.qty})`;
          } else {
            return null;
          }
        })
        .filter((e) => e !== null)
        .join(", ");
    }

    return value;
  };
  const renderDiets = (params) => {
    const firstItem = params.value.slice(0, 1).shift();

    return (
      <Grid container spacing={1} justifyContent={"center"}>
        {params.value.length > 0 ? (
          <Grid item>
            <Chip size="small" label={firstItem.nom} />
          </Grid>
        ) : (
          <Grid item>
            <Tooltip title="Sans régime">
              <DoNotDisturbIcon color="disabled" />
            </Tooltip>
          </Grid>
        )}
        {params.value.length > 1 && (
          <Grid item>
            <Tooltip title={params.value.map((diet) => diet.nom).join(", ")}>
              <Chip size="small" label="..." />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  };
  const renderAllergens = (params) => {
    const firstItem = params.value.slice(0, 1).shift();

    return (
      <Grid container spacing={1} justifyContent={"center"}>
        {params.value.length > 0 && (
          <Grid item>
            <Chip size="small" label={firstItem.nom} />
          </Grid>
        )}
        {params.value.length > 1 && (
          <Grid item>
            <Tooltip
              title={params.value.map((allergen) => allergen.nom).join(", ")}
            >
              <Chip label="..." />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    );
  };
  const getFullName = (params) => {
    const gender =
      params.row.gender === "h"
        ? "M. "
        : params.row.gender === "f"
        ? "Mme. "
        : "";
    return `${gender || ""} ${params.row.name || ""}`;
  };
  const setFullName = (params) => {
    const [gender, name] = params.value.toString.split(" ");
    return { ...params.row, gender, name };
  };
  const getFormules = (params) => {
    const lunchMenuName = params.row.lunchMenu.name;
    const dinnerMenuName = params.row.dinnerMenu && params.row.dinnerMenu.name;
    const lunchExtras = params.row.lunchExtras
      .map((e) => `${e.quantity}x ${e.extra.name}`)
      .join(", ");
    const dinnerExtras = params.row.dinnerExtras
      .map((e) => `${e.quantity}x ${e.extra.name}`)
      .join(", ");
    return `${lunchMenuName || ""}${
      lunchExtras.length > 0 ? " (" + lunchExtras + ")" : ""
    }${(dinnerMenuName && " + " + dinnerMenuName) || ""}${
      dinnerMenuName && dinnerExtras.length > 0 ? " (" + dinnerExtras + ")" : ""
    }`;
  };
  const renderState = (params) => {
    return params.value === RECIPIENT_STATE.ACTIVE.status ? (
      <Tooltip title={RECIPIENT_STATE.ACTIVE.description}>
        <Chip
          size="small"
          icon={<DoneOutlinedIcon fontSize="small" />}
          color="success"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : params.value === RECIPIENT_STATE.CANCELLED.status ? (
      <Tooltip title={RECIPIENT_STATE.CANCELLED.description}>
        <Chip
          size="small"
          icon={<ClearOutlinedIcon fontSize="small" />}
          color="error"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : params.value === RECIPIENT_STATE.PAUSED.status ? (
      <Tooltip title={RECIPIENT_STATE.PAUSED.description}>
        <Chip
          size="small"
          icon={<HourglassEmptyOutlinedIcon fontSize="small" />}
          color="warning"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : (
      ""
    );
  };
  const renderSelectState = (params) => {
    return <SelectStateInputCell {...params} />;
  };
  const SelectStateInputCell = (params) => {
    const { id, value, field } = params;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
      props.handleSubmitStateFromList(params.row, event.target.value);
    };

    return (
      <Select fullWidth value={value} onChange={handleChange}>
        {Object.values(RECIPIENT_STATE).map((state, i) => {
          return (
            <MenuItem value={state.status} key={i}>
              {state.description}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const renderPosition = (params) => {
    return params.value === RECIPIENT_POSITION.LEAD.status ? (
      <Tooltip title={RECIPIENT_POSITION.LEAD.description}>
        <Chip
          size="small"
          icon={<PhoneCallbackOutlinedIcon fontSize="small" />}
          color="primary"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : params.value === RECIPIENT_POSITION.NEW.status ? (
      <Tooltip title={RECIPIENT_POSITION.NEW.description}>
        <Chip
          size="small"
          icon={<AutoAwesomeOutlinedIcon fontSize="small" />}
          color="secondary"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : params.value === RECIPIENT_POSITION.ACTIVE.status ? (
      <Tooltip title={RECIPIENT_POSITION.ACTIVE.description}>
        <Chip
          size="small"
          icon={<DoneOutlinedIcon fontSize="small" />}
          color="success"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : (
      ""
    );
  };
  const renderSelectPosition = (params) => {
    return <SelectPositionInputCell {...params} />;
  };
  const SelectPositionInputCell = (params) => {
    const { id, value, field } = params;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
      props.handleSubmitPositionFromList(params.row, event.target.value);
    };

    return (
      <Select fullWidth value={value} onChange={handleChange}>
        {Object.values(RECIPIENT_POSITION).map((state, i) => {
          return (
            <MenuItem value={state.status} key={i}>
              {state.description}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", flex: 0.5 },
      {
        field: "fullName",
        headerName: "Convive",
        flex: 2,
        valueGetter: getFullName,
        valueSetter: setFullName,
      },
      {
        field: "organization",
        headerName: "Client",
        flex: 2,
        valueGetter: ({ value }) => value.name,
      },
      {
        field: "position",
        headerName: "Statut",
        headerAlign: "center",
        align: "center",
        flex: 1,
        editable: true,
        renderCell: renderPosition,
        renderEditCell: renderSelectPosition,
      },
      {
        field: "state",
        headerName: "Prise de commande",
        headerAlign: "center",
        align: "center",
        flex: 1.5,
        editable: true,
        renderCell: renderState,
        renderEditCell: renderSelectState,
      },
      {
        field: "round",
        headerName: "Tournée",
        flex: 2,
        valueGetter: ({ value }) => value.name,
      },
      {
        headerName: "Formules",
        flex: 3,
        valueGetter: getFormules,
      },
      {
        field: "mealDays",
        headerName: "Jours Repas",
        renderCell: renderMealDays,
        flex: 2,
      },
      {
        field: "diets",
        headerName: "Régimes",
        headerAlign: "center",
        align: "center",
        renderCell: renderDiets,
        flex: 2.3,
      },
      {
        field: "allergens",
        headerName: "Allergènes",
        headerAlign: "center",
        align: "center",
        renderCell: renderAllergens,
        flex: 2,
      },
      {
        field: "createdAt",
        headerName: "MàJ le",
        type: "date",
        valueGetter: (params) =>
          params.value
            ? new Date(params.value)
            : new Date(params.row.createdAt),
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params) => [
          <GridActionsCellItem
            color="primary"
            icon={
              <Tooltip title="Plus d'infos...">
                <NotesIcon />
              </Tooltip>
            }
            onClick={() => handleOpenContacts(params.row)}
            label="Infos"
            disabled={params.row["@type"] === "RecipientGroup"}
            showInMenu
          />,
          <GridActionsCellItem
            color="primary"
            icon={
              <Tooltip title="Copier">
                <ContentCopyIcon />
              </Tooltip>
            }
            onClick={() => props.dupplicateRecipient(params.row)}
            label="Copier"
            showInMenu
          />,
          <GridActionsCellItem
            color="primary"
            icon={
              <Tooltip title="Modifier">
                <EditIcon />
              </Tooltip>
            }
            onClick={() => props.toggleEdit(params.row.id)}
            label="Modifier"
          />,
          <GridActionsCellItem
            color="error"
            icon={
              <Tooltip title="Supprimer">
                <DeleteIcon />
              </Tooltip>
            }
            onClick={() => toggleConfirmDialog(params.row.id)}
            label="Supprimer"
            showInMenu
          />,
        ],
      },
    ],
    [props.handleDelete]
  );

  return (
    <>
      <DataGrid
        autoHeight
        columns={columns}
        rows={props.recipients}
        pageSize={50}
        density="comfortable"
        disableSelectionOnClick
        pagination
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={props.loading}
        experimentalFeatures={{ newEditingApi: true }}
        getRowHeight={() => "auto"}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
      />

      <ContactsDialog
        isOpen={isContactsOpen}
        handleClose={handleCloseDialogs}
        recipient={recipient}
      />

      <ConfirmDeleteDialog
        isOpen={isDeleting.isOpen}
        id={isDeleting.id}
        handleClose={handleCloseDialogs}
        handleDelete={props.handleDelete}
      />
    </>
  );
};

export default RecipientsListDataGrid;
