import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { requests } from "../../../agent";

const AddOnContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [addOn, setAddOn] = useState(null);
  const [addOns, setAddOns] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setIsLoading(true);
    const promises = Promise.all([
      requests.get("/add_ons", true),
      requests.get("/taxes", true),
      requests.get("/subscriptions", true),
    ]);

    promises
      .then((responses) => {
        setAddOns(responses[0]["hydra:member"]);
        setTaxes(responses[1]["hydra:member"]);
        setSubscriptions(responses[2]["hydra:member"]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (id) => {
    const addOn = addOns.find((p) => p.id === id);
    if (!addOn) return;

    setAddOn(addOn);
    setEdit(true);
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setAddOn(null);
  };
  const handleSubmit = async (data) => {
    setIsLoading(true);
    requests
      .post("/add_ons", data, true)
      .then((response) => {
        setAddOns((prevAddOns) => [response, ...prevAddOns]);
        setIsLoading(false);
        toggleList();
        setAlert({
          active: true,
          type: "success",
          message: "L'extension a bien été créé !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleUpdate = async (data) => {
    setIsLoading(true);
    requests
      .patch(`/add_ons/${data.id}`, data, true)
      .then((response) => {
        setAddOns((prevAddOns) =>
          prevAddOns.map((p) => (p.id === response.id ? response : p))
        );
        setIsLoading(false);
        toggleList();
        setAlert({
          active: true,
          type: "success",
          message: "L'extension a bien été modifié !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDisable = (id) => {
    setIsLoading(true);
    requests
      .patch(`/add_ons/${id}`, { isEnabled: false }, true)
      .then((response) => {
        setAddOns((prevAddOns) =>
          prevAddOns.map((p) => (p.id === response.id ? response : p))
        );
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "L'extension a bien été désactivé !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") return;
    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const handleSubmitFilters = (data, from) => {
    setIsLoading(true);
    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.fullName !== "") dataUrl += `name=${data.fullName}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setAddOns(result["hydra:member"]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };

  return (
    <>
      {add && (
        <Add
          isLoading={isLoading}
          add={add}
          edit={edit}
          taxes={taxes}
          subscriptions={subscriptions}
          toggleList={toggleList}
          validation={handleSubmit}
          userData={userData}
        />
      )}

      {edit && (
        <Edit
          isLoading={isLoading}
          edit={edit}
          addOn={addOn}
          taxes={taxes}
          subscriptions={subscriptions}
          toggleList={toggleList}
          validation={handleUpdate}
          userData={userData}
        />
      )}

      {!add && !edit && (
        <List
          isLoading={isLoading}
          addOns={addOns}
          subscriptions={subscriptions}
          taxes={taxes}
          add={add}
          toggleAdd={toggleAdd}
          toggleEdit={toggleEdit}
          edit={edit}
          handleDisable={handleDisable}
          handleSubmitFilters={handleSubmitFilters}
          userData={userData}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AddOnContainer;
