import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import "./index.css";
import { requests } from "../../agent";
import logo from "../../assets/img/home/basil_red.svg";
import Particulier from "./particulier/Index";
import Professionnel from "./professionnel/Index";
import Footer from "./common/Footer/Footer";
import MenuBar from "./common/MenuBar/MenuBar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E84F36",
    },
    secondary: {
      main: "#FAD086",
    },
    infos: {
      main: "white",
    },
  },
  typography: {
    fontFamily: "Kanit",
  },
});

const Index = () => {
  const [formules, setFormules] = useState([]);
  const [selected, setSelected] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    setIsPageLoading(true);

    requests
      .get("/formules", true)
      .then((result) => {
        setFormules(result["hydra:member"]);
        setIsPageLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        {isPageLoading ? (
          <Stack justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <>
            <MenuBar logo={logo} />

            <Grid
              container
              flexDirection="column"
              alignItems="center"
              spacing={1}
              marginY={2}
              marginTop={7}
            >
              <Grid item>
                <img className="logo" src={logo} alt="Logo Basil Ainés" />
              </Grid>

              <Grid item>
                <Typography variant="h4" color="primary" textAlign="center">
                  Repas maison livrés <br /> chez nos ainés
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h6" textAlign="center">
                  Basil invente une nouvelle solution de portage de repas.
                </Typography>
                <Typography variant="h5" textAlign="center">
                  <strong>
                    Parce que bien manger ne devrait jamais être une option
                  </strong>
                </Typography>
              </Grid>

              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                marginTop={1}
              >
                <Grid item>
                  <Button
                    sx={{ width: "25ch" }}
                    variant={selected === 1 ? "outlined" : "contained"}
                    {...(selected === 1 && {
                      disableElevation: true,
                      disableRipple: true,
                      sx: {
                        "&:hover": {
                          backgroundColor: "white",
                          cursor: "default",
                        },
                      },
                    })}
                    color="primary"
                    onClick={() => {
                      setSelected(1);
                    }}
                  >
                    Je suis un particulier
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ width: "25ch" }}
                    variant={selected === 2 ? "outlined" : "contained"}
                    {...(selected === 2 && {
                      disableElevation: true,
                      disableRipple: true,
                      sx: {
                        "&:hover": {
                          backgroundColor: "white",
                          cursor: "default",
                        },
                      },
                    })}
                    color="primary"
                    onClick={() => {
                      setSelected(2);
                    }}
                  >
                    Je suis un professionnel
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {!isPageLoading && selected === 1 ? (
              <Particulier selected={selected} formules={formules} />
            ) : (
              <Professionnel selected={selected} formules={formules} />
            )}
          </>
        )}

        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default Index;
