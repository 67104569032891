import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import RecipientForm from "../RecipientForm";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { RECIPIENT_POSITION, RECIPIENT_STATE } from "../../Common/Enums/Enums";
import { format } from "date-fns";
import { isAdmin } from "../../../../utils";

const Add = (props) => {
  const classes = UseStyle();
  const [client, setClient] = useState(
    !isAdmin(props.userData)
      ? props.userData.organization
      : props.recipientToDupplicate
      ? props.recipientToDupplicate.client
      : null
  );
  const [selectedClient, setSelectedClient] = useState(
    !isAdmin(props.userData)
      ? { user: props.userData }
      : props.recipientToDupplicate
      ? { user: props.recipientToDupplicate.client }
      : null
  );

  const clients =
    props.clients.length > 0 &&
    props.clients
      .filter((c) => c.mode === "repertory")
      .map((c) => {
        const filtre = c.name[0].toUpperCase();
        return {
          filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
          client: c,
        };
      });

  return (
    <form>
      <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Button
            variant={"contained"}
            onClick={props.toggleList}
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Retour
          </Button>
        </Stack>

        <Card className={classes.card}>
          <CardHeader
            subheader="Ces informations peuvent être modifier."
            title="Création d'un convive"
          />
        </Card>

        {isAdmin(props.userData) && (
          <Paper className={classes.paper} elevation={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={clients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.client.id === value.client.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.client) return "";
                    return option.client.id + " - " + option.client.name;
                  }}
                  value={selectedClient}
                  onChange={(event, value) => {
                    setSelectedClient(value);

                    if (value !== null) {
                      props.setFieldValue("client", value.client.id);
                      setClient(value.client);
                    } else {
                      props.setFieldValue("client", null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      error={
                        props.touched.recipient &&
                        Boolean(props.errors.recipient)
                      }
                      helperText={
                        props.touched.recipient &&
                        props.errors.recipient &&
                        props.errors.recipient
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        )}

        {client && props.values.client && (
          <Paper className={classes.paper} elevation={6}>
            <RecipientForm {...props} client={client} />

            <Stack direction={"row"} justifyContent={"flex-end"} paddingTop={2}>
              {props.loading ? (
                <CircularProgress color="secondary" />
              ) : (
                <Button
                  onClick={props.handleSubmit}
                  variant="contained"
                  startIcon={<SaveRoundedIcon />}
                >
                  Créer
                </Button>
              )}
            </Stack>
          </Paper>
        )}
      </Stack>
    </form>
  );
};
export default withFormik({
  mapPropsToValues: (props) => ({
    client: !isAdmin(props.userData)
      ? props.userData.organization.id
      : props.recipientToDupplicate
      ? props.recipientToDupplicate.organization.id
      : null,
    gender: props?.recipientToDupplicate?.gender ?? "",
    name: props?.recipientToDupplicate?.name ?? "",
    email: props?.recipientToDupplicate?.email ?? "",
    address: {
      latitude: 0.0,
      longitude: 0.0,
      number: "",
      street: "",
      city: "",
      locality: "",
      country: "",
      postalCode: "",
      unparsed: "",
      notes: "",
    },
    birthdate: props?.recipientToDupplicate?.birthdate ?? null,
    contacts: props?.recipientToDupplicate?.contact ?? [],
    startedAt: new Date(),
    cancelledAt: null,
    recipientNote: props?.recipientToDupplicate?.recipientNote ?? "",
    adminNote: props?.recipientToDupplicate?.adminNote ?? "",
    origin: props?.recipientToDupplicate?.origin?.id ?? "none",
    state: RECIPIENT_STATE.ACTIVE.status,
    position: RECIPIENT_POSITION.LEAD.status,
    round: props?.recipientToDupplicate?.round.id ?? "",
    lunchMenu: props?.recipientToDupplicate?.lunchMenu.id ?? "",
    dinnerMenu: props?.recipientToDupplicate?.dinnerMenu?.id ?? "none",
    mealDays: props?.recipientToDupplicate?.mealDays ?? [],
    diets: props?.recipientToDupplicate?.diets.map((d) => d.id) ?? [],
    dietNote: props?.recipientToDupplicate?.dietNote ?? "",
    allergens: props?.recipientToDupplicate?.allergens.map((a) => a.id) ?? [],
    hasNewsletterSubscribed:
      props?.recipientToDupplicate?.hasNewsletterSubscribed ?? false,
    lunchExtras:
      props?.recipientToDupplicate?.lunchExtras.map((l) => l.extra.id) ?? [],
    dinnerExtras:
      props?.recipientToDupplicate?.dinnerExtras.map((d) => d.extra.id) ?? [],
    billingAddress: {
      latitude: 0.0,
      longitude: 0.0,
      number: "",
      street: "",
      city: "",
      locality: "",
      country: "",
      postalCode: "",
      unparsed: "",
      notes: "",
    },
    billingEmail: props?.recipientToDupplicate?.billingEmail ?? "",
    rank: null,
    wantsInvoicesByEmail: true,
  }),
  validationSchema: Yup.object().shape({
    client: Yup.number().positive().required("Champs obligatoire"),
    gender: Yup.string().length(1).required("Champs obligatoire"),
    name: Yup.string().required("Champs obligatoire"),
    email: Yup.string()
      .email("Veuillez saisir une email valide. Ex: hello@touva.fr ")
      .required("Champs obligatoire"),
    birthdate: Yup.date().nullable(),
    address: Yup.object()
      .shape({
        latitude: Yup.number().required("Champs obligatoire"),
        longitude: Yup.number().required("Champs obligatoire"),
        number: Yup.string().nullable(),
        street: Yup.string().required(),
        city: Yup.string().required(),
        locality: Yup.string().required(),
        country: Yup.string().required(),
        postalCode: Yup.string().nullable(),
        unparsed: Yup.string().required(
          "Sélectionner une adresse dans la liste déroulante."
        ),
        latitude: Yup.number().required(),
        longitude: Yup.number().required(),
        notes: Yup.string().nullable(),
      })
      .required("Champs obligatoire"),
    contacts: Yup.array().of(
      Yup.object().shape({
        nomContact: Yup.string().required("Champs obligatoire").matches(/^[^:]+$/, "Le nom du contact ne doit pas contenir le caractère :"),
        telephoneContact: Yup.string()
          .required("Champs obligatoire")
          .test(
            "len",
            "Veuillez saisir un numéro de téléphone valide",
            (tel) => tel && tel.toString().length === 10
          ),
      })
    ),
    startedAt: Yup.date(),
    cancelledAt: Yup.date().notRequired().nullable(),
    round: Yup.number().positive().required("Champs obligatoire"),
    lunchMenu: Yup.number().positive().required("Champs obligatoire"),
    mealDays: Yup.array().of(Yup.number()),
    billingAddress: Yup.object()
      .shape({
        latitude: Yup.number().required("Champs obligatoire"),
        longitude: Yup.number().required("Champs obligatoire"),
        number: Yup.string().nullable(),
        street: Yup.string().required(),
        city: Yup.string().required(),
        locality: Yup.string().required(),
        country: Yup.string().required(),
        postalCode: Yup.string().nullable(),
        unparsed: Yup.string().required(
          "Sélectionner une adresse dans la liste déroulante."
        ),
        latitude: Yup.number().required(),
        longitude: Yup.number().required(),
        notes: Yup.string().nullable(),
      })
      .required("Champs obligatoire"),
    billingEmail: Yup.string()
      .email("Veuillez saisir une email valide. Ex: hello@touva.fr ")
      .required("Champs obligatoire"),
    rank: Yup.number().positive().nullable(),
    wantsInvoicesByEmail: Yup.boolean(),
  }),
  handleSubmit: (values, { props }) => {
    const recipient = {
      organization: `/organizations/${values.client}`,
      gender: values.gender ? values.gender : null,
      name: values.name,
      email: values.email,
      address: {
        ...values.address,
        latitude: parseFloat(values.address.latitude),
        longitude: parseFloat(values.address.longitude)
      },
      birthdate: values.birthdate
        ? format(values.birthdate, "yyyy-MM-dd")
        : null,
      contacts: values.contacts,
      startedAt: format(values.startedAt, "yyyy-MM-dd"),
      cancelledAt: values.cancelledAt
        ? format(values.cancelledAt, "yyyy-MM-dd")
        : null,
      recipientNote: values.recipientNote ? values.recipientNote : null,
      adminNote: values.adminNote ? values.adminNote : null,
      origin: values.origin !== "none" ? `/origins/${values.origin}` : null,
      state: values.state,
      position: values.position,
      round: `/rounds/${values.round}`,
      lunchMenu: `/formules/${values.lunchMenu}`,
      dinnerMenu:
        values.dinnerMenu !== "none" ? `/formules/${values.dinnerMenu}` : null,
      mealDays: values.mealDays,
      diets: values.diets.map((d) => `/regimes/${d}`),
      dietNote: values.dietNote ? values.dietNote : null,
      allergens: values.allergens.map((a) => `/allergenes/${a}`),
      hasNewsletterSubscribed: values.hasNewsletterSubscribed,
      lunchExtras: values.lunchExtras.map((extra) => ({
        extra: `/extra_categories/${extra}`,
        quantity: 1,
      })),
      dinnerExtras: values.dinnerExtras.map((extra) => ({
        extra: `/extra_categories/${extra}`,
        quantity: 1,
      })),
      billingAddress: values.billingAddress,
      billingEmail: values.billingEmail,
      rank: values.rank !== "" ? values.rank : null,
      wantsInvoicesByEmail: values.wantsInvoicesByEmail,
    };

    props.validation(recipient, "units");
  },
})(Add);
