import React, { useState } from "react";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import Filters from "../../Common/Filters";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import { locale, mask } from "../../../../localisation";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { isAdmin } from "../../../../utils";

const AddDialog = ({
  loading,
  toggleAdd,
  handleClose,
  handleSubmitAdd,
  recipients,
}) => {
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  const formik = useFormik({
    initialValues: {
      recipient: null,
      startDate: new Date(),
      endDate: null,
    },
    validationSchema: Yup.object({
      recipient: Yup.number()
        .positive()
        .required("Champs obligatoire")
        .typeError("Champs obligatoire"),
      startDate: Yup.date().required().typeError("Date invalide"),
      endDate: Yup.date().required().typeError("Date invalide"),
    }),
    onSubmit: (values) => {
      handleSubmitAdd(values.recipient, values.startDate, values.endDate);
      handleClose();
    },
  });

  return (
    <Dialog open={toggleAdd} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        Gérer l'absence d'un convive.
        <DialogContentText>
          Rentrer les dates pendant lequel le convive sera absent et son statut
          sera modifié automatiquement jusqu'à la date de son retour.
        </DialogContentText>
        <DialogContentText>Chaque champs est obligatoire.</DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={
                  formik.errors.recipient && formik.touched.recipient
                    ? true
                    : false
                }
              >
                <Autocomplete
                  options={recipients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.recipient.id === value.recipient.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.recipient) return "";
                    return option.recipient.id + " - " + option.recipient.name;
                  }}
                  value={selectedRecipient}
                  onChange={(event, value) => {
                    setSelectedRecipient(value);

                    value !== null
                      ? formik.setFieldValue("recipient", value.recipient.id)
                      : formik.setFieldValue("recipient", null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Convive"
                      error={
                        formik.errors.recipient && formik.touched.recipient
                          ? true
                          : false
                      }
                    />
                  )}
                />
                <FormHelperText error>
                  {formik.errors.recipient &&
                    formik.touched.recipient &&
                    formik.errors.recipient}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth required>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de début"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.startDate}
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue("startDate", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        error={
                          formik.errors.startDate && formik.touched.startDate
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </LocalizationProvider>

                <FormHelperText error>
                  {formik.errors.startDate &&
                    formik.touched.startDate &&
                    formik.errors.startDate}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth required>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de fin"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.endDate}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("endDate", value)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        error={
                          formik.errors.endDate && formik.touched.endDate
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </LocalizationProvider>

                <FormHelperText error>
                  {formik.errors.endDate &&
                    formik.touched.endDate &&
                    formik.errors.endDate}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Valider
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};

const List = (props) => {
  const classes = UseStyle();
  const [toggleAdd, setToggleAdd] = useState(false);
  const handleOpenAbsence = () => {
    setToggleAdd(true);
  };
  const handleClose = () => {
    setToggleAdd(false);
  };
  const recipients = props.recipients.map((recipient) => {
    const filtre = recipient.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      recipient: recipient,
    };
  });
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "recipient",
      headerName: "Convive",
      flex: 1.5,
      valueGetter: ({ value }) => `${value.name}`,
    },
    {
      field: "isActive",
      headerName: "Active",
      type: "boolean",
      flex: 0.5,
    },
    {
      field: "beginAt",
      headerName: "Débute le",
      type: "date",
      flex: 1,
      valueGetter: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
    },
    {
      field: "endAt",
      headerName: "Termine le",
      type: "date",
      flex: 1,
      valueGetter: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
    },
    {
      field: "createdAt",
      headerName: "Créé le",
      type: "datetime",
      flex: 1,
      valueGetter: ({ value }) => format(new Date(value), "dd/MM/yyyy HH:mm"),
    },
    {
      field: "updatedAt",
      headerName: "MàJ le",
      type: "datetime",
      flex: 1,
      valueGetter: ({ value }) =>
        value && format(new Date(value), "dd/MM/yyyy HH:mm"),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Supprimer">
              <DeleteIcon color="error" />
            </Tooltip>
          }
          onClick={() => props.handleDelete(params.id)}
          label="Supprimer"
        />,
      ],
    },
  ];

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        spacing={1}
        paddingBottom={2}
      >
        {isAdmin(props.userData) && (
          <>
            <Button disabled>Import</Button>
            <Button disabled>Export</Button>
          </>
        )}
        <LoadingButton
          loading={props.loading}
          color="secondary"
          variant="contained"
          onClick={handleOpenAbsence}
          startIcon={<AddIcon />}
        >
          Absence
        </LoadingButton>
      </Stack>

      <Stack spacing={2}>
        <Filters
          types={["recipient"]}
          from="absences"
          recipients={props.recipients}
          handleSubmitFilters={props.handleSubmitFilters}
          userData={props.userData}
          loading={props.loading}
        />

        <Paper className={classes.paper} elevation={6}>
          <Grid container sx={{ height: "70vh", width: "100%" }}>
            <DataGrid
              columns={columns}
              rows={props.absences}
              density="comfortable"
              disableSelectionOnClick
              autoPageSize
              pagination
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              loading={props.loading}
            />
          </Grid>
        </Paper>
      </Stack>

      <AddDialog
        toggleAdd={toggleAdd}
        handleClose={handleClose}
        loading={props.loading}
        handleSubmitAdd={props.handleSubmit}
        recipients={recipients}
      />
    </>
  );
};

export default List;
