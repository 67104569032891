import React, { useState } from "react";
import { format } from "date-fns";
import { ORDER_STATUS, DELIVERY_STATUS } from "../../../Common/Enums/Enums";
import OrderCard from "./OrderCard";
import PlacesAutocomplete from "../../../Common/PlacesAutocomplete/PlacesAutocomplete";
import {
  Paper,
  Grid,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Typography,
  Stack,
  IconButton,
  TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import UseStyle from "../../../Common/StyledComponent/UseStyle";
import { isAdmin } from "../../../../../utils";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const DeliveryCard = (props) => {
  const classes = UseStyle();
  const [isEdit, setIsEdit] = useState(false);
  const [newAddress, setNewAddress] = useState(props.delivery.address);

  return (
    <Grid
      container
      spacing={2}
      direction={"column"}
      alignItems="stretch"
      sx={{ padding: 3 }}
    >
      <Grid item>
        <Paper className={classes.paper} elevation={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                <Typography variant="body1">
                  <strong>Client :</strong> {props.delivery.organization.name}
                </Typography>

                {props.delivery.organization.mode === "diets" ? (
                  <Typography variant="body1" gutterBottom>
                    {" "}
                    <strong>Téléphone :</strong>{" "}
                    {props.delivery.organization.phone}
                  </Typography>
                ) : (
                  props.delivery.recipient &&
                  props.delivery.recipient.contacts.map((c, i) => (
                    <Typography key={i} variant="body1" gutterBottom>
                      {" "}
                      <strong>Contact #{i + 1} : </strong>
                      {c.nomContact} / {c.telephoneContact}
                    </Typography>
                  ))
                )}
              </Grid>

              <Grid item>
                <Typography variant="body1">
                  <strong>Livraison du : </strong>{" "}
                  {format(new Date(props.delivery.date), "dd/MM/yyyy")}
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Tournée : </strong> {props.delivery.round.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={2}>
                <Typography variant="body1">
                  <strong>Adresse : </strong>
                </Typography>
              </Grid>

              <Grid item xs={10}>
                <Stack direction={"row"} spacing={1} alignItems="center">
                  {isEdit ? (
                    <>
                      <PlacesAutocomplete
                        fullWidth
                        query={newAddress.unparsed}
                        label="Adresse de livraison"
                        size="small"
                        callback={(data) => {
                          setNewAddress(data);
                        }}
                      />

                      <Tooltip title="Valider">
                        <IconButton
                          aria-label="done"
                          color="success"
                          size="small"
                          onClick={() => {
                            props.handleUpdateDelivery(props.delivery.id, {
                              address: newAddress,
                            });
                            setIsEdit(false);
                            setNewAddress("");
                          }}
                        >
                          <DoneIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Annuler">
                        <IconButton
                          aria-label="clear"
                          color="error"
                          size="small"
                          onClick={() => {
                            setIsEdit(false);
                            setNewAddress("");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Typography variant="body1">
                        {props.delivery.address.unparsed}
                      </Typography>

                      <Tooltip title="Modifier">
                        <IconButton
                          aria-label="edit"
                          color="primary"
                          size="small"
                          onClick={() => setIsEdit(true)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Stack>
              </Grid>
            </Grid>

            {(isEdit ||
              (props.delivery.address.addressSupplement &&
                props.delivery.address.addressSupplement.length > 0)) && (
              <Grid item xs={12} container alignItems="center">
                <Grid item xs={2}>
                  <Typography variant="body1">
                    <strong>Compléments d'adresse :</strong>
                  </Typography>
                </Grid>

                <Grid item xs={10}>
                  {isEdit ? (
                    <TextField
                      fullWidth
                      size="small"
                      name={"infos"}
                      label={"Compléments d'adresse"}
                      value={newAddress?.addressSupplement ?? ""}
                      onChange={(event) =>
                        setNewAddress({
                          ...newAddress,
                          addressSupplement: event.target.value,
                        })
                      }
                    />
                  ) : (
                    <Typography variant="body1">
                      {props.delivery.address.addressSupplement}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}

            {(isEdit ||
              (props.delivery.address.notes &&
                props.delivery.address.notes.length > 0)) && (
              <Grid item xs={12} container alignItems="center">
                <Grid item xs={2}>
                  <Typography variant="body1">
                    <strong>Note de livraison :</strong>
                  </Typography>
                </Grid>

                <Grid item xs={10}>
                  {isEdit ? (
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      size="small"
                      name={"cplts"}
                      label={"Compléments d'adresse"}
                      value={newAddress?.notes ?? ""}
                      onChange={(event) =>
                        setNewAddress({
                          ...newAddress,
                          notes: event.target.value,
                        })
                      }
                    />
                  ) : (
                    <Typography variant="body1">
                      {props.delivery.address.notes}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid item container spacing={1}>
              {(props.delivery.organization.mode === "diets"
                ? props.delivery.organization.organizationNote
                : props.delivery.recipient &&
                  props.delivery.recipient.recipientNote) && (
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Note client :</strong> <br />
                    {props.delivery?.recipient?.recipientNote ??
                      props.delivery.organization.organizationNote}
                  </Typography>
                </Grid>
              )}
              {(props.delivery.organization.mode === "diets"
                ? props.delivery.organization.adminNote
                : props.delivery.recipient &&
                  props.delivery.recipient.adminNote) && (
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Note admin :</strong> <br />
                    {props.delivery?.recipient?.adminNote ??
                      props.delivery.organization.adminNote}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {isAdmin(props.userData) && (
        <Grid item container spacing={2} alignItems={"center"}>
          <Grid item xs={6} md={3}>
            <Tooltip title="Modifier le statut de toutes les commandes à prêt">
              <Button
                {...(props.delivery.commandes.every(
                  (commande) =>
                    commande.statut === ORDER_STATUS.PREPARED.status ||
                    commande.statut === ORDER_STATUS.DELIVERING.status ||
                    commande.statut === ORDER_STATUS.DELIVERED.status ||
                    commande.statut === ORDER_STATUS.CANCELLED.status
                )
                  ? { disabled: true }
                  : null)}
                fullWidth
                color="success"
                variant="contained"
                endIcon={<CheckIcon />}
                onClick={(event) => {
                  let ids = props.delivery.commandes
                    .filter((c) => c.statut !== ORDER_STATUS.CANCELLED.status)
                    .map((order) => order.id);
                  props.handleUpdateOrders(event, ids);
                }}
              >
                Commandes prêtes
              </Button>
            </Tooltip>
          </Grid>

          <Grid item xs={6} md={3}>
            <Tooltip title="Modifier le statut de la livraison à prêt">
              <Button
                {...((props.delivery.commandes.every(
                  (order) => order.statut === ORDER_STATUS.PREPARED.status
                ) &&
                  props.delivery.status ===
                    DELIVERY_STATUS.PENDING_PREP.status) ||
                (props.delivery.status !==
                  DELIVERY_STATUS.PENDING_DELIV.status &&
                  props.delivery.status !== DELIVERY_STATUS.DELIVERING.status &&
                  props.delivery.status !== DELIVERY_STATUS.DELIVERED.status)
                  ? null
                  : { disabled: true })}
                fullWidth
                color="success"
                variant="contained"
                endIcon={<RocketLaunchIcon />}
                onClick={() =>
                  props.handleUpdateDelivery(props.delivery.id, {
                    status: "pending_deliv",
                  })
                }
              >
                Livraison prête
              </Button>
            </Tooltip>
          </Grid>

          <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="deliveryStatus">
                Statut de la livraison
              </InputLabel>
              <Select
                labelId="deliveryStatus"
                id="deliveryStatus"
                label="Statut de la livraison"
                value={props.delivery.status}
                onChange={(event) => {
                  props.handleUpdateDelivery(props.delivery.id, {
                    status: event.target.value,
                  });
                }}
                sx={{ zIndex: "tooltip" }}
              >
                {Object.values(DELIVERY_STATUS).map((s) => (
                  <MenuItem key={s.status} value={s.status}>
                    {s.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} md={3}>
            <LoadingButton
              loading={props.isLoading}
              fullWidth
              color="secondary"
              variant="contained"
              endIcon={<DownloadIcon />}
              onClick={() =>
                props.handleDownloadDeliveryNote(props.delivery.id)
              }
            >
              Bon de préparation
            </LoadingButton>
          </Grid>
        </Grid>
      )}

      <Grid item container spacing={3} direction={"column"}>
        {props.delivery.commandes
          .sort((a, b) => a.id - b.id)
          .map((order, i) => (
            <OrderCard
              key={i}
              userData={props.userData}
              index={order.id}
              order={order}
              diets={order?.recipient?.diets ?? []}
              handleUpdateOrder={props.handleUpdateOrder}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default DeliveryCard;
