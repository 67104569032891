import { LinearProgress } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import React from "react";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";

const Table = (props) => {
  return (
    <DataGrid
      autoHeight
      loading={props.loading}
      columns={props.columns}
      rows={props.rows}
      rowCount={props.rowCount}
      rowsPerPageOptions={[50]}
      pagination
      page={props.page}
      pageSize={props.pageSize}
      paginationMode="server"
      onPageChange={(newPage) => props.setPage(newPage)}
      onPageSizeChange={(newPageSize) => props.setPageSize(newPageSize)}
      density="comfortable"
      checkboxSelection
      onSelectionModelChange={(value) => props.setSelectedOrders(value)}
      selectionModel={props.selectedOrders}
      disableSelectionOnClick
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "date", sort: "desc" }],
        },
      }}
      experimentalFeatures={{ newEditingApi: true }}
      getRowHeight={() => "auto"}
      sx={{
        [`& .${gridClasses.cell}`]: {
          py: 1,
        },
      }}
    />
  );
};

export default Table;
