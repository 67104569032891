import {
  Card,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import React from "react";
import { toFixedTrunc } from "../../../admin/Common/toFixedTrunc";
import { CardContentNoPadding } from "../../common/Formules/Formules";

const Formules = (props) => {
  const lunchMenus = props.formules
    .map((formule) => ({
      ...formule,
      composition: formule.composition.sort(
        (a, b) => b.categorie.id - a.categorie.id
      ),
    }))
    .filter((formule) => !formule.estDiner && formule.name.includes("PANDO"));
  const dinnerMenus = props.formules.filter(
    (formule) => formule.estDiner && formule.name.includes("PANDO")
  );

  return (
    <Grid container spacing={5} flexDirection="column" alignItems="center">
      <Grid item container flexDirection="column">
        <Grid item>
          <Typography variant="h6" textAlign="center" gutterBottom>
            <strong>Les Déjeuners Basil pour nos ainés</strong>
          </Typography>
        </Grid>

        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {lunchMenus.map((formule, i) => (
            <Grid key={formule.id} item>
              <Card
                variant="outlined"
                sx={{
                  width: { xs: 295, md: 345 },
                  boxShadow: `0 7px 12px rgb(${props.colors[i].rgb} / 0.3)`,
                  borderRadius: "21px",
                }}
              >
                <CardHeader
                  title={
                    <strong>
                      {toFixedTrunc(formule.priceIncludingTax, 2) + "€ TTC"}
                    </strong>
                  }
                  sx={{
                    textAlign: "center",
                    backgroundColor: props.colors[i].hex,
                  }}
                />
                <CardContentNoPadding>
                  <List
                    style={{
                      maxHeight: "100%",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    {formule.composition.map((c) => (
                      <ListItem disablePadding key={c.categorie.id}>
                        <ListItemButton>
                          <TaskAltRoundedIcon color="secondary" />
                          <ListItemText
                            primary={
                              c.categorie.nom +
                              (c.categorie.nom === "Plats" ? " (au choix)" : "")
                            }
                            sx={{ paddingLeft: 3 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </CardContentNoPadding>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item container flexDirection="column">
        <Grid item>
          <Typography variant="h6" textAlign="center" gutterBottom>
            <strong>Les Dîners Basil pour nos ainés</strong>
          </Typography>
        </Grid>

        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {dinnerMenus.map((formule) => (
            <Grid key={formule.id} item>
              <Card
                variant="outlined"
                sx={{
                  width: { xs: 295, md: 345 },
                  boxShadow: `0 7px 12px rgb(${props.colors[3].rgb} / 0.3)`,
                  borderRadius: "21px",
                }}
              >
                <CardHeader
                  title={
                    <strong>
                      {toFixedTrunc(formule.priceIncludingTax, 2) + "€ TTC"}
                    </strong>
                  }
                  sx={{
                    textAlign: "center",
                    backgroundColor: props.colors[3].hex,
                  }}
                />
                <CardContentNoPadding>
                  <List
                    style={{
                      maxHeight: "100%",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    {formule.composition.map((c) => (
                      <ListItem disablePadding key={c.categorie.id}>
                        <ListItemButton>
                          <TaskAltRoundedIcon color="secondary" />
                          <ListItemText
                            primary={
                              c.categorie.nom +
                              (c.categorie.nom === "Plats" ? " (au choix)" : "")
                            }
                            sx={{ paddingLeft: 3 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </CardContentNoPadding>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item container justifyContent="end" alignItems="end">
        <Typography
          variant="body1"
          sx={{
            border: "1px solid white",
            borderRadius: "21px",
            padding: 2,
            background: "rgba(249, 249, 249)",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
          }}
        >
          <strong>Les petits +</strong>
          <br />- Petit pain Bio & solidaire 0,50€
          <br />- Laitage bio ou Fromage 0,50€
          <br />- Potage 1,50€
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Formules;
