import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { requests } from "../agent";
import { PURGE } from "redux-persist";

export const login = createAsyncThunk(
  "auth/connexion",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await requests.post(
        "/authentication_token",
        { email, password },
        false
      );
      return response;
    } catch (err) {
      return err.code === 401
        ? rejectWithValue({
            ...err,
            message: "L'email et/ou le mot de passe est incorrect.",
          })
        : rejectWithValue(err);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async ({ hasSessionExpired }, { rejectWithValue }) => {
    try {
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      if (hasSessionExpired)
        return {
          message: "Votre session a expiré. Veuillez vous reconnecter.",
        };
      else return { message: null };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await requests.get(`/users/${id}`, false);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  isAuthenticated: false,
  userData: null,
  isLoginLoading: false,
  loginError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [PURGE]: (state) => {
      state.isLoginLoading = false;
      state.loginError = null;
      state.isAuthenticated = false;
      state.userData = null;
    },
    [login.pending]: (state) => {
      state.isLoginLoading = true;
      state.loginError = null;
      state.isAuthenticated = false;
      state.userData = null;
    },
    [login.fulfilled]: (state, action) => {
      const { payload } = action;
      state.isAuthenticated = true;
      state.loginError = null;
      state.isLoginLoading = false;
      state.userData = payload.data;
    },
    [login.rejected]: (state, action) => {
      const { payload } = action;
      state.isAuthenticated = false;
      state.userData = null;
      state.loginError = payload.message;
      state.isLoginLoading = false;
    },
    [logout.fulfilled]: (state, action) => {
      const { payload } = action;
      state.loginError = payload.message;
      state.isAuthenticated = false;
      state.userData = null;
    },
    [fetchUserData.fulfilled]: (state, action) => {
      const { payload } = action;
      state.userData = payload;
    },
  },
});

export default authSlice.reducer;
