import React, { useEffect, useState } from "react";
import { requests } from "../../../agent";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { Snackbar, Alert } from "@mui/material";

const CategoryContainer = (props) => {
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryEdit, setCategoryEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPageLoading, setisPageLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setisPageLoading(true);

    requests
      .get("/categories", true)
      .then((result) => {
        setCategories(result["hydra:member"]);
        setisPageLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    setCategoryEdit(category);
    setEdit(!edit);
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setCategoryEdit(null);
  };
  const handleSubmit = (name, description) => {
    setLoading(true);

    const newCategory = {
      nom: name,
      description,
      priorite: categories.length,
    };

    requests
      .post("/categories", newCategory, true)
      .then((response) => {
        const newCategoriesList = [...categories];
        newCategoriesList.unshift(response);
        setCategories(newCategoriesList);
        setAdd(false);
        setAlert({
          active: true,
          type: "success",
          message: "La catégorie a bien été créé !",
        });
        setLoading(false);
      })
      .catch((err) => {
        setAdd(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
        setLoading(false);
      });
  };
  const handleSubmitEdit = (id, nom, description) => {
    setLoading(true);
    requests
      .patch(`/categories/${id}`, { nom, description }, true)
      .then((response) => {
        const newCategoriesList = categories.map((c) => {
          if (c.id !== response.id) {
            return c;
          }

          return response;
        });
        setCategories(newCategoriesList);
        setEdit(false);
        setCategoryEdit(null);
        setAlert({
          active: true,
          type: "success",
          message: "La catégorie a bien été modifié !",
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitFilters = (data, from) => {
    setLoading(true);
    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.lastname !== "") dataUrl += `nom=${data.lastname}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setCategories(result["hydra:member"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      {add && (
        <Add
          add={add}
          categories={categories}
          validation={handleSubmit}
          toggleList={toggleList}
          loading={loading}
          alert={alert}
        />
      )}

      {edit && (
        <Edit
          edit={edit}
          categoryEdit={categoryEdit}
          categories={categories}
          validation={handleSubmitEdit}
          toggleList={toggleList}
          loading={loading}
          alert={alert}
        />
      )}

      {!edit && !add && (
        <List
          edit={edit}
          add={add}
          categories={categories}
          loading={loading}
          isPageLoading={isPageLoading}
          alert={alert}
          toggleEdit={toggleEdit}
          toggleAdd={toggleAdd}
          handleSubmitFilters={handleSubmitFilters}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CategoryContainer;
