import React from "react";
import {
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormHelperText,
  ListItemText,
} from "@mui/material";
import PlacesAutocomplete from "../../Common/PlacesAutocomplete/PlacesAutocomplete";
import { isAnyAdmin } from "../../../../utils";

const Informations = (props) => {
  let rounds = props.rounds;
  if (!isAnyAdmin(props.userData)) {
    rounds = rounds.filter((round) => round.organization === null);
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={6}>
        <TextField
          required
          fullWidth
          name="name"
          label="Nom"
          type="text"
          value={props.values.name}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          error={props.touched.name && Boolean(props.errors.name)}
          helperText={
            props.errors.name && props.touched.name && props.errors.name
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          required
          fullWidth
          name="email"
          label="Adresse email"
          value={props.values.email}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          error={props.touched.email && Boolean(props.errors.email)}
          helperText={
            props.errors.email && props.touched.email && props.errors.email
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          required
          fullWidth
          name="phone"
          label="Téléphone"
          type="tel"
          value={props.values.phone}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          error={props.touched.phone && Boolean(props.errors.phone)}
          helperText={
            props.errors.phone && props.touched.phone && props.errors.phone
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Mode de gestion</InputLabel>
          <Select
            label="Mode de gestion"
            name="mode"
            value={props.values.mode}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={props.touched.mode && Boolean(props.errors.mode)}
          >
            <MenuItem value={"repertory"}>Répertoire</MenuItem>
            <MenuItem value={"diets"}>Régimes</MenuItem>
          </Select>
          <FormHelperText error>
            {props.errors.mode && props.touched.mode && props.errors.mode}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <PlacesAutocomplete
          query={props.values.address.unparsed}
          label="Adresse postale"
          fullWidth={true}
          callback={(data) => {
            data.notes = props.values.address.notes;
            props.setFieldValue("address", data);
          }}
          error={props.touched.address && Boolean(props.errors.address)}
          helpertext={
            props.errors.address &&
            props.touched.address &&
            props.errors.address &&
            props.errors.address.unparsed
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="address.addressSupplement"
          label="Compléments d'adresse"
          value={props.values.address?.addressSupplement}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={2}
          name="address.notes"
          label={"Note de livraison"}
          value={props.values.address?.notes}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          name={"organizationNote"}
          label={"Note client"}
          value={props.values.organizationNote}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          name={"adminNote"}
          label={"Note admin"}
          value={props.values.adminNote}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <InputLabel>Formules</InputLabel>
          <Select
            multiple
            name="packages"
            label="Formules"
            value={props.values.packages}
            onChange={props.handleChange}
            renderValue={(selected) =>
              selected
                .map(
                  (id) =>
                    props.formules.find((formule) => formule.id === id).name
                )
                .join(", ")
            }
            error={props.touched.packages && Boolean(props.errors.packages)}
          >
            {props.formules.map((formule, i) => {
              return (
                <MenuItem value={formule.id} key={i}>
                  <Checkbox
                    checked={props.values.packages.indexOf(formule.id) > -1}
                  />
                  <ListItemText primary={formule.name} />
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText error>
            {props.errors.packages &&
              props.touched.packages &&
              props.errors.packages}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item md={6} xs={12}>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <InputLabel>Tournées</InputLabel>
          <Select
            multiple
            name="rounds"
            label="Tournées"
            value={props.values.rounds}
            onChange={props.handleChange}
            renderValue={(selected) =>
              selected
                .map((id) => props.rounds.find((round) => round.id === id).name)
                .join(", ")
            }
            error={props.touched.rounds && Boolean(props.errors.rounds)}
          >
            {rounds.map((round, i) => {
              return (
                <MenuItem value={round.id} key={i}>
                  <Checkbox
                    checked={props.values.rounds.indexOf(round.id) > -1}
                  />
                  <ListItemText primary={round.name} />
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText error>
            {props.errors.rounds && props.touched.rounds && props.errors.rounds}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item md={6} xs={12}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Créanciers</InputLabel>
          <Select
            multiple
            name={"creditors"}
            label="Créanciers"
            value={props.values.creditors}
            onChange={props.handleChange}
            renderValue={(selected) =>
              selected
                .map(
                  (id) =>
                    props.creditors.find((creditor) => creditor.id === id).nom
                )
                .join(", ")
            }
            error={props.touched.creditors && Boolean(props.errors.creditors)}
          >
            {props.creditors.map((creditor, index) => (
              <MenuItem key={index} value={creditor.id}>
                <Checkbox
                  checked={props.values.creditors.indexOf(creditor.id) > -1}
                />
                <ListItemText primary={creditor.nom} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {props.errors.creditors &&
              props.touched.creditors &&
              props.errors.creditors}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item md={6} xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Origine</InputLabel>
          <Select
            name={"origin"}
            label="Origine"
            value={props.values.origin}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          >
            <MenuItem value="none">
              <i>Aucune</i>
            </MenuItem>
            {props.origins.map((origin, index) => (
              <MenuItem key={index} value={origin.id}>
                {origin.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <PlacesAutocomplete
          query={props.values.billingAddress.unparsed}
          label="Adresse de facturation"
          fullWidth={true}
          callback={(data) => {
            data.notes = props.values.billingAddress.notes;
            props.setFieldValue("billingAddress", data);
          }}
          error={
            props.touched.billingAddress && Boolean(props.errors.billingAddress)
          }
          helpertext={
            props.errors.billingAddress &&
            props.touched.billingAddress &&
            props.errors.billingAddress &&
            props.errors.billingAddress.unparsed
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            name={"billingEmail"}
            label={"Email de facturation"}
            type={"email"}
            value={props.values.billingEmail}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={
              props.touched.billingEmail && Boolean(props.errors.billingEmail)
            }
            helperText={
              props.touched.billingEmail &&
              props.errors.billingEmail &&
              props.errors.billingEmail
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default Informations;
