import React, { useEffect, useMemo, useState } from "react";
import { locale, mask } from "../../../../localisation";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import { format } from "date-fns";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WarningIcon from "@mui/icons-material/Warning";
import { CSVLink } from "react-csv";
import * as Yup from "yup";
import { useFormik } from "formik";

const List = (props) => {
  const filterFormik = useFormik({
    initialValues: props.filters,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      date: Yup.date().nullable().default(null),
      round: Yup.number(),
    }),
    onSubmit: (values) => {
      props.setFilters({ ...values });
    },
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const rowCount = props.rowCount;
  const queryOptions = useMemo(
    () => ({
      page: page + 1,
      pageSize,
      filters: { ...props.filters },
    }),
    [page, pageSize, props.filters]
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    props.fetchMenus(queryOptions);
  }, [queryOptions]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const [confirmation, setConfirmation] = useState(false);
  const [exportDateDebut, setExportDateDebut] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [exportDateFin, setExportDateFin] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [exportAll, setExportAll] = useState(false);
  const handleClose = () => {
    setConfirmation(false);
  };
  const renderCellProduits = (params) => {
    const lunchProducts = params.row.lunchProducts
      .sort((a, b) => a.categorie.priorite - b.categorie.priorite)
      .map((item) => item.nom);
    const dinnerProducts = params.row.dinnerProducts
      .sort((a, b) => a.categorie.priorite - b.categorie.priorite)
      .map((item) => item.nom);

    return (
      <p>
        <i>
          <u>Déjeuner :</u>
        </i>{" "}
        {lunchProducts.join(", ")}
        <br />
        {dinnerProducts.length > 0 && (
          <>
            <i>
              <u>Dîner :</u>
            </i>{" "}
            {dinnerProducts.join(", ")}
          </>
        )}
      </p>
    );
  };
  const renderCellIsComplete = (params) => {
    if (
      Object.keys(params.row.isLunchDietsComplete).length > 0 ||
      Object.keys(params.row.isDinnerDietsComplete).length > 0 ||
      Object.keys(params.row.isLunchAllergensComplete).length > 0 ||
      Object.keys(params.row.isDinnerAllergensComplete).length > 0
    ) {
      let title = "";
      if (Object.keys(params.row.isLunchDietsComplete).length > 0) {
        for (const [category, diets] of Object.entries(
          params.row.isLunchDietsComplete
        )) {
          title +=
            `• Déjeuner : ${category} non adapté aux régimes : ` +
            diets.join(", ") +
            "\n";
        }
      }
      if (Object.keys(params.row.isDinnerDietsComplete).length > 0) {
        for (const [category, diets] of Object.entries(
          params.row.isDinnerDietsComplete
        )) {
          title +=
            `• Dîner : ${category} dîner non adapté aux régimes : ` +
            diets.join(", ") +
            "\n";
        }
      }
      if (Object.keys(params.row.isLunchAllergensComplete).length > 0) {
        for (const [category, diets] of Object.entries(
          params.row.isLunchAllergensComplete
        )) {
          title +=
            `• Déjeuner : ${category} non adapté aux allergènes : ` +
            diets.join(", ") +
            "\n";
        }
      }
      if (Object.keys(params.row.isDinnerAllergensComplete).length > 0) {
        for (const [category, diets] of Object.entries(
          params.row.isDinnerAllergensComplete
        )) {
          title +=
            `• Dîner : ${category} non adapté aux allergènes : ` +
            diets.join(", ");
        }
      }

      return (
        <Tooltip
          title={<span style={{ whiteSpace: "pre-line" }}>{title}</span>}
        >
          <WarningIcon color="warning" />
        </Tooltip>
      );
    } else {
      return "";
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) =>
        params.value ? format(new Date(params.value), "dd/MM/yyyy") : "",
    },
    {
      field: "name",
      headerName: "Nom",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "diets",
      headerName: "Régimes",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => {
        if (params.value.length > 0) {
          return params.value.map((item) => item.nom).join(", ");
        } else {
          return "Normal";
        }
      },
    },
    {
      headerName: "Produits",
      flex: 2,
      headerAlign: "center",
      renderCell: renderCellProduits,
    },
    {
      field: "rounds",
      headerName: "Tournées",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params.value.map((item) => item.name).join(", "),
    },
    {
      field: "priorityIndex",
      headerName: "Priorité",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "isLunchDietsComplete",
      headerName: "Alerte",
      headerAlign: "center",
      align: "center",
      renderCell: renderCellIsComplete,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Copier">
              <ContentCopyIcon />
            </Tooltip>
          }
          onClick={() => props.handleDupplicate(params.row)}
          label="Copier"
        />,
      ],
    },
  ];

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        spacing={1}
        paddingBottom={2}
      >
        <Button disabled>Import</Button>
        <Button
          disabled={props.isLoading}
          onClick={() => setConfirmation(true)}
        >
          Export
        </Button>
        <LoadingButton
          loading={props.isLoading}
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Menu
        </LoadingButton>
      </Stack>

      <Stack spacing={2}>
        <Paper elevation={6} sx={{ p: 2 }}>
          <form onSubmit={filterFormik.handleSubmit}>
            <Grid container alignItems="center" spacing={1}>
              <Grid container item spacing={2} xs={12} md={12} lg={9}>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    disabled={props.isLoading}
                    size="small"
                    fullWidth
                    name={"id"}
                    label="ID"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={filterFormik.handleChange}
                    onBlur={filterFormik.handleBlur}
                    value={filterFormik.values.id}
                    error={
                      filterFormik.touched.id && Boolean(filterFormik.errors.id)
                    }
                    helperText={
                      filterFormik.touched.id && filterFormik.errors.id
                    }
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <FormControl variant="outlined" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={locale["fr"]}
                      disabled={props.isLoading}
                    >
                      <DatePicker
                        mask={mask["fr"]}
                        label="Date"
                        inputFormat="dd/MM/yyyy"
                        onChange={(value) =>
                          filterFormik.setFieldValue("date", value)
                        }
                        onBlur={filterFormik.handleBlur}
                        value={filterFormik.values.date}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            disabled={props.isLoading}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText error>
                      {filterFormik.errors.date && filterFormik.touched.date
                        ? "Date invalide"
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="round">Tournée</InputLabel>
                    <Select
                      disabled={props.isLoading}
                      labelId="round"
                      id="round"
                      label="Tournée"
                      name="round"
                      onChange={(event) =>
                        filterFormik.setFieldValue(
                          "round",
                          event.target.value === "none"
                            ? ""
                            : event.target.value
                        )
                      }
                      onBlur={filterFormik.handleBlur}
                      value={filterFormik.values.round}
                      error={
                        filterFormik.touched.round &&
                        Boolean(filterFormik.errors.round)
                      }
                      helperText={
                        filterFormik.touched.round && filterFormik.errors.round
                      }
                    >
                      <MenuItem value="none">
                        <i>Aucune</i>
                      </MenuItem>

                      {props.rounds.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container item spacing={1} xs={12} md={12} lg={3}>
                <Grid item xs md lg>
                  <LoadingButton
                    loading={props.isLoading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="small"
                  >
                    Valider
                  </LoadingButton>
                </Grid>

                <Grid item xs md lg>
                  <LoadingButton
                    loading={props.isLoading}
                    fullWidth
                    variant="text"
                    size="small"
                    onClick={() => {
                      filterFormik.setValues({ ...props.filterInitialValues });
                      props.setFilters({ ...props.filterInitialValues });
                    }}
                  >
                    Réinitialiser
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Paper elevation={6}>
          <DataGrid
            autoHeight
            loading={props.isLoading}
            columns={columns}
            rows={props.menus}
            rowCount={rowCountState}
            rowsPerPageOptions={[50]}
            page={page}
            pageSize={pageSize}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            density="comfortable"
            disableSelectionOnClick
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            experimentalFeatures={{ newEditingApi: true }}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
          />
        </Paper>
      </Stack>

      <Dialog
        open={confirmation}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>Exporter des menus</DialogTitle>
        <DialogContent dividers>
          {props.isLoading ? (
            <Grid container justifyContent={"center"}>
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                xs={6}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    disabled={exportAll ? true : false}
                    mask={mask["fr"]}
                    label="De"
                    inputFormat="dd/MM/yyyy"
                    value={exportDateDebut}
                    onChange={(value) => {
                      setExportDateDebut(format(new Date(value), "yyyy-MM-dd"));
                      props.resetDataExport();
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={6}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    disabled={exportAll ? true : false}
                    mask={mask["fr"]}
                    label="à"
                    inputFormat="dd/MM/yyyy"
                    value={exportDateFin}
                    onChange={(value) => {
                      setExportDateFin(format(new Date(value), "yyyy-MM-dd"));
                      props.resetDataExport();
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={12}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Exporter tout les menus"
                    checked={exportAll}
                    onChange={(event) => {
                      setExportAll(event.target.checked);
                      props.resetDataExport();
                    }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              props.resetDataExport();
            }}
          >
            Annuler
          </Button>
          {props.dataCsv.length === 0 ? (
            <Button
              variant="contained"
              onClick={() => {
                exportAll
                  ? props.sendDataExport(exportAll, null, null)
                  : props.sendDataExport(
                      exportAll,
                      exportDateDebut,
                      exportDateFin
                    );
              }}
            >
              Générer les données
            </Button>
          ) : (
            <Button variant="contained">
              <CSVLink
                data={props.dataCsv}
                filename={`export_menus_${format(
                  new Date(),
                  "dd-MM-yyyy"
                )}.csv`}
                onClick={() => setConfirmation(false)}
                style={{
                  color: "#ffffff",
                  textDecoration: "none",
                }}
              >
                Télécharger
              </CSVLink>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default List;
