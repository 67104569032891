import React from "react";
import { format } from "date-fns";
import { Paper, Grid, Chip, Typography, Stack } from "@mui/material";
import RedeemIcon from "@mui/icons-material/Redeem";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import OrderBag from "../../Deliveries/List/Delivery/OrderBag";
import { ORDER_STATUS } from "../../Common/Enums/Enums";

const OrderCard = ({ index, order, diets }) => {
  const classes = UseStyle();
  let color = null;

  if (
    order.statut === ORDER_STATUS.PREPARED.status ||
    order.statut === ORDER_STATUS.DELIVERED.status
  ) {
    color = "#BDECB8";
  } else if (order.statut === ORDER_STATUS.CANCELLED.status) {
    color = "#F5BDC8";
  }

  return (
    <Grid item>
      <Paper
        className={classes.paper}
        sx={{
          bgcolor: color,
        }}
        elevation={6}
        key={index}
      >
        <Grid container>
          <Grid
            item
            container
            spacing={2}
            padding={2}
            alignItems="center"
            justifyContent="space-between"
            xs={12}
          >
            <Grid item>
              <Stack direction={"row"} spacing={1}>
                <Typography variant="body1">
                  Commande <u>n° {order.id}</u>{" "}
                  {order["@type"] === "OrderPilote" && "(Pilote)"}
                </Typography>
                {order.estOffert && <RedeemIcon />}
              </Stack>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                {order["@type"] === "OrderUnit"
                  ? `Convive : ${order.name}`
                  : `Quantité : ${order.quantity}`}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                Repas du : {format(new Date(order.date), "dd/MM/yyy")}
              </Typography>
            </Grid>

            <Grid item>
              {diets.length > 0 ? (
                diets.map((regime, i) => (
                  <Chip size="medium" key={i} label={regime.nom} />
                ))
              ) : (
                <Chip size="medium" label="Sans régime" />
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            spacing={3}
            direction={"column"}
            paddingX={5}
            marginBottom={3}
          >
            {order.produitsDejeuner.length > 0 && (
              <Grid item>
                <OrderBag
                  formule={order.lunchMenu}
                  products={order.produitsDejeuner}
                  extras={order.supplementsDejeuner}
                  isDinner={false}
                />
              </Grid>
            )}

            {order.produitsDiner.length > 0 && (
              <Grid item>
                <OrderBag
                  formule={order.dinnerMenu}
                  products={order.produitsDiner}
                  extras={order.supplementsDiner}
                  isDinner={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default OrderCard;
