import React, { useState } from "react";
import { format } from "date-fns";
import { locale, mask } from "../../../../localisation";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import Filters from "../../Common/Filters";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const ExportDialog = (props) => {
  const formik = useFormik({
    initialValues: {
      all: false,
      start: null,
      end: null,
    },
    validationSchema: Yup.object({
      start: Yup.date().nullable().typeError("Date invalide"),
      end: Yup.date().nullable().typeError("Date invalide"),
      all: Yup.boolean().test(
        "isValid",
        "Si vous ne cochez pas cette case, vous devez rentrer au moins une date.",
        function (value) {
          return (!value && (this.parent.start || this.parent.end)) || value;
        }
      ),
    }),
    onSubmit: (values) => {
      props.sendDataExport(
        values.all,
        values.start ? format(values.start, "yyyy-MM-dd") : null,
        values.end ? format(values.end, "yyyy-MM-dd") : null
      );
      props.handleClose();
    },
  });

  return (
    <Dialog
      open={props.isExporting}
      onClose={props.handleClose}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle>
        Exporter les clients
        <DialogContentText>
          Si vous indiquez seulement la date de début, vous récupérerez tout les
          clients inscrit avant et jusqu'à cette date.
        </DialogContentText>
        <DialogContentText>
          Si vous indiquez seulement la date de fin, vous récupérerez tout les
          clients inscrit à partir de cette date.
        </DialogContentText>
        <DialogContentText>
          Indiquez les 2 dates afin de récupérer tout les clients inscrit entre
          ces dates.
        </DialogContentText>
      </DialogTitle>

      <DialogContent dividers>
        <form>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={
                  formik.errors.start && formik.touched.start ? true : false
                }
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    {...(formik.values.all && { disabled: true })}
                    mask={mask["fr"]}
                    label="Date de début"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.start}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("start", value)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.start &&
                    formik.touched.start &&
                    formik.errors.start}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={formik.errors.end && formik.touched.end ? true : false}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    {...(formik.values.all && { disabled: true })}
                    mask={mask["fr"]}
                    label="Date de fin"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.end}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("end", value)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.end && formik.touched.end && formik.errors.end}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item container xs={12} justifyContent={"center"}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.all}
                      onChange={(event) =>
                        formik.setFieldValue("all", event.target.checked)
                      }
                      name="all"
                    />
                  }
                  label="Exporter tout les clients"
                />
                <FormHelperText error>
                  {formik.errors.all && formik.touched.all && formik.errors.all}
                </FormHelperText>
              </FormGroup>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        {props.loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <Button onClick={props.handleClose}>Annuler</Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<DownloadRoundedIcon />}
              onClick={formik.handleSubmit}
            >
              Exporter
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};

const List = (props) => {
  const classes = UseStyle();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const handleClose = () => {
    setIsExporting(false);
  };
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   flex: 1,
    // },
    {
      field: "phone",
      headerName: "Téléphone",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Date d'inscription",
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
      ],
    },
  ];

  return props.isPageLoading ? (
    <LinearProgress />
  ) : (
    <Stack spacing={2} marginBottom={2}>
      <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
        <LoadingButton
          loading={props.loading}
          onClick={() => {
            props.toggleImportModal();
            setSelectedFile(null);
          }}
        >
          Import
        </LoadingButton>
        <LoadingButton
          loading={props.loading}
          onClick={() => setIsExporting(true)}
        >
          Export
        </LoadingButton>
        <LoadingButton
          loading={props.loading}
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Client
        </LoadingButton>
      </Stack>

      <Stack spacing={2}>
        <Filters
          userData={props.userData}
          types={["client", "email", "phone"]}
          from="organizations"
          clients={props.clientsList}
          handleSubmitFilters={props.handleSubmitFilters}
          loading={props.loading}
        />

        <Paper className={classes.paper} elevation={6}>
          <DataGrid
            autoHeight
            columns={columns}
            rows={props.clients}
            rowsPerPageOptions={[50]}
            density="comfortable"
            disableSelectionOnClick
            pagination
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={props.loading}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
          />
        </Paper>
      </Stack>

      <Dialog
        open={props.showImportModal}
        onClose={props.toggleImportModal}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>Importer des utilisateurs</DialogTitle>
        <DialogContent dividers>
          {props.loading ? (
            <Grid container justifyContent={"center"}>
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                xs={6}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  variant="contained"
                  component="span"
                  endIcon={<FileDownloadIcon />}
                >
                  Télécharger le modèle
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                {selectedFile && (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    direction={"column"}
                  >
                    <Grid item>
                      <FilePresentIcon />
                    </Grid>
                    <Grid item>{selectedFile.name}</Grid>
                  </Grid>
                )}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      inputProps={{
                        accept:
                          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      }}
                      id="contained-button-file"
                      type="file"
                      sx={{ display: "none" }}
                      onChange={(e) => {
                        setSelectedFile(e.target.files[0]);
                      }}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      endIcon={<FileUploadIcon />}
                    >
                      Choisir un fichier
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.toggleImportModal} disabled={props.loading}>
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={() => props.importUsers(selectedFile)}
            disabled={props.loading || !selectedFile}
          >
            Importer
          </Button>
        </DialogActions>
      </Dialog>

      <ExportDialog
        {...props}
        isExporting={isExporting}
        handleClose={handleClose}
      />
    </Stack>
  );
};

export default List;
