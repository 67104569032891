export function isSuperAdmin(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    userData.roles.includes("ROLE_SUPER_ADMIN")
  ) {
    return true;
  } else {
    return false;
  }
}

export function isAdmin(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    (userData.roles.includes("ROLE_SUPER_ADMIN") ||
      userData.roles.includes("ROLE_ADMIN"))
  ) {
    return true;
  } else {
    return false;
  }
}

export function isOwner(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    userData.roles.includes("ROLE_OWNER")
  ) {
    return true;
  } else {
    return false;
  }
}

export function isFinanceAdmin(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    userData.roles.includes("ROLE_FINANCE_ADMIN")
  ) {
    return true;
  } else {
    return false;
  }
}

export function isSalesAdmin(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    userData.roles.includes("ROLE_COMMERCIAL_ADMIN")
  ) {
    return true;
  } else {
    return false;
  }
}

export function isProdAdmin(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    userData.roles.includes("ROLE_PRODUCTION_ADMIN")
  ) {
    return true;
  } else {
    return false;
  }
}

export function isFinanceUser(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    userData.roles.includes("ROLE_FINANCE")
  ) {
    return true;
  } else {
    return false;
  }
}

export function isSalesUser(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    userData.roles.includes("ROLE_COMMERCIAL")
  ) {
    return true;
  } else {
    return false;
  }
}

export function isProdUser(userData) {
  if (
    userData &&
    Array.isArray(userData.roles) &&
    userData.roles.includes("ROLE_PRODUCTION")
  ) {
    return true;
  } else {
    return false;
  }
}

export function isAnyAdmin(userData) {
  if (
    userData &&
    (isAdmin(userData) ||
      isFinanceAdmin(userData) ||
      isSalesAdmin(userData) ||
      isProdAdmin(userData))
  ) {
    return true;
  } else {
    return false;
  }
}
