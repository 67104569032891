import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import {
  Box,
  Card,
  CardHeader,
  Button,
  Grid,
  Paper,
  TextField,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <>
      <form>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant={"contained"}
              sx={{ mb: 3 }}
              onClick={props.toggleList}
              color="secondary"
              startIcon={<ArrowBackIcon />}
            >
              Retour
            </Button>
          </Box>
        </Box>

        <Card className={classes.card} sx={{ mb: 3 }}>
          <CardHeader
            subheader="Ces informations peuvent être modifier."
            title="Modification d'une catégorie"
          />
        </Card>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={props.errors.nom && props.touched.nom ? true : false}
                    {...(props.errors.nom &&
                      props.touched.nom && { helperText: props.errors.nom })}
                    fullWidth
                    name={"nom"}
                    label={"Nom"}
                    value={props.values.nom}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={props.values.description}
                    multiline
                    rows={4}
                    fullWidth
                    name={"description"}
                    label={"Description"}
                    variant="outlined"
                    type={"text"}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {props.loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                color="primary"
                onClick={props.handleSubmit}
                variant="contained"
                endIcon={<SendIcon />}
              >
                Modifier la catégorie
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    nom: props.categoryEdit.nom,
    description: props.categoryEdit.description,
  }),
  validationSchema: Yup.object().shape({
    nom: Yup.string()
      .min(3)
      .max(15)
      .required("Le nom de la catégorie est obligatoire."),
  }),
  handleSubmit: (values, { props }) => {
    props.validation(props.categoryEdit.id, values.nom, values.description);
  },
})(Edit);
