import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import RoundForm from "../RoundForm";
import { isAdmin } from "../../../../utils";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifiers."
          title="Modification d'une tournée"
        />
      </Card>

      <RoundForm {...props} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    organization: isAdmin(props.userData)
      ? props.round.organization.id
      : props.userData.organization.id,
    organizationName: props.round.organization.name,
    name: props.round.name,
    deliveryDays: props.round.deliveryDays,
  }),
  validationSchema: Yup.object().shape({
    organization: Yup.number("Sélectionnez un client")
      .positive()
      .required("Champs obligatoire")
      .typeError("Sélectionnez un client"),
    name: Yup.string()
      .required("Champs obligatoire")
      .min(3, "Minimum 3 caractères"),
    deliveryDays: Yup.array(),
  }),
  handleSubmit: (values, { props }) => {
    const updatedItem = {
      id: props.round.id,
      organization: `/organizations/${values.organization}`,
      name: values.name.toUpperCase(),
      deliveryDays: values.deliveryDays.map((deliveryDay) => ({
        estActif: deliveryDay.repasDu.length > 0,
        repasDu: deliveryDay.repasDu,
      })),
    };

    props.validation(updatedItem);
  },
})(Edit);
