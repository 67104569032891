import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import CreditNoteForm from "../CreditNoteForm";
import { format } from "date-fns";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifiers."
          title="Modification d'un avoir"
        />
      </Card>

      <CreditNoteForm
        {...props}
        orders={[props.creditNote.orderUnit.id]}
        isLoadingData={false}
      />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    recipient: props.creditNote.recipient,
    order: props.creditNote.orderUnit,
    formules: props.creditNote.formules.map((formule) => formule.id),
    date: new Date(props.creditNote.year, props.creditNote.month - 1, 1),
  }),
  validationSchema: Yup.object().shape({
    date: Yup.date()
      .min(new Date(2022, 1, 1), "Date invalide")
      .typeError("Date invalide")
      .required("Champs obligatoire"),
  }),
  handleSubmit: (values, { props }) => {
    const newCreditNote = {
      id: props.creditNote.id,
      recipient: `/recipients/${values.recipient.id}`,
      orderUnit: `/order_units/${values.order.id}`,
      formules: values.formules.map((formule) => `/formules/${formule}`),
      month: parseInt(format(values.date, "MM")),
      year: parseInt(format(values.date, "yyyy")),
    };

    props.validation(newCreditNote);
  },
})(Add);
