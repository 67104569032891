import React, { useEffect, useState } from "react";
import { requests } from "../../../agent";
import { Snackbar, Alert } from "@mui/material";
import List from "./List/List";
import _superagent from "superagent";
import { useSelector } from "react-redux";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";

const FormuleContainer = (props) => {
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formuleEdit, setFormuleEdit] = useState(false);
  const [formules, setFormules] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [categories, setCategories] = useState(null);
  const [extraCategories, setExtraCategories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [alert, setAlert] = useState({
    isActive: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setIsPageLoading(true);

    const promises = Promise.all([
      requests.get("/categories", true),
      requests.get("/formules", true),
      requests.get("/taxes", true),
      requests.get("/extra_categories", true),
    ]);

    promises
      .then((results) => {
        const dataCategories = results[0]["hydra:member"];
        let categoriesId = [];
        let categoriesLabel = [];
        if (dataCategories) {
          dataCategories.forEach((categorie) => {
            categoriesId.push(categorie.id);
            categoriesLabel[categorie.id] = categorie.nom;
          });
        }
        setCategories({
          datas: dataCategories,
          ids: categoriesId,
          labels: categoriesLabel,
        });
        setFormules(results[1]["hydra:member"]);
        setTaxes(results[2]["hydra:member"]);

        const dataExtraCategories = results[3]["hydra:member"];
        let extraCategoriesId = [];
        let extraCategoriesLabel = [];
        if (dataExtraCategories) {
          dataExtraCategories.forEach((extraCategory) => {
            extraCategoriesId.push(extraCategory.id);
            extraCategoriesLabel[extraCategory.id] = extraCategory.name;
          });
        }
        setExtraCategories({
          datas: dataExtraCategories,
          ids: extraCategoriesId,
          labels: extraCategoriesLabel.filter((item) => item),
        });
        setIsPageLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
        setAlert({
          isActive: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const handleSubmit = (data) => {
    setLoading(true);

    requests
      .post("/formules", data, true)
      .then((response) => {
        const updatedList = [...formules];
        updatedList.unshift(response);
        setFormules(updatedList);
        setAdd(false);
        setLoading(false);
        setAlert({
          isActive: true,
          type: "success",
          message: "La bien à bien été enregistrée !",
        });
      })
      .catch((err) => {
        setAlert({
          isActive: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitEdit = (data, id) => {
    setLoading(true);

    requests
      .patch(`/formules/${id}`, data, true)
      .then((response) => {
        const updatedList = formules.map((formule) => {
          if (formule.id === response.id) return response;

          return formule;
        });
        setFormules(updatedList);
        setEdit(false);
        setLoading(false);
        setAlert({
          isActive: true,
          type: "success",
          message: "La formule à bien été modifiée !",
        });
      })
      .catch((err) => {
        setAlert({
          isActive: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (id) => {
    const item = formules.find((f) => f.id === id);
    setEdit(true);
    setFormuleEdit(item);
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setFormuleEdit(null);
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setLoading(false);
    setAlert({
      isActive: false,
      type: "",
      message: "",
    });
  };
  const handleSubmitFilters = (data, from) => {
    setLoading(true);

    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.lastname !== "") dataUrl += `name=${data.lastname}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setFormules(result["hydra:member"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          isActive: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDelete = (formuleId) => {
    setLoading(true);
    requests
      .del(`/formules/${formuleId}`, true)
      .then((response) => {
        setLoading(false);
        setFormules(formules.filter((formule) => formuleId !== formule.id));
        setAlert({
          isActive: true,
          type: "success",
          message: "La formule a été supprimée !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          isActive: true,
          type: "error",
          message: err.message,
        });
      });
  };
  return (
    <>
      {add && (
        <Add
          add={add}
          validation={handleSubmit}
          toggleList={toggleList}
          taxes={taxes}
          categories={categories}
          extraCategories={extraCategories}
          loading={loading}
        />
      )}

      {edit && (
        <Edit
          edit={edit}
          formuleEdit={formuleEdit}
          validation={handleSubmitEdit}
          toggleList={toggleList}
          taxes={taxes}
          categories={categories}
          extraCategories={extraCategories}
          loading={loading}
        />
      )}

      {!add && !edit && (
        <List
          toggleAdd={toggleAdd}
          toggleEdit={toggleEdit}
          formules={formules}
          isPageLoading={isPageLoading}
          loading={loading}
          handleSubmitFilters={handleSubmitFilters}
          handleDelete={handleDelete}
        />
      )}

      {alert.isActive && (
        <Snackbar
          open={alert.isActive}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          sx={{ zIndex: "snackbar" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default FormuleContainer;
