import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { requests } from "../../../agent";
import List from "./List/List";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const AbsenceContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [absences, setAbsences] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    const promises = Promise.all([
      requests.get("/absences", true),
      requests.get("/recipients?client.organization.mode=repertory", true),
    ]);

    promises
      .then((results) => {
        setAbsences(results[0]["hydra:member"]);
        setRecipients(results[1]["hydra:member"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const handleSubmitFilters = (data, from) => {
    setLoading(true);
    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.recipient !== null) dataUrl += `recipient.id=${data.recipient}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setAbsences(result["hydra:member"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDelete = (absenceId) => {
    setLoading(true);
    requests
      .del(`/absences/${absenceId}`, true)
      .then((response) => {
        setLoading(false);
        setAbsences(absences.filter((a) => absenceId !== a.id));
        setAlert({
          active: true,
          type: "success",
          message: "L'absence a bien été supprimée",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const handleSubmit = (recipientId, startDate, endDate) => {
    const newItem = {
      recipient: `/recipients/${recipientId}`,
      beginAt: format(startDate, "yyyy-MM-dd"),
      endAt: format(endDate, "yyyy-MM-dd"),
    };

    setLoading(true);
    requests
      .post("/absences", newItem, true)
      .then((response) => {
        const updatedList = [...absences];
        updatedList.unshift(response);
        setAbsences(updatedList);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "L'absence à bien été enregistrée !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };

  return (
    <>
      <List
        userData={userData}
        absences={absences}
        recipients={recipients}
        loading={loading}
        alert={alert}
        handleSubmitFilters={handleSubmitFilters}
        handleDelete={handleDelete}
        handleSubmit={handleSubmit}
      />

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AbsenceContainer;
