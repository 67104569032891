import * as React from "react";
import {
  Button,
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  TextField,
  Stack,
  ListItemButton,
} from "@mui/material";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransfertListSupplement(props) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(props.left);
  const [right, setRight] = React.useState(props.right);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(
    checked,
    right.map((supplement) => supplement.supplement)
  );
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const numberOfChecked = (items) => intersection(checked, items).length;
  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  const handleToggleAllRight = (items) => () => {
    if (
      numberOfChecked(items.map((supplement) => supplement.supplement)) ===
      items.length
    ) {
      setChecked(
        not(
          checked,
          items.map((supplement) => supplement.supplement)
        )
      );
    } else {
      setChecked(
        union(
          checked,
          items.map((supplement) => supplement.supplement)
        )
      );
    }
  };
  const handleCheckedRight = () => {
    setRight(
      right.concat(
        leftChecked.map((supplement) => ({ supplement: supplement, qte: 1 }))
      )
    );
    setLeft(not(left, leftChecked));
    props.chosen(
      right.concat(
        leftChecked.map((supplement) => ({ supplement: supplement, qte: 1 }))
      )
    );
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    const notRightChecked = not(
      right.map((supplement) => supplement.supplement),
      rightChecked
    );
    setRight(
      right.filter((supplement) =>
        notRightChecked.includes(supplement.supplement)
      )
    );
    props.chosen(
      right.filter((supplement) =>
        notRightChecked.includes(supplement.supplement)
      )
    );
    setChecked(not(checked, rightChecked));
  };
  const customListLeft = (title, items) => (
    <Stack direction={"row"} spacing={1} justifyContent={"center"}>
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} sélectionné(s)`}
        />
        <Divider />
        <List
          sx={{
            width: { md: 300, lg: 350 },
            height: { md: 220, lg: 280 },
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
          disablePadding={true}
        >
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItemButton key={value} onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                    value={value}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${props.labels[value]}`} />
              </ListItemButton>
            );
          })}
        </List>
      </Card>
    </Stack>
  );
  const customListRight = (title, items) => (
    <Stack direction={"row"} spacing={1} justifyContent={"center"}>
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAllRight(items)}
              checked={
                numberOfChecked(
                  items.map((supplement) => supplement.supplement)
                ) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(
                  items.map((supplement) => supplement.supplement)
                ) !== items.length &&
                numberOfChecked(
                  items.map((supplement) => supplement.supplement)
                ) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} sélectionné(s)`}
        />

        <Divider />

        <List
          sx={{
            width: { md: 300, lg: 350 },
            height: { md: 220, lg: 280 },
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value, index) => {
            const labelId = `transfer-list-all-item-${value.supplement}-label`;

            return (
              <ListItemButton
                key={index}
                onClick={handleToggle(value.supplement)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value.supplement) !== -1}
                    onChange={handleToggle(value.supplement)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                    value={value.supplement}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${props.labels[value.supplement]}`}
                />
                <TextField
                  label="Qté"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "80px" }}
                  size="small"
                  value={value.qte}
                  onChange={(event) => {
                    const qte = +event.target.value;
                    if (qte >= 0) {
                      setRight(
                        right.map((commandeSupplement, indexCS) =>
                          commandeSupplement.supplement === value.supplement
                            ? { ...commandeSupplement, qte: qte }
                            : commandeSupplement
                        )
                      );
                      props.chosen(
                        right.map((commandeSupplement, indexCS) =>
                          commandeSupplement.supplement === value.supplement
                            ? { ...commandeSupplement, qte: qte }
                            : commandeSupplement
                        )
                      );
                    }
                  }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Card>
    </Stack>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12} lg={"auto"}>
        {customListLeft("Choix", left)}
      </Grid>

      <Grid
        item
        container
        direction="column"
        alignItems="center"
        xs={12}
        md={12}
        lg={"auto"}
      >
        <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
      </Grid>

      <Grid item xs={12} md={12} lg={"auto"}>
        {customListRight("Sélectionnés", right)}
      </Grid>
    </Grid>
  );
}
