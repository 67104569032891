import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import UseStyle from "../Common/StyledComponent/UseStyle";

const PlanForm = (props) => {
  const classes = UseStyle();

  useEffect(() => {
    let priceExcludingTax = 0;
    let tva = props.taxes.find((t) => t.id === props.values.tax).taux;
    if (props.values.priceIncludingTax !== "") {
      priceExcludingTax = (
        parseFloat(props.values.priceIncludingTax) /
        (1 + tva)
      ).toFixed(4);
    }
    props.setFieldValue("priceExcludingTax", priceExcludingTax);
  }, [props.values.priceIncludingTax, props.values.tax]);

  return (
    <Paper className={classes.paper} elevation={6}>
      <form>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={props.edit ? 10 : 12}>
            <TextField
              fullWidth
              name="name"
              label="Nom"
              value={props.values.name}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.name && Boolean(props.errors.name)}
              helperText={
                props.errors.name && props.touched.name && props.errors.name
              }
            />
          </Grid>

          {props.edit && (
            <Grid item xs={2}>
              <FormGroup>
                <FormControlLabel
                  onChange={props.handleChange}
                  checked={props.values.isEnabled}
                  name="isEnabled"
                  onBlur={props.handleBlur}
                  control={<Checkbox />}
                  label="Actif"
                />
              </FormGroup>
            </Grid>
          )}

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Taxe</InputLabel>
              <Select
                error={props.touched.tax && Boolean(props.errors.tax)}
                helperText={
                  props.errors.tax && props.touched.tax && props.errors.tax
                }
                label="Taxe"
                name="tax"
                value={props.values.tax}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              >
                {props.taxes.map((taxe) => {
                  return (
                    <MenuItem key={taxe.id} value={taxe.id}>
                      {taxe.nom}%
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="priceIncludingTax">Prix TTC</InputLabel>
              <OutlinedInput
                id="priceIncludingTax"
                value={props.values.priceIncludingTax}
                onChange={props.handleChange}
                startAdornment={
                  <InputAdornment position="start">€</InputAdornment>
                }
                label="Prix TTC"
                type="number"
                error={
                  props.touched.priceIncludingTax &&
                  Boolean(props.errors.priceIncludingTax)
                }
              />
              <FormHelperText>
                {props.errors.priceIncludingTax &&
                  props.touched.priceIncludingTax &&
                  props.errors.priceIncludingTax}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <Tooltip title="Le prix HT est calculé automatiquement">
              <FormControl fullWidth>
                <InputLabel htmlFor="priceExcludingTax">Prix HT</InputLabel>
                <OutlinedInput
                  readOnly
                  id="priceExcludingTax"
                  value={props.values.priceExcludingTax}
                  startAdornment={
                    <InputAdornment position="start">€</InputAdornment>
                  }
                  label="Prix HT"
                />
              </FormControl>
            </Tooltip>
          </Grid>

          <Grid container item justifyContent="flex-end" xs={12}>
            <LoadingButton
              loading={props.isLoading}
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={props.handleSubmit}
            >
              {props.edit ? "Sauvegarder" : "Créer"}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default PlanForm;
