import React, { useState } from "react";
import { format } from "date-fns";
import { fr, is } from "date-fns/locale";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UseStyle from "../Common/StyledComponent/UseStyle";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import CachedIcon from "@mui/icons-material/Cached";
import { isAnyAdmin } from "../../../utils";

const DunningDialog = (props) => {
  let month = format(
    new Date(props.invoice.year + "-" + props.invoice.month + "-01"),
    "LLLL yyyy",
    {
      locale: fr,
    }
  );
  month = month.charAt(0).toUpperCase() + month.slice(1);

  return (
    <Dialog
      open={props.isDunning}
      onClose={props.handleClose}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>Confirmation de relance</DialogTitle>
      <DialogContent>
        Confirmez-vous la relance de la dernière facture du mois de{" "}
        <u>{month}</u> ?
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Annuler</Button>
        <LoadingButton
          loading={props.loading}
          color="primary"
          variant="contained"
          onClick={() =>
            props.handleInvoiceDunning(props.invoice.id, props.from)
          }
        >
          Valider
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const InvoiceCard = ({
  invoice,
  user,
  handleDownloadInvoice,
  handleInvoiceDunning,
  loading,
  from,
}) => {
  const [isDunning, setIsDunning] = useState(false);
  const classes = UseStyle();
  const handleClose = () => {
    setIsDunning(false);
  };

  return (
    <>
      <Card className={classes.card} elevation={6}>
        <CardContent>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography component="div" variant="h5">
              {format(
                new Date(invoice.year + "-" + invoice.month + "-01"),
                "LLLL yyyy",
                {
                  locale: fr,
                }
              ).toUpperCase()}
            </Typography>

            <Typography component="div" variant="h5">
              {Math.round(invoice.totalInclusiveTaxes * 100) / 100} €
            </Typography>
          </Stack>

          <Typography
            color="text.secondary"
            marginBottom={invoice.updatedAt ? 0 : 2}
          >
            Créé le :{" "}
            {format(new Date(invoice.createdAt), "dd/MM/yyyy", {
              locale: fr,
            })}
          </Typography>

          {invoice.updatedAt && (
            <Typography color="text.secondary" marginBottom={2}>
              Mise à jour le :{" "}
              {format(new Date(invoice.updatedAt), "dd/MM/yyyy HH:mm", {
                locale: fr,
              })}
            </Typography>
          )}

          {isAnyAdmin(user) ? (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={1}
            >
              <Accordion sx={{ bgcolor: "#f5f5f5" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1" color="text.secondary">
                    <u>Historique</u>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={1}>
                    {invoice.history
                      .sort((a, b) => b.id - a.id)
                      .map((invoiceHistory, key) => (
                        <Tooltip key={key} title="Télécharger">
                          <Link
                            component="button"
                            underline="none"
                            color="text.secondary"
                            onClick={() =>
                              handleDownloadInvoice(invoiceHistory.id, true)
                            }
                          >
                            #
                            {invoiceHistory.id +
                              " - " +
                              format(
                                new Date(invoiceHistory.createdAt),
                                "dd/MM/yyyy HH:mm",
                                { locale: fr }
                              )}
                          </Link>
                        </Tooltip>
                      ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <LoadingButton
                loading={loading}
                color="secondary"
                variant="contained"
                startIcon={<CachedIcon />}
                onClick={() => setIsDunning(true)}
              >
                Relance
              </LoadingButton>

              <LoadingButton
                loading={loading}
                color="secondary"
                variant="contained"
                startIcon={<FileDownloadRoundedIcon />}
                onClick={() => handleDownloadInvoice(invoice.id, false)}
              >
                Dernière facture
              </LoadingButton>
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
            >
              <LoadingButton
                loading={loading}
                color="secondary"
                variant="contained"
                startIcon={<FileDownloadRoundedIcon />}
                onClick={() => handleDownloadInvoice(invoice.id, false)}
              >
                Télécharger
              </LoadingButton>
            </Stack>
          )}
        </CardContent>
      </Card>

      <DunningDialog
        invoice={invoice}
        isDunning={isDunning}
        loading={loading}
        handleClose={handleClose}
        handleInvoiceDunning={handleInvoiceDunning}
        from={from}
      />
    </>
  );
};

export default InvoiceCard;
