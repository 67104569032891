import React, { useState } from "react";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import {
  Card,
  Divider,
  CardHeader,
  Paper,
  Autocomplete,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OrderForm from "../OrderForm";
import { isAdmin } from "../../../../utils";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const Add = (props) => {
  const classes = UseStyle();
  const [selectedClient, setSelectedClient] = useState(null);
  const clients =
    props.clients.length > 0 &&
    props.clients.map((client) => {
      const filtre = client.name[0].toUpperCase();
      return {
        filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
        client: client,
      };
    });

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          loading={props.loading}
          variant={"contained"}
          onClick={props.toggleList}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </LoadingButton>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'une commande"
        />
      </Card>

      {isAdmin(props.userData) && (
        <Paper className={classes.paper} elevation={6}>
          <Autocomplete
            sx={{ width: "100%" }}
            options={clients.sort((a, b) => -b.filtre.localeCompare(a.filtre))}
            isOptionEqualToValue={(option, value) =>
              option.client.id === value.client.id
            }
            groupBy={(value) => value.filtre}
            getOptionLabel={(option) => {
              if (!option.client) return "";
              return option.client.id + " - " + option.client.name;
            }}
            value={selectedClient}
            onChange={(event, value) => {
              setSelectedClient(value);

              value !== null
                ? props.handleChangeUser(value.client.id)
                : props.handleChangeUser(null);
            }}
            renderInput={(params) => <TextField {...params} label="Client" />}
          />
        </Paper>
      )}

      {props.user && (
        <Paper className={classes.paper} elevation={6}>
          <Stack
            divider={<Divider flexItem />}
            spacing={3}
            alignItems={"center"}
          >
            <OrderForm {...props} />
          </Stack>
        </Paper>
      )}
    </Stack>
  );
};

export default Add;
