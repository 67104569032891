import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import {
  Card,
  CardHeader,
  Button,
  Paper,
  TextField,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={props.toggleList}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Stack>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'une catégorie de supplément"
        />
      </Card>

      <form>
        <Paper className={classes.paper} elevation={6}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              name={"name"}
              label={"Nom"}
              value={props.values.name}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.name && Boolean(props.errors.name)}
              helperText={
                props.errors.name && props.touched.name && props.errors.name
              }
            />

            <Stack alignItems="flex-end">
              <LoadingButton
                loading={props.isLoading}
                color="primary"
                onClick={props.handleSubmit}
                variant="contained"
                endIcon={<SendIcon />}
              >
                Créer la catégorie
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </form>
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .min(3, "Le nom de la catégorie doit faire au moins 3 caractères.")
      .required("Le nom de la catégorie est obligatoire."),
  }),
  handleSubmit: (values, { props }) => {
    props.validation({ name: values.name });
  },
})(Add);
