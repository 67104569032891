import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, LinearProgress, Paper, Stack, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import React from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import Filters from "../../Common/Filters";

const List = (props) => {
  const classes = UseStyle();
  let columns = [
    { field: "id", headerName: "ID" },
    {
      field: "organization",
      headerName: "Client",
      flex: 1,
      valueGetter: ({ value }) => value.name,
    },
    {
      field: "plan",
      headerName: "Offre",
      flex: 1,
      valueGetter: ({ value }) => value.name,
    },
    {
      field: "addOns",
      headerName: "Extensions",
      flex: 1,
      valueGetter: ({ value }) =>
        value
          .sort((a, b) => a.name - b.name)
          .map((addOn) => addOn.name)
          .join(", "),
    },
    {
      field: "createdAt",
      headerName: "Créé le",
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => new Date(value),
    },
    {
      field: "updatedAt",
      headerName: "Màj le",
      flex: 1,
      type: "datetime",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <DeleteIcon color="error" />
            </Tooltip>
          }
          onClick={() => props.handleDelete(params.id)}
          label="Supprimer"
        />,
      ],
    },
  ];

  return (
    <Stack spacing={2} marginBottom={2}>
      <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>
        <LoadingButton
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
          loading={props.isLoading}
        >
          Souscription
        </LoadingButton>
      </Stack>

      <Filters
        userData={props.userData}
        types={["client"]}
        from="subscriptions"
        clients={props.organizations}
        handleSubmitFilters={props.handleSubmitFilters}
        loading={props.isLoading}
      />

      <Paper className={classes.paper} elevation={6}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={props.subscriptions}
          pageSize={30}
          density="comfortable"
          disableSelectionOnClick
          pagination
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          loading={props.isLoading}
          experimentalFeatures={{ newEditingApi: true }}
          getRowHeight={() => "auto"}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </Paper>
    </Stack>
  );
};

export default List;
