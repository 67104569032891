import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { requests } from "../../../agent";
import ClientsList from "./Lists/ClientsList";
import RecipientsList from "./Lists/RecipientsList";
import { Snackbar, Alert } from "@mui/material";
import { format, lastDayOfMonth } from "date-fns";
import _superagent from "superagent";
import superagentPromise from "superagent-promise";
import { ENTRYPOINT } from "../../../config/entrypoint";
import { isAdmin, isFinanceAdmin } from "../../../utils";

const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = (response) => response.body;
let token = null;
const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) request.set("Authorization", `Bearer ${token}`);
  };
};

const InvoiceContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [invoices, setInvoices] = useState([]);
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [recipientsList, setRecipientsList] = useState([]);
  const [recipient, setRecipient] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });
  const [isFileDownloaded, setIsFileDownloaded] = useState({
    returnValue: false,
    loaded: 0,
    timeStamp: 0,
  });
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    let urlUser =
      isAdmin(userData) || isFinanceAdmin(userData)
        ? "/organizations/lists?order[invoices.id]"
        : `/organizations/lists?order[invoices.id]&user=` + userData.id;

    if (location.pathname === "/admin/factures/convives") {
      urlUser += "&mode=repertory";
    }

    const promises = Promise.all([
      requests.get(urlUser, true),
      requests.get("/recipients/invoices?discriminator[]=unit", true),
      requests.get("/rounds", true),
    ]);

    promises
      .then((results) => {
        setClients(results[0]["hydra:member"]);
        setClientsList(results[0]["hydra:member"]);
        setRecipients(results[1]["hydra:member"]);
        setRecipientsList(results[1]["hydra:member"]);
        setRounds(
          isAdmin(userData)
            ? results[2]["hydra:member"]
            : userData.organization.rounds
        );
        setLoading(false);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleDrawer = (isOpen, id, from, param) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    let client =
      from === "invoice_clients"
        ? clients.find((user) => user.id === id)
        : recipients.find((recipient) => recipient.id === id);

    if (isOpen) {
      requests
        .get(`/${from}?${param}.id=${id}&order[id]=desc`, true)
        .then((results) => {
          setInvoices(results["hydra:member"]);
          from === "invoice_clients" ? setClient(client) : setRecipient(client);
          setLoading(false);
          setOpen(isOpen);
        })
        .catch((err) => {
          setOpen(false);
          setInvoices([]);
          setClient(null);
          setRecipient(null);
          setLoading(false);
        });
    } else {
      setOpen(false);
      setInvoices([]);
      setClient(null);
      setRecipient(null);
      setLoading(false);
    }
  };
  const handleSubmitFilters = (data, from) => {
    setLoading(true);
    let dataUrl = "";

    if (location.pathname === "/admin/factures/clients") {
      if (data.client && data.client !== "") dataUrl += `id=${data.client}&`;
    } else {
      if (data.client && data.client !== "")
        dataUrl += `organization.id=${data.client}&`;
      if (data.id !== "" || data.recipient !== null)
        dataUrl += `id=${data.id || data.recipient}&`;
      if (data.month !== "") dataUrl += `invoices.month=${data.month}&`;
      if (data.bool !== null) dataUrl += `invoices.isEmailSent=${data.bool}&`;
    }
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    if (location.pathname === "/admin/factures/convives") {
      from += "/invoices";
      dataUrl += "&discriminator[]=unit";
    }

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        if (location.pathname === "/admin/factures/clients") {
          setClientsList(result["hydra:member"]);
        } else {
          setRecipientsList(result["hydra:member"]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmit = (dataId, invoiceDate, invoiceComments, from) => {
    setLoading(true);
    const date = format(lastDayOfMonth(invoiceDate), "yyyy-MM-dd");
    let invoice = {
      date: date,
      comments: invoiceComments,
    };

    if (from === "clients") {
      invoice = {
        ...invoice,
        user: `/organizations/${dataId}`,
      };
    } else if (from === "recipients") {
      invoice = {
        ...invoice,
        recipient: `/recipients/${dataId}`,
      };
    }

    requests
      .post("/invoices", invoice, true)
      .then((response) => {
        const newItem = response["hydra:member"][0];
        let updatedInvoices = [...invoices];

        if (
          updatedInvoices.some(
            (invoice) => invoice.month === newItem.month && invoice.year
          )
        ) {
          updatedInvoices = updatedInvoices.map((invoice) => {
            if (invoice.id === newItem.id) {
              return newItem;
            }

            return invoice;
          });
        } else {
          updatedInvoices.unshift(newItem);
        }

        setInvoices(updatedInvoices);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "La facture a bien été créé !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const handleGenerate = (date, type) => {
    setLoading(true);
    const data = {
      date: format(lastDayOfMonth(date), "yyyy-MM-dd"),
      type,
    };

    requests
      .post("/invoices/generate", data, true)
      .then((response) => {
        if (type === "client") {
          const updatedClientsList = clients.map((c) => {
            const updatedInvoice = response["hydra:member"].find(
              (invoice) => invoice.organization.id === c.id
            );

            if (updatedInvoice) {
              const updatedInvoicesList = c.invoices.map((invoice) => {
                if (
                  invoice.month === updatedInvoice.month &&
                  invoice.year === updatedInvoice.year
                ) {
                  return updatedInvoice;
                }
                return invoice;
              });

              const updatedClient = {
                ...c,
                invoices: updatedInvoicesList,
              };

              return updatedClient;
            }

            return c;
          });

          setClientsList(updatedClientsList);
        } else {
          const updatedRecipientsList = recipients.map((recipient) => {
            const updatedInvoice = response["hydra:member"].find(
              (invoice) => invoice.recipient.id === recipient.id
            );

            if (updatedInvoice) {
              const updatedInvoicesList = recipient.invoices.map((invoice) => {
                if (
                  invoice.month === updatedInvoice.month &&
                  invoice.year === updatedInvoice.year
                ) {
                  return updatedInvoice;
                }
                return invoice;
              });

              const updatedRecipient = {
                ...recipient,
                invoices: updatedInvoicesList,
              };

              return updatedRecipient;
            }

            return recipient;
          });

          setRecipientsList(updatedRecipientsList);
        }

        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les factures ont bien été créés !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDownloadInvoice = (id, isInvoiceHistory) => {
    setLoading(true);
    let path = isInvoiceHistory ? "/invoice_histories/pdf" : "/invoices/pdf";

    superagent
      .get(`${ENTRYPOINT}${path}/${id}`)
      .use(tokenPlugin(true))
      .responseType("blob")
      .then(responseBody)
      .then((results) => {
        const fileUrl = URL.createObjectURL(results);
        window.open(fileUrl);
        URL.revokeObjectURL(fileUrl);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const exportInvoices = (mode, date, client, type) => {
    const month = format(date, "M");
    const year = format(date, "yyyy");
    setLoading(true);

    if (mode === "zip") {
      superagent
        .get(
          `${ENTRYPOINT}/invoices/zip?month=${month}&year=${year}&discriminator[]=${type}`
        )
        .use(tokenPlugin(true))
        .responseType("blob")
        .on("progress", (event) => {
          setIsFileDownloaded({
            returnValue: event.returnValue,
            loaded: event.loaded,
            timeStamp: event.timeStamp,
          });
        })
        .then(responseBody)
        .then((result) => {
          const fileUrl = URL.createObjectURL(result);
          const filename = `factures_${format(date, "MM_yyyy")}.zip`;

          const a = document.createElement("a");
          a.href = fileUrl;
          a.download = filename;

          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(fileUrl);
          setLoading(false);
          setAlert({
            active: true,
            type: "success",
            message: "Vous avez exportés des factures.",
          });
        })
        .catch((err) => {
          setLoading(false);
          setAlert({
            active: true,
            type: "error",
            message: err.message,
          });
          setIsFileDownloaded({
            isDownload: false,
            loaded: 0,
            timeStamp: 0,
          });
        });
    } else if (mode === "kpi") {
      const start = format(date, "yyyy-MM-01");
      const end = format(lastDayOfMonth(date), "yyyy-MM-dd");
      let url = `/commandes/export/${mode}?date[before]=${end}&date[after]=${start}`;
      if (client) url += `&organization.id=${client}`;

      superagent
        .get(`${ENTRYPOINT}${url}`)
        .use(tokenPlugin(true))
        .responseType("blob")
        .then(responseBody)
        .then((results) => {
          const fileUrl = URL.createObjectURL(results);
          const filename = `export_kpi_${start}_${end}.xlsx`;

          const a = document.createElement("a");
          a.href = fileUrl;
          a.download = filename;

          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(fileUrl);
          setLoading(false);
          setAlert({
            active: true,
            type: "success",
            message: "Les données ont été exportées !",
          });
        })
        .catch((err) => {
          setLoading(false);
          setAlert({
            active: true,
            type: "error",
            message: err.message,
          });
        });
    } else if (mode === "excel") {
      let url = `/invoices/export?month=${month}&year=${year}&discriminator[]=${type}`;
      if (client) url += `&clientId=${client}`;
      superagent
        .get(`${ENTRYPOINT}${url}`)
        .use(tokenPlugin(true))
        .responseType("blob")
        .then(responseBody)
        .then((results) => {
          const fileUrl = URL.createObjectURL(results);
          const filename = `factures_${year}${month}.xlsx`;

          const a = document.createElement("a");
          a.href = fileUrl;
          a.download = filename;

          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(fileUrl);
          setLoading(false);
          setAlert({
            active: true,
            type: "success",
            message: "Les données ont été exportées !",
          });
        })
        .catch((err) => {
          setLoading(false);
          setAlert({
            active: true,
            type: "error",
            message: err.message,
          });
        });
    }
  };
  const handleInvoiceDunning = async (invoiceId, from) => {
    setLoading(true);

    requests
      .post(
        "/invoices/dunning",
        { invoice: `/invoices/${invoiceId}`, from: from },
        true
      )
      .then((res) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "L'email de relance a été envoyée !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSendEmails = async (
    date,
    mode,
    clientId,
    recipientId,
    roundId
  ) => {
    setLoading(true);

    requests
      .post(
        "/invoices/emails",
        {
          date: date,
          mode: mode,
          client: clientId ? `/organizations/${clientId}` : null,
          recipient: recipientId ? `/recipients/${recipientId}` : null,
          round: roundId !== "" ? `/rounds/${roundId}` : null,
        },
        true
      )
      .then((res) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Envoyé avec succès.",
        });
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };

  return (
    <>
      {location.pathname === "/admin/factures/clients" && (
        <ClientsList
          userData={userData}
          client={client}
          clients={clients}
          clientsList={clientsList}
          invoices={invoices}
          loading={loading}
          open={open}
          handleSubmitFilters={handleSubmitFilters}
          toggleDrawer={toggleDrawer}
          validation={handleSubmit}
          handleGenerate={handleGenerate}
          handleDownloadInvoice={handleDownloadInvoice}
          exportInvoices={exportInvoices}
          isFileDownloaded={isFileDownloaded}
          handleInvoiceDunning={handleInvoiceDunning}
          from="clients"
        />
      )}

      {location.pathname === "/admin/factures/convives" && (
        <RecipientsList
          userData={userData}
          clients={clients}
          rounds={rounds}
          recipients={recipients}
          recipientsList={recipientsList}
          recipient={recipient}
          invoices={invoices}
          loading={loading}
          open={open}
          handleSubmitFilters={handleSubmitFilters}
          toggleDrawer={toggleDrawer}
          validation={handleSubmit}
          handleGenerate={handleGenerate}
          handleDownloadInvoice={handleDownloadInvoice}
          exportInvoices={exportInvoices}
          isFileDownloaded={isFileDownloaded}
          handleInvoiceDunning={handleInvoiceDunning}
          handleSendEmails={handleSendEmails}
          from="recipients"
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          sx={{ zIndex: "tooltip" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default InvoiceContainer;
