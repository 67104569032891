import * as React from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./configureStore";
import { injectStore } from "./agent";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./App";
import Login from "./components/login/Login";
import ResetPassword from "./components/resetPassword/ResetPassword";
import OrganizationContainer from "./components/admin/Organizations/OrganizationContainer";
import ProduitContainer from "./components/admin/Produits/ProduitContainer";
import AllergeneContainer from "./components/admin/Allergenes/AllergeneContainer";
import FormuleContainer from "./components/admin/Formules/FormuleContainer";
import MenuContainer from "./components/admin/Menus/MenuContainer";
import DeliveryContainer from "./components/admin/Deliveries/DeliveryContainer";
import SupplementContainer from "./components/admin/Supplements/SupplementContainer";
import RegimeContainer from "./components/admin/Regimes/RegimeContainer";
import RecipientContainer from "./components/admin/Recipients/RecipientContainer";
import OrderContainer from "./components/admin/Orders/OrderContainer";
import InvoiceContainer from "./components/admin/Invoices/InvoiceContainer";
import AbsenceContainer from "./components/admin/Absences/AbsenceContainer";
import ProfileContainer from "./components/admin/Profile/ProfileContainer";
import CategoryContainer from "./components/admin/Categories/CategoryContainer";
import Dashboard from "./components/admin/Dashboard/Dashboard";
import Home from "./components/home/Index";
import { isAdmin, isSuperAdmin } from "./utils";
import PageNotFound from "./404";
import { adminTheme } from "./theme";
import ExtraCategoryContainer from "./components/admin/ExtraCategories/ExtraCategoryContainer";
import TaxContainer from "./components/admin/Taxes/TaxContainer";
import CreditorContainer from "./components/admin/Creditors/CreditorContainer";
import CreditNoteContainer from "./components/admin/CreditNotes/CreditNoteContainer";
import UserContainer from "./components/admin/Users/UserContainer";
import ServiceContainer from "./components/admin/Services/ServiceContainer";
import PlanContainer from "./components/admin/Plans/PlanContainer";
import AddOnContainer from "./components/admin/AddOns/AddOnContainer";
import SubscriptionContainer from "./components/admin/Subscriptions/SubscriptionContainer";
import RoundContainer from "./components/admin/Rounds/RoundContainer";
import CustomDeliveryNoteContainer from "./components/admin/Customizations/DeliveryNote/CustomDeliveryNoteContainer";
import DriverContainer from "./components/admin/Drivers/DriverContainer";

injectStore(store);

const RequireAuth = ({ children, redirectTo }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const ProtectedRoute = ({ children }) => {
  const userData = useSelector((state) => state.auth.userData);
  return isAdmin(userData) ? children : <Navigate to="*" />;
};

const SuperProtectedRoute = ({ children }) => {
  const userData = useSelector((state) => state.auth.userData);
  return isSuperAdmin(userData) ? children : <Navigate to="*" />;
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={adminTheme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="admin"
              exact
              element={
                <RequireAuth redirectTo="/login">
                  <App />
                </RequireAuth>
              }
            >
              <Route path="dashboard" element={<Dashboard />} />
              <Route
                path="clients"
                element={
                  <ProtectedRoute>
                    <OrganizationContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="produits"
                element={
                  <ProtectedRoute>
                    <ProduitContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="allergenes"
                element={
                  <ProtectedRoute>
                    <AllergeneContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="categories/produit"
                element={
                  <ProtectedRoute>
                    <CategoryContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="categories/supplement"
                element={
                  <ProtectedRoute>
                    <ExtraCategoryContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="formules"
                element={
                  <ProtectedRoute>
                    <FormuleContainer />
                  </ProtectedRoute>
                }
              />
              <Route path="menus" element={<MenuContainer />} />
              <Route path="commandes" element={<OrderContainer />} />
              <Route path="factures/clients" element={<InvoiceContainer />} />
              <Route path="factures/convives" element={<InvoiceContainer />} />
              <Route path="avoirs" element={<CreditNoteContainer />} />
              <Route path="livraisons" element={<DeliveryContainer />} />
              <Route
                path="supplements"
                element={
                  <ProtectedRoute>
                    <SupplementContainer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="regimes"
                element={
                  <ProtectedRoute>
                    <RegimeContainer />
                  </ProtectedRoute>
                }
              />
              <Route path="tournees" element={<RoundContainer />} />
              <Route path="convives" element={<RecipientContainer />} />
              <Route path="absences" element={<AbsenceContainer />} />
              <Route
                path="taxes"
                element={
                  <ProtectedRoute>
                    <TaxContainer />
                  </ProtectedRoute>
                }
              />
              <Route path="creanciers" element={<CreditorContainer />} />
              <Route path="utilisateurs" element={<UserContainer />} />
              <Route path="services" element={<ServiceContainer />} />
              <Route
                path="offres"
                element={
                  <SuperProtectedRoute>
                    <PlanContainer />
                  </SuperProtectedRoute>
                }
              />
              <Route
                path="extensions"
                element={
                  <SuperProtectedRoute>
                    <AddOnContainer />
                  </SuperProtectedRoute>
                }
              />
              <Route path="souscriptions" element={<SubscriptionContainer />} />
              <Route path="profile" element={<ProfileContainer />} />
              <Route path="personnalisations">
                <Route
                  path="bons_livraison"
                  element={<CustomDeliveryNoteContainer />}
                />
                <Route path="factures" element={<ProfileContainer />} />
              </Route>
              <Route path="drivers" element={<DriverContainer />} />
            </Route>
            <Route path="/" exact element={<Home />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/reset/password/change" element={<ResetPassword />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.querySelector("#root")
);
