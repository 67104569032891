import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button, LinearProgress, Paper, Stack, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import React from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import Filters from "../../Common/Filters";
import { isAdmin } from "../../../../utils";

const List = (props) => {
  const classes = UseStyle();
  const getDeliveryDays = (deliveryDays) => {
    const jours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];

    const joursRepasLivre = deliveryDays.map((jour, index) => {
      if (jour.estActif) {
        return (
          `${jours[index]} => (` +
          jour.repasDu.map((repas) => `${jours[repas]}`).join(", ") +
          ")"
        );
      }

      return null;
    });

    return joursRepasLivre.filter((item) => item !== null).join(", ");
  };
  let columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "deliveryDays",
      headerName: "Jours livrés",
      flex: 3,
      valueGetter: ({ value }) => getDeliveryDays(value),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
      ],
    },
  ];
  let types = ["fullName"];

  if (isAdmin(props.userData)) {
    columns.splice(1, 0, {
      field: "organization",
      headerName: "Client",
      flex: 1,
      valueGetter: ({ value }) => (value ? value.name : ""),
    });
    types.splice(0, 0, "client");
  }

  return (
    <Stack spacing={2} marginBottom={2}>
      <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>
        <LoadingButton
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
          loading={props.isLoading}
        >
          Tournée
        </LoadingButton>
      </Stack>

      <Filters
        userData={props.userData}
        types={types}
        from="rounds"
        clients={props.organizations}
        handleSubmitFilters={props.handleSubmitFilters}
        loading={props.isLoading}
      />

      <Paper className={classes.paper} elevation={6}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={props.rounds}
          pageSize={30}
          density="comfortable"
          disableSelectionOnClick
          pagination
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          loading={props.isLoading}
          experimentalFeatures={{ newEditingApi: true }}
          getRowHeight={() => "auto"}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </Paper>
    </Stack>
  );
};

export default List;
