import { Grid, Typography } from "@mui/material";
import React from "react";
import "./delivery.css";
import emoji from "../../../../assets/img/home/Emoji.svg";

const Delivery = () => {
  return (
    <div className="delivery">
      <Grid
        container
        flexDirection="column"
        spacing={2}
        alignItems="center"
        padding={3}
        minHeight="55vh"
      >
        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <img src={emoji} alt="emoji" />
          </Grid>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              <strong>BONS BAISERS DU SUD</strong>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          borderRadius={3}
          style={{ backgroundColor: "rgba(255,255,255,.5)" }}
        >
          <Typography variant="h3" color="primary" textAlign="center">
            <strong>Basil vous livre sur</strong>
          </Typography>

          <Typography variant="h5" textAlign="center">
            Tous les arrondissements de Marseille, Aix-en-Provence et bientôt
            dans toute la France
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Delivery;
