import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomDeliveryNoteForm from "../CustomDeliveryNoteForm";
import UseStyle from "../../../Common/StyledComponent/UseStyle";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Personnalisation d'un bon de livraison"
        />
      </Card>

      <CustomDeliveryNoteForm {...props} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    title: props.customDeliveryNote.title,
    footer: props.customDeliveryNote.footer,
    file: props.customDeliveryNote.file,
    contentUrl: props.customDeliveryNote.contentUrl,
    showAddressNotes: props.customDeliveryNote.showAddressNotes,
    bannerColor: props.customDeliveryNote.bannerColor,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string(),
    footer: Yup.string(),
    showAddressNotes: Yup.boolean(),
    bannerColor: Yup.string()
      .min(6, "Une couleur hexadecimal contient au minimum 6 caractères.")
      .max(6, "Une couleur hexadecimal contient au maximum 6 caractères."),
  }),
  handleSubmit: (values, { props }) => {
    const updatedItem = {
      id: props.customDeliveryNote.id,
      title: values.title,
      footer: values.footer,
      file: values.file,
      showAddressNotes: values.showAddressNotes,
      bannerColor:
        values.bannerColor !== "" ? values.bannerColor.toUpperCase() : null,
    };

    props.validation(updatedItem);
  },
})(Edit);
