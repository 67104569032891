import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { ENTRYPOINT } from "./config/entrypoint";
import { logout } from "./slices/authSlice";

const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = (response) => response.body;

let token = null;

const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) request.set("Authorization", `Bearer ${token}`);
  };
};

let store;

export const injectStore = (_store) => {
  store = _store;
};

const handleError = (err) => {
  console.error(err);

  if (err.response && [401, 403].includes(err.response.status)) {
    store.dispatch(logout({ hasSessionExpired: true }));
  }
  let message = err.response.status + " : Une erreur est survenue !";
  if (err.response.body && err.response.body["hydra:description"]) {
    message = err.response.body["hydra:description"];
  } else if (err.response.body && err.response.body.message) {
    message = err.response.body.message;
  }

  const error = {
    message,
    code: err.response.status,
  };

  return Promise.reject(error);
};

export const requests = {
  get: (url, secured = false) =>
    superagent
      .get(`${ENTRYPOINT}${url}`)
      .use(tokenPlugin(secured))
      .then(responseBody)
      .catch(handleError),
  post: (url, body = null, secured = true) =>
    superagent
      .post(`${ENTRYPOINT}${url}`, body)
      .use(tokenPlugin(secured))
      .then(responseBody)
      .catch(handleError),
  patch: (url, body = null, secured = false) =>
    superagent
      .patch(`${ENTRYPOINT}${url}`, body)
      .use(tokenPlugin(secured))
      .then(responseBody)
      .catch(handleError),
  put: (url, body = null, secured = false) =>
    superagent
      .put(`${ENTRYPOINT}${url}`, body)
      .use(tokenPlugin(secured))
      .set("Content-Type", "application/ld+json")
      .then(responseBody)
      .catch(handleError),
  del: (url, secured = false) =>
    superagent
      .del(`${ENTRYPOINT}${url}`)
      .use(tokenPlugin(secured))
      .then(responseBody)
      .catch(handleError),
};
