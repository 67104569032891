import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { format } from "date-fns";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import MenuForm from "../MenuForm";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={props.toggleList}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Modification des informations du menu."
          title="Modification d'un menu"
        />
      </Card>

      <MenuForm {...props} edit={props.edit} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    ...props.dataEdit,
    name: props.dataEdit.name,
    date: props.dataEdit.date,
    priorityIndex: props.dataEdit.priorityIndex,
    description: props.dataEdit.description,
    lunchProducts: props.dataEdit.lunchProducts,
    dinnerProducts: props.dataEdit.dinnerProducts,
    lunchExtras: props.dataEdit.lunchExtras,
    dinnerExtras: props.dataEdit.dinnerExtras,
    rounds: props.dataEdit.rounds.map((item) => item.id),
    diets: props.dataEdit.diets.map((item) => item.id),
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Le nom du menu est obligatoire")
      .min(1, "Le nom du menu doit avoir au minimum 1 caractère alphanumérique")
      .max(2, "Le nom du menu doit avoir au maximum 2 caractères alphanumérique")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Le nom du menu doit être composé de lettres ou chiffres"
      ),
    date: Yup.date("Saisissez une date").required().min("2021-01-01"),
    priorityIndex: Yup.number()
      .nullable()
      .min(-2000000, "La priorité ne peut pas être en dessous de -2 000 000")
      .max(2000000, "La priorité ne peut pas être au dessus de 2 000 000"),
    description: Yup.string()
      .max(255, "La description ne peut contenir que 255 caractères.")
      .nullable(),
    lunchProducts: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().positive(),
        })
      )
      .min(1, "Le menu doit être composé d'au moins un produit")
      .test(
        "max-one-product-by-category",
        "Vous ne pouvez pas ajouter plus d'un produit par catégorie",
        (lunchProducts) => {
          let categories = [];
          lunchProducts.forEach((dp) => {
            if (
              categories[dp?.produit?.categorie.id ?? dp.categorie.id] ===
              undefined
            ) {
              categories[dp?.produit?.categorie.id ?? dp.categorie.id] = 0;
            }

            categories[dp?.produit?.categorie.id ?? dp.categorie.id] += 1;
          });

          return categories.every((c) => c <= 1);
        }
      ),
    dinnerProducts: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().positive(),
          estPrioritaire: Yup.boolean(),
        })
      )
      .test(
        "max-one-product-by-category",
        "Vous ne pouvez pas ajouter plus d'un produit par catégorie",
        (dinnerProducts) => {
          let categories = [];
          dinnerProducts.forEach((dp) => {
            if (
              categories[dp?.produit?.categorie.id ?? dp.categorie.id] ===
              undefined
            ) {
              categories[dp?.produit?.categorie.id ?? dp.categorie.id] = 0;
            }

            categories[dp?.produit?.categorie.id ?? dp.categorie.id] += 1;
          });

          return categories.every((c) => c <= 1);
        }
      ),
    rounds: Yup.array()
      .of(Yup.number().positive())
      .min(1, "Un minimum d'une tournée est obligatoire"),
    diets: Yup.array().of(Yup.number().positive()),
  }),
  handleSubmit: (values, { props }) => {
    const menu = {
      name: values.name,
      date: values.date,
      priorityIndex: values.priorityIndex !== "" ? values.priorityIndex : null,
      description: values.description,
      lunchProducts: values.lunchProducts.map(
        (product) => `/produits/${product.id}`
      ),
      dinnerProducts: values.dinnerProducts.map(
        (product) => `/produits/${product.id}`
      ),
      lunchExtras: values.lunchExtras.map((item) => `/supplements/${item.id}`),
      dinnerExtras: values.dinnerExtras.map(
        (item) => `/supplements/${item.id}`
      ),
      rounds: values.rounds.map((id) => `/rounds/${id}`),
      diets: values.diets.map((id) => `/regimes/${id}`),
    };

    props.validation(props.dataEdit.id, menu);
  },
})(Edit);
