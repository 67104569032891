import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React from "react";
import worker from "../../../../../assets/img/home/basil-7.jpg";
import seniors from "../../../../../assets/img/home/basil-9-coupe.jpg";
import plat from "../../../../../assets/img/home/dishes/lieu-pistou-aneth-pommes-de-terre-fondue-poireaux.jpg";
import icon from "../../../../../assets/img/home/plat_1.svg";
import "../feature.css";

const Second = () => {
  const items = [
    "Ceux qui cherchent un confort de vie avec la livraison de repas variés et savoureux.",
    "Ceux qui souhaitent bénéficier d'un accompagnement temporaire de qualité suite à une hospitalisation",
    "Ceux qui sont en perte d'autonomie, ou avec un handicap",
  ];
  return (
    <div style={{ paddingY: "3rem" }}>
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={6}
          container
          flexDirection="column"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <img src={icon} alt="icone petit plat" />
          </Grid>

          <Grid item>
            <Typography variant="h4" color="primary">
              On ne vous prend pas
              <br />
              pour des prunes
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" fontSize={18}>
              <strong>
                Peu importe votre âge et vos besoins, le service Basil s'adapte
                à :
              </strong>
            </Typography>

            <List disablePadding>
              {items.map((item, i) => (
                <ListItem key={i}>
                  <ListItemIcon>
                    <FavoriteIcon sx={{ color: "#E60000" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontSize={18}>
                        <strong>{item}</strong>
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} container alignItems="center" padding={3}>
          <Grid item xs={6} md={4}>
            <img src={worker} alt="worker" className="worker cover" />
          </Grid>
          <Grid item xs={6} md={8}>
            <img src={seniors} alt="seniors" className="seniors cover" />
            <img src={plat} alt="" className="plat cover" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Second;
