import React, { useEffect } from "react";
import TransferList from "../Common/TransfertList/TransferList";
import {
  CircularProgress,
  Grid,
  TextField,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

export const FormuleForm = (props) => {
  const handleSelectedCategories = (item) => {
    props.setFieldValue("categories", item);
  };
  const handleSelectedExtraCategories = (item) => {
    props.setFieldValue("extraCategories", item);
  };

  useEffect(() => {
    let totalTtc = 0;
    let totalHt = 0;
    let repasHt = 0;
    let serviceHt = 0;
    let tva = props.taxes.find((t) => t.id === props.values.tax).taux;

    if (props.values.repasTtc !== "" && props.values.serviceTtc !== "") {
      repasHt = parseFloat(props.values.repasTtc) / (1 + tva);
      serviceHt = parseFloat(props.values.serviceTtc) / (1 + tva);
      totalHt = repasHt + serviceHt;
      totalTtc =
        parseFloat(props.values.repasTtc) + parseFloat(props.values.serviceTtc);
    }
    props.setFieldValue("repasHt", repasHt);
    props.setFieldValue("serviceHt", serviceHt);
    props.setFieldValue("totalHt", totalHt);
    props.setFieldValue("totalTtc", totalTtc);
  }, [props.values.repasTtc, props.values.serviceTtc, props.values.tax]);

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <TextField
            error={props.errors.nom && props.touched.nom ? true : false}
            {...(props.errors.nom &&
              props.touched.nom && { helperText: props.errors.nom })}
            fullWidth
            name={"nom"}
            label={"Nom"}
            value={props.values.nom}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>

        <Grid item xs={3} sx={{ mt: 1 }}>
          <FormGroup>
            <FormControlLabel
              onChange={props.handleChange}
              checked={props.values.estDiner}
              name={"estDiner"}
              onBlur={props.handleBlur}
              control={<Checkbox />}
              label="Formule du soir"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel>Taxes</InputLabel>
            <Select
              error={props.errors.tax && props.touched.tax ? true : false}
              {...(props.errors.tax &&
                props.touched.tax && { helperText: props.errors.tax })}
              label="Taxes"
              name={"tax"}
              value={props.values.tax}
              onChange={(event) =>
                props.setFieldValue("tax", event.target.value)
              }
              onBlur={props.handleBlur}
              style={{ textAlign: "left" }}
            >
              {props.taxes.length > 0 &&
                props.taxes.map((tax) => {
                  return (
                    <MenuItem key={tax.id} value={tax.id}>
                      {tax.nom}%
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="repasTtc">Repas TTC</InputLabel>
            <OutlinedInput
              id="repasTtc"
              value={props.values.repasTtc}
              onChange={(event) =>
                props.setFieldValue("repasTtc", event.target.value)
              }
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              label="Repas TTC"
              type="number"
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="repasHt">Repas HT</InputLabel>
            <OutlinedInput
              readOnly
              id="repasHt"
              value={props.values.repasHt}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              label="Repas HT"
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} />

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="serviceTtc">Service TTC</InputLabel>
            <OutlinedInput
              id="serviceTtc"
              value={props.values.serviceTtc}
              onChange={(event) =>
                props.setFieldValue("serviceTtc", event.target.value)
              }
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              label="Service TTC"
              type="number"
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="serviceHt">Service HT</InputLabel>
            <OutlinedInput
              readOnly
              id="serviceHt"
              value={props.values.serviceHt}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              label="Service HT"
            />
          </FormControl>
        </Grid>

        <Grid item xs={4} />

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="totalTtc">Total TTC</InputLabel>
            <OutlinedInput
              readOnly
              id="totalTtc"
              value={props.values.totalTtc}
              onChange={(event) =>
                props.setFieldValue("totalTtc", event.target.value)
              }
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              label="Total TTC"
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="totalHt">Total HT</InputLabel>
            <OutlinedInput
              readOnly
              id="totalHt"
              value={props.values.totalHt}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              label="Total HT"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Composition de la formule</Typography>

          <Typography variant="subtitle1" align={"center"} gutterBottom>
            Catégorie de plat
          </Typography>

          <TransferList
            left={
              props.values.categories.length > 0
                ? props.categories.ids.filter(
                    (id) =>
                      !props.values.categories
                        .map((item) => item.id)
                        .includes(id)
                  )
                : props.categories.ids
            }
            right={props.values.categories}
            chosen={handleSelectedCategories}
            items={props.categories.datas}
            categories={[]}
            regimes={[]}
            qte={true}
          />
          <input type="hidden" values={props.values.categories} />

          <Typography
            variant="subtitle1"
            align={"center"}
            gutterBottom
            marginTop={2}
          >
            Catégorie de suppléments
          </Typography>

          <TransferList
            left={
              props.values.extraCategories.length > 0
                ? props.extraCategories.ids.filter(
                    (id) =>
                      !props.values.extraCategories
                        .map((item) => item.id)
                        .includes(id)
                  )
                : props.extraCategories.ids
            }
            right={props.values.extraCategories}
            chosen={handleSelectedExtraCategories}
            items={props.extraCategories.datas.map((item) => ({
              ...item,
              id: item.id,
              nom: item.name,
            }))}
            categories={[]}
            regimes={[]}
          />

          <input type="hidden" values={props.values.extraCategories} />
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {props.loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              color="primary"
              onClick={props.handleSubmit}
              variant="contained"
              endIcon={<SendIcon />}
            >
              {props.add ? "Créer" : "Modifier"} la formule
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
