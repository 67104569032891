import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Filters from "../../Common/Filters";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import DrawerInformations from "../DrawerInformations";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LoadingButton from "@mui/lab/LoadingButton";
import DescriptionIcon from "@mui/icons-material/Description";
import UpdateIcon from "@mui/icons-material/Update";
import CachedIcon from "@mui/icons-material/Cached";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import OfflinePinRoundedIcon from "@mui/icons-material/OfflinePinRounded";
import UserInvoices from "../UserInvoices";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { isAdmin, isFinanceAdmin } from "../../../../utils";
import { locale, mask } from "../../../../localisation";
import { toFixedTrunc } from "../../Common/toFixedTrunc";
import { format } from "date-fns";

const GenerateInvoicesDialog = ({
  loading,
  isGenerating,
  handleClose,
  handleGenerate,
}) => {
  const formik = useFormik({
    initialValues: {
      date: new Date(),
    },
    validationSchema: Yup.object({
      date: Yup.date().required().typeError("Date invalide"),
    }),
    onSubmit: (values) => {
      handleGenerate(values.date, "client");
      handleClose();
    },
  });

  return (
    <Dialog open={isGenerating} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        Génération des factures.
        <DialogContentText>
          Selectionner un mois pour générer les factures.
        </DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={formik.errors.date && formik.touched.date ? true : false}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  views={["month", "year"]}
                  label="Choississez le mois"
                  inputFormat="MM/yyyy"
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </FormHelperText>
            </FormControl>
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<CachedIcon />}
              onClick={formik.handleSubmit}
            >
              Générer
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};
const ExportDialog = ({
  loading,
  isOpen,
  handleClose,
  validation,
  isFileDownloaded,
  clients,
}) => {
  const [selectedClient, setSelectedClient] = useState(null);

  const formik = useFormik({
    initialValues: {
      mode: "zip",
      date: new Date(),
      client: "",
      all: false,
    },
    validationSchema: Yup.object({
      date: Yup.date().required().typeError("Date invalide"),
    }),
    onSubmit: (values, { actions }) => {
      validation(values.mode, values.date, values.client, "client");
      actions.resetForm();
    },
  });

  const values = clients.map((o) => {
    const filtre = o.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: o,
    };
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        formik.handleReset();
        setSelectedClient(null);
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        Exporter des données.
        <DialogContentText>
          Selectionnez un mode et remplissez les différents champs afin
          d'exporter les données souhaitées.
        </DialogContentText>
      </DialogTitle>

      <form>
        <DialogContent dividers>
          <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <FormControl>
              <FormLabel id="export-mode-group">Mode</FormLabel>
              <RadioGroup
                row
                aria-labelledby="export-mode-group"
                name="row-radio-buttons-group"
                value={formik.values.mode}
                onChange={(event) =>
                  formik.setFieldValue("mode", event.target.value)
                }
              >
                <FormControlLabel
                  value="zip"
                  control={<Radio />}
                  label="Factures (.zip)"
                />
                <FormControlLabel
                  value="excel"
                  control={<Radio />}
                  label="Factures (.xlsx)"
                />
                <FormControlLabel value="kpi" control={<Radio />} label="KPI" />
              </RadioGroup>
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              required
              error={formik.errors.date && formik.touched.date ? true : false}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  views={["month", "year"]}
                  label="Choississez le mois"
                  inputFormat="MM/yyyy"
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </FormHelperText>
            </FormControl>

            {formik.values.mode === "kpi" && (
              <>
                <Autocomplete
                  {...(formik.values.all && { disabled: true })}
                  fullWidth
                  options={values.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.client.id === value.client.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.client) return "";
                    return option.client.id + " - " + option.client.name;
                  }}
                  value={selectedClient}
                  onChange={(event, value) => {
                    setSelectedClient(value);

                    value !== null
                      ? formik.setFieldValue("client", value.client.id)
                      : formik.setFieldValue("client", null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Client" />
                  )}
                />

                <FormControlLabel
                  value={formik.values.all}
                  checked={formik.values.all}
                  onChange={(event) => {
                    formik.setFieldValue("all", event.target.checked);
                    if (event.target.checked)
                      formik.setFieldValue("client", null);
                  }}
                  control={<Checkbox />}
                  label="Tous les clients"
                />
              </>
            )}
          </Stack>
        </DialogContent>
      </form>

      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            {isFileDownloaded.returnValue && (
              <Stack direction={"row"} spacing={1}>
                <OfflinePinRoundedIcon color="sucess" />
                <Typography variant="body1">
                  {toFixedTrunc(isFileDownloaded.loaded / (1024 * 1024), 2)} Mo
                  téléchargés en{" "}
                  {toFixedTrunc(isFileDownloaded.timeStamp / 1000 / 60, 2)}{" "}
                  minutes
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={4}>
            <Stack direction={"row"} spacing={2}>
              <Button
                {...(loading && { disabled: true })}
                onClick={() => {
                  handleClose();
                  formik.handleReset();
                  setSelectedClient(null);
                }}
              >
                Annuler
              </Button>
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                startIcon={<FileDownloadRoundedIcon />}
                onClick={formik.handleSubmit}
              >
                Exporter
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const ClientsList = (props) => {
  const classes = UseStyle();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Client",
      flex: 1,
    },
    {
      field: "invoices",
      headerName: "Dernière facture le",
      flex: 0.7,
      valueGetter: ({ value }) => {
        let date = "";
        const lastInvoice = value.pop();

        if (lastInvoice) {
          date = format(
            new Date(lastInvoice?.updatedAt ?? lastInvoice.createdAt),
            "dd/MM/yyyy HH:mm"
          );
        }

        return date;
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Afficher les factures">
              <DescriptionIcon />
            </Tooltip>
          }
          onClick={props.toggleDrawer(
            true,
            params.row.id,
            "invoice_clients",
            "organization"
          )}
          label="Afficher"
        />,
      ],
    },
  ];
  const handleClose = () => {
    setIsGenerating(false);
    setIsExporting(false);
  };

  return (
    <>
      {isAdmin(props.userData) && (
        <Grid container justifyContent={"space-between"} paddingBottom={2}>
          <Grid item>
            <Tooltip title="Générer les factures du mois actuel">
              <Button
                color="secondary"
                variant="contained"
                startIcon={<UpdateIcon />}
                onClick={() => setIsGenerating(true)}
              >
                Génération
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              <Button disabled>Import</Button>
              <Button onClick={() => setIsExporting(true)}>Export</Button>
            </Stack>
          </Grid>
        </Grid>
      )}

      {isAdmin(props.userData) || isFinanceAdmin(props.userData) ? (
        <Stack spacing={2}>
          <Filters
            userData={props.userData}
            types={["client"]}
            clients={props.clients}
            from="organizations/lists"
            handleSubmitFilters={props.handleSubmitFilters}
            loading={props.loading}
          />

          <Paper className={classes.paper} elevation={6}>
            <Grid container>
              <DataGrid
                autoHeight
                columns={columns}
                rows={props.clientsList}
                getRowHeight={() => "auto"}
                disableSelectionOnClick
                pagination
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                loading={props.loading}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "fullName", sort: "asc" }],
                  },
                }}
              />
            </Grid>
          </Paper>
        </Stack>
      ) : (
        <Paper className={classes.paper} elevation={6}>
          <UserInvoices {...props} />
        </Paper>
      )}

      <Drawer
        anchor="right"
        open={props.open}
        client={props.client}
        onClose={props.toggleDrawer(false, null, null)}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <DrawerInformations
          {...props}
          data={props.client}
          invoices={props.invoices}
          from={props.from}
        />
      </Drawer>

      <GenerateInvoicesDialog
        loading={props.loading}
        isGenerating={isGenerating}
        handleClose={handleClose}
        handleGenerate={props.handleGenerate}
      />

      <ExportDialog
        loading={props.loading}
        isOpen={isExporting}
        handleClose={handleClose}
        validation={props.exportInvoices}
        isFileDownloaded={props.isFileDownloaded}
        clients={props.clients}
      />
    </>
  );
};

export default ClientsList;
