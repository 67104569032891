import {
  Card,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import React from "react";
import { CardContentNoPadding } from "../../common/Formules/Formules";

const Formules = (props) => {
  const dej = {
    name: "Déjeuner",
    compo: ["Entrée", "Plat", "Dessert", "Fromage", "Pain bio"],
  };

  const din = [
    {
      name: "Dîner 1",
      compo: ["Potage", "Dessert"],
    },
    {
      name: "Dîner 2",
      compo: ["Entrée", "Plat", "Dessert", "Pain bio"],
    },
  ];
  return (
    <Grid container spacing={5} flexDirection="column" alignItems="center">
      <Grid item>
        <Card
          variant="outlined"
          sx={{
            width: { xs: 295, md: 345 },
            boxShadow: `0 7px 12px rgb(${props.colors[0].rgb} / 0.3)`,
            borderRadius: "21px",
          }}
        >
          <CardHeader
            title={dej.name}
            sx={{
              textAlign: "center",
              backgroundColor: props.colors[0].hex,
            }}
          />
          <CardContentNoPadding>
            <List
              style={{
                maxHeight: "100%",
                width: "100%",
                overflow: "auto",
              }}
            >
              {dej.compo.map((c, i) => (
                <ListItem disablePadding key={i}>
                  <ListItemButton>
                    <TaskAltRoundedIcon color="secondary" />
                    <ListItemText primary={c} sx={{ paddingLeft: 3 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </CardContentNoPadding>
        </Card>
      </Grid>

      <Grid
        item
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        {din.map((formule, i) => (
          <Grid key={i} item>
            <Card
              variant="outlined"
              sx={{
                width: { xs: 295, md: 345 },
                boxShadow: `0 7px 12px rgb(${props.colors[3].rgb} / 0.3)`,
                borderRadius: "21px",
              }}
            >
              <CardHeader
                title={formule.name}
                sx={{
                  textAlign: "center",
                  backgroundColor: props.colors[3].hex,
                }}
              />
              <CardContentNoPadding>
                <List
                  style={{
                    maxHeight: "100%",
                    width: "100%",
                    overflow: "auto",
                  }}
                >
                  {formule.compo.map((c, i) => (
                    <ListItem disablePadding key={i}>
                      <ListItemButton>
                        <TaskAltRoundedIcon color="secondary" />
                        <ListItemText primary={c} sx={{ paddingLeft: 3 }} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </CardContentNoPadding>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid item container justifyContent="end" alignItems="end">
        <Typography
          variant="body1"
          sx={{
            border: "1px solid white",
            borderRadius: "21px",
            padding: 2,
            background: "rgba(249, 249, 249)",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
          }}
        >
          <strong>Suppléments</strong>
          <br />- Petit pain Bio & solidaire
          <br />- Laitage bio ou Fromage
          <br />- Potage
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Formules;
