import React from "react";
import {
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormHelperText,
  OutlinedInput,
  ListItemText,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const Pilote = (props) => {
  return (
    <Grid container spacing={2} alignItems="center">
      {props.values.pilotes.length >= 1 ? (
        props.values.pilotes.map((pilote, index) => (
          <Grid
            key={index}
            item
            container
            md={12}
            spacing={2}
            alignItems="center"
          >
            <Grid item md={2}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={
                  props.errors.pilotes &&
                  props.errors.pilotes[index] &&
                  props.errors.pilotes[index].round &&
                  props.touched.pilotes &&
                  props.touched.pilotes[index] &&
                  props.touched.pilotes[index].round
                    ? true
                    : false
                }
              >
                <InputLabel id="round-label">Tournée</InputLabel>
                <Select
                  fullWidth
                  labelId="round-label"
                  id="round"
                  name={`pilotes[${index}].round`}
                  value={pilote.round}
                  onChange={props.handleChange}
                  input={<OutlinedInput label="Tournée" />}
                >
                  {props.rounds.map((r, i) => (
                    <MenuItem value={r.id} key={i}>
                      <ListItemText primary={r.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {props.errors.pilotes &&
                    props.errors.pilotes[index] &&
                    props.errors.pilotes[index].round &&
                    props.touched.pilotes &&
                    props.touched.pilotes[index] &&
                    props.touched.pilotes[index].round &&
                    props.errors.pilotes[index].round}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={2}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={
                  props.errors.pilotes &&
                  props.errors.pilotes[index] &&
                  props.errors.pilotes[index].lunchMenu &&
                  props.touched.pilotes &&
                  props.touched.pilotes[index] &&
                  props.touched.pilotes[index].lunchMenu
                    ? true
                    : false
                }
              >
                <InputLabel id="lunchMenu-label">Formule Déj</InputLabel>
                <Select
                  fullWidth
                  labelId="lunchMenu-label"
                  id="lunchMenu"
                  name={`pilotes[${index}].lunchMenu`}
                  value={pilote.lunchMenu}
                  onChange={props.handleChange}
                  input={<OutlinedInput label="Formule Déj" />}
                >
                  {props.formules
                    .filter((formule) => !formule.estDiner)
                    .map((f, i) => (
                      <MenuItem value={f.id} key={i}>
                        {f.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {props.errors.pilotes &&
                    props.errors.pilotes[index] &&
                    props.errors.pilotes[index].lunchMenu &&
                    props.touched.pilotes &&
                    props.touched.pilotes[index] &&
                    props.touched.pilotes[index].lunchMenu &&
                    props.errors.pilotes[index].lunchMenu}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="extras-lunch-label">
                  Suppléments déjeuner
                </InputLabel>

                <Select
                  fullWidth
                  multiple
                  labelId="extras-lunch-label"
                  id="lunchExtras"
                  name={`pilotes[${index}].lunchExtras`}
                  value={pilote.lunchExtras}
                  onChange={props.handleChange}
                  input={<OutlinedInput label="Suppléments déjeuner" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (s) =>
                          props.extraCategories.find(
                            (category) => category.id === s
                          ).name
                      )
                      .join(", ")
                  }
                >
                  {props.extraCategories.map((extraCategory, i) => (
                    <MenuItem value={extraCategory.id} key={i}>
                      <Checkbox
                        checked={props.values.pilotes[
                          index
                        ].lunchExtras.includes(extraCategory.id)}
                      />
                      <ListItemText primary={extraCategory.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="dinnerMenu-label">Formule Dîner</InputLabel>
                <Select
                  fullWidth
                  labelId="dinnerMenu-label"
                  id="dinnerMenu"
                  name={`pilotes[${index}].dinnerMenu`}
                  value={pilote.dinnerMenu}
                  onChange={props.handleChange}
                  input={<OutlinedInput label="Formule Dîner" />}
                >
                  <MenuItem value={"none"}>
                    <i>Aucune</i>
                  </MenuItem>
                  {props.formules
                    .filter((formule) => formule.estDiner)
                    .map((f, i) => (
                      <MenuItem value={f.id} key={i}>
                        {f.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={1}>
              <FormControl
                variant="outlined"
                fullWidth
                {...(props.values.pilotes[index].dinnerMenu === "none" && {
                  disabled: true,
                })}
              >
                <InputLabel id="extras-dinner-label">
                  Suppléments dîner
                </InputLabel>

                <Select
                  fullWidth
                  multiple
                  labelId="extras-dinner-label"
                  id="dinnerExtras"
                  name={`pilotes[${index}].dinnerExtras`}
                  value={pilote.dinnerExtras}
                  onChange={props.handleChange}
                  input={<OutlinedInput label="Suppléments dîner" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (s) =>
                          props.extraCategories.find(
                            (category) => category.id === s
                          ).name
                      )
                      .join(", ")
                  }
                >
                  {props.extraCategories.map((category, i) => (
                    <MenuItem value={category.id} key={i}>
                      <Checkbox
                        checked={props.values.pilotes[
                          index
                        ].dinnerExtras.includes(category.id)}
                      />
                      <ListItemText primary={category.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="diet-label">Régime</InputLabel>
                <Select
                  fullWidth
                  labelId="diet-label"
                  id="diet"
                  name={`pilotes[${index}].diet`}
                  value={pilote.diet}
                  onChange={props.handleChange}
                  input={<OutlinedInput label="Régime" />}
                >
                  <MenuItem value={"none"}>Sans régime</MenuItem>
                  {props.diets.map((d, i) => (
                    <MenuItem value={d.id} key={i}>
                      <ListItemText primary={d.nom} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={1}>
              <TextField
                name={`pilotes[${index}].qty`}
                label="Quantité"
                type="number"
                value={pilote.qty}
                onChange={props.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  props.errors.pilotes &&
                  props.errors.pilotes[index] &&
                  props.errors.pilotes[index].qty &&
                  props.touched.pilotes &&
                  props.touched.pilotes[index] &&
                  props.touched.pilotes[index].qty
                    ? true
                    : false
                }
                {...(props.errors.pilotes &&
                  props.errors.pilotes[index] &&
                  props.errors.pilotes[index].qty &&
                  props.touched.pilotes &&
                  props.touched.pilotes[index] &&
                  props.touched.pilotes[index].qty && {
                    helperText: props.errors.pilotes[index].qty,
                  })}
              />
            </Grid>

            <Grid item md={1}>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    props.setFieldValue(
                      "pilotes",
                      props.values.pilotes.filter((item) => item !== pilote)
                    )
                  }
                >
                  <RemoveCircleIcon />
                </IconButton>

                {index === props.values.pilotes.length - 1 && (
                  <IconButton
                    aria-label="add"
                    onClick={() =>
                      props.setFieldValue("pilotes", [
                        ...props.values.pilotes,
                        {
                          round: "",
                          lunchMenu: "",
                          dinnerMenu: "none",
                          diet: "none",
                          qty: 1,
                          lunchExtras: [],
                          dinnerExtras: [],
                        },
                      ])
                    }
                  >
                    <AddCircleIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() =>
              props.setFieldValue("pilotes", [
                {
                  round: "",
                  lunchMenu: "",
                  dinnerMenu: "none",
                  diet: "none",
                  qty: 1,
                  lunchExtras: [],
                  dinnerExtras: [],
                },
              ])
            }
          >
            Ajouter un repas pilote
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Pilote;
