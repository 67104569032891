import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { format } from "date-fns";
import { isAdmin } from "../../../../utils";
import RecipientForm from "../RecipientForm";

const EditGroup = (props) => {
  const classes = UseStyle();

  return (
    <form>
      <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Button
            variant={"contained"}
            onClick={props.toggleList}
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Retour
          </Button>
        </Stack>

        <Card className={classes.card}>
          <CardHeader
            subheader="Ces informations peuvent être modifier."
            title="Modification d'un convive"
          />
        </Card>

        {isAdmin(props.userData) && (
          <Paper className={classes.paper} elevation={6}>
            <TextField
              fullWidth
              name={"clientFullname"}
              label={"Client (non modifiable)"}
              type={"text"}
              value={props.values.clientFullname}
              InputProps={{
                readOnly: true,
              }}
            />
          </Paper>
        )}

        <Paper className={classes.paper} elevation={6}>
          <RecipientForm {...props} client={props.recipientEdit.organization} />

          <Stack direction={"row"} justifyContent={"flex-end"} paddingTop={2}>
            {props.loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                onClick={props.handleSubmit}
                variant="contained"
                endIcon={<SendIcon />}
              >
                Modifier le groupe de convives
              </Button>
            )}
          </Stack>
        </Paper>
      </Stack>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    clientFullname: props.recipientEdit.organization.name,
    startedAt: new Date(props.recipientEdit.startedAt),
    cancelledAt: props.recipientEdit.cancelledAt
      ? new Date(props.recipientEdit.cancelledAt)
      : null,
    recipientNote: props.recipientEdit.recipientNote,
    adminNote: props.recipientEdit.adminNote,
    state: props.recipientEdit.state,
    round: props.recipientEdit.round.id,
    lunchMenu: props.recipientEdit.lunchMenu.id,
    dinnerMenu: props.recipientEdit?.dinnerMenu?.id ?? "none",
    mealDays: props.recipientEdit.mealDays,
    diet:
      props.recipientEdit.diets.length > 0
        ? props.recipientEdit.diets[0].id
        : "none",
    dietsNote: props.recipientEdit.dietNote,
  }),
  validationSchema: Yup.object().shape({
    startedAt: Yup.date(),
    cancelledAt: Yup.date().notRequired().nullable(),
    round: Yup.number().positive().required("Champs obligatoire"),
    lunchMenu: Yup.number().positive().required("Champs obligatoire"),
    mealDays: Yup.array().of(
      Yup.object().shape({
        day: Yup.number().min(0).required("Champs obligatoire"),
        qty: Yup.number().min(0).required("Champs obligatoire"),
      })
    ),
  }),
  handleSubmit: (values, { props }) => {
    const recipient = {
      id: props.recipientEdit.id,
      startedAt: format(values.startedAt, "yyyy-MM-dd"),
      cancelledAt: values.cancelledAt
        ? format(values.cancelledAt, "yyyy-MM-dd")
        : null,
      recipientNote: values.recipientNote ? values.recipientNote : null,
      adminNote: values.adminNote ? values.adminNote : null,
      state: values.state,
      round: `/rounds/${values.round}`,
      lunchMenu: `/formules/${values.lunchMenu}`,
      dinnerMenu:
        values.dinnerMenu !== "none" ? `/formules/${values.dinnerMenu}` : null,
      mealDays: values.mealDays,
      diets: values.diet !== "none" ? [`/regimes/${values.diet}`] : [],
      dietNote: values.dietsNote ? values.dietsNote : null,
    };

    props.validation(recipient);
  },
})(EditGroup);
