import { Stack } from "@mui/material";
import React from "react";
import Contact from "../common/Contact/Contact";
import Delivery from "../common/Delivery/Delivery";
import FirstFeature from "../common/Features/First/First";
import Formules from "../common/Formules/Formules";
import Gallery from "../common/Gallery/Gallery";
import Menus from "../common/Menus/Menus";
import SecondFeature from "../common/Features/Second/Second";
import Testimonials from "../common/Testimonials/Testimonials";

const Index = (props) => {
  return (
    <Stack spacing={5}>
      <Contact selected={props.selected} />
      <Testimonials />
      <FirstFeature selected={props.selected} />
      <Formules formules={props.formules} selected={props.selected} />
      <Contact selected={props.selected} />
      <SecondFeature />
      <Menus />
      <Contact selected={props.selected} />
      <Delivery />
      <Gallery />
    </Stack>
  );
};

export default Index;
