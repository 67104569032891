import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    active: false,
    message: null,
    type: null,
  },
  reducers: {
    show: (state, action) => {
      const { payload } = action;
      state.active = payload.active;
      state.message = payload.message;
      state.type = payload.type;
    },
    hide: (state) => {
      state.active = false;
      state.message = null;
      state.type = null;
    },
  },
});

export const { show, hide } = alertSlice.actions;

export default alertSlice.reducer;
