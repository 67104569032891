import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import CreditNoteForm from "../CreditNoteForm";
import { format } from "date-fns";

const Add = (props) => {
  const classes = UseStyle();
  const [orders, setOrders] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    if (props.values.recipient) {
      setIsLoadingData(true);
      fetch(
        `/order_units?recipient.id=${props.values.recipient}&exists[creditNote]=false`
      ).then((res) =>
        res.json().then((data) => {
          const orders = data["hydra:member"];
          const values = orders.map((order) => {
            const filtre = order.id;
            return {
              filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
              order: order,
            };
          });

          setIsLoadingData(false);
          setOrders(values);
        })
      );
    }
  }, [props.values.recipient]);

  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'un avoir"
        />
      </Card>

      <CreditNoteForm
        {...props}
        orders={orders}
        isLoadingData={isLoadingData}
      />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    recipient: null,
    order: null,
    formules: [],
    date: new Date(),
  }),
  validationSchema: Yup.object().shape({
    recipient: Yup.number()
      .positive()
      .required("Champs obligatoire")
      .typeError("Champs obligatoire"),
    order: Yup.number()
      .positive()
      .required("Champs obligatoire")
      .typeError("Champs obligatoire"),
    date: Yup.date()
      .min(new Date(2022, 1, 1), "Date invalide")
      .typeError("Date invalide")
      .required("Champs obligatoire"),
  }),
  handleSubmit: (values, { props }) => {
    const newCreditNote = {
      recipient: `/recipients/${values.recipient}`,
      orderUnit: `/order_units/${values.order}`,
      formules: values.formules.map((formule) => `/formules/${formule}`),
      month: parseInt(format(values.date, "MM")),
      year: parseInt(format(values.date, "yyyy")),
    };

    props.validation(newCreditNote);
  },
})(Add);
