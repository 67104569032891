import SendIcon from "@mui/icons-material/Send";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
import UseStyle from "../Common/StyledComponent/UseStyle";
import { isAdmin } from "../../../utils";

const RoundForm = (props) => {
  const classes = UseStyle();
  const [selectedClient, setSelectedClient] = useState(null);
  const clients = props.organizations.map((o) => {
    const filtre = o.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: o,
    };
  });

  const removeItemOnce = (jour, repas) => {
    let tableau = props.values.deliveryDays[jour].repasDu;
    const index = tableau.indexOf(repas);
    index > -1 && tableau.splice(index, 1);
    props.setFieldValue(`deliveryDays[${jour}].repasDu`, tableau);
  };
  let checkbox = (jour, repas) => {
    return (
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          label=""
          checked={props.values.deliveryDays[jour].repasDu.includes(repas)}
          onChange={(e) => {
            e.target.checked
              ? props.setFieldValue(`deliveryDays[${jour}].repasDu`, [
                  ...new Set([
                    ...props.values.deliveryDays[jour].repasDu,
                    repas,
                  ]),
                ])
              : removeItemOnce(jour, repas);
          }}
        />
      </FormGroup>
    );
  };
  const createData = (
    jour,
    lundi,
    mardi,
    mercredi,
    jeudi,
    vendredi,
    samedi,
    dimanche
  ) => {
    return { jour, lundi, mardi, mercredi, jeudi, vendredi, samedi, dimanche };
  };
  const rows = [
    createData(
      "Lundi",
      checkbox(1, 1),
      checkbox(1, 2),
      checkbox(1, 3),
      checkbox(1, 4),
      checkbox(1, 5),
      checkbox(1, 6),
      checkbox(1, 0)
    ),
    createData(
      "Mardi",
      checkbox(2, 1),
      checkbox(2, 2),
      checkbox(2, 3),
      checkbox(2, 4),
      checkbox(2, 5),
      checkbox(2, 6),
      checkbox(2, 0)
    ),
    createData(
      "Mercredi",
      checkbox(3, 1),
      checkbox(3, 2),
      checkbox(3, 3),
      checkbox(3, 4),
      checkbox(3, 5),
      checkbox(3, 6),
      checkbox(3, 0)
    ),
    createData(
      "Jeudi",
      checkbox(4, 1),
      checkbox(4, 2),
      checkbox(4, 3),
      checkbox(4, 4),
      checkbox(4, 5),
      checkbox(4, 6),
      checkbox(4, 0)
    ),
    createData(
      "Vendredi",
      checkbox(5, 1),
      checkbox(5, 2),
      checkbox(5, 3),
      checkbox(5, 4),
      checkbox(5, 5),
      checkbox(5, 6),
      checkbox(5, 0)
    ),
    createData(
      "Samedi",
      checkbox(6, 1),
      checkbox(6, 2),
      checkbox(6, 3),
      checkbox(6, 4),
      checkbox(6, 5),
      checkbox(6, 6),
      checkbox(6, 0)
    ),
    createData(
      "Dimanche",
      checkbox(0, 1),
      checkbox(0, 2),
      checkbox(0, 3),
      checkbox(0, 4),
      checkbox(0, 5),
      checkbox(0, 6),
      checkbox(0, 0)
    ),
  ];

  return (
    <Paper className={classes.paper} elevation={6}>
      <form>
        <Grid container spacing={2} alignItems="center">
          {isAdmin(props.userData) && (
            <Grid item xs={12}>
              {props.edit ? (
                <TextField
                  fullWidth
                  name={"client"}
                  label={"Client (non modifiable)"}
                  type={"text"}
                  value={props.values.organizationName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Autocomplete
                  fullWidth
                  options={clients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.client.id === value.client.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.client) return "";
                    return option.client.id + " - " + option.client.name;
                  }}
                  value={selectedClient}
                  onChange={(event, value) => {
                    setSelectedClient(value);

                    if (value !== null)
                      props.setFieldValue("organization", value.client.id);
                    else props.setFieldValue("organization", null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      error={
                        props.touched.organization &&
                        Boolean(props.errors.organization)
                      }
                      helperText={
                        props.touched.organization &&
                        props.errors.organization &&
                        props.errors.organization
                      }
                    />
                  )}
                />
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label="Nom"
              value={props.values.name}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.name && Boolean(props.errors.name)}
              helperText={
                props.errors.name && props.touched.name && props.errors.name
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" component="h6" align={"left"}>
              Jours de livraison
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              align={"left"}
              component="div"
            >
              Cochez les jours que vous souhaitez livré pour cette tournée
            </Typography>

            <TableContainer>
              <Table className={classes.table} aria-label="table-tournees">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Button
                            disabled
                            startIcon={<ArrowDropDownOutlinedIcon />}
                          >
                            Jours de livraisons
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button disabled endIcon={<ArrowRightOutlinedIcon />}>
                            Repas du
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>Lundi</TableCell>
                    <TableCell>Mardi</TableCell>
                    <TableCell>Mercredi</TableCell>
                    <TableCell>Jeudi</TableCell>
                    <TableCell>Vendredi</TableCell>
                    <TableCell>Samedi</TableCell>
                    <TableCell>Dimanche</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.jour}>
                      <TableCell>{row.jour}</TableCell>
                      <TableCell>{row.lundi}</TableCell>
                      <TableCell>{row.mardi}</TableCell>
                      <TableCell>{row.mercredi}</TableCell>
                      <TableCell>{row.jeudi}</TableCell>
                      <TableCell>{row.vendredi}</TableCell>
                      <TableCell>{row.samedi}</TableCell>
                      <TableCell>{row.dimanche}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {props.loading && (
                <Box
                  sx={{ display: "flex", justifyContent: "center" }}
                  m={5}
                  align="center"
                >
                  <CircularProgress color="secondary" />
                </Box>
              )}
            </TableContainer>
          </Grid>

          <Grid container item justifyContent="flex-end" xs={12}>
            <LoadingButton
              loading={props.isLoading}
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={props.handleSubmit}
            >
              {props.edit ? "Sauvegarder" : "Créer"}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default RoundForm;
