import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { Card, CardHeader, Paper, Button, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormuleForm } from "../FormuleForm";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button
          variant={"contained"}
          onClick={props.toggleList}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Stack>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Modification d'une formule"
        />
      </Card>

      <Paper className={classes.paper} elevation={6}>
        <FormuleForm {...props} />
      </Paper>
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    nom: props.formuleEdit.name,
    totalTtc: props.formuleEdit.priceIncludingTax,
    totalHt: props.formuleEdit.priceExcludingTax,
    serviceTtc: props.formuleEdit.serviceTtc,
    serviceHt: props.formuleEdit.serviceHt,
    repasTtc: props.formuleEdit.repasTtc,
    repasHt: props.formuleEdit.repasHt,
    tax: props.formuleEdit.tax.id,
    categories: props.formuleEdit.composition.map((c) => ({
      id: c.categorie.id,
      qte: c.qte,
    })),
    estDiner: props.formuleEdit.estDiner,
    extraCategories: props.formuleEdit.extraCategories,
  }),
  validationSchema: Yup.object().shape({
    nom: Yup.string().required("Ce champs est obligatoire"),
    totalTtc: Yup.string().required("Ce champs est obligatoire"),
    totalHt: Yup.string().required("Ce champs est obligatoire"),
    serviceTtc: Yup.string().required("Ce champs est obligatoire"),
    serviceHt: Yup.string().required("Ce champs est obligatoire"),
    repasTtc: Yup.string().required("Ce champs est obligatoire"),
    repasHt: Yup.string().required("Ce champs est obligatoire"),
    tax: Yup.number().positive().required("Ce champs est obligatoire"),
    categories: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().positive(),
      })
    ),
  }),
  handleSubmit: (values, { props }) => {
    const updatedItem = {
      ...props.formuleEdit,
      name: values.nom,
      priceIncludingTax: values.totalTtc.toString(),
      priceExcludingTax: values.totalHt.toString(),
      serviceTtc: values.serviceTtc.toString(),
      serviceHt: values.serviceHt.toString(),
      repasTtc: values.repasTtc.toString(),
      repasHt: values.repasHt.toString(),
      tax: `/taxes/${values.tax}`,
      composition: values.categories.map((categorie) => ({
        categorie: `/categories/${categorie.id}`,
        qte: categorie.qte,
      })),
      estDiner: values.estDiner,
      extraCategories: values.extraCategories.map(
        (extraCategory) => `/extra_categories/${extraCategory.id}`
      ),
    };

    props.validation(updatedItem, props.formuleEdit.id);
  },
})(Edit);
