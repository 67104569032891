import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import logo from "../../assets/img/logo.png";
import { requests } from "../../agent";
import { login } from "../../slices/authSlice";
import { hide, show } from "../../slices/alertSlice";

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Email invalide").required("Champs obligatoire"),
  password: Yup.string().required("Champs obligatoire"),
});

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Email invalide").required("Champs obligatoire"),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isForgotten, setIsForgotten] = useState(false);
  const [isForgottenLoading, setIsForgottenLoading] = useState(false);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userData = useSelector((state) => state.auth.userData);
  const isLoginLoading = useSelector((state) => state.auth.isLoginLoading);
  const loginError = useSelector((state) => state.auth.loginError);
  const alert = {
    isActive: useSelector((state) => state.alert.active),
    message: useSelector((state) => state.alert.message),
    type: useSelector((state) => state.alert.type),
  };

  useEffect(() => {
    if (!isLoginLoading) {
      if (isAuthenticated && userData) {
        navigate("/admin/dashboard");
      }

      if (loginError && !isAuthenticated) {
        dispatch(
          show({
            active: true,
            message: loginError,
            type: "error",
          })
        );
      }
    }
  }, [isLoginLoading, loginError, isAuthenticated]);

  const togglePasswordForgot = (event) => {
    setIsForgotten(!isForgotten);
  };
  const handleSubmitForgottenPassword = (email) => {
    setIsForgottenLoading(true);
    requests
      .post("/reset/password/request", { email: email }, true)
      .then((response) => {})
      .catch((err) => {})
      .finally(() => {
        setIsForgotten(false);
        setIsForgottenLoading(false);
        dispatch(
          show({
            active: true,
            message: "Le mail de réinitialisation à bien été envoyé !",
            type: "success",
          })
        );
      });
  };
  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(login({ email: values.email, password: values.password }));
    },
  });
  const resetPasswordFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      handleSubmitForgottenPassword(values.email);
    },
  });
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(hide());
  };

  return (
    <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        height: "100vh"
      }}>
      <Grid
        container
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={4}
        padding={3}
      >
        <Grid item>
          <img
            alt="logo"
            src={logo}
            style={{
              width: "175px",
              height: "150px",
            }}
            variant="rounded"
          />
        </Grid>

        <Grid item container justifyContent={"center"} alignItems={"center"}>
          <Paper
            sx={{
              padding: 5,
              width: "40vw",
            }}
            elevation={6}
          >
            {isForgotten ? (
              <form onSubmit={resetPasswordFormik.handleSubmit}>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Stack
                      spacing={1}
                      divider={<Divider flexItem />}
                      justifyContent={"center"}
                    >
                      <Typography gutterBottom variant="h4" align="center">
                        Back-Office
                      </Typography>

                      <Button
                        variant={"text"}
                        startIcon={<ArrowBackIcon />}
                        size="small"
                        onClick={togglePasswordForgot}
                      >
                        Retour
                      </Button>

                      <Typography gutterBottom variant="h5" align="center">
                        Récupération de mot de passe
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={resetPasswordFormik.values.email}
                      onChange={resetPasswordFormik.handleChange}
                      error={
                        resetPasswordFormik.touched.email &&
                        Boolean(resetPasswordFormik.errors.email)
                      }
                      helperText={
                        resetPasswordFormik.touched.email &&
                        resetPasswordFormik.errors.email
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {isForgottenLoading ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        fullWidth
                        variant="contained"
                      >
                        Envoyer le mail
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </form>
            ) : (
              <form onSubmit={loginFormik.handleSubmit}>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Stack
                      spacing={1}
                      divider={<Divider flexItem />}
                      justifyContent={"center"}
                    >
                      <Typography gutterBottom variant="h4" align="center">
                        Back-Office
                      </Typography>

                      <Typography gutterBottom variant="h5" align="center">
                        Connexion
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2} justifyContent={"center"}>
                      <TextField
                        fullWidth
                        autoComplete="username"
                        label="Email"
                        name="email"
                        value={loginFormik.values.email}
                        onChange={loginFormik.handleChange}
                        error={
                          loginFormik.touched.email &&
                          Boolean(loginFormik.errors.email)
                        }
                        helperText={
                          loginFormik.touched.email && loginFormik.errors.email
                        }
                      />
                      <TextField
                        fullWidth
                        name="password"
                        label="Mot de passe"
                        type="password"
                        autoComplete="current-password"
                        value={loginFormik.values.password}
                        onChange={loginFormik.handleChange}
                        error={
                          loginFormik.touched.password &&
                          Boolean(loginFormik.errors.password)
                        }
                        helperText={
                          loginFormik.touched.password &&
                          loginFormik.errors.password
                        }
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2}>
                      <Button
                        disabled={isLoginLoading}
                        color="primary"
                        fullWidth
                        onClick={() => togglePasswordForgot()}
                      >
                        Mot de passe oublié ?
                      </Button>

                      <LoadingButton
                        loading={isLoginLoading}
                        type="submit"
                        color="primary"
                        fullWidth
                        variant="contained"
                      >
                        Se connecter
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            )}
          </Paper>
        </Grid>

        <Grid item>
          <Typography variant="body2" color="text.secondary">
            Copyright © Basil 2021
          </Typography>
        </Grid>

        {alert.isActive && (
          <Snackbar
            open={alert.isActive}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              variant={"filled"}
              severity={alert.type}
            >
              {alert.message}
            </Alert>
          </Snackbar>
        )}
      </Grid>
    </div>
  );
};

export default Login;
