import React, { useState } from "react";
import { compose } from "redux";
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { locale, mask } from "../../../localisation";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { RECIPIENT_STATE } from "./Enums/Enums";
import UseStyle from "./StyledComponent/UseStyle";
import { isAdmin, isSalesAdmin } from "../../../utils";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const Filters = (props) => {
  const classes = UseStyle();
  const [selectedClient, setSelectedClient] = useState(
    !isAdmin(props.userData) ? { client: props.userData } : null
  );
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  let clients = [];
  let recipients = [];

  if (props.types.includes("client")) {
    clients = props.clients.map((client) => {
      const filtre = client.name[0].toUpperCase();
      return {
        filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
        client: client,
      };
    });
  }

  if (props.types.includes("recipient")) {
    recipients = props.recipients.map((recipient) => {
      const filtre = recipient.name[0].toUpperCase();
      return {
        filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
        recipient: recipient,
      };
    });
  }

  const months = [
    { id: 1, name: "Janvier" },
    { id: 2, name: "Février" },
    { id: 3, name: "Mars" },
    { id: 4, name: "Avril" },
    { id: 5, name: "Mai" },
    { id: 6, name: "Juin" },
    { id: 7, name: "Juillet" },
    { id: 8, name: "Août" },
    { id: 9, name: "Septembre" },
    { id: 10, name: "Octobre" },
    { id: 11, name: "Novembre" },
    { id: 12, name: "Décembre" },
  ];

  return (
    <form>
      <Paper className={classes.paper} elevation={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid container item spacing={2} xs={12} lg={9}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                fullWidth
                size="small"
                name={"id"}
                label="ID"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={props.values.id}
                onChange={props.handleChange}
                error={props.errors.id && props.touched.id ? true : false}
                {...(props.errors.id &&
                  props.touched.id && { helperText: props.errors.id })}
              />
            </Grid>

            {(isAdmin(props.userData) || isSalesAdmin(props.userData)) &&
              props.types.includes("client") && (
                <Grid item xs={12} md={6} lg={3}>
                  <Autocomplete
                    options={clients.sort(
                      (a, b) => -b.filtre.localeCompare(a.filtre)
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.client.id === value.client.id
                    }
                    groupBy={(value) => value.filtre}
                    getOptionLabel={(option) => {
                      if (!option.client) return "";
                      return option.client.id + " - " + option.client.name;
                    }}
                    value={selectedClient}
                    onChange={(event, value) => {
                      setSelectedClient(value);

                      value !== null
                        ? props.setFieldValue("client", value.client.id)
                        : props.setFieldValue("client", null);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Client" size="small" />
                    )}
                  />
                </Grid>
              )}

            {props.types.includes("recipient") && (
              <Grid item xs={12} md={6} lg={3}>
                <Autocomplete
                  options={recipients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.recipient.id === value.recipient.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.recipient) return "";
                    return option.recipient.id + " - " + option.recipient.name;
                  }}
                  value={selectedRecipient}
                  onChange={(event, value) => {
                    setSelectedRecipient(value);

                    value !== null
                      ? props.setFieldValue("recipient", value.recipient.id)
                      : props.setFieldValue("recipient", null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Convive" size="small" />
                  )}
                />
              </Grid>
            )}

            {props.types.includes("lastname") && (
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  size="small"
                  name={"lastname"}
                  label="Nom"
                  type={"text"}
                  variant="outlined"
                  fullWidth
                  value={props.values.lastname}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Grid>
            )}

            {props.types.includes("fullName") && (
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  size="small"
                  name={"fullName"}
                  label="Nom"
                  type={"text"}
                  variant="outlined"
                  fullWidth
                  value={props.values.fullName}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Grid>
            )}

            {props.types.includes("email") && (
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  size="small"
                  name={"email"}
                  label="Email"
                  type={"text"}
                  variant="outlined"
                  fullWidth
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Grid>
            )}

            {props.types.includes("phone") && (
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  size="small"
                  name={"phone"}
                  label="Téléphone"
                  type={"text"}
                  variant="outlined"
                  fullWidth
                  value={props.values.phone}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
              </Grid>
            )}

            {props.types.includes("status") && (
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="status">Statut</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    label="Statut"
                    value={props.values.status}
                    onChange={(event) =>
                      props.setFieldValue("status", event.target.value)
                    }
                  >
                    {Object.values(props.statusType).map((value) => (
                      <MenuItem key={value.status} value={value.status}>
                        {value.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {props.types.includes("state") && (
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="state">Statut</InputLabel>
                  <Select
                    labelId="state"
                    id="state"
                    label="Statut"
                    value={props.values.state}
                    onChange={(event) =>
                      props.setFieldValue(
                        "state",
                        event.target.value === "none" ? "" : event.target.value
                      )
                    }
                  >
                    <MenuItem value="none">
                      <i>Aucun</i>
                    </MenuItem>

                    {Object.values(RECIPIENT_STATE).map((value) => (
                      <MenuItem key={value.status} value={value.status}>
                        {value.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {props.types.includes("date") && (
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={locale["fr"]}
                  >
                    <DatePicker
                      mask={mask["fr"]}
                      label="Date"
                      inputFormat="dd/MM/yyyy"
                      value={props.values.date}
                      onChange={(value) => props.setFieldValue("date", value)}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                  <FormHelperText error>
                    {props.errors.date && props.touched.date
                      ? "Date invalide"
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}

            {props.types.includes("round") && (
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="round">Tournée</InputLabel>
                  <Select
                    labelId="round"
                    id="round"
                    label="Tournée"
                    value={props.values.round}
                    onChange={(event) =>
                      props.setFieldValue(
                        "round",
                        event.target.value === "none" ? "" : event.target.value
                      )
                    }
                  >
                    <MenuItem value="none">
                      <i>Aucune</i>
                    </MenuItem>

                    {props.rounds
                      .sort((a, b) => -b.name.localeCompare(a.name))
                      .map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {props.types.includes("month") && (
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="month">Mois</InputLabel>
                  <Select
                    labelId="month"
                    id="month"
                    label="Mois"
                    value={props.values.month}
                    onChange={(event) =>
                      props.setFieldValue(
                        "month",
                        event.target.value === "none" ? "" : event.target.value
                      )
                    }
                    error={props.touched.month && Boolean(props.errors.month)}
                  >
                    <MenuItem value="none">
                      <i>Aucun</i>
                    </MenuItem>

                    {months.map((value) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {props.errors.month &&
                      props.touched.month &&
                      props.errors.month}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}

            {props.types.includes("bool") && (
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="bool">{props.boolName}</InputLabel>
                  <Select
                    labelId="bool"
                    id="bool"
                    label={props.boolName}
                    value={props.values.bool}
                    onChange={(event) =>
                      props.setFieldValue(
                        "bool",
                        event.target.value === null ? null : event.target.value
                      )
                    }
                    error={props.touched.bool && Boolean(props.errors.bool)}
                  >
                    <MenuItem value={null}>
                      <i>Aucun</i>
                    </MenuItem>
                    <MenuItem value={true}>Oui</MenuItem>
                    <MenuItem value={false}>Non</MenuItem>
                  </Select>
                  <FormHelperText error>
                    {props.errors.bool &&
                      props.touched.bool &&
                      props.errors.bool}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid container item spacing={1} xs={12} lg={3}>
            <Grid item xs>
              <LoadingButton
                loading={props.loading}
                type="submit"
                fullWidth
                variant="contained"
                size="small"
                onClick={props.handleSubmit}
              >
                Valider
              </LoadingButton>
            </Grid>

            <Grid item xs>
              <LoadingButton
                loading={props.loading}
                fullWidth
                variant="text"
                size="small"
                onClick={() => {
                  props.resetForm();
                  props.handleSubmitFilters(
                    {
                      id: "",
                      recipient: null,
                      client: null,
                      lastname: "",
                      fullName: "",
                      email: "",
                      phone: "",
                      status: [],
                      state: "",
                      date: null,
                      round: "",
                      month: "",
                      bool: null,
                    },
                    props.from
                  );
                  setSelectedClient(null);
                  setSelectedRecipient(null);
                }}
              >
                Réinitialiser
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: () => ({
      id: "",
      client: null,
      recipient: null,
      lastname: "",
      fullName: "",
      email: "",
      phone: "",
      status: [],
      state: "",
      date: null,
      round: "",
      month: "",
      bool: null,
    }),
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      date: Yup.date().nullable().default(null),
      month: Yup.string().when("bool", {
        is: (bool) => bool !== null,
        then: Yup.string().required("Sélectionnez un mois"),
      }),
      // bool: Yup.bool().when(
      //   "month",
      //   (month, schema) =>
      //     month !== "" && schema.required("Sélectionnez un mois")
      // ),
    }),
    handleSubmit: (values, { props }) => {
      const data = {
        id: values.id,
        client: values.client,
        recipient: values.recipient,
        lastname: values.lastname,
        fullName: values.fullName,
        email: values.email,
        phone: values.phone,
        status: values.status,
        state: values.state,
        date:
          values.date !== null
            ? format(new Date(values.date), "yyyy-MM-dd")
            : null,
        round: values.round,
        month: values.month,
        bool: values.bool,
      };

      props.handleSubmitFilters(data, props.from);
    },
  })
)(Filters);
