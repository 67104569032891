import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Drawer,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Chip,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  DialogContentText,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DownloadIcon from "@mui/icons-material/Download";
import DoneIcon from "@mui/icons-material/Done";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { useFormik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
import DeliveryCard from "./Delivery/DeliveryCard";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import { locale, mask } from "../../../../localisation";
import { DELIVERY_STATUS } from "../../Common/Enums/Enums";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { isAdmin, isAnyAdmin } from "../../../../utils";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const ExportDialog = ({
  loading,
  isOpen,
  handleClose,
  validation,
  clients,
  userData,
}) => {
  const [selectedClient, setSelectedClient] = useState(
    !isAdmin(userData) ? { client: userData } : null
  );
  const formik = useFormik({
    initialValues: {
      mode: "notes",
      date: new Date(),
      client: null,
      all: false,
    },
    validationSchema: Yup.object({
      date: Yup.date().required().typeError("Date invalide"),
      client: Yup.number()
        .nullable()
        .when("all", {
          is: false,
          then: Yup.number().required().typeError("Champs obligatoire"),
        }),
      all: Yup.boolean().required(),
    }),
    onSubmit: (values, { actions }) => {
      validation(values.mode, values.date, values.client, values.all);
      actions.resetForm();
      setSelectedClient(null);
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        formik.handleReset();
        setSelectedClient(null);
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        Exporter des données.
        <DialogContentText>
          Selectionnez un mode et remplissez les différents champs afin
          d'exporter les données souhaitées.
        </DialogContentText>
      </DialogTitle>

      <form>
        <DialogContent>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <FormControl>
              <FormLabel id="export-mode-group">Mode</FormLabel>
              <RadioGroup
                row
                aria-labelledby="export-mode-group"
                name="row-radio-buttons-group"
                value={formik.values.mode}
                onChange={(event) =>
                  formik.setFieldValue("mode", event.target.value)
                }
              >
                <FormControlLabel
                  value="notes"
                  control={<Radio />}
                  label="Bons de livraison"
                />
                <FormControlLabel
                  value="onfleet"
                  control={<Radio />}
                  label="OnFleet"
                />
                {/* <FormControlLabel
                  value="summary"
                  control={<Radio />}
                  label="Récapitulatif Prep"
                /> */}
              </RadioGroup>
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              required
              error={formik.errors.date && formik.touched.date ? true : false}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  label="Date"
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </FormHelperText>
            </FormControl>

            <Autocomplete
              fullWidth
              disabled={formik.values.all}
              options={clients.sort(
                (a, b) => -b.filtre.localeCompare(a.filtre)
              )}
              isOptionEqualToValue={(option, value) =>
                option.client.id === value.client.id
              }
              groupBy={(value) => value.filtre}
              getOptionLabel={(option) => {
                if (!option.client) return "";
                return option.client.id + " - " + option.client.name;
              }}
              value={selectedClient}
              onChange={(event, value) => {
                setSelectedClient(value);

                value !== null
                  ? formik.setFieldValue("client", value.client.id)
                  : formik.setFieldValue("client", null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  error={formik.touched.client && Boolean(formik.errors.client)}
                  helperText={
                    formik.errors.client &&
                    formik.touched.client &&
                    formik.errors.client
                  }
                />
              )}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.all}
                    onChange={(event) => {
                      formik.setFieldValue("all", event.target.checked);
                      if (event.target.checked) {
                        setSelectedClient(null);
                        formik.setFieldValue("client", null);
                      }
                    }}
                    name="all"
                  />
                }
                label="Tout les clients"
              />
              <FormHelperText error>
                {formik.errors.all && formik.touched.all && formik.errors.all}
              </FormHelperText>
            </FormGroup>
          </Stack>
        </DialogContent>
      </form>

      <DialogActions>
        <Button
          {...(loading && { disabled: true })}
          onClick={() => {
            handleClose();
            formik.handleReset();
            setSelectedClient(null);
          }}
        >
          Annuler
        </Button>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={formik.handleSubmit}
        >
          Exporter
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const StateChangerDialog = ({
  loading,
  isPrepared,
  handleClose,
  handleSubmitPreparedDeliveries,
  fetchDeliveries,
  queryOptions,
  clients,
  userData,
}) => {
  const [selectedClient, setSelectedClient] = useState(
    !isAdmin(userData) ? { client: userData } : null
  );
  const formik = useFormik({
    initialValues: {
      date: new Date(),
      status: "",
      client: null,
      all: false,
    },
    validationSchema: Yup.object({
      date: Yup.date().required().typeError("Date invalide"),
      status: Yup.string().required("Champs obligatoire"),
      client: Yup.number()
        .nullable()
        .when("all", {
          is: false,
          then: Yup.number().required().typeError("Champs obligatoire"),
        }),
      all: Yup.boolean().required(),
    }),
    onSubmit: (values, actions) => {
      handleSubmitPreparedDeliveries(
        values.date,
        values.status,
        values.client,
        values.all,
        () => fetchDeliveries(queryOptions)
      );
      handleClose();
      actions.resetForm();
      setSelectedClient(null);
    },
  });

  return (
    <Dialog
      open={isPrepared}
      onClose={() => {
        handleClose();
        formik.handleReset();
        setSelectedClient(null);
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        Changer le statut des livraisons.
        <DialogContentText>Chaque champs est obligatoire.</DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={formik.touched.date && Boolean(formik.errors.date)}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  label="Date"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              error={formik.touched.status && Boolean(formik.errors.status)}
            >
              <InputLabel id="status">Statut</InputLabel>
              <Select
                labelId="status"
                id="status"
                label="Statut"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
              >
                {Object.values(DELIVERY_STATUS).map((value) => (
                  <MenuItem key={value.status} value={value.status}>
                    {value.description}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {formik.errors.status &&
                  formik.touched.status &&
                  formik.errors.status}
              </FormHelperText>
            </FormControl>

            <Autocomplete
              fullWidth
              disabled={formik.values.all}
              options={clients.sort(
                (a, b) => -b.filtre.localeCompare(a.filtre)
              )}
              isOptionEqualToValue={(option, value) =>
                option.client.id === value.client.id
              }
              groupBy={(value) => value.filtre}
              getOptionLabel={(option) => {
                if (!option.client) return "";
                return option.client.id + " - " + option.client.name;
              }}
              value={selectedClient}
              onChange={(event, value) => {
                setSelectedClient(value);

                value !== null
                  ? formik.setFieldValue("client", value.client.id)
                  : formik.setFieldValue("client", null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  error={formik.touched.client && Boolean(formik.errors.client)}
                  helperText={
                    formik.errors.client &&
                    formik.touched.client &&
                    formik.errors.client
                  }
                />
              )}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.all}
                    onChange={(event) => {
                      formik.setFieldValue("all", event.target.checked);
                      if (event.target.checked) {
                        setSelectedClient(null);
                        formik.setFieldValue("client", null);
                      }
                    }}
                    name="all"
                  />
                }
                label="Tout les clients"
              />
              <FormHelperText error>
                {formik.errors.all && formik.touched.all && formik.errors.all}
              </FormHelperText>
            </FormGroup>
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={() => {
            handleClose();
            formik.handleReset();
            setSelectedClient(null);
          }}
        >
          Annuler
        </LoadingButton>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          startIcon={<DoneIcon />}
          onClick={formik.handleSubmit}
        >
          Valider
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const filterSchema = Yup.object().shape({
  id: Yup.number(),
  date: Yup.date().nullable().default(null),
});
const filterInitialValues = {
  id: "",
  recipient: null,
  client: null,
  status: "",
  date: null,
  round: "",
  driver: "",
};

const List = (props) => {
  const classes = UseStyle();
  const filterFormik = useFormik({
    initialValues: filterInitialValues,
    validationSchema: filterSchema,
    onSubmit: (values) => {
      setFilters({
        ...values,
        client: values.client?.client.id,
        recipient: values.recipient?.recipient.id,
      });
    },
  });
  const recipients = props.recipients.map((recipient) => {
    const filtre = recipient.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      recipient: recipient,
    };
  });
  const clients = props.clients.map((client) => {
    const filtre = client.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: client,
    };
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const rowCount = props.rowCount;
  const [filters, setFilters] = useState(filterInitialValues);
  const queryOptions = useMemo(
    () => ({
      page: page + 1,
      pageSize,
      filters: { ...filters },
    }),
    [page, pageSize, filters]
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  const [isPrepared, setIsPrepared] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  useEffect(() => {
    props.fetchDeliveries(queryOptions);
  }, [queryOptions]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const [isExportOpen, setIsExportOpen] = useState(false);
  const handleClickOpen = () => {
    setIsPrepared(true);
  };
  const handleClose = () => {
    setIsExportOpen(false);
    setIsPrepared(false);
  };
  const renderState = (params) => {
    if (params.value === DELIVERY_STATUS.DELIVERED.status) {
      return (
        <Chip
          label={DELIVERY_STATUS.DELIVERED.description}
          color="success"
          variant="outlined"
        />
      );
    } else if (params.value === DELIVERY_STATUS.CANCELLED.status) {
      return (
        <Chip
          label={DELIVERY_STATUS.CANCELLED.description}
          color="error"
          variant="outlined"
        />
      );
    } else if (params.value === DELIVERY_STATUS.DELIVERING.status) {
      return (
        <Chip
          label={DELIVERY_STATUS.DELIVERING.description}
          color="secondary"
          variant="outlined"
        />
      );
    } else if (params.value === DELIVERY_STATUS.PENDING_DELIV.status) {
      return (
        <Chip
          label={DELIVERY_STATUS.PENDING_DELIV.description}
          color="info"
          variant="outlined"
        />
      );
    } else {
      return (
        <Chip
          label={DELIVERY_STATUS.PENDING_PREP.description}
          color="primary"
          variant="outlined"
        />
      );
    }
  };
  let columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Statut",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: renderState,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      flex: 1,
      type: "date",
      valueFormatter: ({ value }) => format(new Date(value), "dd/MM/yyyy"),
    },
    {
      field: "organization",
      headerName: "Client",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: ({ value }) => value.name,
    },
    {
      field: "recipient",
      headerName: "Convive",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: ({ value }) => (value ? value.name : ""),
    },
    {
      field: "round",
      headerName: "Tournée",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: ({ value }) => value.name,
    },
    {
      field: "updatedAt",
      headerName: "MàJ le",
      headerAlign: "center",
      align: "center",
      flex: 1,
      type: "datetime",
      valueFormatter: ({ value }) =>
        value ? format(new Date(value), "dd/MM/yyyy") : null,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <ShoppingBagIcon />
            </Tooltip>
          }
          onClick={props.toggleDrawer(true, params.row.id)}
          label="Modifier"
        />,
      ],
    },
  ];

  return (
    <>
      {isAnyAdmin(props.userData) && (
        <Grid container justifyContent={"space-between"} paddingBottom={2}>
          <Grid item>
            <Tooltip title="Changer le statut de plusieurs livraisons à la fois">
              <LoadingButton
                loading={props.isLoading}
                variant="contained"
                color="secondary"
                startIcon={<ChangeCircleOutlinedIcon />}
                onClick={handleClickOpen}
              >
                Statut
              </LoadingButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Stack direction={"row"} justifyContent={"center"} spacing={1}>
              <Button disabled>Import</Button>
              <Tooltip title="Générer les bons de livraison">
                <span>
                  <LoadingButton
                    loading={props.isLoading}
                    onClick={() => setIsExportOpen(true)}
                    disabled={!isAnyAdmin(props.userData)}
                  >
                    Export
                  </LoadingButton>
                </span>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      )}

      <Stack spacing={2}>
        <Paper className={classes.paper} elevation={6}>
          <form onSubmit={filterFormik.handleSubmit}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item container spacing={2} xs={12} md={12} lg={9}>
                <Grid item xs={12} md={3}>
                  <TextField
                    size="small"
                    fullWidth
                    name="id"
                    label="ID"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={filterFormik.handleChange}
                    onBlur={filterFormik.handleBlur}
                    value={filterFormik.values.id}
                    error={
                      filterFormik.touched.id && Boolean(filterFormik.errors.id)
                    }
                    helpertext={
                      filterFormik.touched.id && filterFormik.errors.id
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={locale["fr"]}
                    >
                      <DatePicker
                        mask={mask["fr"]}
                        label="Date"
                        inputFormat="dd/MM/yyyy"
                        onChange={(value) =>
                          filterFormik.setFieldValue("date", value)
                        }
                        onBlur={filterFormik.handleBlur}
                        value={filterFormik.values.date}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText error>
                      {filterFormik.errors.date && filterFormik.touched.date
                        ? "Date invalide"
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {isAnyAdmin(props.userData) && (
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      options={clients.sort(
                        (a, b) => -b.filtre.localeCompare(a.filtre)
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.client.id === value.client.id
                      }
                      groupBy={(value) => value.filtre}
                      getOptionLabel={(option) => {
                        if (!option.client) return "";
                        return option.client.id + " - " + option.client.name;
                      }}
                      value={selectedClient}
                      onChange={(event, value) => {
                        setSelectedClient(value);
                        filterFormik.setFieldValue("client", value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Client" size="small" />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={recipients.sort(
                      (a, b) => -b.filtre.localeCompare(a.filtre)
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.recipient.id === value.recipient.id
                    }
                    groupBy={(value) => value.filtre}
                    getOptionLabel={(option) => {
                      if (!option.recipient) return "";
                      return (
                        option.recipient.id + " - " + option.recipient.name
                      );
                    }}
                    value={selectedRecipient}
                    onChange={(event, value) => {
                      setSelectedRecipient(value);
                      filterFormik.setFieldValue("recipient", value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Convive" size="small" />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="status">Statut</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      label="Statut"
                      value={filterFormik.values.status}
                      onChange={(event) =>
                        filterFormik.setFieldValue(
                          "status",
                          event.target.value === "none"
                            ? ""
                            : event.target.value
                        )
                      }
                    >
                      <MenuItem value="none">
                        <i>Aucun</i>
                      </MenuItem>

                      {Object.values(DELIVERY_STATUS).map((value) => (
                        <MenuItem key={value.status} value={value.status}>
                          {value.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="round">Tournée</InputLabel>
                    <Select
                      labelId="round"
                      id="round"
                      label="Tournée"
                      name="round"
                      onChange={(event) =>
                        filterFormik.setFieldValue(
                          "round",
                          event.target.value === "none"
                            ? ""
                            : event.target.value
                        )
                      }
                      onBlur={filterFormik.handleBlur}
                      value={filterFormik.values.round}
                      error={
                        filterFormik.touched.round &&
                        Boolean(filterFormik.errors.round)
                      }
                      helpertext={
                        filterFormik.touched.round && filterFormik.errors.round
                      }
                    >
                      <MenuItem value="none">
                        <i>Aucun</i>
                      </MenuItem>

                      {props.rounds
                        .sort((a, b) => -b.name.localeCompare(a.name))
                        .map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container spacing={1} xs={12} md={12} lg={3}>
                <Grid item xs>
                  <LoadingButton
                    loading={props.isLoading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="small"
                  >
                    Valider
                  </LoadingButton>
                </Grid>

                <Grid item xs>
                  <LoadingButton
                    loading={props.isLoading}
                    fullWidth
                    variant="text"
                    size="small"
                    onClick={() => {
                      filterFormik.setValues({ ...filterInitialValues });
                      setFilters({ ...filterInitialValues });
                      setSelectedClient(null);
                      setSelectedRecipient(null);
                    }}
                  >
                    Réinitialiser
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Paper className={classes.paper} elevation={6}>
          <DataGrid
            autoHeight
            loading={props.isLoading}
            columns={columns}
            rows={props.deliveries}
            rowCount={rowCountState}
            rowsPerPageOptions={[50]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            density="comfortable"
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
            }}
          />
        </Paper>
      </Stack>

      <Drawer
        anchor="right"
        open={props.isOpen}
        delivery={props.delivery}
        onClose={props.toggleDrawer(false, null, () =>
          props.fetchDeliveries(queryOptions)
        )}
        sx={{
          width: "80%",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        className={classes.drawer}
      >
        {props.isLoading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", padding: 3 }}
            fontSize="large"
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <DeliveryCard {...props} />
        )}
      </Drawer>

      <ExportDialog
        isOpen={isExportOpen}
        handleClose={handleClose}
        loading={props.isLoading}
        validation={props.handleDownloadDeliveriesNote}
        clients={clients}
        userData={props.userData}
      />

      <StateChangerDialog
        isPrepared={isPrepared}
        handleClose={handleClose}
        loading={props.isLoading}
        handleSubmitPreparedDeliveries={props.handleSubmitPreparedDeliveries}
        fetchDeliveries={props.fetchDeliveries}
        queryOptions={queryOptions}
        clients={clients}
        userData={props.userData}
      />
    </>
  );
};

export default List;
