import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import image from "./assets/img/not-found.jpg";

const PageNotFound = () => {
  return (
    <Stack
      spacing={3}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        height: "100vh",
        bgcolor: "#f7f7f7",
      }}
    >
      <img src={image} alt="Page Not Found" />

      <Typography variant="h2" gutterBottom>
        PAGE NOT FOUND
      </Typography>

      <Typography variant="h4" gutterBottom>
        Nous sommes désolés, mais nous ne trouvons pas la page que vous
        cherchiez.
      </Typography>

      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={() => window.history.back()}
      >
        Revenir en arrière
      </Button>
    </Stack>
  );
};

export default PageNotFound;
