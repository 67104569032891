import {
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import PlacesAutocomplete from "../Common/PlacesAutocomplete/PlacesAutocomplete";
import UseStyle from "../Common/StyledComponent/UseStyle";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const CreditorForm = (props) => {
  const classes = UseStyle();

  return (
    <form>
      <Paper className={classes.paper} elevation={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              required
              label="Nom"
              name="name"
              onChange={props.handleChange}
              value={props.values.name}
              variant="outlined"
              error={props.touched.name && Boolean(props.errors.name)}
              helperText={
                props.touched.name && props.errors.name && props.errors.name
              }
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              required
              label="Email"
              name="email"
              onChange={props.handleChange}
              value={props.values.email}
              variant="outlined"
              error={props.touched.email && Boolean(props.errors.email)}
              helperText={
                props.touched.email && props.errors.email && props.errors.email
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={
                props.errors.address && props.touched.address ? true : false
              }
            >
              <PlacesAutocomplete
                query={props.values.address?.address?.unparsed}
                label="Adresse"
                fullWidth={true}
                error={
                  props.errors.address && props.touched.address ? true : false
                }
                callback={(data) => {
                  data.notes = props.values.address.notes ?? "";
                  props.setFieldValue("address", data);
                }}
              />
              <FormHelperText error>
                {props.errors.address &&
                  props.touched.address &&
                  props.errors.address}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="Capital (€)"
              name="capital"
              type="number"
              onChange={props.handleChange}
              value={props.values.capital}
              variant="outlined"
              error={props.touched.capital && Boolean(props.errors.capital)}
              helperText={
                props.touched.capital &&
                props.errors.capital &&
                props.errors.capital
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="SIREN"
              name="siren"
              onChange={props.handleChange}
              value={props.values.siren}
              variant="outlined"
              error={props.touched.siren && Boolean(props.errors.siren)}
              helperText={
                props.touched.siren && props.errors.siren && props.errors.siren
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="TVA"
              name="tva"
              onChange={props.handleChange}
              value={props.values.tva}
              variant="outlined"
              error={props.touched.tva && Boolean(props.errors.tva)}
              helperText={
                props.touched.tva && props.errors.tva && props.errors.tva
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="SAP"
              name="sap"
              onChange={props.handleChange}
              value={props.values.sap}
              variant="outlined"
              error={props.touched.sap && Boolean(props.errors.sap)}
              helperText={
                props.touched.sap && props.errors.sap && props.errors.sap
              }
            />
          </Grid>

          <Grid item container justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              onClick={props.handleSubmit}
              loading={props.isLoading}
              startIcon={<SaveRoundedIcon />}
            >
              {props.isEdit ? "Sauvegarder" : "Créer"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default CreditorForm;
