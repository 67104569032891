import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import React, { useState } from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import { isAdmin } from "../../../../utils";
import Filters from "../../Common/Filters";

const ConfirmDeleteDialog = (props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>
        Confirmez-vous la suppression de cet utilisateur ?
        <br />
        <Typography variant="caption" fontSize={16}>
          <i>
            Attention ! Cette action n'est pas réversible et supprimera
            définitivement l'utilisateur.
          </i>
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Annuler
        </Button>
        <Button
          onClick={() => {
            props.handleDelete(props.id);
            props.handleClose();
          }}
          color="primary"
          variant="contained"
          startIcon={<DeleteRoundedIcon />}
          autoFocus
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const List = (props) => {
  const classes = UseStyle();
  const [isDeleting, setIsDeleting] = useState({
    isOpen: false,
    id: null,
  });
  const handleCloseDialogs = () => {
    setIsDeleting({
      isOpen: false,
      id: null,
    });
  };
  const toggleConfirmDialog = (id) => {
    setIsDeleting({
      isOpen: !isDeleting.isOpen,
      id: id,
    });
  };
  let columns = [
    { field: "id", headerName: "ID" },
    {
      headerName: "Utilisateur",
      flex: 1,
      valueGetter: ({ row }) => `${row.lastName} ${row.firstName}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      valueGetter: ({ value }) => value.join(", "),
    },
    {
      field: "createdAt",
      headerName: "Créé le",
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => new Date(value),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditRoundedIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
        <GridActionsCellItem
          color="error"
          icon={
            <Tooltip title="Supprimer">
              <DeleteRoundedIcon />
            </Tooltip>
          }
          onClick={() => toggleConfirmDialog(params.row.id)}
          label="Modifier"
        />,
      ],
    },
  ];
  let types = ["lastname", "phone", "email"];

  if (isAdmin(props.userData)) {
    columns.splice(2, 0, {
      field: "organization",
      headerName: "Société",
      flex: 1,
      valueGetter: ({ value }) => value.name,
    });
    types.splice(0, 0, "client");
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button disabled>Import</Button>
          <Button disabled>Export</Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.toggleAdd}
            startIcon={<AddRoundedIcon />}
          >
            Utilisateur
          </Button>
        </Stack>

        <Filters
          userData={props.userData}
          types={types}
          from="users"
          clients={props.clients}
          handleSubmitFilters={props.handleSubmitFilters}
          loading={props.isLoading}
        />

        <Paper
          className={classes.paper}
          elevation={6}
          sx={{ height: "80vh", width: "100%", marginBottom: 2 }}
        >
          <DataGrid
            columns={columns}
            rows={props.users}
            density="comfortable"
            disableSelectionOnClick
            pagination
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={props.isLoading}
            experimentalFeatures={{ newEditingApi: true }}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "id", sort: "desc" }],
              },
            }}
          />
        </Paper>
      </Stack>

      <ConfirmDeleteDialog
        isOpen={isDeleting.isOpen}
        id={isDeleting.id}
        handleClose={handleCloseDialogs}
        handleDelete={props.handleDelete}
      />
    </>
  );
};

export default List;
