import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import UseStyle from "../../Common/StyledComponent/UseStyle";
import TaxForm from "../TaxForm";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'une taxe"
        />
      </Card>

      <TaxForm {...props} isEdit={false} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: "",
    rate: 0,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .min(1)
      .max(100)
      .required("Un nom est nécessaire pour la taxe. Ex: 10"),
    rate: Yup.number()
      .min(0.01)
      .required("Le taux de la taxe est nécessaire. Ex: 0.01"),
  }),
  handleSubmit: (values, { props }) => {
    const newTaxe = {
      nom: values.name,
      taux: values.rate,
    };

    props.validation(newTaxe);
  },
})(Add);
