import {
  Autocomplete,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import { withFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { locale, mask } from "../../../localisation";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import LoadingButton from "@mui/lab/LoadingButton";
import { lastDayOfMonth } from "date-fns";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  marginTop: "60px",
}));

const DrawerFilters = (props) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [allClientChecked, setAllClientChecked] = useState(true);

  const clients = props.clients.map((client) => {
    const filtre = client.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: client,
    };
  });

  return (
    <Drawer
      sx={{
        width: props.drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: props.drawerWidth,
        },
      }}
      variant="persistent"
      anchor="right"
      open={props.isOpen}
    >
      <DrawerHeader>
        <IconButton onClick={props.handleClose}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>

      <Divider />

      <form>
        <Stack spacing={2} paddingX={1} paddingY={2}>
          {props.isAdmin && (
            <>
              <Stack
                spacing={1}
                alignItems="center"
                divider={<Divider flexItem>OU</Divider>}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Tous les clients"
                    checked={allClientChecked}
                    onChange={(event) => {
                      setAllClientChecked(event.target.checked);

                      if (event.target.checked) {
                        props.setFieldValue(
                          "clients",
                          props.clients.map((client) => client["@id"])
                        );
                        props.setFieldTouched("clients", false, false);
                        setSelectedClient(null);
                      }
                    }}
                  />
                </FormGroup>

                <Autocomplete
                  fullWidth
                  options={clients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.client.id === value.client.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.client) return "";
                    return option.client.id + " - " + option.client.name;
                  }}
                  value={selectedClient}
                  onChange={(event, value) => {
                    setSelectedClient(value);

                    value !== null
                      ? props.setFieldValue("clients", [value.client["@id"]])
                      : props.setFieldValue("clients", []);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      size="small"
                      error={
                        props.touched.clients && Boolean(props.errors.clients)
                      }
                      helperText={
                        props.errors.clients &&
                        props.touched.clients &&
                        props.errors.clients
                      }
                    />
                  )}
                  {...(allClientChecked && { disabled: true })}
                />
              </Stack>

              <Divider />
            </>
          )}

          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="type">Valeurs</InputLabel>
            <Select
              labelId="type"
              id="type"
              label="Valeurs"
              value={props.values.type}
              onChange={(event) => {
                props.setFieldValue("type", event.target.value);
                if (event.target.value === "hebdo") {
                  props.setFieldValue("month", "");
                } else {
                  props.setFieldValue("startDate", "");
                  props.setFieldValue("endDate", "");
                }
              }}
            >
              <MenuItem value="mensuel">Mensuel</MenuItem>
              <MenuItem value="hebdo">Hebdomadaire</MenuItem>
            </Select>
          </FormControl>

          {props.values.type === "mensuel" ? (
            <FormControl variant="outlined" fullWidth>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  views={["month", "year"]}
                  label="Choississez le mois"
                  inputFormat="MM/yyyy"
                  value={props.values.month}
                  onBlur={props.handleBlur}
                  onChange={(value) => props.setFieldValue("month", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={props.touched.month && Boolean(props.errors.month)}
                    />
                  )}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {props.errors.month &&
                  props.touched.month &&
                  props.errors.month}
              </FormHelperText>
            </FormControl>
          ) : (
            <>
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de début"
                    inputFormat="dd/MM/yyyy"
                    value={props.values.startDate}
                    onBlur={props.handleBlur}
                    onChange={(value) =>
                      props.setFieldValue("startDate", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={
                          props.touched.startDate &&
                          Boolean(props.errors.startDate)
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {props.errors.startDate &&
                    props.touched.startDate &&
                    props.errors.startDate}
                </FormHelperText>
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de fin"
                    inputFormat="dd/MM/yyyy"
                    value={props.values.endDate}
                    onBlur={props.handleBlur}
                    onChange={(value) => props.setFieldValue("endDate", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={
                          props.touched.endDate && Boolean(props.errors.endDate)
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {props.errors.endDate &&
                    props.touched.endDate &&
                    props.errors.endDate}
                </FormHelperText>
              </FormControl>
            </>
          )}

          <Divider />

          <LoadingButton
            loading={props.isLoading}
            variant="contained"
            size="small"
            onClick={props.handleSubmit}
          >
            Valider
          </LoadingButton>
        </Stack>
      </form>
    </Drawer>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    clients: props.clients.map((user) => user["@id"]),
    type: "mensuel",
    month: "",
    startDate: "",
    endDate: "",
  }),
  validationSchema: Yup.object().shape({
    clients: Yup.array().of(Yup.string()).min(1, "Sélectionnez un client."),
    type: Yup.string(),
    month: Yup.date().typeError("Date invalide").nullable(),
    startDate: Yup.date()
      .typeError("Date invalide")
      .when("type", {
        is: (type) => type === "hebdo",
        then: Yup.date()
          .typeError("Date invalide")
          .required("Une date de début est obligatoire."),
      }),
    endDate: Yup.date()
      .nullable()
      .min(
        Yup.ref("startDate"),
        "La date de fin doit être supérieure à la date de début."
      ),
  }),
  handleSubmit: (values, { props }) => {
    const filters = {
      type: values.type,
      clients: values.clients,
      month: values.month instanceof Date ? lastDayOfMonth(values.month) : null,
      startDate: values.startDate instanceof Date ? values.startDate : null,
      endDate: values.endDate instanceof Date ? values.endDate : null,
    };

    props.handleSubmitFilters(filters);
  },
})(DrawerFilters);
