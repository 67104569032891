import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CardHeader,
  Card,
  Button,
  Paper,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { withFormik } from "formik";
import React from "react";
import { compose } from "redux";
import * as Yup from "yup";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import Informations from "../Forms/Informations";
import Pilote from "../Forms/Pilote";
import Notifications from "../Forms/Notifications";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <>
      <form>
        <Stack spacing={2} sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent={"flex-end"}>
            <Button
              variant={"contained"}
              onClick={props.toggleList}
              color="secondary"
              startIcon={<ArrowBackIcon />}
            >
              Retour
            </Button>
          </Stack>

          <Card className={classes.card}>
            <CardHeader
              subheader="Ces informations peuvent être modifier."
              title="Modification d'un client"
            />
          </Card>

          <Paper className={classes.paper} elevation={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Informations personnelles</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Informations {...props} />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Repas Pilote</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Pilote {...props} />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Notifications</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Notifications {...props} />
              </AccordionDetails>
            </Accordion>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 2,
              }}
            >
              <LoadingButton
                loading={props.loading}
                onClick={props.handleSubmit}
                variant="contained"
                startIcon={<SaveRoundedIcon />}
              >
                Sauvegarder
              </LoadingButton>
            </Box>
          </Paper>
        </Stack>
      </form>
    </>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      return {
        name: props.client.name,
        mode: props.client.mode,
        email: props.client.email,
        phone: props.client.phone,
        address: props.client.address,
        newsletter: props.client.newsletter,
        organizationNote: props.client.organizationNote,
        adminNote: props.client.adminNote,
        origin: props.client.origin?.id ?? "none",
        creditors: props.client.creditors.map((c) => c.id),
        pilotes: props.client.pilotes.map((p) => ({
          ...p,
          round: p.round.id,
          lunchMenu: p.lunchMenu.id,
          dinnerMenu: p?.dinnerMenu?.id ?? "none",
          diet: p?.diet?.id ?? "none",
          qty: p.qty,
          lunchExtras: p.lunchExtras.map((e) => e.id),
          dinnerExtras: p.dinnerExtras.map((e) => e.id),
        })),
        packages: props.client.packages.map((p) => p.id),
        rounds: props.client.rounds.map((r) => r.id),
        billingAddress: props.client?.billingAddress ?? {
          latitude: 0.0,
          longitude: 0.0,
          number: "",
          street: "",
          city: "",
          locality: "",
          country: "",
          postalCode: "",
          unparsed: "",
          notes: "",
        },
        billingEmail: props.client?.billingEmail ?? "",
        wantMenus: props.client.wantMenus,
        wantMenusBy: props.client.wantMenusBy,
        wantInvoices: props.client.wantInvoices,
        wantInvoicesBy: props.client.wantInvoicesBy,
      };
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Champs obligatoire"),
      mode: Yup.string().required("Champs obligatoire"),
      address: Yup.object()
        .shape({
          latitude: Yup.number().required(),
          longitude: Yup.number().required(),
          number: Yup.string().nullable(),
          street: Yup.string().required(),
          city: Yup.string().required(),
          locality: Yup.string().required(),
          country: Yup.string().required(),
          postalCode: Yup.string().nullable(),
          unparsed: Yup.string().required(
            "Sélectionner une adresse dans la liste déroulante."
          ),
          latitude: Yup.number().required(),
          longitude: Yup.number().required(),
          notes: Yup.string().nullable(),
        })
        .required("Champs obligatoire"),
      email: Yup.string().email().required("Champs obligatoire"),
      phone: Yup.string()
        .required("Un numéro de téléphone est requis")
        .matches(
          "[0]{1}[1-7]{1}[0-9]{8}",
          "Veuillez saisir un numéro de téléphone valide."
        ),
      newsletter: Yup.boolean(),
      creditors: Yup.array()
        .of(Yup.number().positive().required("Champs obligatoire"))
        .min(1, "Au minimum 1 créancier est requis."),
      pilotes: Yup.array().of(
        Yup.object()
          .shape({
            round: Yup.number().positive().required("Champs obligatoire"),
            lunchMenu: Yup.number().positive().required("Champs obligatoire"),
            qty: Yup.number().positive().required("Champs obligatoire"),
          })
          .notRequired()
      ),
      packages: Yup.array().of(Yup.number().positive()),
      rounds: Yup.array().of(Yup.number().positive()),
      billingAddress: Yup.object()
        .shape({
          latitude: Yup.number().required(),
          longitude: Yup.number().required(),
          number: Yup.string().nullable(),
          street: Yup.string().required(),
          city: Yup.string().required(),
          locality: Yup.string().required(),
          country: Yup.string().required(),
          postalCode: Yup.string().nullable(),
          unparsed: Yup.string().required(),
          latitude: Yup.number().required(),
          longitude: Yup.number().required(),
          notes: Yup.string().nullable(),
        })
        .required("Champs obligatoire"),
      billingEmail: Yup.string()
        .email("Veuillez saisir une email valide. Ex: hello@touva.fr ")
        .required("Champs obligatoire"),
    }),
    handleSubmit: (values, { props }) => {
      const updatedClient = {
        id: props.client.id,
        name: values.name,
        mode: values.mode,
        email: values.email.toLowerCase(),
        phone: values.phone,
        address: values.address,
        newsletter: values.newsletter,
        origin: values.origin !== "none" ? `/origins/${values.origin}` : null,
        organizationNote: values.organizationNote,
        adminNote: values.adminNote,
        creditors: values.creditors.map((c) => `/creanciers/${c}`),
        pilotes: values.pilotes.map((p) => ({
          ...p,
          round: `/rounds/${p.round}`,
          lunchMenu: `/formules/${p.lunchMenu}`,
          dinnerMenu:
            p.dinnerMenu !== "none" ? `/formules/${p.dinnerMenu}` : null,
          diet: p.diet !== "none" ? `/regimes/${p.diet}` : null,
          qty: p.qty,
          lunchExtras: p.lunchExtras.map((e) => `/extra_categories/${e}`),
          dinnerExtras: p.dinnerExtras.map((e) => `/extra_categories/${e}`),
        })),
        packages: values.packages.map((p) => `/formules/${p}`),
        rounds: values.rounds.map((p) => `/rounds/${p}`),
        billingAddress: values.billingAddress,
        billingEmail: values.billingEmail,
        wantMenus: values.wantMenus,
        wantMenusBy: values.wantMenusBy,
        wantInvoices: values.wantInvoices,
        wantInvoicesBy: values.wantInvoicesBy,
      };

      props.validation(updatedClient);
    },
  })
)(Edit);
