import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  Chip,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { RECIPIENT_STATE } from "../../Common/Enums/Enums";
import { locale, mask } from "../../../../localisation";
import { getDatesOfWeek } from "../../Menus/Calendar/WeekView";
import Day from "./Day";

const Choices = (props) => {
  const classes = UseStyle();
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const recipients = props.recipients
    .filter(
      (recipient) =>
        recipient["@type"] === "RecipientUnit" &&
        recipient.state === RECIPIENT_STATE.ACTIVE.status
    )
    .map((recipient) => {
      const filtre = recipient.name[0].toUpperCase();
      return {
        filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
        recipient: recipient,
      };
    })
    .sort((a, b) => -b.filtre.localeCompare(a.filtre));

  useEffect(() => {
    if (selectedRecipient !== null && selectedDate !== null) {
      const week = getDatesOfWeek(selectedDate);
      const start = format(new Date(Object.keys(week[0])[0]), "yyyy-MM-dd");
      const end = format(new Date(Object.keys(week[6])[0]), "yyyy-MM-dd");
      props.fetchOrdersChoices(selectedRecipient.recipient.id, start, end);
      props.fetchMenusChoices(start, end, selectedRecipient.recipient.round.id);
    }
  }, [selectedRecipient, selectedDate]);

  const handleChangeWeek = (direction) => {
    const date = new Date(selectedDate);
    if (direction === "next") date.setDate(date.getDate() + 7);
    else date.setDate(date.getDate() - 7);
    setSelectedDate(date);
  };

  return (
    <Stack spacing={2} marginBottom={2}>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={props.toggleList}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Stack>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Appliquer les choix de plats d'un convive"
        />
      </Card>

      <Paper className={classes.paper} elevation={6}>
        <Stack spacing={2}>
          {props.loading && <LinearProgress />}

          <Autocomplete
            sx={{ width: "100%" }}
            options={recipients}
            isOptionEqualToValue={(option, value) =>
              option.recipient.id === value.recipient.id
            }
            groupBy={(value) => value.filtre}
            getOptionLabel={(option) => {
              if (!option.recipient) return "";
              return option.recipient.id + " - " + option.recipient.name;
            }}
            value={selectedRecipient}
            onChange={(event, value) => {
              setSelectedRecipient(value);
            }}
            renderInput={(params) => <TextField {...params} label="Convive" />}
          />

          {selectedRecipient && (
            <Stack flexDirection="row" alignItems="center" spacing={1}>
              {selectedRecipient.recipient.diets.map((diet, i) => (
                <Tooltip key={i} title="Régime">
                  <Chip label={diet.nom} color="primary" />
                </Tooltip>
              ))}

              {selectedRecipient.recipient.allergens.map((allergen, i) => (
                <Tooltip key={i} title="Allergène">
                  <Chip label={allergen.nom} color="secondary" />
                </Tooltip>
              ))}
            </Stack>
          )}

          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Tooltip title="Semaine précédente" arrow placement="left">
              <IconButton
                aria-label="last-week"
                onClick={() => handleChangeWeek("previous")}
              >
                <ArrowCircleLeftOutlinedIcon size="large" />
              </IconButton>
            </Tooltip>

            <Stack flexDirection="row" alignItems="center">
              <Typography variant="h5" paddingRight={3}>
                Semaine {format(selectedDate, "w")}
              </Typography>

              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  label="Date"
                  inputFormat="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={(value) => {
                    if (value instanceof Date && value !== null)
                      setSelectedDate(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      color="primary"
                      disabled
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Tooltip title="Prochaine semaine" arrow placement="right">
              <IconButton
                aria-label="next-week"
                onClick={() => handleChangeWeek("next")}
              >
                <ArrowCircleRightOutlinedIcon size="large" />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack flexDirection="row">
            {getDatesOfWeek(selectedDate).map((day, i) => {
              return (
                <Day
                  key={i}
                  day={day}
                  selectedRecipient={selectedRecipient}
                  {...props}
                />
              );
            })}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Choices;
