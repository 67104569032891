import { Button, LinearProgress, Paper, Stack, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import UseStyle from "../../Common/StyledComponent/UseStyle";

const List = (props) => {
  const classes = UseStyle();
  const columns = [
    { field: "id", headerName: "ID" },
    { field: "nom", headerName: "Nom", flex: 0.5 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
      ],
    },
  ];

  return (
    <Stack spacing={2}>
      <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Créancier
        </Button>
      </Stack>

      <Paper
        className={classes.paper}
        elevation={6}
        sx={{ height: "80vh", width: "100%" }}
      >
        <DataGrid
          columns={columns}
          rows={props.creditors}
          density="comfortable"
          disableSelectionOnClick
          pagination
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={props.isLoading}
          experimentalFeatures={{ newEditingApi: true }}
          getRowHeight={() => "auto"}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </Paper>
    </Stack>
  );
};

export default List;
