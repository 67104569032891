import {
  Card,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import React from "react";
import sun from "../../../../assets/img/home/sun.svg";
import moon from "../../../../assets/img/home/moon.svg";
import "./menus.css";
import { CardContentNoPadding } from "../../common/Formules/Formules";

const DayCard = styled(Card)(`
  padding: 0;
  border-radius: 10px;

`);

const DayCardHeader = styled(CardHeader)(`
  padding: 7px 12px;
  background-color: #FAD086;
`);

const Menus = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));
  let lunchMenus = [
    {
      day: "Lundi",
      items: {
        starter: ["Brouillade d'oeuf, sauce tomage"],
        main: [
          "Quenelle au bleu et purée de brocolis",
          "Canelloni à la bolognese",
        ],
        dessert: ["Compote pomme poire"],
        cheese: ["Mimolette", "Edam"],
      },
    },
    {
      day: "Mardi",
      items: {
        starter: ["Carottes râpées et dés d'emmental"],
        main: [
          "Couscous aux légumes d'hiver",
          "Boulettes de thon à la provençale et semoule",
        ],
        dessert: ["Éclair au chocolat", "Fruit frais"],
        cheese: ["Camembert", "Fromage frais"],
      },
    },
    {
      day: "Mercredi",
      items: {
        starter: ["Velouté de pois cassé"],
        main: [
          "Boulettes de boeuf et penne, sauce tomage",
          "Gratin de brocolis",
        ],
        dessert: ["Yaourt vanille bio"],
        cheese: ["Emmental", "Fromage frais"],
      },
    },
    {
      day: "Jeudi",
      items: {
        starter: ["Rosette de lyon, cornichons"],
        main: [
          "Crêpes à l'emmental et haricots verts persillés",
          "Accras de morue et chou fleur persillés",
        ],
        dessert: ["Fruit frais", "Compote"],
        cheese: ["Gouda"],
      },
    },
    {
      day: "Vendredi",
      items: {
        starter: ["Velouté de lentilles corail"],
        main: ["Gratin de chou fleur"],
        dessert: ["La belle Madeleine", "Laitage", "Crème dessert"],
        cheese: ["Mimolette"],
      },
    },
    {
      day: "Samedi",
      items: {
        starter: ["Velouté de courge"],
        main: ["Risotto aux cépes et noisettes", "Flan de légumes aux oeufs"],
        dessert: ["Yaourt bio, goût banane", "Paris brest"],
        cheese: ["Tomme noire", "Fromage frais"],
      },
    },
    {
      day: "Dimanche",
      items: {
        starter: ["Salade de riz jaune", "Humous de betterave"],
        main: [
          "Galette de pomme de terre et chou sauté",
          "Blanquette de poulet et pommes de terre rôties",
        ],
        dessert: ["Crème dessert vanille", "Fruit frais"],
        cheese: ["Cantal", "Laitage"],
      },
    },
  ];
  let dinnerMenus = [
    {
      day: "Lundi",
      items: {
        starter: [
          "Velouté de tomates et pommes de terre",
          "Salade de pois chiches au cumin",
        ],
        main: ["Jambon blanc et haricots verts persillés"],
        dessert: ["Yaourt bio, goût vanille"],
      },
    },
    {
      day: "Mardi",
      items: {
        starter: ["Velouté de tomates et pommes", "Velouté de haricots rouge"],
        main: ["Feuilleté chèvre, épinard"],
        dessert: ["Compote"],
      },
    },
    {
      day: "Mercredi",
      items: {
        starter: [
          "Velouté de légumes verts et pomme de terre",
          "Taboulé aux herbes",
        ],
        main: ["Dos de clin et purée de brocolis"],
        dessert: ["Novely chocolat", "Fruit frais"],
      },
    },
    {
      day: "Jeudi",
      items: {
        starter: [
          "Velouté de poireaux et pommes de terre",
          "Velouté de corail",
        ],
        main: [
          "Crêpes à l'emmental et haricots verts persillés",
          "Gratin de chou fleur",
        ],
        dessert: ["Yaourt brassé"],
      },
    },
    {
      day: "Vendredi",
      items: {
        starter: ["Velouté de 7 légumes", "Salade de blé et noix"],
        main: [
          "Aiguillette de poulet et carottes rôties, sauce tomate",
          "Falafels et carottes rôties, sauce tomage",
        ],
        dessert: ["Petits suisses"],
      },
    },
    {
      day: "Samedi",
      items: {
        starter: ["Velouté de carottes à la crème", "Salade de riz jaune"],
        main: ["Galette de pomme de terre et chou sauté"],
        dessert: ["Crème dessert, goût vanille", "Fruit frais"],
      },
    },
    {
      day: "Dimanche",
      items: {
        starter: [
          "Velouté de tomates et pommes de terre",
          "Velouté de légumes au lait entier",
        ],
        main: ["Tarte aux poireaux"],
        dessert: ["Compote"],
      },
    },
  ];

  if (matchDownMd) {
    lunchMenus = lunchMenus.slice(4);
    dinnerMenus = dinnerMenus.slice(4);
  }

  return (
    <div className="menus">
      <Grid container flexDirection="column" padding={2} spacing={3}>
        <Grid item container flexDirection="column" spacing={1}>
          <Grid item container spacing={3} alignItems="center">
            <Grid item>
              <img src={sun} alt="soleil" />
            </Grid>

            <Grid item>
              <Typography variant="h5" color="primary">
                <strong>Déjeuners</strong>
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              Pssst, un exemple de menus c'est par ici :
            </Typography>
          </Grid>

          <Grid item container spacing={3}>
            {lunchMenus.map((l, i) => (
              <Grid key={i} item xs={12} md={3}>
                <DayCard variant="outlined">
                  <DayCardHeader title={l.day} avatar={<TodayRoundedIcon />} />
                  <CardContentNoPadding>
                    <List
                      sx={{
                        width: "100%",
                        maxHeight: "100%",
                        overflow: "auto",
                        paddingX: 2,
                        paddingY: 0,
                      }}
                    >
                      {l.items.starter.map((s, i) => (
                        <div key={i}>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={s}
                              sx={{ textAlign: "center" }}
                            />
                          </ListItem>
                          {l.items.starter.length > 1 &&
                            i + 1 !== l.items.starter.length && (
                              <ListItem disablePadding>
                                <ListItemText
                                  primary="OU"
                                  sx={{
                                    textAlign: "center",
                                    fontStyle: "italic",
                                  }}
                                />
                              </ListItem>
                            )}
                        </div>
                      ))}

                      <Divider />

                      {l.items.main.map((m, i) => (
                        <div key={i}>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={m}
                              sx={{ textAlign: "center" }}
                            />
                          </ListItem>
                          {l.items.main.length > 1 &&
                            i + 1 !== l.items.main.length && (
                              <ListItem disablePadding>
                                <ListItemText
                                  primary="OU"
                                  sx={{
                                    textAlign: "center",
                                    fontStyle: "italic",
                                  }}
                                />
                              </ListItem>
                            )}
                        </div>
                      ))}

                      <Divider />

                      {l.items.dessert.map((d, i) => (
                        <div key={i}>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={d}
                              sx={{ textAlign: "center" }}
                            />
                          </ListItem>
                          {l.items.dessert.length > 1 &&
                            i + 1 !== l.items.dessert.length && (
                              <ListItem disablePadding>
                                <ListItemText
                                  primary="OU"
                                  sx={{
                                    textAlign: "center",
                                    fontStyle: "italic",
                                  }}
                                />
                              </ListItem>
                            )}
                        </div>
                      ))}

                      <Divider />

                      {l.items.cheese.map((c, i) => (
                        <div key={i}>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={c}
                              sx={{ textAlign: "center" }}
                            />
                          </ListItem>
                          {l.items.cheese.length > 1 &&
                            i + 1 !== l.items.cheese.length && (
                              <ListItem disablePadding>
                                <ListItemText
                                  primary="OU"
                                  sx={{
                                    textAlign: "center",
                                    fontStyle: "italic",
                                  }}
                                />
                              </ListItem>
                            )}
                        </div>
                      ))}
                    </List>
                  </CardContentNoPadding>
                </DayCard>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item container flexDirection="column" spacing={1}>
          <Grid item container spacing={3} alignItems="center">
            <Grid item>
              <img src={moon} alt="lune" />
            </Grid>

            <Grid item>
              <Typography variant="h5" color="primary">
                <strong>Dîners</strong>
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              Pssst, un exemple de menus c'est par ici :
            </Typography>
          </Grid>

          <Grid item container spacing={3}>
            {dinnerMenus.map((l, i) => (
              <Grid key={i} item xs={12} md={3}>
                <DayCard variant="outlined">
                  <DayCardHeader
                    title={l.day}
                    avatar={<TodayRoundedIcon />}
                    sx={{
                      backgroundColor: "#373737",
                      color: "white",
                    }}
                  />
                  <CardContentNoPadding>
                    <List
                      sx={{
                        width: "100%",
                        maxHeight: "100%",
                        overflow: "auto",
                        paddingX: 2,
                        paddingY: 0,
                      }}
                    >
                      {l.items.starter.map((s, i) => (
                        <div key={i}>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={s}
                              sx={{ textAlign: "center" }}
                            />
                          </ListItem>
                          {l.items.starter.length > 1 &&
                            i + 1 !== l.items.starter.length && (
                              <ListItem disablePadding>
                                <ListItemText
                                  primary="OU"
                                  sx={{
                                    textAlign: "center",
                                    fontStyle: "italic",
                                  }}
                                />
                              </ListItem>
                            )}
                        </div>
                      ))}

                      <Divider />

                      {l.items.main.map((m, i) => (
                        <div key={i}>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={m}
                              sx={{ textAlign: "center" }}
                            />
                          </ListItem>
                          {l.items.main.length > 1 &&
                            i + 1 !== l.items.main.length && (
                              <ListItem disablePadding>
                                <ListItemText
                                  primary="OU"
                                  sx={{
                                    textAlign: "center",
                                    fontStyle: "italic",
                                  }}
                                />
                              </ListItem>
                            )}
                        </div>
                      ))}

                      <Divider />

                      {l.items.dessert.map((d, i) => (
                        <div key={i}>
                          <ListItem disablePadding>
                            <ListItemText
                              primary={d}
                              sx={{ textAlign: "center" }}
                            />
                          </ListItem>
                          {l.items.dessert.length > 1 &&
                            i + 1 !== l.items.dessert.length && (
                              <ListItem disablePadding>
                                <ListItemText
                                  primary="OU"
                                  sx={{
                                    textAlign: "center",
                                    fontStyle: "italic",
                                  }}
                                />
                              </ListItem>
                            )}
                        </div>
                      ))}

                      <Divider />
                    </List>
                  </CardContentNoPadding>
                </DayCard>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Menus;
