import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, LinearProgress, Paper, Stack, Tooltip } from "@mui/material";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";

const List = (props) => {
  const classes = UseStyle();
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "recipient",
      headerName: "Convive",
      flex: 1,
      valueGetter: ({ value }) => value.name,
    },
    {
      field: "orderUnit",
      headerName: "Commande",
      flex: 1,
      valueGetter: ({ value }) =>
        value.id + " - " + format(new Date(value.date), "dd/MM/yyyy"),
    },
    {
      headerName: "Appliqué au",
      flex: 1,
      type: "date",
      valueGetter: (params) => {
        const date = new Date(params.row.year, params.row.month - 1, 1);
        const month = format(date, "LLLL yyyy", {
          locale: fr,
        });

        return month.charAt(0).toUpperCase() + month.slice(1);
      },
    },
    {
      field: "createdAt",
      headerName: "Créé le",
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => new Date(value),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <DeleteIcon color="error" />
            </Tooltip>
          }
          onClick={() => props.handleDelete(params.id)}
          label="Supprimer"
        />,
      ],
    },
  ];

  return (
    <Stack spacing={2} marginBottom={2}>
      <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Avoir
        </Button>
      </Stack>

      <Paper className={classes.paper} elevation={6}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={props.creditNotes}
          pageSize={30}
          density="comfortable"
          disableSelectionOnClick
          pagination
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          loading={props.isLoading}
          experimentalFeatures={{ newEditingApi: true }}
          getRowHeight={() => "auto"}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </Paper>
    </Stack>
  );
};

export default List;
