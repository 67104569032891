import CancelIcon from "@mui/icons-material/Cancel";
import ModeNightOutlinedIcon from "@mui/icons-material/ModeNightOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ORDER_STATUS } from "../../Common/Enums/Enums";
import { format } from "date-fns";

const isItemAdaptedToRecipient = (item, recipient) => {
  const isNotDietsAdapted = item.regimesNonAdaptes.some((diet) => {
    return recipient.recipient.diets.some((rd) => rd.id === diet.id);
  });
  const isNotAllergensAdapted = item.allergenes.some((allergen) => {
    return recipient.recipient.allergens.some((ra) => ra.id === allergen.id);
  });

  if (isNotDietsAdapted || isNotAllergensAdapted) {
    return false;
  } else {
    return true;
  }
};

const Day = (props) => {
  const [selected, setSelected] = useState(1);

  const date = new Date(Object.keys(props.day)[0]);
  const dayName = date.toLocaleString("fr-FR", {
    weekday: "narrow",
  });
  const formatedDate = format(date, "dd/MM");
  let order = null;

  if (props.selectedRecipient) {
    order = props.ordersChoices.find(
      (order) =>
        format(new Date(order.date), "yyyy-MM-dd") ===
        format(date, "yyyy-MM-dd")
    );
  }

  let menus = props.menus.filter(
    (menu) =>
      format(new Date(menu.date), "yyyy-MM-dd") === format(date, "yyyy-MM-dd")
  );

  let lunchProducts = [];
  let dinnerProducts = [];
  let lunchExtras = [];
  let dinnerExtras = [];

  if (order) {
    // Fonction pour traiter les produits pour une catégorie donnée
    const processCategory = (menus, isDinner) => {
      let categories = [];

      // On récupère toutes les catégories de produits et suppléments
      menus.forEach((menu) => {
        const menuProducts = isDinner
          ? menu.dinnerProducts
          : menu.lunchProducts;
        menuProducts.forEach((p) => {
          const categorie = p.categorie;
          if (!categories.some((c) => c.id === categorie.id)) {
            categories.push(categorie);
          }
        });

        // On récupère tous les suppléments
        menu.lunchExtras.forEach((e) => {
          if (!lunchExtras.some((c) => c.id === e.id)) {
            lunchExtras.push(e);
          }
        });

        menu.dinnerExtras.forEach((e) => {
          if (!dinnerExtras.some((d) => d.id === e.id)) {
            dinnerExtras.push(e);
          }
        });
      });

      categories.sort((a, b) => a.priorite - b.priorite);

      // Pour chaque catégorie, parcourir tous les menus et ajouter les produits correspondant à la catégorie
      categories.forEach((categorie) => {
        let productsByCategorie = [];
        menus.forEach((menu) => {
          const menuProducts = isDinner
            ? menu.dinnerProducts
            : menu.lunchProducts;
          const filteredProducts = menuProducts.filter(
            (p) => p.categorie.id === categorie.id
          );
          filteredProducts.forEach((p) => productsByCategorie.push(p));
        });

        // On supprime les produits en doublons (cela signifie que le produit est présent dans plusieurs menus)
        productsByCategorie = productsByCategorie.filter(
          (p, i, self) => self.findIndex((p2) => p2.id === p.id) === i
        );

        if (isDinner) {
          dinnerProducts.push({
            categorie: categorie,
            dinnerProducts: productsByCategorie,
          });
        } else {
          lunchProducts.push({
            categorie: categorie,
            lunchProducts: productsByCategorie,
          });
        }
      });
    };

    // Traiter les produits du déjeuner
    processCategory(menus, false);

    // Traiter les produits du dîner si disponible
    if (order.dinnerMenu) {
      processCategory(menus, true);
    }
  }

  return (
    <Stack
      flex={1}
      height="100%"
      width="100%"
      sx={{
        bgcolor: !order
          ? null
          : order.statut === ORDER_STATUS.CANCELLED.status
          ? "#F9EDED"
          : order?.statut !== ORDER_STATUS.PENDING.status
          ? "#EBF3EB"
          : null,
      }}
    >
      <Typography variant="body1" textAlign="center">
        {dayName} {formatedDate}
      </Typography>

      <Stack
        padding={2}
        spacing={1}
        height="100%"
        sx={{
          borderLeft: 1,
          borderRight: props.key === 6 ? 1 : 0,
          borderColor: "divider",
        }}
        justifyContent="center"
        alignItems={order ? "flex-start" : "center"}
      >
        {order ? (
          <>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="status">Statut</InputLabel>
              <Select
                labelId="status"
                id="status"
                label="Statut"
                value={order.statut}
                onChange={(event) =>
                  props.validation(order.id, {
                    statut: event.target.value,
                  })
                }
              >
                {Object.values(ORDER_STATUS).map((value) => (
                  <MenuItem key={value.status} value={value.status}>
                    {value.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack direction="row" spacing={1} width="100%">
              <Tooltip
                title={
                  selected === 1
                    ? "Produits du déjeuner sélectionné"
                    : "Afficher les produits du déjeuner"
                }
              >
                <Button
                  fullWidth
                  size="small"
                  variant={selected === 1 ? "outlined" : "contained"}
                  {...(selected === 1 && {
                    disableElevation: true,
                    disableRipple: true,
                    sx: {
                      "&:hover": {
                        cursor: "default",
                      },
                    },
                  })}
                  color="primary"
                  onClick={() => {
                    setSelected(1);
                  }}
                >
                  <LightModeOutlinedIcon />
                </Button>
              </Tooltip>

              {order.dinnerMenu && (
                <Tooltip
                  title={
                    selected === 2
                      ? "Produits du dîner sélectionné"
                      : "Afficher les produits du dîner"
                  }
                >
                  <Button
                    fullWidth
                    size="small"
                    variant={selected === 2 ? "outlined" : "contained"}
                    {...(selected === 2 && {
                      disableElevation: true,
                      disableRipple: true,
                      sx: {
                        "&:hover": {
                          cursor: "default",
                        },
                      },
                    })}
                    color="primary"
                    onClick={() => {
                      setSelected(2);
                    }}
                  >
                    <ModeNightOutlinedIcon />
                  </Button>
                </Tooltip>
              )}
            </Stack>

            {selected === 1 ? (
              <>
                {lunchProducts.map((item, i) => (
                  <FormControl key={i}>
                    <FormLabel id="radio-category">
                      {item.categorie.nom}
                    </FormLabel>
                    {item.lunchProducts.map((p, i) => {
                      let checked = false;
                      const orderProduct = order.produitsDejeuner.find(
                        (odp) => odp.id === p.id
                      );

                      if (orderProduct) checked = true;

                      return (
                        <FormControlLabel
                          key={i}
                          value={p.id}
                          control={
                            <Checkbox
                              size="small"
                              checked={checked}
                              onChange={(event) => {
                                const checked = event.target.checked;
                                let orderProducts = order.produitsDejeuner;

                                if (checked) {
                                  const productToReplace = orderProducts.find(
                                    (op) => op.categorie.id === p.categorie.id
                                  );

                                  if (productToReplace) {
                                    orderProducts = orderProducts.map((op) => {
                                      if (op.id === productToReplace.id)
                                        return `/produits/${p.id}`;
                                      else return `/produits/${op.id}`;
                                    });
                                  } else {
                                    orderProducts = orderProducts.map(
                                      (op) => `/produits/${op.id}`
                                    );
                                    orderProducts.push(`/produits/${p.id}`);
                                  }
                                } else {
                                  orderProducts = orderProducts
                                    .filter((op) => op.id !== p.id)
                                    .map((op) => `/produits/${op.id}`);
                                }

                                props.validation(order.id, {
                                  produitsDejeuner: orderProducts,
                                });
                              }}
                              {...(!isItemAdaptedToRecipient(
                                p,
                                props.selectedRecipient
                              ) && { color: "error" })}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              {...(!isItemAdaptedToRecipient(
                                p,
                                props.selectedRecipient
                              ) && { color: "error" })}
                            >
                              {p.nom}
                            </Typography>
                          }
                        />
                      );
                    })}
                  </FormControl>
                ))}

                {lunchExtras.length > 0 && (
                  <FormControl>
                    <FormLabel>Suppléments</FormLabel>
                    {lunchExtras.map((e, i) => {
                      let checked = false;
                      const orderExtra = order.supplementsDejeuner.find(
                        (osp) => osp.supplement.id === e.id
                      );
                      if (orderExtra) checked = true;

                      return (
                        <FormControlLabel
                          key={i}
                          value={e.id}
                          control={
                            <Checkbox
                              size="small"
                              checked={checked}
                              {...(!isItemAdaptedToRecipient(
                                e,
                                props.selectedRecipient
                              ) && { color: "error" })}
                              onChange={(event) => {
                                let orderExtras = order.supplementsDejeuner;
                                let orderFormuleExtras =
                                  order.lunchMenu.extraCategories;

                                // On créé un tableau avec les catégories de suppléments afin de pouvoir vérifier si le supplément est inclus dans le menu
                                orderFormuleExtras = orderFormuleExtras.map(
                                  (ofe) => ({
                                    categoryId: ofe.id,
                                    qteNeeded: 1,
                                    qteInOrder: 0,
                                  })
                                );

                                // On parcourt les suppléments de la commande afin de vérifier si le supplément est inclus dans le menu
                                orderExtras.forEach((oe) => {
                                  const category = oe.supplement.category;
                                  const index = orderFormuleExtras.findIndex(
                                    (ofe) => ofe.categoryId === category.id
                                  );
                                  if (index !== -1) {
                                    orderFormuleExtras[index].qteInOrder++;
                                  }
                                });

                                if (event.target.checked) {
                                  let isMenuIncluded = false;
                                  const index = orderFormuleExtras.findIndex(
                                    (ofe) =>
                                      ofe.categoryId === e.category.id &&
                                      ofe.qteNeeded === ofe.qteInOrder
                                  );

                                  // Si le supplément est inclus dans le menu, on met à jour la variable isMenuIncluded
                                  if (index !== -1) isMenuIncluded = true;

                                  orderExtras = orderExtras.map((oe) => ({
                                    supplement: `/supplements/${oe.supplement.id}`,
                                    qte: 1,
                                    isMenuIncluded: oe.isMenuIncluded,
                                  }));

                                  orderExtras.push({
                                    supplement: `/supplements/${e.id}`,
                                    qte: 1,
                                    isMenuIncluded: isMenuIncluded,
                                  });
                                } else {
                                  orderExtras = orderExtras
                                    .filter((oe) => oe.supplement.id !== e.id)
                                    .map((oe) => ({
                                      supplement: `/supplements/${oe.supplement.id}`,
                                      qte: 1,
                                      isMenuIncluded: oe.isMenuIncluded,
                                    }));
                                }

                                props.validation(order.id, {
                                  supplementsDejeuner: orderExtras,
                                });
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              {...(!isItemAdaptedToRecipient(
                                e,
                                props.selectedRecipient
                              ) && { color: "error" })}
                            >
                              {e.nom}
                            </Typography>
                          }
                        />
                      );
                    })}
                  </FormControl>
                )}
              </>
            ) : (
              <>
                {dinnerProducts.map((item, i) => (
                  <>
                    <FormControl key={i}>
                      <FormLabel id="radio-category">
                        {item.categorie.nom}
                      </FormLabel>
                      {item.dinnerProducts.map((p, i) => {
                        let checked = false;
                        const orderProduct = order.produitsDiner.find(
                          (odp) => odp.id === p.id
                        );

                        if (orderProduct) checked = true;

                        return (
                          <FormControlLabel
                            key={i}
                            value={p.id}
                            control={
                              <Checkbox
                                size="small"
                                checked={checked}
                                {...(!isItemAdaptedToRecipient(
                                  p,
                                  props.selectedRecipient
                                ) && { color: "error" })}
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  let orderProducts = order.produitsDiner;

                                  if (checked) {
                                    const productToReplace = orderProducts.find(
                                      (op) => op.categorie.id === p.categorie.id
                                    );

                                    if (productToReplace) {
                                      orderProducts = orderProducts.map(
                                        (op) => {
                                          if (op.id === productToReplace.id)
                                            return `/produits/${p.id}`;
                                          else return `/produits/${op.id}`;
                                        }
                                      );
                                    } else {
                                      orderProducts = orderProducts.map(
                                        (op) => `/produits/${op.id}`
                                      );
                                      orderProducts.push(`/produits/${p.id}`);
                                    }
                                  } else {
                                    orderProducts = orderProducts
                                      .filter((op) => op.id !== p.id)
                                      .map((op) => `/produits/${op.id}`);
                                  }

                                  props.validation(order.id, {
                                    produitsDiner: orderProducts,
                                  });
                                }}
                              />
                            }
                            label={
                              <Typography
                                variant="subtitle2"
                                {...(!isItemAdaptedToRecipient(
                                  p,
                                  props.selectedRecipient
                                ) && { color: "error" })}
                              >
                                {p.nom}
                              </Typography>
                            }
                          />
                        );
                      })}
                    </FormControl>
                  </>
                ))}

                {dinnerExtras.length > 0 && (
                  <FormControl>
                    <FormLabel>Suppléments</FormLabel>
                    {dinnerExtras.map((e, i) => {
                      let checked = false;
                      const orderExtra = order.supplementsDiner.find(
                        (osp) => osp.supplement.id === e.id
                      );
                      if (orderExtra) checked = true;

                      return (
                        <FormControlLabel
                          key={i}
                          value={e.id}
                          control={
                            <Checkbox
                              size="small"
                              checked={checked}
                              {...(!isItemAdaptedToRecipient(
                                e,
                                props.selectedRecipient
                              ) && { color: "error" })}
                              onChange={(event) => {
                                let orderExtras = order.supplementsDiner;
                                let orderFormuleExtras =
                                  order.dinnerMenu.extraCategories;

                                orderFormuleExtras = orderFormuleExtras.map(
                                  (ofe) => ({
                                    categoryId: ofe.id,
                                    qteNeeded: 1,
                                    qteInOrder: 0,
                                  })
                                );

                                orderExtras.forEach((oe) => {
                                  const category = oe.supplement.category;
                                  const index = orderFormuleExtras.findIndex(
                                    (ofe) => ofe.categoryId === category.id
                                  );
                                  if (index !== -1) {
                                    orderFormuleExtras[index].qteInOrder++;
                                  }
                                });

                                if (event.target.checked) {
                                  let isMenuIncluded = false;
                                  const index = orderFormuleExtras.findIndex(
                                    (ofe) =>
                                      ofe.categoryId === e.category.id &&
                                      ofe.qteNeeded === ofe.qteInOrder
                                  );
                                  if (index !== -1) {
                                    isMenuIncluded = true;
                                  }

                                  orderExtras = orderExtras.map((oe) => ({
                                    supplement: `/supplements/${oe.supplement.id}`,
                                    qte: 1,
                                    isMenuIncluded: oe.isMenuIncluded,
                                  }));

                                  orderExtras.push({
                                    supplement: `/supplements/${e.id}`,
                                    qte: 1,
                                    isMenuIncluded: isMenuIncluded,
                                  });
                                } else {
                                  orderExtras = orderExtras
                                    .filter((oe) => oe.supplement.id !== e.id)
                                    .map((oe) => ({
                                      supplement: `/supplements/${oe.supplement.id}`,
                                      qte: 1,
                                      isMenuIncluded: oe.isMenuIncluded,
                                    }));
                                }

                                props.validation(order.id, {
                                  supplementsDiner: orderExtras,
                                });
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              {...(!isItemAdaptedToRecipient(
                                e,
                                props.selectedRecipient
                              ) && { color: "error" })}
                            >
                              {e.nom}
                            </Typography>
                          }
                        />
                      );
                    })}
                  </FormControl>
                )}
              </>
            )}
          </>
        ) : (
          <Tooltip title="Aucune commande">
            <CancelIcon fontSize="medium" color="disabled" />
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default Day;
