import React, { useState } from "react";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  Paper,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddIcon from "@mui/icons-material/Add";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RecipientsListDataGrid from "./RecipientsListDataGrid";
import Filters from "../../Common/Filters";
import { locale, mask } from "../../../../localisation";
import RecipientsListMacro from "./RecipientsListMacro";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { isAdmin, isOwner, isProdAdmin, isSalesAdmin } from "../../../../utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";

const ExportDialog = (props) => {
  const [selectedClient, setSelectedClient] = useState(null);

  const clients = props.clients.map((client) => {
    const filtre = client.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: client,
    };
  });
  const formik = useFormik({
    initialValues: {
      start: null,
      end: null,
      client: null,
    },
    validationSchema: Yup.object({
      start: Yup.date().nullable().typeError("Date invalide"),
      end: Yup.date().nullable().typeError("Date invalide"),
      client: Yup.number().required("Veuillez sélectionner un client").typeError("Veuillez sélectionner un client"),
    }),
    onSubmit: (values, { actions }) => {
      props.sendDataExport(
        values.start ? format(values.start, "yyyy-MM-dd") : null,
        values.end ? format(values.end, "yyyy-MM-dd") : null,
        values.client
      );
      props.handleClose();
      actions.resetForm();
      setSelectedClient(null);
    },
  });

  return (
    <Dialog open={props.isExporting} onClose={props.handleClose} fullWidth>
      <DialogTitle>
        Exporter les convives
        <DialogContentText>
          Si vous indiquez seulement la date de début, vous récupérerez tous les
          convives mangeant à partir de cette date.
        </DialogContentText>
        <DialogContentText>
          Si vous indiquez seulement la date de fin, vous récupérerez tous les
          convives mangeant jusqu'à cette date.
        </DialogContentText>
        <DialogContentText>
          Indiquez les 2 dates afin de récupérer tous les convives mangeant entre
          ces dates.
        </DialogContentText>
      </DialogTitle>

      <DialogContent dividers>
        <form>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={formik.touched.start && Boolean(formik.errors.start)}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de début"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.start}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("start", value)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.start &&
                    formik.touched.start &&
                    formik.errors.start}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={formik.touched.end && Boolean(formik.errors.end)}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de fin"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.end}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("end", value)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.end && formik.touched.end && formik.errors.end}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                value={selectedClient}
                options={clients.sort(
                  (a, b) => -b.filtre.localeCompare(a.filtre)
                )}
                isOptionEqualToValue={(option, value) =>
                  option.client.id === value.client.id
                }
                groupBy={(value) => value.filtre}
                getOptionLabel={(option) => {
                  if (!option.client) return "";
                  return option.client.id + " - " + option.client.name;
                }}
                onChange={(event, value) => {
                  setSelectedClient(value);
                  value !== null
                    ? formik.setFieldValue("client", value.client.id)
                    : formik.setFieldValue("client", null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    disabled={props.loading}
                    error={
                      formik.touched.client && Boolean(formik.errors.client)
                    }
                    helperText={
                      formik.errors.client &&
                      formik.touched.client &&
                      formik.errors.client
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <LoadingButton loading={props.loading} onClick={props.handleClose}>
          Annuler
        </LoadingButton>
        <LoadingButton
          loading={props.loading}
          color="primary"
          variant="contained"
          startIcon={<DownloadRoundedIcon />}
          onClick={formik.handleSubmit}
        >
          Exporter
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const ImportDialog = (props) => {
  const [selectedClient, setSelectedClient] = useState(
    !isAdmin(props.userData) ? { client: props.userData } : null
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const handleResetForm = () => {
    setSelectedFile(null);
    setSelectedClient(null);
  };
  const formik = useFormik({
    initialValues: {
      file: "",
      client: null,
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required("Champs obligatoire"),
      client: Yup.number().required().typeError("Champs obligatoire"),
    }),
    onSubmit: (values, { actions }) => {
      props.handleImport(values.file, values.client);
      props.handleClose();
      handleResetForm();
      setSelectedClient(null);
      actions.resetForm();
    },
  });
  const clients = props.clients.map((client) => {
    const filtre = client.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: client,
    };
  });

  return (
    <Dialog
      open={props.isImporting}
      onClose={() => {
        props.handleClose();
        handleResetForm();
        setSelectedClient(null);
      }}
      fullWidth
    >
      <DialogTitle>
        Importer des convives
        <DialogContentText></DialogContentText>
      </DialogTitle>

      <DialogContent>
        <form>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} marginTop={1}>
              <Autocomplete
                options={clients.sort(
                  (a, b) => -b.filtre.localeCompare(a.filtre)
                )}
                isOptionEqualToValue={(option, value) =>
                  option.client.id === value.client.id
                }
                groupBy={(value) => value.filtre}
                getOptionLabel={(option) => {
                  if (!option.client) return "";
                  return option.client.id + " - " + option.client.name;
                }}
                value={selectedClient}
                onChange={(event, value) => {
                  setSelectedClient(value);

                  value !== null
                    ? formik.setFieldValue("client", value.client.id)
                    : formik.setFieldValue("client", null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    size="small"
                    error={
                      formik.touched.client && Boolean(formik.errors.client)
                    }
                    helperText={
                      formik.errors.client &&
                      formik.touched.client &&
                      formik.errors.client
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <label htmlFor="contained-button-file">
                <Input
                  inputProps={{
                    accept: ".csv,.xls,.xlsx",
                  }}
                  id="contained-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    formik.setFieldValue("file", e.target.files[0]);
                  }}
                />
                <LoadingButton
                  loading={props.loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<UploadRoundedIcon />}
                >
                  Importer un fichier
                </LoadingButton>
              </label>
            </Grid>

            <Grid item xs={6}>
              <LoadingButton
                disabled={!selectedClient}
                fullWidth
                loading={props.loading}
                color="primary"
                variant="contained"
                startIcon={<DownloadRoundedIcon />}
                onClick={() =>
                  props.downloadImportTemplate(formik.values.client)
                }
              >
                Télécharger le modèle
              </LoadingButton>
            </Grid>

            <Grid item xs={12}>
              {selectedFile ? (
                <Typography variant="body2">{selectedFile.name}</Typography>
              ) : (
                <Typography
                  variant="body2"
                  color={
                    formik.touched.file &&
                    Boolean(formik.errors.file) &&
                    "error"
                  }
                >
                  Aucun fichier sélectionné.
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                <i>
                  Informations concernant le modèle d'import :
                  <br />• Sélectionnez un client afin de télécharger le modèle.
                  <br />• Veuillez respecter le même format que l'exemple donné
                  sur la ligne 2.
                  <br />• Ne pas modifier les entêtes de colonnes.
                  <br />
                  <u>
                    • Ne pas supprimer l'exemple.
                    <br />• Les données sont importés à partir de la ligne 3.
                  </u>
                </i>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={() => {
            props.handleClose();
            handleResetForm();
            setSelectedClient(null);
          }}
        >
          Annuler
        </LoadingButton>
        <LoadingButton
          loading={props.loading}
          color="primary"
          variant="contained"
          startIcon={<UploadRoundedIcon />}
          onClick={formik.handleSubmit}
        >
          Importer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const AbsenceDialog = ({
  loading,
  openAbsence,
  handleClose,
  handleSubmitAbsence,
  recipients,
}) => {
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const formik = useFormik({
    initialValues: {
      recipient: null,
      startDate: new Date(),
      endDate: null,
    },
    validationSchema: Yup.object({
      recipient: Yup.number()
        .positive()
        .required("Champs obligatoire")
        .typeError("Champs obligatoire"),
      startDate: Yup.date().required().typeError("Date invalide"),
      endDate: Yup.date().required().typeError("Date invalide"),
    }),
    onSubmit: (values) => {
      handleSubmitAbsence(values.recipient, values.startDate, values.endDate);
      handleClose();
    },
  });

  return (
    <Dialog open={openAbsence} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        Gérer l'absence d'un convive.
        <DialogContentText>
          Rentrer les dates pendant lequel le convive sera absent et son statut
          sera modifié automatiquement jusqu'à la date de son retour.
        </DialogContentText>
        <DialogContentText>Chaque champs est obligatoire.</DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={
                  formik.errors.recipient && formik.touched.recipient
                    ? true
                    : false
                }
              >
                <Autocomplete
                  options={recipients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.recipient.id === value.recipient.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.recipient) return "";
                    return option.recipient.id + " - " + option.recipient.name;
                  }}
                  value={selectedRecipient}
                  onChange={(event, value) => {
                    setSelectedRecipient(value);

                    value !== null
                      ? formik.setFieldValue("recipient", value.recipient.id)
                      : formik.setFieldValue("recipient", null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Convive"
                      error={
                        formik.errors.recipient && formik.touched.recipient
                          ? true
                          : false
                      }
                    />
                  )}
                />
                <FormHelperText error>
                  {formik.errors.recipient &&
                    formik.touched.recipient &&
                    formik.errors.recipient}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth required>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de début"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.startDate}
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue("startDate", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        error={
                          formik.errors.startDate && formik.touched.startDate
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </LocalizationProvider>

                <FormHelperText error>
                  {formik.errors.startDate &&
                    formik.touched.startDate &&
                    formik.errors.startDate}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth required>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de fin"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.endDate}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("endDate", value)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        error={
                          formik.errors.endDate && formik.touched.endDate
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </LocalizationProvider>

                <FormHelperText error>
                  {formik.errors.endDate &&
                    formik.touched.endDate &&
                    formik.errors.endDate}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <LoadingButton onClick={handleClose}>Annuler</LoadingButton>
            <LoadingButton
              fullWidth
              color="secondary"
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Valider
            </LoadingButton>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};

const List = (props) => {
  const classes = UseStyle();
  const [openAbsence, setOpenAbsence] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [tab, setTab] = React.useState("list");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const handleOpenAbsence = () => {
    setOpenAbsence(true);
  };
  const handleCloseDialogs = () => {
    setOpenAbsence(false);
    setIsExporting(false);
    setIsImporting(false);
  };
  const recipients = props.recipientsList.map((recipient) => {
    const filtre = recipient.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      recipient: recipient,
    };
  });

  return (
    <Stack spacing={2} marginBottom={2}>
      <Grid container>
        <Grid item flex={1} container spacing={1}>
          <Grid item>
            <LoadingButton
              loading={props.loading}
              color="secondary"
              variant="contained"
              onClick={handleOpenAbsence}
              startIcon={<AddIcon />}
            >
              Absence
            </LoadingButton>
          </Grid>

          {(isAdmin(props.userData) || isProdAdmin(props.userData)) && (
            <Grid item>
              <LoadingButton
                loading={props.loading}
                color="secondary"
                variant="contained"
                onClick={props.toggleUpdateRecipients}
                startIcon={<ChangeCircleOutlinedIcon />}
              >
                Rang & Tournée
              </LoadingButton>
            </Grid>
          )}
        </Grid>

        <Grid item flex={1} container justifyContent="flex-end" spacing={1}>
          {(isAdmin(props.userData) ||
            isSalesAdmin(props.userData) ||
            isOwner(props.userData)) && (
            <>
              <Grid item>
                <LoadingButton
                  disabled={
                    isOwner(props.userData) &&
                    props.userData.organization.mode === "diets"
                  }
                  loading={props.loading}
                  onClick={() => setIsImporting(true)}
                >
                  Import
                </LoadingButton>
              </Grid>

              <Grid item>
                <LoadingButton
                  loading={props.loading}
                  onClick={() => setIsExporting(true)}
                >
                  Export
                </LoadingButton>
              </Grid>
            </>
          )}

          {isAdmin(props.userData) || isSalesAdmin(props.userData) ? (
            <>
              <Grid item>
                <LoadingButton
                  loading={props.loading}
                  color="secondary"
                  variant="contained"
                  onClick={props.toggleAdd}
                  startIcon={<AddIcon />}
                >
                  Convive
                </LoadingButton>
              </Grid>

              <Grid item>
                <LoadingButton
                  loading={props.loading}
                  color="secondary"
                  variant="contained"
                  onClick={props.toggleMultipleAdd}
                  startIcon={<AddIcon />}
                >
                  Convives par régimes
                </LoadingButton>
              </Grid>
            </>
          ) : props.userData.organization.mode === "diets" ? (
            <Grid item>
              <LoadingButton
                loading={props.loading}
                color="secondary"
                variant="contained"
                onClick={props.toggleMultipleAdd}
                startIcon={<AddIcon />}
              >
                Convives par régimes
              </LoadingButton>
            </Grid>
          ) : (
            <Grid item>
              <LoadingButton
                loading={props.loading}
                color="secondary"
                variant="contained"
                onClick={props.toggleAdd}
                startIcon={<AddIcon />}
              >
                Convive
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Stack spacing={2}>
        <Filters
          userData={props.userData}
          types={["client", "recipient", "state", "round"]}
          from="recipients"
          recipients={props.recipientsList}
          clients={props.clients}
          rounds={props.rounds}
          handleSubmitFilters={props.handleSubmitFilters}
          loading={props.loading}
        />

        <Paper className={classes.paper} elevation={6}>
          <TabContext value={tab}>
            <TabList onChange={handleChange} variant="fullWidth" centered>
              <Tab value="list" label="Par convive" />
              <Tab value="macro" label="Par régime" />
            </TabList>
            <TabPanel value="list">
              <RecipientsListDataGrid {...props} />
            </TabPanel>
            <TabPanel value="macro">
              <RecipientsListMacro {...props} />
            </TabPanel>
          </TabContext>
        </Paper>
      </Stack>

      <ExportDialog
        {...props}
        isExporting={isExporting}
        handleClose={handleCloseDialogs}
      />

      <ImportDialog
        {...props}
        isImporting={isImporting}
        handleClose={handleCloseDialogs}
      />

      <AbsenceDialog
        openAbsence={openAbsence}
        handleClose={handleCloseDialogs}
        loading={props.loading}
        handleSubmitAbsence={props.handleSubmitAbsence}
        recipients={recipients}
      />
    </Stack>
  );
};

export default List;
