import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

export const getDatesOfWeek = (date) => {
  return Array.from(Array(7).keys()).map((idx) => {
    const d = new Date(date.getTime());
    d.setDate(d.getDate() - d.getDay() + idx + 1);
    return { [format(d, "yyyy-MM-dd")]: null }; //TODO : Pourquoi renvoyer un objet avec une valeur null ?
  });
};

const WeekView = (props) => {
  return (
    <Stack flexDirection="row">
      {getDatesOfWeek(props.date).map((day, i) => {
        const menu = props.menus.find(
          (menu) =>
            format(new Date(menu.date), "yyyy-MM-dd") === Object.keys(day)[0]
        );

        const date = new Date(Object.keys(day)[0]);
        const dayName = date.toLocaleString("fr-FR", { weekday: "short" });
        const dayNumber = format(date, "dd");

        const lunchProductsByCategories = [];
        const dinnerProductsByCategories = [];
        if (menu) {
          menu.lunchProducts.forEach((product) => {
            const category = product.categorie.nom;
            const index = lunchProductsByCategories.findIndex(
              (pbc) => pbc[0] === category
            );
            if (index === -1) {
              lunchProductsByCategories.push([category, [product]]);
            } else {
              lunchProductsByCategories[index][1].push(product);
            }
          });

          menu.lunchExtras.forEach((extra) => {
            const category = extra.category.name;
            const index = lunchProductsByCategories.findIndex(
              (pbc) => pbc[0] === category
            );
            if (index === -1) {
              lunchProductsByCategories.push([category, [extra]]);
            } else {
              lunchProductsByCategories[index][1].push(extra);
            }
          });

          menu.dinnerProducts.forEach((product) => {
            const category = product.categorie.nom;
            const index = dinnerProductsByCategories.findIndex(
              (pbc) => pbc[0] === category
            );
            if (index === -1) {
              dinnerProductsByCategories.push([category, [product]]);
            } else {
              dinnerProductsByCategories[index][1].push(product);
            }
          });

          menu.dinnerExtras.forEach((extra) => {
            const category = extra.category.name;
            const index = dinnerProductsByCategories.findIndex(
              (pbc) => pbc[0] === category
            );
            if (index === -1) {
              dinnerProductsByCategories.push([category, [extra]]);
            } else {
              dinnerProductsByCategories[index][1].push(extra);
            }
          });
        }

        return (
          <Stack key={i} flex={1} height="80vh">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderBottom: 1,
                borderBottomColor: "divider",
              }}
            >
              <Typography variant="body2" marginLeft={2}>
                {dayName} {dayNumber}
              </Typography>
            </Box>

            {menu ? (
              <Stack
                padding={2}
                spacing={2}
                height="100%"
                sx={{
                  borderLeft: 1,
                  borderRight: i === 6 ? 1 : 0,
                  borderColor: "divider",
                }}
              >
                <Stack
                  padding={2}
                  spacing={2}
                  sx={{
                    height: "100%",
                    overflow: "auto",
                    background: "rgba(238, 175, 97, 0.4)",
                    borderRadius: "10px",
                  }}
                >
                  {lunchProductsByCategories.map((pbc, i) => (
                    <Stack key={i} padding={1}>
                      <Typography variant="body2" textAlign="left">
                        <i>{pbc[0]}</i>
                      </Typography>

                      <Stack justifyContent="space-between">
                        {pbc[1].map((item, i) => (
                          <Stack key={i} direction="row" alignItems="center">
                            <Typography variant="body2" textAlign="left">
                              • {item ? item.nom : item.nom}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>

                {dinnerProductsByCategories.length > 0 && (
                  <Stack
                    padding={2}
                    spacing={2}
                    sx={{
                      height: "100%",
                      overflow: "auto",
                      background: "rgba(106, 13, 131, 0.4)",
                      borderRadius: "10px",
                    }}
                  >
                    {dinnerProductsByCategories.map((pbc, i) => (
                      <Stack key={i} padding={1}>
                        <Typography variant="body2" textAlign="left">
                          <i>{pbc[0]}</i>
                        </Typography>

                        <Stack justifyContent="space-between">
                          {pbc[1].map((item, i) => (
                            <Stack key={i} direction="row" alignItems="center">
                              <Typography variant="body2" textAlign="left">
                                • {item ? item.nom : item.nom}
                              </Typography>
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                )}
              </Stack>
            ) : (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                height="70vh"
                sx={{
                  borderLeft: 1,
                  borderRight: i === 6 ? 1 : 0,
                  borderColor: "divider",
                }}
              >
                <Tooltip title="Aucun menu">
                  <CancelIcon fontSize="medium" color="disabled" />
                </Tooltip>
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default WeekView;
