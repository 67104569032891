import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { format, set } from "date-fns";
import _superagent from "superagent";
import superagentPromise from "superagent-promise";
import { requests } from "../../../agent";
import Add from "./Add/Add";
import Edit from "./Edit/EditUnit";
import List from "./List/List";
import MultipleAdd from "./Add/MultipleAdd";
import { RECIPIENT_STATE } from "../Common/Enums/Enums";
import { isAdmin, isSalesAdmin } from "../../../utils";
import EditGroup from "./Edit/EditGroup";
import { ENTRYPOINT } from "../../../config/entrypoint";
import RecipientsUpdateList from "./RecipientsUpdateList";

const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = (response) => response.body;
let token = null;
const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) request.set("Authorization", `Bearer ${token}`);
  };
};

const RecipientContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [add, setAdd] = useState(false);
  const [editUnit, setEditUnit] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [multipleAdd, setMultipleAdd] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [recipientsList, setRecipientsList] = useState([]);
  const [recipientEdit, setRecipientEdit] = useState(null);
  const [recipientToDupplicate, setRecipientToDupplicate] = useState(null);
  const [formules, setFormules] = useState([]);
  const [categories, setCategories] = useState([]);
  const [diets, setDiets] = useState([]);
  const [allergens, setAllergens] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });
  const [recipientsMacro, setRecipientsMacro] = useState([]);
  const [updateRecipients, setUpdateRecipients] = useState(false);

  useEffect(() => {
    setLoading(true);
    let arrayRequests = [
      requests.get("/recipients", true),
      requests.get("/regimes", true),
      requests.get("/allergenes", true),
      requests.get("/origins", true),
      requests.get("/organizations/lists", true),
      requests.get("/extra_categories", true),
      requests.get("/drivers", true),
    ];
    let adminRequests = null;

    if (isAdmin(userData) || isSalesAdmin(userData)) {
      adminRequests = [
        requests.get("/formules", true),
        requests.get("/rounds", true),
      ];
    }
    const allRequests = arrayRequests.concat(adminRequests);
    const promises = Promise.all(allRequests);

    promises
      .then((results) => {
        setRecipients(results[0]["hydra:member"]);
        setRecipientsList(results[0]["hydra:member"]);
        setDiets(results[1]["hydra:member"]);
        setAllergens(results[2]["hydra:member"]);
        setOrigins(results[3]["hydra:member"]);
        setClients(results[4]["hydra:member"]);
        setCategories(results[5]["hydra:member"]);
        setDrivers(results[6]["hydra:member"]);

        if (isAdmin(userData) || isSalesAdmin(userData)) {
          setFormules(results[7]["hydra:member"]);
          setRounds(results[8]["hydra:member"]);
        } else {
          setFormules(userData.organization.packages);
          setRounds(userData.organization.rounds);
        }
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      const data = [
        [
          { lunch: 0, dinner: 0 },
          { lunch: 0, dinner: 0 },
          { lunch: 0, dinner: 0 },
          { lunch: 0, dinner: 0 },
          { lunch: 0, dinner: 0 },
          { lunch: 0, dinner: 0 },
          { lunch: 0, dinner: 0 },
          { total: 0 },
        ],
      ];
      diets.forEach(
        (diet) =>
          (data[diet.id] = [
            { lunch: 0, dinner: 0 },
            { lunch: 0, dinner: 0 },
            { lunch: 0, dinner: 0 },
            { lunch: 0, dinner: 0 },
            { lunch: 0, dinner: 0 },
            { lunch: 0, dinner: 0 },
            { lunch: 0, dinner: 0 },
            { total: 0 },
          ])
      );

      recipients.forEach((recipient) => {
        let recipientQty = 0;

        if (recipient.diets.length === 0) {
          let day;
          recipientQty = 1;

          recipient.mealDays.forEach((mealDay) => {
            if (recipient["@type"] === "RecipientGroup") {
              day = mealDay.day;
              recipientQty = mealDay.qty;
            } else {
              day = mealDay;
            }

            data[0][day].lunch = data[0][day].lunch + recipientQty;

            if (recipient.dinnerMenu) {
              data[0][day].dinner = data[0][day].dinner + recipientQty;
            }
          });

          data[0][7].total += recipientQty;
        } else {
          recipient.diets.forEach((diet) => {
            const dietId = diet.id;
            recipientQty = 1;

            recipient.mealDays.forEach((mealDay) => {
              let day;
              if (recipient["@type"] === "RecipientGroup") {
                day = mealDay.day;
                recipientQty = mealDay.qty;
              } else {
                day = mealDay;
              }

              data[dietId][day].lunch = data[dietId][day].lunch + recipientQty;

              if (recipient.dinnerMenu) {
                data[dietId][day].dinner =
                  data[dietId][day].dinner + recipientQty;
              }

              data[dietId][7].total += recipientQty;
            });
          });
        }
      });

      setRecipientsMacro(data);
    }
  }, [loading]);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleMultipleAdd = () => {
    setMultipleAdd(true);
  };
  const toggleEdit = (recipientId) => {
    setLoading(true);
    requests
      .get(`/recipients/${recipientId}`, true)
      .then((result) => {
        setRecipientEdit(result);

        if (result["@type"] === "RecipientUnit") {
          setEditUnit(!editUnit);
        } else {
          setEditGroup(!editGroup);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const toggleList = () => {
    setAdd(false);
    setMultipleAdd(false);
    setEditUnit(false);
    setEditGroup(false);
    setRecipientEdit(null);
    setRecipientToDupplicate(null);
    setUpdateRecipients(false);
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const handleSubmitFilters = (data, from) => {
    setLoading(true);
    let dataUrl = "";
    if (data.client && data.client !== "")
      dataUrl += `organization.id=${data.client}&`;
    if (data.id !== "" || data.recipient !== null)
      dataUrl += `id=${data.id || data.recipient}&`;
    if (data.state !== "") dataUrl += `state=${data.state}&`;
    if (data.round !== "") dataUrl += `round.id=${data.round}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setRecipients(result["hydra:member"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmit = (recipient, from) => {
    setLoading(true);
    requests
      .post(`/recipient_${from}`, recipient, true)
      .then((response) => {
        const newListRecipients = [...recipients];
        newListRecipients.unshift(response);
        setLoading(false);
        setRecipients(newListRecipients);
        setAdd(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le convive a bien été créé !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitMultiple = (clientId, newRecipients) => {
    const data = {
      recipients: newRecipients,
    };

    setLoading(true);
    requests
      .put(`/organizations/${clientId}`, data, true)
      .then((response) => {
        const recipientsWithoutUser = recipients.filter(
          (recipient) => recipient.organization.id !== clientId
        );
        const newListRecipients = [
          ...response.recipients,
          ...recipientsWithoutUser,
        ];
        setLoading(false);
        setRecipients(newListRecipients);
        setMultipleAdd(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les convives ont été créés.",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitMacro = (clientId, data) => {
    setLoading(true);
    requests
      .patch(`/organizations/${clientId}/recipients/batch`, data, true)
      .then((response) => {
        const recipientsId = recipients.map((r) => r.id);

        let newRecipients = response.recipients
          .map((recipient) => {
            if (!recipientsId.includes(recipient.id)) {
              const newRecipient = {
                ...recipient,
                organization: {
                  name: response.name,
                },
              };
              return newRecipient;
            }
          })
          .filter((e) => e !== undefined);

        const updatedRecipients = newRecipients.concat(recipients);

        setLoading(false);
        setRecipients(updatedRecipients);
        setMultipleAdd(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les convives ont été créés.",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitEdit = (recipient) => {
    setLoading(true);
    requests
      .patch(`/recipients/${recipient.id}`, recipient, true)
      .then((response) => {
        const newListRecipients = recipients.map((recipient) => {
          if (recipient.id !== response.id) {
            return recipient;
          }

          return response;
        });
        setLoading(false);
        setRecipients(newListRecipients);
        toggleList();
        setAlert({
          active: true,
          type: "success",
          message: "Le convive a bien été modifié !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDelete = (recipientId) => {
    setLoading(true);
    requests
      .del(`/recipients/${recipientId}`, true)
      .then((response) => {
        setLoading(false);
        setRecipients(
          recipients.filter((recipient) => recipientId !== recipient.id)
        );
        setAlert({
          active: true,
          type: "success",
          message: "Le convive a bien été supprimé",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitStateFromList = (recipient, newState) => {
    setLoading(true);
    let data = {
      state: newState,
    };

    if (newState === RECIPIENT_STATE.CANCELLED.status) {
      data = {
        ...data,
        cancelledAt: format(new Date(), "yyyy-MM-dd"),
      };
    }

    requests
      .patch(`/recipients/${recipient.id}`, data, true)
      .then((response) => {
        setLoading(false);
        setRecipients((prevRecipients) =>
          prevRecipients.map((r) => (r.id === response.id ? response : r))
        );
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le statut sur la prise de commande à bien été mis à jour !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitPositionFromList = (recipient, newPosition) => {
    setLoading(true);
    let data = {
      position: newPosition,
    };

    requests
      .patch(`/recipients/${recipient.id}`, data, true)
      .then((response) => {
        setLoading(false);
        setRecipients((prevRecipients) =>
          prevRecipients.map((r) => (r.id === response.id ? response : r))
        );
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le statut du convive à bien été mis à jour !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitAbsence = (recipientId, startDate, endDate) => {
    const data = {
      recipient: `/recipients/${recipientId}`,
      beginAt: startDate, // TODO: Ne pas utiliser de décalage
      endAt: endDate, // TODO: Ne pas utiliser de décalage
    };

    setLoading(true);
    requests
      .post("/absences", data, true)
      .then((response) => {
        const updatedRecipientsList = recipients.map((r) => {
          if (r.id === response.recipient.id) {
            const updatedRecipient = {
              ...r,
              state: response.recipient.state,
            };

            return updatedRecipient;
          }

          return r;
        });
        setRecipients(updatedRecipientsList);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "L'absence à bien été enregistrée !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const sendDataExport = (start, end, clientId) => {
    setLoading(true);
    let dataUrl = `organization.id=${clientId}&`;
    if (start !== null) dataUrl += `startedAt[before]=${end}&`;
    if (end !== null) dataUrl += `cancelledAt[after]=${start}&`;
    if (dataUrl.length > 0) dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    superagent
      .get(`${ENTRYPOINT}/recipients/export?${dataUrl}`)
      .use(tokenPlugin(true))
      .responseType("blob")
      .then((results) => {
        const fileUrl = URL.createObjectURL(results.body);
        const filename = results.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .replaceAll('"', '')
          .trim()
        ;
        console.log(filename)

        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(fileUrl);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les données ont été exportées !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const dupplicateRecipient = (recipient) => {
    setRecipientToDupplicate(recipient);
    recipient["@type"] === "RecipientUnit"
      ? setAdd(true)
      : setMultipleAdd(true);
  };
  const downloadImportTemplate = (clientId) => {
    setLoading(true);
    superagent
      .get(`${ENTRYPOINT}/recipients/import/template?client=${clientId}`)
      .use(tokenPlugin(true))
      .responseType("blob")
      .then(responseBody)
      .then((results) => {
        const fileUrl = URL.createObjectURL(results);

        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = "template_import_convives.xlsx";

        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(fileUrl);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le fichier a bien été téléchargé !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleImport = (file, clientId) => {
    setLoading(true);

    superagent
      .post(`${ENTRYPOINT}/recipient_units/import`)
      .use(tokenPlugin(true))
      .attach("file", file)
      .field("id", clientId)
      .then((response) => {
        setRecipients((prevRecipients) => [
          ...response.body["hydra:member"],
          ...prevRecipients,
        ]);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le fichier a bien été importé !",
        });
      })
      .catch((error) => {
        if (error instanceof Error) {
          // Assurez-vous que l'erreur est une instance de Error
          const errorData = JSON.parse(error.message); // Analyse la chaîne JSON dans l'erreur
          if (errorData && errorData["hydra:description"]) {
            const hydraDescription = errorData["hydra:description"];
            console.error("hydra:description :", hydraDescription);
            setAlert({
              active: true,
              type: "error",
              message: hydraDescription,
            });
          } else {
            console.error("L'objet d'erreur ne contient pas hydra:description.");
          }
        } else {
          console.error("Une erreur non gérée s'est produite :", error);
        }
        setLoading(false);
      });
  };
  const toggleUpdateRecipients = () => {
    setUpdateRecipients(true);
  };
  const fetchRecipients = (clientId, params, callbackFetchRecipients) => {
    setLoading(true);

    let dataUrl = "";
    if (params) {
      if (params.recipient !== null) dataUrl += `id=${params.recipient}&`;
      if (params.state !== "") dataUrl += `state=${params.state}&`;
      if (params.round !== "") dataUrl += `round.id=${params.round}&`;
      dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop
    }

    requests
      .get(
        `/recipients/ranks?organization.id=${clientId}${
          params ? "&" : ""
        }${dataUrl}`,
        true
      )
      .then((response) => {
        callbackFetchRecipients(response["hydra:member"]);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleUpdateFromList = async (recipientId, data) => {
    setLoading(true);

    const response = await requests
      .patch(`/recipients/${recipientId}`, data, true)
      .then((response) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le statut du convive à bien été mis à jour !",
        });
        return response;
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });

    return response;
  };

  return (
    <>
      {add && (
        <Add
          userData={userData}
          loading={loading}
          add={add}
          multipleAdd={multipleAdd}
          recipients={recipients}
          recipientToDupplicate={recipientToDupplicate}
          formules={formules}
          categories={categories}
          diets={diets}
          allergens={allergens}
          origins={origins}
          rounds={rounds}
          clients={clients}
          alert={alert}
          toggleList={toggleList}
          validation={handleSubmit}
        />
      )}

      {multipleAdd && (
        <MultipleAdd
          userData={userData}
          loading={loading}
          multipleAdd={multipleAdd}
          recipients={recipients}
          recipientToDupplicate={recipientToDupplicate}
          formules={formules}
          categories={categories}
          diets={diets}
          allergens={allergens}
          origins={origins}
          rounds={rounds}
          clients={clients}
          alert={alert}
          toggleList={toggleList}
          validation={handleSubmitMultiple}
          validationMacro={handleSubmitMacro}
        />
      )}

      {editUnit && (
        <Edit
          userData={userData}
          edit={editUnit}
          recipientEdit={recipientEdit}
          formules={formules}
          categories={categories}
          diets={diets}
          allergens={allergens}
          origins={origins}
          rounds={rounds}
          clients={clients}
          loading={loading}
          alert={alert}
          toggleList={toggleList}
          validation={handleSubmitEdit}
        />
      )}

      {editGroup && (
        <EditGroup
          userData={userData}
          edit={editGroup}
          recipientEdit={recipientEdit}
          formules={formules}
          diets={diets}
          rounds={rounds}
          loading={loading}
          alert={alert}
          toggleList={toggleList}
          validation={handleSubmitEdit}
        />
      )}

      {!editUnit && !editGroup && !add && !multipleAdd && !updateRecipients && (
        <List
          userData={userData}
          edit={editUnit}
          add={add}
          recipients={recipients}
          recipientsList={recipientsList}
          recipientsMacro={recipientsMacro}
          clients={clients}
          formules={formules}
          rounds={rounds}
          diets={diets}
          allergens={allergens}
          origins={origins}
          loading={loading}
          alert={alert}
          toggleEdit={toggleEdit}
          toggleAdd={toggleAdd}
          toggleMultipleAdd={toggleMultipleAdd}
          handleSubmitFilters={handleSubmitFilters}
          handleDelete={handleDelete}
          handleSubmitStateFromList={handleSubmitStateFromList}
          handleSubmitPositionFromList={handleSubmitPositionFromList}
          handleSubmitAbsence={handleSubmitAbsence}
          sendDataExport={sendDataExport}
          dupplicateRecipient={dupplicateRecipient}
          downloadImportTemplate={downloadImportTemplate}
          handleImport={handleImport}
          toggleUpdateRecipients={toggleUpdateRecipients}
        />
      )}

      {updateRecipients && (
        <RecipientsUpdateList
          loading={loading}
          clients={clients}
          rounds={rounds}
          drivers={drivers}
          toggleList={toggleList}
          fetchRecipients={fetchRecipients}
          handleUpdateFromList={handleUpdateFromList}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default RecipientContainer;
