import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { requests } from "../../../../agent";

const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(null);
  const formik = useFormik({
    initialValues: {
      company: "",
      email: "",
      phone: "",
      quantity: "",
      comment: "",
    },
    validationSchema: Yup.object({
      company: Yup.string().required("Le nom de votre société est requis."),
      email: Yup.string().required("Un numéro de téléphone est requis."),
      phone: Yup.string()
        .required("Un numéro de téléphone est requis.")
        .matches(
          "[0]{1}[1-7]{1}[0-9]{8}",
          "Veuillez saisir un numéro de téléphone valide."
        ),
      quantity: Yup.string().required(
        "Veuillez choisir une fourchette de convives."
      ),
      comment: Yup.string()
        .min(20, "Veuillez fournir au moins 20 caractères.")
        .required(
          "Dîtes-nous en plus sur votre demande afin de pouvoir adapter notre retour à vos besoins !"
        ),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      const data = {
        company: values.company,
        emailPro: values.email,
        phone: values.phone,
        quantity: values.quantity,
        comment: values.comment,
      };
      requests
        .post("/users/contact/pro", data, true)
        .then((response) => {
          setIsLoading(false);
          setIsSubmit(true);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsSubmit(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} paddingTop={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Nom de société"
            size="small"
            value={formik.values.company}
            name="company"
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Email"
            size="small"
            value={formik.values.email}
            name="email"
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            label="Téléphone"
            size="small"
            value={formik.values.phone}
            name="phone"
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth required size="small">
            <InputLabel>Nombre de convives</InputLabel>
            <Select
              name={"quantity"}
              label="Nombre de convives"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
            >
              <MenuItem value="1-10">1-10</MenuItem>
              <MenuItem value="10-50">10-50</MenuItem>
              <MenuItem value="50-100">50-100</MenuItem>
              <MenuItem value="100-300">100-300</MenuItem>
              <MenuItem value="300+">300+</MenuItem>
            </Select>
            <FormHelperText error>
              {formik.touched.quantity && formik.errors.quantity}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Commentaires"
            size="small"
            multiline
            rows={4}
            value={formik.values.comment}
            name="comment"
            onChange={formik.handleChange}
            error={formik.touched.comment && Boolean(formik.errors.comment)}
            helperText={formik.touched.comment && formik.errors.comment}
          />
        </Grid>

        <Grid item>
          <ReCAPTCHA
            sitekey="6Lemk8ckAAAAAPdLsajjt9sOWs3p8UvnrxXnM5fI"
            onChange={(token) => setIsCaptchaValid(token)}
          />
        </Grid>

        <Grid item>
          <LoadingButton
            {...(!isCaptchaValid && { disabled: true })}
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Obtenir un devis
          </LoadingButton>
        </Grid>

        {isSubmit && (
          <Grid item>
            <Alert severity="success">
              Nous avons bien reçu votre demande, vous serez prochainement
              contacté par téléphone.
            </Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default Form;
