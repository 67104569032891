import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  ListItemIcon,
  ListItemText,
  Collapse,
  Menu,
  MenuItem,
  Alert,
  Snackbar,
  ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import RestaurantMenuOutlinedIcon from "@mui/icons-material/RestaurantMenuOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { isAdmin, isFinanceAdmin, isFinanceUser, isOwner } from "../../utils";
import { hide, show } from "../../slices/alertSlice";
import { logout } from "../../slices/authSlice";

function Copyright(props) {
  return null;
}
const drawerWidth = 260;
const useStyles = makeStyles((theme) => {
  return {
    active: {
      background: "#f8cac3",
      "&:hover": {
        background: "#ed725e",
      },
    },
  };
});
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    zIndex: theme.zIndex.appBar + 1,
    overflowX: "hidden",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    maxHeight: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      zIndex: theme.zIndex.appBar,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    }),
  },
}));

const Layout = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [submenuCustoms, setSubmenuCustoms] = useState(false);
  const [submenuInvoice, setSubmenuInvoice] = useState(false);
  const [submenuProductSettings, setSubmenuProductSettings] = useState(false);
  const open = Boolean(anchorEl);
  const openSettings = Boolean(anchorElSettings);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector((state) => state.auth.userData);
  const alert = {
    isActive: useSelector((state) => state.alert.active),
    message: useSelector((state) => state.alert.message),
    type: useSelector((state) => state.alert.type),
  };

  const classes = useStyles();
  const toggleDrawer = (event) => {
    anchorEl !== event.currentTarget && setAnchorEl(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
    setSubmenuCustoms(false);
    setSubmenuInvoice(false);
    setSubmenuProductSettings(false);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const handleClickCustoms = () => {
    setSubmenuCustoms(!submenuCustoms);
  };
  const handleClickInvoice = () => {
    setSubmenuInvoice(!submenuInvoice);
  };
  const handleClickProductSettings = () => {
    setSubmenuProductSettings(!submenuProductSettings);
  };
  const capitalizeWords = (date) => {
    const dateToString = date.toString();
    const dateModified = dateToString.replace(
      /(^\w{1})|(\s+\w{1})/g,
      (letter) => letter.toUpperCase()
    );

    return dateModified;
  };
  const menuItems = [
    {
      path: "/admin/dashboard",
      key: "ADMIN_INDEX",
      icon: <DashboardOutlinedIcon color={"primary"} />,
      text: "Dashboard",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_FINANCE_ADMIN",
        "ROLE_COMMERCIAL_ADMIN",
        "ROLE_PRODUCTION_ADMIN",
        "ROLE_OWNER",
        "ROLE_FINANCE",
        "ROLE_COMMERCIAL",
        "ROLE_PRODUCTION",
      ],
    },
    {
      path: "/admin/clients",
      key: "ADMIN_ORGANIZATIONS_INDEX",
      icon: <PeopleOutlinedIcon color={"primary"} />,
      text: "Clients",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    },
    {
      path: "/admin/convives",
      key: "ADMIN_RECIPIENTS_INDEX",
      icon: <GroupsOutlinedIcon color={"primary"} />,
      text: "Convives",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_OWNER",
        "ROLE_COMMERCIAL_ADMIN",
        "ROLE_COMMERCIAL",
      ],
    },
    {
      path: "/admin/absences",
      key: "ADMIN_ABSENCES_INDEX",
      icon: <PersonOffOutlinedIcon color={"primary"} />,
      text: "Absences",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_COMMERCIAL_ADMIN",
        "ROLE_OWNER",
        "ROLE_COMMERCIAL",
      ],
    },
    {
      path: "/admin/commandes",
      key: "ADMIN_ORDERS_INDEX",
      icon: <ShoppingBagOutlinedIcon color={"primary"} />,
      text: "Commandes",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_USER",
        "ROLE_FINANCE_ADMIN",
        "ROLE_COMMERCIAL_ADMIN",
        "ROLE_PRODUCTION_ADMIN",
        "ROLE_OWNER",
        "ROLE_FINANCE",
        "ROLE_COMMERCIAL",
        "ROLE_PRODUCTION",
      ],
    },
    {
      path: "/admin/livraisons",
      key: "ADMIN_DELIVERIES_INDEX",
      icon: <LocalShippingOutlinedIcon color={"primary"} />,
      text: "Livraisons",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_FINANCE_ADMIN",
        "ROLE_COMMERCIAL_ADMIN",
        "ROLE_PRODUCTION_ADMIN",
        "ROLE_OWNER",
        "ROLE_FINANCE",
        "ROLE_COMMERCIAL",
        "ROLE_PRODUCTION",
      ],
    },
    {
      path: "/admin/menus",
      key: "ADMIN_MENUS_INDEX",
      icon: <RestaurantMenuOutlinedIcon color={"primary"} />,
      text: "Menus",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_COMMERCIAL_ADMIN",
        "ROLE_COMMERCIAL",
        "ROLE_OWNER",
        "ROLE_PRODUCTION",
      ],
    },
    {
      path: "/admin/produits",
      key: "ADMIN_PRODUCTS_INDEX",
      icon: <ShoppingCartOutlinedIcon color={"primary"} />,
      text: "Produits",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    },
    {
      path: "/admin/supplements",
      key: "ADMIN_EXTRAS_INDEX",
      icon: <AddShoppingCartOutlinedIcon color={"primary"} />,
      text: "Suppléments",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    },
    {
      path: "/admin/formules",
      key: "ADMIN_FORMULES_INDEX",
      icon: <MenuBookOutlinedIcon color={"primary"} />,
      text: "Formules",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTION_ADMIN"],
    },
    {
      path: "/admin/tournees",
      key: "ADMIN_ROUNDS_INDEX",
      icon: <TourOutlinedIcon color={"primary"} />,
      text: "Tournées",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_PRODUCTION_ADMIN",
        "ROLE_OWNER",
        "ROLE_PRODUCTION",
      ],
    },
    {
      path: "/admin/creanciers",
      key: "ADMIN_CREDITORS_INDEX",
      icon: <AccountBalanceOutlinedIcon color={"primary"} />,
      text: "Créanciers",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_FINANCE_ADMIN",
        "ROLE_OWNER",
        "ROLE_FINANCE",
      ],
    },
    {
      path: "/admin/services",
      key: "ADMIN_SERVICES_INDEX",
      icon: <HomeRepairServiceOutlinedIcon color={"primary"} />,
      text: isAdmin(userData) ? "Services" : "Mes services",
      isGranted: [
        "ROLE_SUPER_ADMIN",
        "ROLE_ADMIN",
        "ROLE_OWNER",
        "ROLE_PRODUCTION",
        "ROLE_FINANCE",
        "ROLE_COMMERCIAL",
      ],
    },
    {
      path: "/admin/utilisateurs",
      key: "ADMIN_USERS_INDEX",
      icon: <BadgeOutlinedIcon color={"primary"} />,
      text: isAdmin(userData) ? "Utilisateurs" : "Mes utilisateurs",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_OWNER"],
    },
    {
      path: "/admin/souscriptions",
      key: "ADMIN_SUBSCRIPTIONS_INDEX",
      icon: <CardMembershipOutlinedIcon color={"primary"} />,
      text: "Souscriptions",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    },
    {
      path: "/admin/offres",
      key: "ADMIN_PLANS_INDEX",
      icon: <MapOutlinedIcon color={"primary"} />,
      text: "Offres",
      isGranted: ["ROLE_SUPER_ADMIN"],
    },
    {
      path: "/admin/extensions",
      key: "ADMIN_ADDONS_INDEX",
      icon: <ExtensionOutlinedIcon color={"primary"} />,
      text: "Extensions",
      isGranted: ["ROLE_SUPER_ADMIN"],
    },
  ];
  const menuItemsSettings = [
    {
      path: "/admin/regimes",
      key: "ADMIN_DIETS_INDEX",
      icon: <CategoryOutlinedIcon color={"primary"} />,
      text: "Régimes",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTION_ADMIN"],
    },
    {
      path: "/admin/allergenes",
      key: "ADMIN_ALLERGENS_INDEX",
      icon: <CategoryOutlinedIcon color={"primary"} />,
      text: "Allergènes",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTION_ADMIN"],
    },
    {
      path: "/admin/categories/produit",
      key: "ADMIN_PRODUCTS_CATEGORIES_INDEX",
      icon: <CategoryOutlinedIcon color={"primary"} />,
      text: "Cat. de produit",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTION_ADMIN"],
    },
    {
      path: "/admin/categories/supplement",
      key: "ADMIN_EXTRAS_CATEGORIES_INDEX",
      icon: <CategoryOutlinedIcon color={"primary"} />,
      text: "Cat. de supplément",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTION_ADMIN"],
    },
    {
      path: "/admin/taxes",
      key: "ADMIN_TAXES_INDEX",
      icon: <PriceChangeOutlinedIcon color={"primary"} />,
      text: "Taxes",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTION_ADMIN"],
    },
    {
      path: "/admin/drivers",
      key: "ADMIN_DRIVERS_INDEX",
      icon: <PersonOutlineOutlinedIcon color={"primary"} />,
      text: "Drivers",
      isGranted: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTION_ADMIN"],
    },
  ];
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") return;
    dispatch(hide());
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar position="absolute" open={open}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{
                flexGrow: 1,
              }}
            >
              {capitalizeWords(
                format(new Date(), "EEEE d MMMM yyyy", { locale: fr })
              )}
              , utilisateur connecté : {userData && userData.firstName}
            </Typography>

            <IconButton
              color="inherit"
              id="account-button"
              aria-controls={openSettings ?? "account-menu"}
              aria-haspopup="true"
              aria-expanded={openSettings ?? "true"}
              onClick={handleClick}
            >
              <SettingsIcon />
            </IconButton>

            <Menu
              id="account-menu"
              anchorEl={anchorElSettings}
              open={openSettings}
              onClose={handleCloseSettings}
              menulistprops={{
                "aria-labelledby": "account-button",
              }}
            >
              <MenuItem onClick={() => navigate("/admin/profile")}>
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Profil</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  dispatch(logout({ hasSessionExpired: false }));
                  dispatch(
                    show({
                      active: true,
                      message: "Déconnexion réussie.",
                      type: "success",
                    })
                  );
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Déconnexion</ListItemText>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            flexShrink: 0,
          }}
          variant="permanent"
          open={open}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={() => open && setAnchorEl(null)}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Toolbar>

          <Divider />

          <List>
            {menuItems.map((item) => {
              if (
                Array.isArray(userData.roles) &&
                item.isGranted.some((value) => userData.roles.includes(value))
              )
                return (
                  <ListItemButton
                    key={item.key}
                    onMouseOver={toggleDrawer}
                    onMouseLeave={handleClose}
                    onClick={() => navigate(item.path)}
                    className={`${
                      location.pathname === item.path && classes.active
                    }`}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                );
            })}

            {(isAdmin(userData) || isOwner(userData)) && (
              <ListItemButton
                onMouseOver={toggleDrawer}
                onClick={handleClickCustoms}
              >
                <ListItemIcon>
                  <ColorLensOutlinedIcon color={"primary"} />
                </ListItemIcon>
                <ListItemText primary="Personnalisations" />
                {submenuCustoms ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}

            <Collapse in={submenuCustoms} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  onMouseOver={toggleDrawer}
                  sx={{ pl: 4 }}
                  onClick={() =>
                    navigate("/admin/personnalisations/bons_livraison")
                  }
                  className={`${
                    location.pathname ===
                      "/admin/personnalisations/bons_livraison" &&
                    classes.active
                  }`}
                >
                  <ListItemIcon>
                    <NoteOutlinedIcon color={"primary"} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      isAdmin(userData)
                        ? "Bons de livraisons"
                        : "Mes bons de livraisons"
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  disabled
                  onMouseOver={toggleDrawer}
                  sx={{ pl: 4 }}
                  onClick={() => navigate("/admin/personnalisations/factures")}
                  className={`${
                    location.pathname === "/admin/personnalisations/factures" &&
                    classes.active
                  }`}
                >
                  <ListItemIcon>
                    <DescriptionOutlinedIcon color={"primary"} />
                  </ListItemIcon>
                  <ListItemText
                    primary={isAdmin(userData) ? "Factures" : "Mes factures"}
                  />
                </ListItemButton>
              </List>
            </Collapse>

            {(isAdmin(userData) ||
              isFinanceAdmin(userData) ||
              isOwner(userData) ||
              isFinanceUser(userData)) && (
              <ListItemButton
                onMouseOver={toggleDrawer}
                onClick={handleClickInvoice}
              >
                <ListItemIcon>
                  <DescriptionOutlinedIcon color={"primary"} />
                </ListItemIcon>
                <ListItemText primary="Factures" />
                {submenuInvoice ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}

            <Collapse in={submenuInvoice} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {(isAdmin(userData) ||
                  isFinanceAdmin(userData) ||
                  isOwner(userData)) && (
                  <ListItemButton
                    onMouseOver={toggleDrawer}
                    sx={{ pl: 4 }}
                    onClick={() => navigate("/admin/factures/clients")}
                    className={`${
                      location.pathname === "/admin/factures/clients" &&
                      classes.active
                    }`}
                  >
                    <ListItemIcon>
                      <PeopleOutlinedIcon color={"primary"} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        isAdmin(userData) || isFinanceAdmin(userData)
                          ? "Clients"
                          : "Mes factures"
                      }
                    />
                  </ListItemButton>
                )}

                {userData.organization &&
                  userData.organization.mode === "repertory" && (
                    <ListItemButton
                      onMouseOver={toggleDrawer}
                      sx={{ pl: 4 }}
                      onClick={() => navigate("/admin/factures/convives")}
                      className={`${
                        location.pathname === "/admin/factures/convives" &&
                        classes.active
                      }`}
                    >
                      <ListItemIcon>
                        <GroupsOutlinedIcon color={"primary"} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          isAdmin(userData) ? "Convives" : "Mes convives"
                        }
                      />
                    </ListItemButton>
                  )}

                {(isAdmin(userData) || isFinanceAdmin(userData)) && (
                  <ListItemButton
                    onMouseOver={toggleDrawer}
                    sx={{ pl: 4 }}
                    onClick={() => navigate("/admin/avoirs")}
                    className={`${
                      location.pathname === "/admin/avoirs" && classes.active
                    }`}
                  >
                    <ListItemIcon>
                      <CardGiftcardIcon color={"primary"} />
                    </ListItemIcon>
                    <ListItemText primary="Avoirs" />
                  </ListItemButton>
                )}
              </List>
            </Collapse>

            {isAdmin(userData) && (
              <ListItemButton
                onMouseOver={toggleDrawer}
                onClick={handleClickProductSettings}
              >
                <ListItemIcon>
                  <SettingsOutlinedIcon color={"primary"} />
                </ListItemIcon>
                <ListItemText primary="Réglages" />
                {submenuProductSettings ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}

            <Collapse in={submenuProductSettings} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menuItemsSettings.map((item) => {
                  if (
                    Array.isArray(userData.roles) &&
                    item.isGranted.some((value) =>
                      userData.roles.includes(value)
                    )
                  )
                    return (
                      <ListItemButton
                        key={item.key}
                        onMouseOver={toggleDrawer}
                        sx={{ pl: 4 }}
                        onClick={() => navigate(item.path)}
                        className={`${
                          location.pathname === item.path && classes.active
                        }`}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItemButton>
                    );
                })}
              </List>
            </Collapse>
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[50],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="auto" sx={{ mt: "90px" }}>
            {props.children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>

      {alert.isActive && (
        <Snackbar
          open={alert.isActive}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Layout;
