import React from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";

const Notifications = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <FormGroup>
          <FormControlLabel
            onChange={(event) =>
              props.setFieldValue("newsletter", event.target.checked)
            }
            checked={props.values.newsletter}
            name={"newsletter"}
            onBlur={props.handleBlur}
            control={<Checkbox />}
            label="Recevoir la newsletter"
          />
        </FormGroup>
      </Grid>

      <Grid
        item
        xs={props.values.wantMenus ? 4 : 12}
        justifyContent="center"
        alignItems="center"
      >
        <FormGroup>
          <FormControlLabel
            onChange={(event) => {
              props.setFieldValue(
                "wantMenusBy",
                event.target.checked ? "paper" : null
              );
              props.setFieldValue("wantMenus", event.target.checked);
            }}
            checked={props.values.wantMenus}
            name={"wantMenus"}
            onBlur={props.handleBlur}
            control={<Checkbox />}
            label="Recevoir les menus"
          />
        </FormGroup>
      </Grid>

      {props.values.wantMenus && (
        <>
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Recevoir les menus par</InputLabel>

              <Select
                label="Recevoir les menus par"
                name="wantMenusBy"
                value={props.values.wantMenusBy}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.touched.wantMenusBy && Boolean(props.errors.wantMenusBy)
                }
              >
                <MenuItem value={"paper"}>Papier</MenuItem>
                <MenuItem value={"email"}>Email</MenuItem>
                <MenuItem value={"both"}>Papier et Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} />
        </>
      )}

      <Grid
        item
        xs={props.values.wantInvoices ? 4 : 12}
        justifyContent="center"
        alignItems="center"
      >
        <FormGroup>
          <FormControlLabel
            onChange={(event) => {
              props.setFieldValue(
                "wantInvoicesBy",
                event.target.checked ? "paper" : null
              );
              props.setFieldValue("wantInvoices", event.target.checked);
            }}
            checked={props.values.wantInvoices}
            name={"wantInvoices"}
            onBlur={props.handleBlur}
            control={<Checkbox />}
            label="Recevoir les factures"
          />
        </FormGroup>
      </Grid>

      {props.values.wantInvoices && (
        <>
          <Grid item xs={4}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Recevoir les factures par</InputLabel>

              <Select
                label="Recevoir les factures par"
                name="wantInvoicesBy"
                value={props.values.wantInvoicesBy}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.touched.wantInvoicesBy &&
                  Boolean(props.errors.wantInvoicesBy)
                }
              >
                <MenuItem value={"paper"}>Papier</MenuItem>
                <MenuItem value={"email"}>Email</MenuItem>
                <MenuItem value={"both"}>Papier et Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4} />
        </>
      )}
    </Grid>
  );
};

export default Notifications;
