import React, { useEffect, useState } from "react";
import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { ENTRYPOINT } from "../../../config/entrypoint";
import { requests } from "../../../agent";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { Snackbar, Alert } from "@mui/material";
import { format, set } from "date-fns";
import { useSelector } from "react-redux";
import { isAdmin, isAnyAdmin } from "../../../utils";
import Choices from "./Choices/Choices";

const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = (response) => response.body;
let token = null;
const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) request.set("Authorization", `Bearer ${token}`);
  };
};

const filterInitialValues = {
  id: "",
  recipient: null,
  client: null,
  status: "",
  date: null,
  completedOrders: "all",
};

const OrderContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [rowCount, setRowCount] = useState(undefined);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [choices, setChoices] = useState(false);
  const [orderEdit, setOrderEdit] = useState(null);
  const [orders, setOrders] = useState([]);
  const [ordersChoices, setOrdersChoices] = useState([]);
  const [delivery, setDelivery] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [user, setUser] = useState(null);
  const [menus, setMenus] = useState([]);
  const [products, setProducts] = useState([]);
  const [formules, setFormules] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [extras, setExtras] = useState([]);
  const [diets, setDiets] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [dataCalendar, setDataCalendar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(filterInitialValues);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setLoading(true);
    const promises = Promise.all([
      requests.get("/menus", true),
      requests.get("/produits", true),
      requests.get("/formules", true),
      requests.get("/supplements", true),
      requests.get("/categories", true),
      requests.get("/rounds", true),
      requests.get("/regimes", true),
      requests.get("/organizations/lists", true),
      requests.get("/recipients", true),
    ]);

    promises
      .then((results) => {
        const dataProducts = results[1]["hydra:member"];
        const productsId = [];
        const productsLabel = [];
        if (dataProducts) {
          dataProducts.forEach((produit) => {
            productsId.push(produit.id);
            productsLabel[produit.id] = produit.nom;
          });
        }

        const dataExtras = results[3]["hydra:member"];
        const extrasId = [];
        const extrasLabel = [];
        if (dataExtras) {
          dataExtras.forEach((extra) => {
            extrasId.push(extra.id);
            extrasLabel[extra.id] = extra.nom;
          });
        }
        setMenus(results[0]["hydra:member"]);
        setProducts({
          datas: dataProducts,
          ids: productsId,
          labels: productsLabel,
        });
        setFormules(
          isAnyAdmin(userData)
            ? results[2]["hydra:member"]
            : userData.organization.packages
        );
        setExtras({
          datas: dataExtras,
          ids: extrasId,
          labels: extrasLabel,
        });
        setCategories(results[4]["hydra:member"]);
        setRounds(
          isAnyAdmin(userData)
            ? results[5]["hydra:member"]
            : userData.organization.rounds
        );
        setDiets(results[6]["hydra:member"]);
        setClients(results[7]["hydra:member"]);
        setRecipients(results[8]["hydra:member"]);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleAdd = () => {
    setAdd(true);
    if (!isAdmin(userData)) {
      setUser(userData.organization);
    }
  };
  const toggleEdit = (orderId) => {
    setLoading(true);
    requests
      .get(`/commandes/${orderId}`, true)
      .then((orderResult) => {
        setOrderEdit(orderResult);
        requests
          .get(`/organizations/${orderResult.organization.id}/recipients`, true)
          .then((userResult) => {
            setUser(userResult);
            setLoading(false);
            setEdit(!edit);
          })
          .catch((err) => {
            setLoading(false);
            setUser(null);
            setAlert({
              active: true,
              type: "error",
              message: err.message,
            });
          });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setOrderEdit(null);
    setChoices(false);
    setMenus([]);
    setOrders([]);
  };
  const handleSubmit = (order) => {
    setLoading(true);
    requests
      .post(
        user.mode === "diets" ? "/order_groups" : "/order_units",
        order,
        true
      )
      .then((response) => {
        const newListOrders = [...orders];
        newListOrders.unshift(response);
        setOrders(newListOrders);
        setAdd(false);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "La commande a bien été créé !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitEdit = (order) => {
    setLoading(true);
    requests
      .put(`/commandes/${order.id}`, order, true)
      .then((response) => {
        const newListOrders = orders.map((order) => {
          if (order.id !== response.id) {
            return order;
          }

          return response;
        });
        setLoading(false);
        setOrders(newListOrders);
        setEdit(false);
        setOrderEdit(null);
        setAlert({
          active: true,
          type: "success",
          message: "La commande a été modifiée !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const fetchOrders = ({ page, pageSize, filters }) => {
    setLoading(true);
    let url = "commandes";
    let dataUrl = "";
    if (filters.id && filters.id !== "") dataUrl += `id=${filters.id}&`;
    if (filters.recipient && filters.recipient !== null) {
      dataUrl += `recipient.id=${filters.recipient}&`;
      url = "order_units";
    }
    if (filters.client && filters.client !== null)
      dataUrl += `organization.id=${filters.client}&`;
    if (filters.date && filters.date !== null)
      dataUrl += `date=${format(filters.date, "yyyy-MM-dd")}&`;
    if (filters.dateBefore && filters.date !== null)
      dataUrl += `date[before]=${filters.dateBefore}&`;
    if (filters.dateAfter && filters.date !== null)
      dataUrl += `date[after]=${filters.dateAfter}&`;
    if (filters.status && filters.status.length > 0)
      dataUrl += `statut=${filters.status}&`;
    if (filters.selectedMonth && filters.selectedMonth !== null)
      dataUrl += `month=${format(filters.selectedMonth, "yyyy-MM-dd")}&`;
    if (filters.completedOrders !== "all")
      dataUrl += `isComplete=${filters.completedOrders}&`;

    requests
      .get(
        `/${url}?${dataUrl}order[date]&page=${page}&pageSize=${pageSize}`,
        true
      )
      .then((results) => {
        setRowCount(results["hydra:totalItems"]);
        setOrders(results["hydra:member"]);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCalendar = (filters, callback) => {
    setLoading(true);
    let dataUrl = "";
    if (filters.id && filters.id !== "") dataUrl += `id=${filters.id}&`;
    if (filters.recipient && filters.recipient !== null)
      dataUrl += `recipient.id=${filters.recipient}&`;
    if (filters.client && filters.client !== null)
      dataUrl += `organization.id=${filters.client}&`;
    if (filters.date && filters.date !== null)
      dataUrl += `date=${format(filters.date, "yyyy-MM-dd")}&`;
    if (filters.dateBefore && filters.date !== null)
      dataUrl += `date[before]=${filters.dateBefore}&`;
    if (filters.dateAfter && filters.date !== null)
      dataUrl += `date[after]=${filters.dateAfter}&`;
    if (filters.status && filters.status.length > 0)
      dataUrl += `statut=${filters.status}&`;
    if (filters.selectedMonth && filters.selectedMonth !== null)
      dataUrl += `month=${format(filters.selectedMonth, "yyyy-MM-dd")}&`;
    if (filters.completedOrders !== "all")
      dataUrl += `isComplete=${filters.completedOrders}&`;

    requests
      .get(`/commandes/calendar?${dataUrl}`, true)
      .then((results) => {
        setDataCalendar(results);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        callback();
      });
  };
  const fetchMenus = (dateMenu, round, callbackFetchMenus) => {
    setLoading(true);

    requests
      .get(
        `/menus/order?date=${format(
          dateMenu,
          "yyyy-MM-dd"
        )}&rounds.id=${round}`,
        true
      )
      .then((results) => {
        callbackFetchMenus(results["hydra:member"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const sendDataExport = (mode, start, end) => {
    setLoading(true);
    superagent
      .get(
        `${ENTRYPOINT}/commandes/export/${mode}?date[before]=${format(
          end,
          "yyyy-MM-dd"
        )}&date[after]=${format(
          start,
          "yyyy-MM-dd"
        )}&statut[]=pending&statut[]=validated&statut[]=prepared&statut[]=delivering&statut[]=delivered`
      )
      .use(tokenPlugin(true))
      .responseType("blob")
      .then(responseBody)
      .then((results) => {
        const fileUrl = URL.createObjectURL(results);
        const filename = `${mode}_hulpo_${format(start, "yyyyMMdd")}_${format(
          end,
          "yyyyMMdd"
        )}.xlsx`;

        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(fileUrl);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les données ont été exportées !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleGenerateOrders = (data, callback) => {
    setLoading(true);
    const values = {
      ...(data.start && { startDate: format(data.start, "yyyy-MM-dd") }),
      ...(data.end && { endDate: format(data.end, "yyyy-MM-dd") }),
      round: `${data.round}`,
      organization: `${data.client}`,
    };

    requests
      .post("/commandes/generate", values, true)
      .then((response) => {
        let message =
          response.orders["hydra:totalItems"] + " commandes générées !";

        if (response.deliveriesNotPersisted["hydra:totalItems"] > 0) {
          message +=
            " " +
            response.deliveriesNotPersisted["hydra:totalItems"] +
            " livraisons non générées.";
        }

        setAlert({
          active: true,
          type: "success",
          message: message,
        });
        callback();
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDelete = (orderId) => {
    setLoading(true);
    requests
      .del(`/commandes/${orderId}`, true)
      .then((response) => {
        setOrders(orders.filter((order) => orderId !== order.id));
        setAlert({
          active: true,
          type: "success",
          message: "La commande a bien été supprimé",
        });
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleMultipleDelete = (ordersId, callback) => {
    setLoading(true);
    const data = {
      commandes: ordersId.map((id) => `/commandes/${id}`),
    };

    requests
      .post("/commandes/delete", data, true)
      .then((response) => {
        setAlert({
          active: true,
          type: "success",
          message: "Les commandes ont bien été supprimés !",
        });
        callback();
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleChangeUser = (id) => {
    setLoading(true);

    if (id !== null) {
      requests
        .get(`/organizations/${id}/recipients`, true)
        .then((result) => {
          setUser(result);
        })
        .catch((err) => {
          setUser(null);
          setAlert({
            active: true,
            type: "error",
            message: err.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setUser(null);
    }
  };
  const handleSubmitOrdersState = (date, state, clientId, all, callback) => {
    setLoading(true);

    const data = {
      date: format(date, "yyyy-MM-dd"),
      state: state,
      client: clientId ? `/organizations/${clientId}` : null,
      all: all,
    };
    requests
      .post("/commandes/multiple", data, true)
      .then((results) => {
        callback();
        setAlert({
          active: true,
          type: "success",
          message:
            results["hydra:totalItems"] + " commandes ont été mises à jours",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const toggleDelivery = (hasClick, id) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    if (hasClick) {
      requests
        .get(`/deliveries/${id}`, true)
        .then((result) => {
          setDelivery(result);
          setLoading(false);
          setIsOpen(true);
        })
        .catch((err) => {
          setIsOpen(false);
          setLoading(false);
          setAlert({
            active: true,
            type: "error",
            message: err.message,
          });
        });
    } else {
      setIsOpen(false);
      setLoading(false);
    }
  };
  const downloadImportTemplate = (item, start, end, roundId) => {
    setLoading(true);
    let roundParam = "";
    if (roundId) roundParam = `&round.id=${roundId}`;

    superagent
      .get(
        `${ENTRYPOINT}/recipients/export/template_choices?organization.id=${item.client.id}&state=active&start=${start}&end=${end}${roundParam}`
      )
      .use(tokenPlugin(true))
      .responseType("blob")
      .then(responseBody)
      .then((results) => {
        const fileUrl = URL.createObjectURL(results);
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = `import_choix_menus_${item.client.name.replaceAll(
          " ",
          "_"
        )}`;

        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(fileUrl);
        setAlert({
          active: true,
          type: "success",
          message: "Le fichier a bien été téléchargé !",
        });
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const toggleChoices = () => {
    setChoices(true);
  };
  const handleUpdateChoice = (orderId, item) => {
    setLoading(true);
    requests
      .patch(`/commandes/${orderId}`, item, true)
      .then((result) => {
        const updatedOrders = orders.map((order) => {
          if (order.id !== result.id) return order;
          return result;
        });
        setOrders(updatedOrders);
        const updatedOrdersChoices = ordersChoices.map((orderChoice) => {
          if (orderChoice.id !== result.id) return orderChoice;
          return result;
        });
        setOrdersChoices(updatedOrdersChoices);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "La commande a bien été sauvegardée !",
        });
      })

      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitStateFromList = (order, newState) => {
    setLoading(true);

    requests
      .patch(
        `/commandes/${order.id}`,
        {
          statut: newState,
        },
        true
      )
      .then((response) => {
        setLoading(false);
        setOrders((prevOrders) =>
          prevOrders.map((o) => (o.id === response.id ? response : o))
        );
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le statut de la commande a bien été mis à jour !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const fetchOrdersChoices = (recipientId, startDate, endDate) => {
    requests
      .get(
        `/order_units/products/choices?recipient=${recipientId}&start=${startDate}&end=${endDate}`,
        true
      )
      .then((results) => {
        setOrdersChoices(results["hydra:member"][0]);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const fetchMenusChoices = (startDate, endDate, roundId) => {
    setLoading(true);
    requests
      .get(
        `/menus/orders/choices?start=${startDate}&end=${endDate}&round=${roundId}`,
        true
      )
      .then((result) => {
        setMenus(result["hydra:member"][0]);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleImport = (file, item, startDate, endDate, callback) => {
    setLoading(true);

    let from = "order_units";
    if (item.client.mode === "diets") from = "order_groups";

    superagent
      .post(`${ENTRYPOINT}/${from}/import/menus`)
      .use(tokenPlugin(true))
      .attach("file", file)
      .field("id", item.client.id)
      .field("startDate", startDate)
      .field("endDate", endDate)
      .then((response) => {
        callback();
        setAlert({
          active: true,
          type: "success",
          message:
            "Le fichier a bien été importé ! Un total de " +
            response.body.orders["hydra:totalItems"] +
            " commandes ont été générées.",
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error instanceof Error) {
          // Assurez-vous que l'erreur est une instance de Error
          const errorData = JSON.parse(error.message); // Analyse la chaîne JSON dans l'erreur
          if (errorData && errorData["hydra:description"]) {
            const hydraDescription = errorData["hydra:description"];
            console.error("hydra:description :", hydraDescription);
            setAlert({
              active: true,
              type: "error",
              message: hydraDescription,
            });
          } else {
            console.error("L'objet d'erreur ne contient pas hydra:description.");
          }
        } else {
          console.error("Une erreur non gérée s'est produite :", error);
        }
      });
  };
  return (
    <>
      {add && (
        <Add
          userData={userData}
          validation={handleSubmit}
          toggleList={toggleList}
          add={add}
          clients={clients}
          user={user}
          loading={loading}
          alert={alert}
          diets={diets}
          extras={extras}
          formules={formules}
          products={products}
          categories={categories}
          menus={menus}
          rounds={rounds}
          handleChangeUser={handleChangeUser}
          fetchMenus={fetchMenus}
        />
      )}

      {edit && (
        <Edit
          userData={userData}
          validation={handleSubmitEdit}
          toggleList={toggleList}
          edit={edit}
          orderEdit={orderEdit}
          user={user}
          loading={loading}
          alert={alert}
          diets={diets}
          extras={extras}
          formules={formules}
          products={products}
          categories={categories}
          menus={menus}
          rounds={rounds}
          fetchMenus={fetchMenus}
        />
      )}

      {choices && (
        <Choices
          loading={loading}
          setLoading={setLoading}
          validation={handleUpdateChoice}
          toggleList={toggleList}
          recipients={recipients}
          ordersChoices={ordersChoices}
          menus={menus}
          fetchOrdersChoices={fetchOrdersChoices}
          fetchMenusChoices={fetchMenusChoices}
        />
      )}

      {!edit && !add && !choices && (
        <List
          userData={userData}
          edit={edit}
          add={add}
          choices={choices}
          clients={clients}
          recipients={recipients}
          orders={orders}
          rowCount={rowCount}
          rounds={rounds}
          loading={loading}
          toggleEdit={toggleEdit}
          toggleAdd={toggleAdd}
          toggleChoices={toggleChoices}
          generateOrders={handleGenerateOrders}
          sendDataExport={sendDataExport}
          handleDelete={handleDelete}
          handleMultipleDelete={handleMultipleDelete}
          fetchOrders={fetchOrders}
          handleSubmitOrdersState={handleSubmitOrdersState}
          isOpen={isOpen}
          delivery={delivery}
          toggleDelivery={toggleDelivery}
          dataCalendar={dataCalendar}
          downloadImportTemplate={downloadImportTemplate}
          handleSubmitStateFromList={handleSubmitStateFromList}
          handleImport={handleImport}
          filters={filters}
          setFilters={setFilters}
          filterInitialValues={filterInitialValues}
          fetchCalendar={fetchCalendar}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default OrderContainer;
