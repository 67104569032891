import { Snackbar, Alert } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Add from "./Add/Add";
import List from "./List/List";
import Edit from "./Edit/Edit";
import { requests } from "../../../agent";
import { isAnyAdmin } from "../../../utils";
import MenuCalendar from "./Calendar/MenuCalendar";

const filterInitialValues = {
  id: "",
  date: null,
  round: "",
};

const MenuContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(undefined);
  const [menus, setMenus] = useState([]);
  const [menuToDupplicate, setMenuToDupplicate] = useState(null);
  const [products, setProducts] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [diets, setDiets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [extras, setExtras] = useState([]);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const [dataCsv, setDataCsv] = useState([]);
  const [filters, setFilters] = useState(filterInitialValues);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setIsLoading(true);

    const promises = Promise.all([
      requests.get("/produits?order[name]=asc", true),
      requests.get("/categories", true),
      requests.get("/rounds?order[name]", true),
      requests.get("/regimes", true),
      requests.get("/supplements", true),
    ]);

    promises
      .then((results) => {
        setProducts({
          datas: results[0]["hydra:member"],
          ids: results[0]["hydra:member"].map((item) => item.id),
          labels: results[0]["hydra:member"].map((item) => item.nom),
        });
        setCategories(results[1]["hydra:member"]);
        setRounds(results[2]["hydra:member"]);
        setDiets(results[3]["hydra:member"]);
        setExtras({
          datas: results[4]["hydra:member"],
          ids: results[4]["hydra:member"].map((item) => item.id),
          labels: results[4]["hydra:member"].map((item) => item.nom),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleAdd = () => {
    setAdd(true);
    setMenuToDupplicate(null);
  };
  const toggleEdit = (menuId) => {
    setIsLoading(true);
    requests
      .get(`/menus/${menuId}`, true)
      .then((result) => {
        setDataEdit(result);
        setIsLoading(false);
        setEdit(true);
      })

      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setDataEdit(false);
    setMenuToDupplicate(null);
  };
  const handleSubmit = (menu) => {
    setIsLoading(true);
    requests
      .post("/menus", menu, true)
      .then((result) => {
        setMenus((prevItems) => [result, ...prevItems]);
        setAdd(false);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le menu à bien été créé !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleUpdate = (id, menu) => {
    setIsLoading(true);
    requests
      .put(`/menus/${id}`, menu, true)
      .then((result) => {
        const newMenus = menus.map((item) => {
          if (item.id === id) {
            return result;
          }
          return item;
        });
        setMenus(newMenus);
        setEdit(false);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Menu modifié avec succès",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const fetchMenus = ({ page, pageSize, filters }) => {
    setIsLoading(true);

    const menuUrlParams = userData.organization.rounds
      .map((round) => "&rounds.id[]=" + round.id)
      .join("");

    let parameters = "";
    if (filters.id && filters.id !== "") parameters += `id=${filters.id}&`;
    if (filters.date && filters.date !== null)
      parameters += `date=${format(filters.date, "yyyy-MM-dd")}&`;
    if (filters.round && filters.round !== "")
      parameters += `rounds.id=${filters.round}&`;
    parameters = parameters.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(
        `/menus?${
          !isAnyAdmin(userData) ? menuUrlParams + "&" : ""
        }order[date]&order[priorityIndex]=desc&${parameters}&page=${page}&pageSize=${pageSize}`,
        true
      )
      .then((result) => {
        setMenus(result["hydra:member"]);
        setRowCount(result["hydra:totalItems"]);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const sendDataExport = (all, start, end) => {
    setIsLoading(true);
    let parameters = "";
    if (all !== null) parameters += `all=${all}&`;
    if (start !== null) parameters += `start=${start}&`;
    if (end !== null) parameters += `end=${end}&`;
    parameters = parameters.slice(0, -1);

    requests
      .get(`/menus/export?${parameters}`, true)
      .then((result) => {
        let data = result["hydra:member"][0].menus;

        setDataCsv(data);
        setIsLoading(false);
        setAlert({
          active: true,
          type: data.length > 0 ? "success" : "warning",
          message:
            data.length > 0
              ? "Exportation réussie"
              : "Aucune données entre ces 2 dates !",
        });
      })
      .catch((err) => {
        setDataCsv([]);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const resetDataExport = () => {
    setDataCsv([]);
  };
  const handleDupplicate = (menu) => {
    setIsLoading(true);
    requests
      .get(`/menus/${menu.id}`, true)
      .then((result) => {
        setMenuToDupplicate(result);
        setAdd(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") return;
    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      {isAnyAdmin(userData) ? (
        <>
          {add && (
            <Add
              isLoading={isLoading}
              validation={handleSubmit}
              toggleAdd={toggleAdd}
              toggleList={toggleList}
              add={add}
              edit={edit}
              rounds={rounds}
              products={products}
              extras={extras}
              categories={categories}
              diets={diets}
              menuToDuplicate={menuToDupplicate}
            />
          )}

          {edit && (
            <Edit
              isLoading={isLoading}
              validation={handleUpdate}
              toggleEdit={toggleEdit}
              toggleList={toggleList}
              edit={edit}
              dataEdit={dataEdit}
              rounds={rounds}
              products={products}
              extras={extras}
              categories={categories}
              diets={diets}
            />
          )}

          {!add && !edit && (
            <List
              isLoading={isLoading}
              menus={menus}
              products={products}
              rounds={rounds}
              dataCsv={dataCsv}
              rowCount={rowCount}
              toggleAdd={toggleAdd}
              toggleEdit={toggleEdit}
              fetchMenus={fetchMenus}
              sendDataExport={sendDataExport}
              resetDataExport={resetDataExport}
              handleDupplicate={handleDupplicate}
              filters={filters}
              setFilters={setFilters}
              filterInitialValues={filterInitialValues}
            />
          )}
        </>
      ) : (
        <MenuCalendar menus={menus} />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default MenuContainer;
