import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import Form from "../../particulier/Contact/Form";
import FormPro from "../../professionnel/Contact/Form";
import "./contact.css";

const Contact = (props) => {
  return (
    <Stack className="contact bg" borderRadius={3}>
      <Container
        maxWidth="sm"
        sx={{ paddingY: 2, backgroundColor: "rgb(255,255,255,.55)" }}
      >
        <Typography
          variant="h4"
          color="primary"
          textAlign="center"
          gutterBottom
        >
          {props.selected === 1
            ? "On vous offre le premier Basil ?"
            : "Votre gestion de portage de repas."}
        </Typography>

        <Typography variant="h6" textAlign="center">
          Appelez-le <strong className="red">04 86 94 09 37</strong> <br />
          {props.selected === 1
            ? "pour recevoir votre premier repas offert."
            : "pour obtenir un devis."}
        </Typography>

        <Typography variant="h6" color="primary" textAlign="center">
          OU
        </Typography>

        <Typography variant="h6" textAlign="center">
          Renseignez votre numéro de téléphone et <br /> nous vous rappelons
          rapidement :
          <br />
        </Typography>

        {props.selected === 1 ? <Form /> : <FormPro />}
      </Container>
    </Stack>
  );
};

export default Contact;
