import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import SubscriptionForm from "../SubscriptionForm";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifiers."
          title="Modification d'une souscription"
        />
      </Card>

      <SubscriptionForm {...props} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    organization: props.subscription.organization.id,
    organizationName: props.subscription.organization.name,
    plan: props.subscription.plan.id,
    planName: props.subscription.plan.name,
    addOns: props.subscription.addOns.map((addOn) => addOn.id),
    addonsName: props.subscription.addOns.map((addOn) => addOn.name),
  }),
  validationSchema: Yup.object().shape({
    organization: Yup.number()
      .positive()
      .required("Ce champs est obligatoire.")
      .typeError("Ce champs est obligatoire."),

    plan: Yup.number()
      .positive()
      .required("Ce champs est obligatoire.")
      .typeError("Ce champs est obligatoire."),

    addOns: Yup.array().of(Yup.number().positive()),
  }),
  handleSubmit: (values, { props }) => {
    const updatedItem = {
      id: props.subscription.id,
      organization: `/organizations/${values.organization}`,
      plan: `/plans/${values.plan}`,
      addOns: values.addOns.map((addOn) => `/add_ons/${addOn}`),
    };

    props.validation(updatedItem);
  },
})(Edit);
