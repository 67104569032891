import React from "react";
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { locale, mask } from "../../../localisation";
import { days } from "../Common/days";

const MultipleRecipientsForm = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          fullWidth
          required
          error={props.touched.startedAt && Boolean(props.errors.startedAt)}
        >
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale["fr"]}
          >
            <DesktopDatePicker
              mask={mask["fr"]}
              label="Date de début de consommation"
              inputFormat="dd/MM/yyyy"
              value={props.values.startedAt}
              onChange={(value) => props.setFieldValue("startedAt", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name={"startedAt"}
                  error={
                    props.touched.startedAt && Boolean(props.errors.startedAt)
                  }
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText error>
            {props.errors.startedAt &&
              props.touched.startedAt &&
              "Date invalide"}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          fullWidth
          required
          error={props.touched.cancelledAt && Boolean(props.errors.cancelledAt)}
        >
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale["fr"]}
          >
            <DesktopDatePicker
              mask={mask["fr"]}
              label="Date de fin de consommation"
              inputFormat="dd/MM/yyyy"
              value={props.values.cancelledAt}
              onChange={(value) => props.setFieldValue("cancelledAt", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name={"cancelledAt"}
                  error={
                    props.touched.cancelledAt &&
                    Boolean(props.errors.cancelledAt)
                  }
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText error>
            {props.errors.cancelledAt &&
              props.touched.cancelledAt &&
              props.errors.cancelledAt}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" color="GrayText">
          <i>
            <u>Informations :</u>
            <br />
            Renseigner qu'une formule déjeuner avec les quantités créera un
            unique convive avec le régime et la tournée sélectionnés.
            <br />
            Si vous renseignez la formule déjeuner et dîner, 2 options s'offrent
            à vous :
            <br />
            - Renseigner des quantités aux 2 formules créera 2 convives avec le
            régime et la tournée sélectionnés. Un avec seulement la formule
            déjeuner, l'autre avec les 2 formules.
            <br />- Renseigner des quantités seulement pour le dîner, cela
            créera un unique convive avec les 2 formules sélectionnées.
          </i>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {props.multipleAdd &&
        props.values.datas.map((data, dIndex) => (
          <Grid item container xs={12} key={dIndex} spacing={2}>
            <Grid item container xs={11} spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="diet-label">Régime</InputLabel>
                  <Select
                    fullWidth
                    labelId="diet-label"
                    id="diet"
                    value={data.diet}
                    onChange={(event) =>
                      props.setFieldValue(
                        `datas[${dIndex}].diet`,
                        event.target.value
                      )
                    }
                    input={<OutlinedInput label="Régime" />}
                  >
                    <MenuItem value={"none"}>
                      <i>Sans régime</i>
                    </MenuItem>
                    {props.diets.map((d, i) => (
                      <MenuItem value={d.id} key={i}>
                        <ListItemText primary={d.nom} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel>Tournée</InputLabel>
                  <Select
                    name={"round"}
                    label="Tournée"
                    value={data.round}
                    onChange={(event) =>
                      props.setFieldValue(
                        `datas[${dIndex}].round`,
                        event.target.value
                      )
                    }
                    onBlur={props.handleBlur}
                    error={
                      props.touched.datas?.[dIndex]?.round &&
                      props.errors.datas?.[dIndex]?.round
                    }
                  >
                    {props.rounds.map((round, i) => {
                      return (
                        <MenuItem value={round.id} key={i}>
                          {round.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error>
                    {props.touched.datas?.[dIndex]?.round &&
                      props.errors.datas?.[dIndex]?.round}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {data.recipients.map((r, rIndex) => (
                <>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    key={rIndex}
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={10}
                      container
                      flexDirection="column"
                      spacing={1}
                    >
                      <Grid item container spacing={1}>
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            required
                            size="small"
                          >
                            <InputLabel>Formule déjeuner</InputLabel>
                            <Select
                              size="small"
                              name="lunchMenu"
                              label="Formule déjeuner"
                              value={r.lunchMenu}
                              onChange={(event) =>
                                props.setFieldValue(
                                  `datas[${dIndex}].recipients[${rIndex}].lunchMenu`,
                                  event.target.value
                                )
                              }
                              onBlur={props.handleBlur}
                              error={
                                props.touched.datas?.[dIndex]?.recipients?.[
                                  rIndex
                                ]?.lunchMenu &&
                                props.errors.datas?.[dIndex]?.recipients?.[
                                  rIndex
                                ]?.lunchMenu
                              }
                            >
                              {props.formules
                                .filter((formule) => !formule.estDiner)
                                .map((formule, i) => {
                                  return (
                                    <MenuItem value={formule.id} key={i}>
                                      {formule.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText error>
                              {props.touched.datas?.[dIndex]?.recipients?.[
                                rIndex
                              ]?.lunchMenu &&
                                props.errors.datas?.[dIndex]?.recipients?.[
                                  rIndex
                                ]?.lunchMenu}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={1.5}>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            textAlign="center"
                            fontSize={12}
                          >
                            Quantité <br /> déjeuner uniquement
                          </Typography>
                        </Grid>

                        {days.map((value, i) => (
                          <Grid item key={i}>
                            <TextField
                              size="small"
                              label={value.day}
                              type="number"
                              value={r.lunchMealDays[i].qty}
                              onChange={(event) => {
                                event.target.value !== ""
                                  ? props.setFieldValue(
                                      `datas[${dIndex}].recipients[${rIndex}].lunchMealDays[${i}].qty`,
                                      parseInt(event.target.value)
                                    )
                                  : props.setFieldValue(
                                      `datas[${dIndex}].recipients[${rIndex}].lunchMealDays[${i}].qty`,
                                      0
                                    );
                              }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              sx={{ maxWidth: 80 }}
                            />
                          </Grid>
                        ))}
                      </Grid>

                      <Grid item container spacing={1}>
                        <Grid item xs={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <InputLabel>Formule dîner</InputLabel>
                            <Select
                              size="small"
                              name="dinnerMenu"
                              label="Formule dîner"
                              value={r.dinnerMenu}
                              onChange={(event) => {
                                if (event.target.value === "none") {
                                  props.setFieldValue(
                                    `datas[${dIndex}].recipients[${rIndex}].dinnerMealDays`,
                                    [
                                      { day: 1, qty: 0 },
                                      { day: 2, qty: 0 },
                                      { day: 3, qty: 0 },
                                      { day: 4, qty: 0 },
                                      { day: 5, qty: 0 },
                                      { day: 6, qty: 0 },
                                      { day: 0, qty: 0 },
                                    ]
                                  );
                                }
                                props.setFieldValue(
                                  `datas[${dIndex}].recipients[${rIndex}].dinnerMenu`,
                                  event.target.value
                                );
                              }}
                              onBlur={props.handleBlur}
                            >
                              <MenuItem value="none">
                                <i>Aucune</i>
                              </MenuItem>
                              {props.formules
                                .filter((formule) => formule.estDiner)
                                .map((formule, i) => (
                                  <MenuItem value={formule.id} key={i}>
                                    {formule.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={1.5}>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            textAlign="center"
                            fontSize={12}
                          >
                            Quantité <br /> déjeuner + dîner
                          </Typography>
                        </Grid>

                        {days.map((value, i) => (
                          <Grid item key={i}>
                            <TextField
                              disabled={r.dinnerMenu === "none"}
                              size="small"
                              label={value.day}
                              type="number"
                              value={r.dinnerMealDays[i].qty}
                              onChange={(event) => {
                                event.target.value !== ""
                                  ? props.setFieldValue(
                                      `datas[${dIndex}].recipients[${rIndex}].dinnerMealDays[${i}].qty`,
                                      parseInt(event.target.value)
                                    )
                                  : props.setFieldValue(
                                      `datas[${dIndex}].recipients[${rIndex}].dinnerMealDays[${i}].qty`,
                                      0
                                    );
                              }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              sx={{ maxWidth: 80 }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>

                    {props.values.datas[dIndex].recipients.length > 1 && (
                      <Grid item>
                        <IconButton
                          aria-label="remove"
                          onClick={() =>
                            props.setFieldValue(
                              `datas[${dIndex}].recipients`,
                              props.values.datas[dIndex].recipients.filter(
                                (data, i) => rIndex !== i
                              )
                            )
                          }
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </Grid>
                    )}

                    {rIndex === data.recipients.length - 1 && (
                      <Grid item>
                        <IconButton
                          aria-label="add"
                          onClick={() =>
                            props.setFieldValue(`datas[${dIndex}].recipients`, [
                              ...props.values.datas[dIndex].recipients,
                              {
                                lunchMealDays: [
                                  { day: 1, qty: 0 },
                                  { day: 2, qty: 0 },
                                  { day: 3, qty: 0 },
                                  { day: 4, qty: 0 },
                                  { day: 5, qty: 0 },
                                  { day: 6, qty: 0 },
                                  { day: 0, qty: 0 },
                                ],
                                dinnerMealDays: [
                                  { day: 1, qty: 0 },
                                  { day: 2, qty: 0 },
                                  { day: 3, qty: 0 },
                                  { day: 4, qty: 0 },
                                  { day: 5, qty: 0 },
                                  { day: 6, qty: 0 },
                                  { day: 0, qty: 0 },
                                ],
                                lunchMenu: "",
                                dinnerMenu: "none",
                              },
                            ])
                          }
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  {rIndex !== data.recipients.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>

            <Grid
              item
              container
              xs={1}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              {props.values.datas.length > 1 && (
                <Grid item>
                  <IconButton
                    aria-label="remove"
                    onClick={() =>
                      props.setFieldValue(
                        `datas`,
                        props.values.datas.filter((data, i) => dIndex !== i)
                      )
                    }
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Grid>
              )}

              {dIndex === props.values.datas.length - 1 && (
                <Grid item>
                  <IconButton
                    aria-label="add"
                    onClick={() =>
                      props.setFieldValue(`datas`, [
                        ...props.values.datas,
                        {
                          diet: "none",
                          round: "",
                          recipients: [
                            {
                              lunchMealDays: [
                                { day: 1, qty: 0 },
                                { day: 2, qty: 0 },
                                { day: 3, qty: 0 },
                                { day: 4, qty: 0 },
                                { day: 5, qty: 0 },
                                { day: 6, qty: 0 },
                                { day: 0, qty: 0 },
                              ],
                              dinnerMealDays: [
                                { day: 1, qty: 0 },
                                { day: 2, qty: 0 },
                                { day: 3, qty: 0 },
                                { day: 4, qty: 0 },
                                { day: 5, qty: 0 },
                                { day: 6, qty: 0 },
                                { day: 0, qty: 0 },
                              ],
                              lunchMenu: "",
                              dinnerMenu: "none",
                            },
                          ],
                        },
                      ])
                    }
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            {dIndex !== props.values.datas.length - 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </Grid>
        ))}

      {props.edit && (
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="diet-label">Régime</InputLabel>
            <Select
              labelId="diet-label"
              label="Régime"
              value={props.values.diet}
              onChange={(event) =>
                props.setFieldValue("diets", [event.target.value])
              }
              onBlur={props.handleBlur}
            >
              <MenuItem value="none">
                <i>Sans régime</i>
              </MenuItem>

              {props.diets.map((diet, i) => (
                <MenuItem key={i} value={diet.id}>
                  {diet.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default MultipleRecipientsForm;
