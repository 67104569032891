import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  Grid,
  Input,
  Stack,
  TextField,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Typography,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { ENTRYPOINT } from "../../../../config/entrypoint";

const CustomDeliveryNoteForm = (props) => {
  const classes = UseStyle();
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    if (selectedLogo) {
      setLogoUrl(URL.createObjectURL(selectedLogo));
    }
  }, [selectedLogo]);

  return (
    <Paper className={classes.paper} elevation={6}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="title"
            label="Titre"
            type="text"
            value={props.values.title}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            rows={2}
            fullWidth
            name="footer"
            label="Téléphone"
            type="text"
            value={props.values.footer}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={
              props.touched.bannerColor && Boolean(props.errors.bannerColor)
            }
            helperText={
              props.errors.bannerColor &&
              props.touched.bannerColor &&
              props.errors.bannerColor
            }
            fullWidth
            name={"bannerColor"}
            label={"Couleur associé aux bons de livraisons"}
            variant="outlined"
            type={"text"}
            value={props.values.bannerColor}
            className={classes.inputField}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
          />

          <Typography
            variant="subtitle2"
            sx={{ color: "GrayText", fontStyle: "italic" }}
          >
            • La couleur doit être au format hexadécimal (ex: #000000)
            <br />
            Vous pouvez utiliser ce site pour choisir votre couleur :{" "}
            <Link href="https://www.color-hex.com/color/def5db">Color-Hex</Link>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Afficher les indications de livraisons"
              name="showAddressNotes"
              checked={props.values.showAddressNotes}
              onChange={props.handleChange}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          {(props.values.contentUrl || (logoUrl && selectedLogo)) && (
            <img
              src={
                logoUrl ? logoUrl : `${ENTRYPOINT}${props.values.contentUrl}`
              }
              alt={logoUrl ? selectedLogo.name : props.values.title}
              style={{ maxHeight: 220 }}
            />
          )}

          <Stack spacing={2} direction="row">
            <label htmlFor="contained-button-file">
              <Input
                inputProps={{
                  accept: "image/*",
                }}
                id="contained-button-file"
                type="file"
                sx={{ display: "none" }}
                onChange={(e) => {
                  setSelectedLogo(e.target.files[0]);
                  props.setFieldValue("file", e.target.files[0]);
                }}
              />
              <LoadingButton
                loading={props.loading}
                variant="outlined"
                component="span"
                startIcon={<DownloadRoundedIcon />}
              >
                Sélectionner un logo
              </LoadingButton>
            </label>
          </Stack>
        </Grid>

        <Grid container item justifyContent="flex-end" xs={12}>
          <LoadingButton
            loading={props.isLoading}
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            onClick={props.handleSubmit}
          >
            Sauvegarder
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CustomDeliveryNoteForm;
