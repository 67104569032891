import EditIcon from "@mui/icons-material/Edit";
import { Button, LinearProgress, Paper, Stack, Tooltip } from "@mui/material";
import React from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import Filters from "../../../Common/Filters";
import UseStyle from "../../../Common/StyledComponent/UseStyle";
import CustomNoRowsOverlay from "../../../Common/CustomNoRowsOverlay";

const List = (props) => {
  const classes = UseStyle();
  let columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Dernière màj",
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => (value ? new Date(value) : null),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
      ],
    },
  ];

  return (
    <Stack spacing={2} marginBottom={2}>
      <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>
      </Stack>
      {/* 
      <Filters
        userData={props.userData}
        from="organizations"
        clients={props.organizations}
        handleSubmitFilters={props.handleSubmitFilters}
      /> */}

      <Paper className={classes.paper} elevation={6}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={props.organizations}
          pageSize={30}
          density="comfortable"
          disableSelectionOnClick
          pagination
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          loading={props.isLoading}
          experimentalFeatures={{ newEditingApi: true }}
          getRowHeight={() => "auto"}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
        />
      </Paper>
    </Stack>
  );
};

export default List;
