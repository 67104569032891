import { Paper, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { isAnyAdmin } from "../../../../utils";

export function getDateFromFilters(filters) {
  if (filters.month) {
    return (
      "de " +
      format(filters.month, "LLLL", {
        locale: fr,
      })
    );
  } else if (filters.startDate && !filters.endDate) {
    return (
      "du " +
      format(filters.startDate, "dd/MM/yyyy", {
        locale: fr,
      }) +
      " à aujourd'hui"
    );
  } else if (filters.startDate && filters.endDate) {
    return (
      "du " +
      format(filters.startDate, "dd/MM/yyyy", {
        locale: fr,
      }) +
      " au " +
      format(filters.endDate, "dd/MM/yyyy", {
        locale: fr,
      })
    );
  } else {
    return format(new Date(), "LLLL", {
      locale: fr,
    });
  }
}

const Turnover = (props) => {
  const classes = UseStyle();

  return (
    <Paper className={classes.paper} elevation={3}>
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h6" gutterBottom>
          {isAnyAdmin(props.userData)
            ? "Chiffre d'affaire "
            : "Total (€) de mes commandes "}
          {getDateFromFilters(props.filters)}
        </Typography>
        <LineChart
          width={props.isOpen ? 390 : 560}
          height={280}
          data={props.data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend
            formatter={(value, entry, index) => {
              if (value === "ttc") {
                return isAnyAdmin(props.userData)
                  ? "Chiffre d'affaire TTC (€)"
                  : "Total TTC (€)";
              } else if (value === "ht") {
                return isAnyAdmin(props.userData)
                  ? "Chiffre d'affaire HT (€)"
                  : "Total HT (€)";
              } else {
                return "Nombre de commandes";
              }
            }}
          />
          <Line type="monotone" dataKey="commandes" stroke="#febd2f" />
          <Line type="monotone" dataKey="ht" stroke="#e8a236" />
          <Line type="monotone" dataKey="ttc" stroke="#e84f36" />
        </LineChart>
      </Stack>
    </Paper>
  );
};

export default Turnover;
