import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import UseStyle from "../Common/StyledComponent/UseStyle";
import { DataGrid, gridClasses, useGridApiContext } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../Common/CustomNoRowsOverlay";
import { RECIPIENT_STATE } from "../Common/Enums/Enums";
import { useFormik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";

const RecipientsUpdateList = (props) => {
  const classes = UseStyle();
  const [recipients, setRecipients] = useState([]);
  const [rounds, setRounds] = useState(props.rounds);
  const [recipientsList, setRecipientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  /*** SELECTION CLIENT ***/
  const clients = props.clients
    .filter((client) => client.mode === "repertory")
    .map((client) => {
      const filtre = client.name[0].toUpperCase();
      return {
        filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
        client: client,
      };
    });

  /*** FILTRE ***/
  useEffect(() => {
    if (recipients.length > 0) {
      const recipientsForFilters = recipients.map((recipient) => {
        const filtre = recipient.name[0].toUpperCase();
        return {
          filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
          recipient: recipient,
        };
      });

      setRecipientsList(recipientsForFilters);
    }
  }, [recipients]);
  useEffect(() => {
    if (selectedClient) {
      setRounds(
        props.rounds.filter(
          (round) => round.organization.id === selectedClient.client.id
        )
      );
    } else {
      setRounds(props.rounds);
    }
  }, [selectedClient]);
  const filterFormik = useFormik({
    initialValues: {
      recipient: null,
      round: "",
      state: "",
    },
    validationSchema: Yup.object().shape({
      recipient: Yup.number().positive().nullable(),
      round: Yup.number().positive(),
      state: Yup.string(),
    }),
    onSubmit: (values) => {
      props.fetchRecipients(
        selectedClient.client.id,
        {
          recipient: values.recipient,
          round: values.round,
          state: values.state,
        },
        handleCallbackRecipients
      );
    },
  });

  /*** DATAGRID ***/
  const renderState = (params) => {
    return params.value === RECIPIENT_STATE.ACTIVE.status ? (
      <Tooltip title={RECIPIENT_STATE.ACTIVE.description}>
        <Chip
          size="small"
          icon={<DoneOutlinedIcon fontSize="small" />}
          color="success"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : params.value === RECIPIENT_STATE.CANCELLED.status ? (
      <Tooltip title={RECIPIENT_STATE.CANCELLED.description}>
        <Chip
          size="small"
          icon={<ClearOutlinedIcon fontSize="small" />}
          color="error"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : params.value === RECIPIENT_STATE.PAUSED.status ? (
      <Tooltip title={RECIPIENT_STATE.PAUSED.description}>
        <Chip
          size="small"
          icon={<HourglassEmptyOutlinedIcon fontSize="small" />}
          color="warning"
          variant="outlined"
          sx={{ "& .MuiChip-label": { padding: 0.35 } }}
        />
      </Tooltip>
    ) : (
      ""
    );
  };
  const renderSelectRound = (params) => {
    return <SelectRoundInputCell {...params} />;
  };
  const SelectRoundInputCell = (params) => {
    const { id, value, field } = params;
    const apiRef = useGridApiContext();
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        fullWidth
        value={value}
        onChange={handleChange}
        renderValue={() =>
          rounds.find((round) => round.id === (value?.id ?? value)).name
        }
      >
        {rounds.map((round, i) => {
          return (
            <MenuItem value={round.id} key={i}>
              {round.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const renderSelectDriver = (params) => {
    return <SelectDriverInputCell {...params} />;
  };
  const SelectDriverInputCell = (params) => {
    const { id, value, field } = params;
    const apiRef = useGridApiContext();
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        fullWidth
        value={value}
        onChange={handleChange}
        renderValue={() =>
          props.drivers.find((driver) => driver.id === (value?.id ?? value))
            .name
        }
      >
        {props.drivers.map((driver, i) => {
          return (
            <MenuItem value={driver.id} key={i}>
              {driver.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "state",
      headerName: "Prise de commande",
      headerAlign: "center",
      align: "center",
      renderCell: renderState,
    },
    {
      field: "rank",
      headerName: "Rang",
      headerAlign: "center",
      align: "center",
      editable: true,
      type: "number",
    },
    {
      field: "name",
      headerName: "Convive",
      flex: 1,
    },
    {
      field: "round",
      headerName: "Tournée",
      flex: 1,
      editable: true,
      renderCell: ({ value }) => value.name,
      renderEditCell: renderSelectRound,
    },
    {
      field: "driver",
      headerName: "Driver",
      flex: 1,
      editable: true,
      renderCell: ({ value }) => (value ? value.name : ""),
      renderEditCell: renderSelectDriver,
    },
    {
      field: "updatedAt",
      headerName: "MàJ",
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) =>
        value ? format(new Date(value), "dd/MM/yyyy HH:mm") : "",
    },
  ];
  const handleCallbackRecipients = (recipients) => {
    setRecipients(recipients);
  };
  const processRowUpdate = useCallback(async (newRow) => {
    const response = await props.handleUpdateFromList(newRow.id, {
      round: `/rounds/${newRow.round?.id ?? newRow.round}`,
      rank: Number(newRow.rank),
      driver: newRow.driver
        ? `/drivers/${newRow.driver?.id ?? newRow.driver}`
        : null,
    });
    filterFormik.handleSubmit();
    return response;
  }, []);

  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          loading={props.loading}
          variant={"contained"}
          onClick={props.toggleList}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </LoadingButton>
      </Box>

      <Paper className={classes.paper} elevation={6}>
        <Autocomplete
          value={selectedClient}
          options={clients.sort((a, b) => -b.filtre.localeCompare(a.filtre))}
          isOptionEqualToValue={(option, value) =>
            option.client.id === value.client.id
          }
          groupBy={(value) => value.filtre}
          getOptionLabel={(option) => {
            if (!option.client) return "";
            return option.client.id + " - " + option.client.name;
          }}
          onChange={(event, value) => {
            setSelectedClient(value);
            props.fetchRecipients(
              value.client.id,
              null,
              handleCallbackRecipients
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label="Client" disabled={props.loading} />
          )}
        />
      </Paper>

      <Paper className={classes.paper} elevation={6}>
        <Stack spacing={2}>
          <Grid container spacing={1}>
            <Grid item container spacing={1} xs={12} lg={9}>
              <Grid item xs={12} md>
                <Autocomplete
                  options={recipientsList.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.recipient.id === value.recipient.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.recipient) return "";
                    return option.recipient.id + " - " + option.recipient.name;
                  }}
                  value={selectedRecipient}
                  onChange={(event, value) => {
                    setSelectedRecipient(value);

                    value !== null
                      ? filterFormik.setFieldValue(
                          "recipient",
                          value.recipient.id
                        )
                      : filterFormik.setFieldValue("recipient", null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Convive" size="small" />
                  )}
                />
              </Grid>

              <Grid item xs={12} md>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="round">Tournée</InputLabel>
                  <Select
                    labelId="round"
                    id="round"
                    label="Tournée"
                    value={filterFormik.values.round}
                    onChange={(event) =>
                      filterFormik.setFieldValue(
                        "round",
                        event.target.value === "none" ? "" : event.target.value
                      )
                    }
                  >
                    <MenuItem value="none">
                      <i>Aucune</i>
                    </MenuItem>

                    {rounds
                      .sort((a, b) => -b.name.localeCompare(a.name))
                      .map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="state">Statut</InputLabel>
                  <Select
                    labelId="state"
                    id="state"
                    label="Statut"
                    value={filterFormik.values.state}
                    onChange={(event) =>
                      filterFormik.setFieldValue(
                        "state",
                        event.target.value === "none" ? "" : event.target.value
                      )
                    }
                  >
                    <MenuItem value="none">
                      <i>Aucun</i>
                    </MenuItem>

                    {Object.values(RECIPIENT_STATE).map((value) => (
                      <MenuItem key={value.status} value={value.status}>
                        {value.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container spacing={1} alignItems="center" xs={12} lg={3}>
              <Grid item xs>
                <LoadingButton
                  loading={props.loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={filterFormik.handleSubmit}
                >
                  Valider
                </LoadingButton>
              </Grid>

              <Grid item xs>
                <LoadingButton
                  loading={props.loading}
                  fullWidth
                  variant="text"
                  size="small"
                  onClick={() => {
                    filterFormik.resetForm();
                    props.fetchRecipients(
                      selectedClient.client.id,
                      null,
                      handleCallbackRecipients
                    );
                  }}
                >
                  Réinitialiser
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>

          <DataGrid
            autoHeight
            columns={columns}
            rows={recipients}
            pageSize={50}
            density="comfortable"
            disableSelectionOnClick
            pagination
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={props.loading}
            experimentalFeatures={{ newEditingApi: true }}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
            editMode="row"
            processRowUpdate={processRowUpdate}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default memo(RecipientsUpdateList);
