import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from "recharts";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { colors } from "./FormulesChart";
import { getDateFromFilters } from "./Turnover";

const AverageBasket = (props) => {
  const classes = UseStyle();

  return (
    <Paper className={classes.paper} elevation={3}>
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Panier moyen {getDateFromFilters(props.filters)}
        </Typography>

        <LineChart
          width={props.isOpen ? 390 : 560}
          height={280}
          data={props.data}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="date" scale="band" />
          <YAxis width={30} />
          <Tooltip />
          <Legend />
          {props.selectedClients.map((client, i) => (
            <Line key={i} dataKey={client.name} stroke={colors[i]} />
          ))}
        </LineChart>
      </Stack>
    </Paper>
  );
};

export default AverageBasket;
