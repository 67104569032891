import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Collapse,
  Switch
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { RECIPIENT_POSITION, RECIPIENT_STATE } from "../Common/Enums/Enums";
import { locale, mask } from "../../../localisation";
import PlacesAutocomplete from "../Common/PlacesAutocomplete/PlacesAutocomplete";
import { days } from "../Common/days";
import { isAnyAdmin } from "../../../utils";

const RecipientForm = (props) => {
  const [showGPSForm, setShowGPSForm] = useState(false);
  
  return (
    <Grid container spacing={2} alignItems={"center"}>
      {props.client.mode === "repertory" && (
        <>
          <Grid item xs={12}>
            <Divider>INFORMATIONS PERSONNELLES</Divider>
          </Grid>

          <Grid item xs={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Genre</InputLabel>
              <Select
                name={"gender"}
                label="Genre"
                value={props.values.gender}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.gender && props.touched.gender ? true : false
                }
              >
                <MenuItem value={"f"}>Madame</MenuItem>
                <MenuItem value={"h"}>Monsieur</MenuItem>
                <MenuItem value={"a"}>Autre</MenuItem>
              </Select>
              <FormHelperText error>
                {props.errors.gender &&
                  props.touched.gender &&
                  props.errors.gender}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={10}>
            <FormControl variant="outlined" fullWidth required>
              <TextField
                required
                fullWidth
                name={"name"}
                label={"NOM Prénom"}
                type={"text"}
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.name && props.touched.name ? true : false}
                {...(props.errors.name &&
                  props.touched.name && { helperText: props.errors.name })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={8}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                name={"email"}
                label={"Email"}
                type={"email"}
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.touched.email && Boolean(props.errors.email)}
                helperText={
                  props.touched.email &&
                  props.errors.email &&
                  props.errors.email
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={
                props.errors.birthdate && props.touched.birthdate ? true : false
              }
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DesktopDatePicker
                  mask={mask["fr"]}
                  label="Date d'anniversaire"
                  inputFormat="dd/MM/yyyy"
                  value={props.values.birthdate}
                  onChange={(value) => props.setFieldValue("birthdate", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name={"birthdate"}
                      error={
                        props.errors.birthdate && props.touched.birthdate
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {props.errors.birthdate &&
                  props.touched.birthdate &&
                  "Date invalide"}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <PlacesAutocomplete
              query={props.values.address.unparsed}
              label="Adresse"
              fullWidth={true}
              callback={(data) => {
                data.notes = props.values.address.notes;
                props.setFieldValue("address", data);
              }}
              error={props.touched.address && Boolean(props.errors.address)}
              helpertext={
                props.errors.address &&
                props.touched.address &&
                props.errors.address &&
                props.errors.address.unparsed
              }
            />
          </Grid>

          <Grid item xs={11} md={5}>
            <TextField
              fullWidth
              name="address.addressSupplement"
              label="Compléments d'adresse"
              value={props.values.address?.addressSupplement}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid>

          <Grid item container direction="column" alignItems="center" xs={1}>
            <Typography textAlign="center">GPS ?</Typography>
            <Switch
              checked={showGPSForm}
              onChange={() => setShowGPSForm((prev) => !prev) }
              disabled={!(props.values.address?.unparsed && props.values.address?.unparsed !== "")}
            />
          </Grid>

          <Grid item xs={12}>
            <Collapse in={showGPSForm}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="address.latitude"
                    label="Latitude"
                    value={props.values.address?.latitude}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="address.longitude"
                    label="Longitude"
                    value={props.values.address?.longitude}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              name="address.notes"
              label={"Note de livraison"}
              value={props.values.address?.notes}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            {props.values.contacts && props.values.contacts.length > 0 ? (
              <Grid container item spacing={2}>
                {props.values.contacts.map((contact, key) => (
                  <Grid
                    container
                    item
                    spacing={2}
                    sx={{ alignItems: "center" }}
                    key={key}
                  >
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        name={`contacts${key}`}
                        label={`Nom du contact #${key + 1}`}
                        value={props.values.contacts[key].nomContact}
                        onChange={(event) =>
                          props.setFieldValue(
                            `contacts[${key}].nomContact`,
                            event.target.value
                          )
                        }
                        onBlur={props.handleBlur}
                        error={
                          props.errors.contacts &&
                          props.touched.contacts &&
                          props.errors.contacts[key] &&
                          props.touched.contacts[key] &&
                          props.errors.contacts[key].nomContact &&
                          props.touched.contacts[key].nomContact
                            ? true
                            : false
                        }
                        {...(props.errors.contacts &&
                          props.touched.contacts &&
                          props.errors.contacts[key] &&
                          props.touched.contacts[key] &&
                          props.errors.contacts[key].nomContact &&
                          props.touched.contacts[key].nomContact && {
                            helperText: props.errors.contacts[key].nomContact,
                          })}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        name={`telephoneContact${key}`}
                        label={`Téléphone du contact #${key + 1}`}
                        type={"tel"}
                        value={props.values.contacts[key].telephoneContact}
                        onChange={(event) =>
                          props.setFieldValue(
                            `contacts[${key}].telephoneContact`,
                            event.target.value
                          )
                        }
                        onBlur={props.handleBlur}
                        error={
                          props.errors.contacts &&
                          props.touched.contacts &&
                          props.errors.contacts[key] &&
                          props.touched.contacts[key] &&
                          props.errors.contacts[key].telephoneContact &&
                          props.touched.contacts[key].telephoneContact
                            ? true
                            : false
                        }
                        {...(props.errors.contacts &&
                          props.touched.contacts &&
                          props.errors.contacts[key] &&
                          props.touched.contacts[key] &&
                          props.errors.contacts[key].telephoneContact &&
                          props.touched.contacts[key].telephoneContact && {
                            helperText:
                              props.errors.contacts[key].telephoneContact,
                          })}
                      />
                    </Grid>

                    <Grid item>
                      {key === props.values.contacts.length - 1 ? (
                        <>
                          <IconButton
                            aria-label="add"
                            onClick={(event) =>
                              props.setFieldValue("contacts", [
                                ...props.values.contacts,
                                {
                                  nomContact: "",
                                  telephoneContact: "",
                                },
                              ])
                            }
                          >
                            <AddCircleIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : null}
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          props.setFieldValue(
                            "contacts",
                            props.values.contacts.filter(
                              (contact, index) => key !== index
                            )
                          )
                        }
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid
                container
                item
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item>
                  <Button
                    color="primary"
                    onClick={() =>
                      props.setFieldValue("contacts", [
                        {
                          nomContact: "",
                          telephoneContact: "",
                        },
                      ])
                    }
                    variant="outlined"
                  >
                    Ajouter un contact
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Origine</InputLabel>
              <Select
                name={"origin"}
                label="Origine"
                value={props.values.origin}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.origin && props.touched.origin ? true : false
                }
                {...(props.errors.origin &&
                  props.touched.origin && {
                    helperText: props.errors.origin,
                  })}
              >
                <MenuItem value="none">
                  <i>Aucune</i>
                </MenuItem>
                {props.origins.map((origin, i) => (
                  <MenuItem key={i} value={origin.id}>
                    {origin.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {props.errors.origin &&
                  props.touched.origin &&
                  props.errors.origin}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6} justifyContent="center" alignItems="center">
            <FormGroup>
              <FormControlLabel
                name={"hasNewsletterSubscribed"}
                checked={props.values.hasNewsletterSubscribed}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                control={<Checkbox />}
                label="Newsletter ?"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider>FACTURATION</Divider>
          </Grid>

          <Grid item xs={5}>
            <PlacesAutocomplete
              query={props.values.billingAddress.unparsed}
              label="Adresse"
              fullWidth={true}
              callback={(data) => {
                data.notes = props.values.billingAddress.notes;
                props.setFieldValue("billingAddress", data);
              }}
              error={
                props.touched.billingAddress &&
                Boolean(props.errors.billingAddress)
              }
              helpertext={
                props.errors.billingAddress &&
                props.touched.billingAddress &&
                props.errors.billingAddress &&
                props.errors.billingAddress.unparsed
              }
            />
          </Grid>

          <Grid item xs={5}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                name={"billingEmail"}
                label={"Email de facturation"}
                type={"email"}
                value={props.values.billingEmail}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.touched.billingEmail &&
                  Boolean(props.errors.billingEmail)
                }
                helperText={
                  props.touched.billingEmail &&
                  props.errors.billingEmail &&
                  props.errors.billingEmail
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormGroup>
              <FormControlLabel
                name="wantsInvoicesByEmail"
                checked={props.values.wantsInvoicesByEmail}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                control={<Checkbox />}
                label="Envoyer les factures par email ?"
              />
            </FormGroup>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Divider>DÉTAILS DU CONVIVE</Divider>
      </Grid>

      {props.client.mode === "repertory" && (
        <>
          <Grid item xs={8}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Statut du convive</InputLabel>
              <Select
                name={"state"}
                label="Statut du convive"
                value={props.values.position}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.touched.position && Boolean(props.errors.position)}
              >
                {Object.values(RECIPIENT_POSITION).map((s, index) => (
                  <MenuItem key={index} value={s.status}>
                    {s.description}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {props.errors.position &&
                  props.touched.position &&
                  props.errors.position}
              </FormHelperText>
            </FormControl>
          </Grid>

          {isAnyAdmin(props.userData) && (
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="totalTtc">Rang OnFleet</InputLabel>
                <OutlinedInput
                  id="rank"
                  value={props.values.rank}
                  onChange={props.handleChange}
                  label="Rang OnFleet"
                  type="number"
                />
              </FormControl>
            </Grid>
          )}
        </>
      )}

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Statut sur la prise de commande</InputLabel>
          <Select
            name={"state"}
            label="Statut sur la prise de commande"
            value={props.values.state}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={props.touched.state && Boolean(props.errors.state)}
          >
            {Object.values(RECIPIENT_STATE).map((s, index) => (
              <MenuItem key={index} value={s.status}>
                {s.description}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {props.errors.state && props.touched.state && props.errors.state}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Tournée</InputLabel>
          <Select
            name={"round"}
            label="Tournée"
            value={props.values.round}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={props.touched.round && Boolean(props.errors.round)}
          >
            {props.client.rounds.map((round, i) => {
              return (
                <MenuItem value={round.id} key={i}>
                  {round.name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText error>
            {props.errors.round && props.touched.round && props.errors.round}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth required>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale["fr"]}
          >
            <DesktopDatePicker
              mask={mask["fr"]}
              label="Date de début de consommation"
              inputFormat="dd/MM/yyyy"
              value={props.values.startedAt}
              onChange={(value) => props.setFieldValue("startedAt", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={
                    props.touched.startedAt && Boolean(props.errors.startedAt)
                  }
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText error>
            {props.errors.startedAt &&
              props.touched.startedAt &&
              "Date invalide"}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale["fr"]}
          >
            <DesktopDatePicker
              mask={mask["fr"]}
              label="Date de fin de consommation"
              inputFormat="dd/MM/yyyy"
              value={props.values.cancelledAt}
              onChange={(value) => props.setFieldValue("cancelledAt", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={
                    props.touched.cancelledAt &&
                    Boolean(props.errors.cancelledAt)
                  }
                />
              )}
            />
          </LocalizationProvider>
          <FormHelperText error>
            {props.errors.cancelledAt &&
              props.touched.cancelledAt &&
              "Date invalide"}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={props.client.mode === "diets" ? 12 : 6}>
        <FormControl variant="outlined" fullWidth>
          {props.client.mode === "diets" ? (
            <>
              <InputLabel id="diet-label">Régime (non modifiable)</InputLabel>
              <Select
                disabled
                labelId="diet-label"
                label="Régime (non modifiable)"
                value={props.values.diet}
                onChange={(event) =>
                  props.setFieldValue("diet", event.target.value)
                }
                onBlur={props.handleBlur}
              >
                <MenuItem value="none">
                  <i>Sans régime</i>
                </MenuItem>

                {props.diets.map((diet, i) => (
                  <MenuItem key={i} value={diet.id}>
                    {diet.nom}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <>
              <InputLabel id="diets-label">Régimes</InputLabel>
              <Select
                fullWidth
                multiple
                labelId="diets-label"
                id="diets"
                value={props.values.diets}
                onChange={(event) =>
                  props.setFieldValue("diets", event.target.value)
                }
                input={<OutlinedInput label="Régimes" />}
                renderValue={(selected) =>
                  selected
                    .map((s) => props.diets.find((r) => r.id === s).nom)
                    .join(", ")
                }
              >
                {props.diets.map((diet, i) => {
                  return (
                    <MenuItem value={diet.id} key={i}>
                      <Checkbox
                        checked={props.values.diets.includes(diet.id)}
                      />
                      <ListItemText primary={diet.nom} />
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          )}
        </FormControl>
      </Grid>

      {props.client.mode === "repertory" && (
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="allergens-label">Allergènes</InputLabel>
            <Select
              fullWidth
              multiple
              labelId="allergens-label"
              id="allergens"
              value={props.values.allergens}
              onChange={(event) =>
                props.setFieldValue("allergens", event.target.value)
              }
              input={<OutlinedInput label="Allergènes" />}
              renderValue={(selected) =>
                selected
                  .map((s) => props.allergens.find((a) => a.id === s).nom)
                  .join(", ")
              }
            >
              {props.allergens.map((allergene, i) => (
                <MenuItem value={allergene.id} key={i}>
                  <Checkbox
                    checked={props.values.allergens.includes(allergene.id)}
                  />
                  <ListItemText primary={allergene.nom} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Formule déjeuner</InputLabel>
          <Select
            name={"lunchMenu"}
            label="Formule déjeuner"
            value={props.values.lunchMenu}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={
              props.errors.lunchMenu && props.touched.lunchMenu ? true : false
            }
            {...(props.errors.lunchMenu &&
              props.touched.lunchMenu && {
                helperText: props.errors.lunchMenu,
              })}
          >
            {props.client.packages
              .filter((formule) => !formule.estDiner)
              .map((formule, i) => {
                return (
                  <MenuItem value={formule.id} key={i}>
                    {formule.name}
                  </MenuItem>
                );
              })}
          </Select>
          <FormHelperText error>
            {props.errors.lunchMenu &&
              props.touched.lunchMenu &&
              props.errors.lunchMenu}
          </FormHelperText>
        </FormControl>
      </Grid>

      {props.formules.filter((formule) => formule.estDiner).length > 0 && (
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Formule dîner</InputLabel>
            <Select
              name={"dinnerMenu"}
              label="Formule dîner"
              value={props.values.dinnerMenu}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            >
              <MenuItem value="none">
                <i>Aucune</i>
              </MenuItem>

              {props.client.packages
                .filter((formule) => formule.estDiner)
                .map((formule, i) => (
                  <MenuItem value={formule.id} key={i}>
                    {formule.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {props.client.mode === "repertory" && (
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="extras-lunch-label">
              Suppléments déjeuner
            </InputLabel>

            <Select
              fullWidth
              multiple
              labelId="extras-lunch-label"
              id="lunchExtras"
              value={props.values.lunchExtras}
              onChange={(event) =>
                props.setFieldValue("lunchExtras", event.target.value)
              }
              input={<OutlinedInput label="Suppléments déjeuner" />}
              renderValue={(selected) =>
                selected
                  .map(
                    (s) =>
                      props.categories.find((category) => category.id === s)
                        .name
                  )
                  .join(", ")
              }
            >
              {props.categories.map((category, i) => (
                <MenuItem value={category.id} key={i}>
                  <Checkbox
                    checked={props.values.lunchExtras.includes(category.id)}
                  />
                  <ListItemText primary={category.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {props.client.mode === "repertory" &&
        props.values.dinnerMenu !== "none" && (
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="extras-dinner-label">
                Suppléments dîner
              </InputLabel>

              <Select
                fullWidth
                multiple
                labelId="extras-dinner-label"
                id="dinnerExtras"
                value={props.values.dinnerExtras}
                onChange={(event) =>
                  props.setFieldValue("dinnerExtras", event.target.value)
                }
                input={<OutlinedInput label="Suppléments dîner" />}
                renderValue={(selected) =>
                  selected
                    .map(
                      (s) =>
                        props.categories.find((category) => category.id === s)
                          .name
                    )
                    .join(", ")
                }
              >
                {props.categories.map((category, i) => (
                  <MenuItem value={category.id} key={i}>
                    <Checkbox
                      checked={props.values.dinnerExtras.includes(category.id)}
                    />
                    <ListItemText primary={category.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

      {props.client.mode === "repertory" ? (
        <Grid
          item
          container
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <FormControl component="fieldset" required>
            <FormLabel component="legend">Jours de repas</FormLabel>
            <FormGroup aria-label="position" row>
              {days.map((jour, index) => (
                <FormControlLabel
                  key={index}
                  checked={props.values.mealDays.includes(jour.value)}
                  onChange={(event) => {
                    event.target.checked
                      ? props.setFieldValue("mealDays", [
                          ...props.values.mealDays,
                          jour.value,
                        ])
                      : props.setFieldValue(
                          "mealDays",
                          props.values.mealDays.filter(
                            (mealDay) => mealDay !== jour.value
                          )
                        );
                  }}
                  control={<Checkbox />}
                  label={jour.day}
                  labelPlacement="top"
                />
              ))}
            </FormGroup>
            <FormHelperText error>
              {props.errors.mealDays &&
                props.touched.mealDays &&
                props.errors.mealDays}
            </FormHelperText>
          </FormControl>
        </Grid>
      ) : (
        <Grid
          item
          container
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={1}
        >
          <Grid item>
            <Typography variant="subtitle1" color="text.secondary">
              Quantité par jour de repas
            </Typography>
          </Grid>

          {props.values.mealDays.map((mealDay, i) => {
            const day = days.find((day) => day.value === mealDay.day);

            return (
              <Grid item>
                <TextField
                  key={i}
                  size="small"
                  label={day.day}
                  type="number"
                  value={mealDay.qty}
                  onChange={(event) => {
                    event.target.value !== ""
                      ? props.setFieldValue(
                          `mealDays[${i}].qty`,
                          parseInt(event.target.value)
                        )
                      : props.setFieldValue(`mealDays[${i}].qty`, 0);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  sx={{ maxWidth: 80 }}
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            fullWidth
            multiline
            rows={3}
            name={"dietNote"}
            label={"Note régime"}
            value={props.values.dietNote}
            type={"text"}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            multiline
            rows={3}
            name={"recipientNote"}
            label={"Note convive"}
            value={props.values.recipientNote}
            type={"text"}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            fullWidth
            multiline
            rows={3}
            name={"adminNote"}
            label={"Note admin"}
            value={props.values.adminNote}
            type={"text"}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default RecipientForm;
