import React from "react";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import {
  Card,
  Divider,
  CardHeader,
  Grid,
  Paper,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OrderForm from "../OrderForm";
import { isAnyAdmin } from "../../../../utils";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          loading={props.loading}
          variant={"contained"}
          onClick={props.toggleList}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </LoadingButton>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Modification d'une commande"
        />
      </Card>

      <Paper className={classes.paper} elevation={6}>
        <Stack divider={<Divider flexItem />} spacing={3} alignItems={"center"}>
          {isAnyAdmin(props.userData) && (
            <TextField
              sx={{ width: 500 }}
              label="Client"
              defaultValue={props.orderEdit.organization.name}
              InputProps={{
                readOnly: true,
              }}
            />
          )}

          <OrderForm {...props} />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Edit;
