import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Filters from "../../Common/Filters";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import DrawerInformations from "../DrawerInformations";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LoadingButton from "@mui/lab/LoadingButton";
import DescriptionIcon from "@mui/icons-material/Description";
import UpdateIcon from "@mui/icons-material/Update";
import CachedIcon from "@mui/icons-material/Cached";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import OfflinePinRoundedIcon from "@mui/icons-material/OfflinePinRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { isAdmin } from "../../../../utils";
import { locale, mask } from "../../../../localisation";
import { toFixedTrunc } from "../../Common/toFixedTrunc";

const GenerateInvoicesDialog = ({
  loading,
  isGenerating,
  handleClose,
  handleGenerate,
}) => {
  const formik = useFormik({
    initialValues: {
      date: new Date(),
    },
    validationSchema: Yup.object({
      date: Yup.date().required().typeError("Date invalide"),
    }),
    onSubmit: (values) => {
      handleGenerate(values.date, "recipient");
      handleClose();
    },
  });

  return (
    <Dialog open={isGenerating} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        Génération des factures.
        <DialogContentText>
          Selectionner un mois pour générer les factures.
        </DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={formik.errors.date && formik.touched.date ? true : false}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  views={["month", "year"]}
                  label="Choississez le mois"
                  inputFormat="MM/yyyy"
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </FormHelperText>
            </FormControl>
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<CachedIcon />}
              onClick={formik.handleSubmit}
            >
              Générer
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};
const ExportDialog = ({
  loading,
  isOpen,
  handleClose,
  validation,
  isFileDownloaded,
  clients,
}) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const formik = useFormik({
    initialValues: {
      mode: "zip",
      date: new Date(),
      client: null,
    },
    validationSchema: Yup.object({
      date: Yup.date().required().typeError("Date invalide"),
    }),
    onSubmit: (values, { actions }) => {
      validation(values.mode, values.date, values.client, "recipient");
      actions.resetForm();
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        formik.handleReset();
        setSelectedClient(null);
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        Exporter des données.
        <DialogContentText>
          Selectionnez un mode et remplissez les différents champs afin
          d'exporter les données souhaitées.
        </DialogContentText>
      </DialogTitle>

      <form>
        <DialogContent dividers>
          <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <FormControl>
              <FormLabel id="export-mode-group">Mode</FormLabel>
              <RadioGroup
                row
                aria-labelledby="export-mode-group"
                name="row-radio-buttons-group"
                value={formik.values.mode}
                onChange={(event) =>
                  formik.setFieldValue("mode", event.target.value)
                }
              >
                <FormControlLabel
                  value="zip"
                  control={<Radio />}
                  label="Factures (.zip)"
                />
                <FormControlLabel
                  value="excel"
                  control={<Radio />}
                  label="Factures (.xlsx)"
                />
              </RadioGroup>
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              required
              error={formik.errors.date && formik.touched.date ? true : false}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  views={["month", "year"]}
                  label="Choississez le mois"
                  inputFormat="MM/yyyy"
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </FormHelperText>
            </FormControl>

            {formik.values.mode === "excel" && (
              <Autocomplete
                fullWidth
                options={clients.sort(
                  (a, b) => -b.filtre.localeCompare(a.filtre)
                )}
                isOptionEqualToValue={(option, value) =>
                  option.client.id === value.client.id
                }
                groupBy={(value) => value.filtre}
                getOptionLabel={(option) => {
                  if (!option.client) return "";
                  return option.client.id + " - " + option.client.name;
                }}
                value={selectedClient}
                onChange={(event, value) => {
                  setSelectedClient(value);

                  value !== null
                    ? formik.setFieldValue("client", value.client.id)
                    : formik.setFieldValue("client", null);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Client (facultatif)" />
                )}
              />
            )}
          </Stack>
        </DialogContent>
      </form>

      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            {isFileDownloaded.returnValue && (
              <Stack direction={"row"} spacing={1}>
                <OfflinePinRoundedIcon color="sucess" />
                <Typography variant="body1">
                  {toFixedTrunc(isFileDownloaded.loaded / (1024 * 1024), 2)} Mo
                  téléchargés en{" "}
                  {toFixedTrunc(isFileDownloaded.timeStamp / 1000 / 60, 2)}{" "}
                  minutes
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={4}>
            <Stack direction={"row"} spacing={2}>
              <Button
                {...(loading && { disabled: true })}
                onClick={() => {
                  handleClose();
                  formik.handleReset();
                  setSelectedClient(null);
                }}
              >
                Annuler
              </Button>
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                startIcon={<FileDownloadRoundedIcon />}
                onClick={formik.handleSubmit}
              >
                Exporter
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
const EmailDialog = (props) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  const formik = useFormik({
    initialValues: {
      date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      client: null,
      recipient: null,
      round: "",
      mode: "all",
    },
    validationSchema: Yup.object({
      date: Yup.date().required().typeError("Date invalide"),
      client: Yup.number()
        .nullable()
        .when("mode", {
          is: "client",
          then: Yup.number().required().typeError("Champs obligatoire"),
        }),
      recipient: Yup.number()
        .nullable()
        .when("mode", {
          is: "recipient",
          then: Yup.number().required().typeError("Champs obligatoire"),
        }),
      round: Yup.number()
        .nullable()
        .when("mode", {
          is: "round",
          then: Yup.number().required().typeError("Champs obligatoire"),
        }),
    }),
    onSubmit: (values, { actions }) => {
      props.handleSendEmails(
        values.date,
        values.mode,
        values.client,
        values.recipient,
        values.round
      );
      actions.resetForm();
      setSelectedClient(null);
    },
  });

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle textAlign="center">
        Confirmez-vous l'envoie des factures de{" "}
        <u>
          {formik.values.date
            .toLocaleString("fr-FR", { month: "long" })
            .toUpperCase()}
        </u>{" "}
        par mail ?
        <br />
        <br />
        <Typography variant="caption" fontSize={16} textAlign="center">
          <i>
            L'envoie se fait sur l'adresse email de facturation du convive si
            celle-ci est renseignée. Sinon, sur l'adresse email de contact.
          </i>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          padding={1}
        >
          <FormControl
            variant="outlined"
            fullWidth
            required
            error={formik.touched.date && Boolean(formik.errors.date)}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale["fr"]}
            >
              <DatePicker
                mask={mask["fr"]}
                views={["month", "year"]}
                label="Choississez le mois"
                inputFormat="MM/yyyy"
                value={formik.values.date}
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue("date", value)}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
            <FormHelperText error>
              {formik.errors.date && formik.touched.date && formik.errors.date}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel id="export-mode-group">Envoie par</FormLabel>
            <RadioGroup
              row
              aria-labelledby="export-mode-group"
              name="row-radio-buttons-group"
              value={formik.values.mode}
              onChange={(event) => {
                formik.setFieldValue("mode", event.target.value);
                if (event.target.value === "all") {
                  formik.setFieldValue("client", null);
                  formik.setFieldValue("recipient", null);
                  formik.setFieldValue("round", "");
                  setSelectedClient(null);
                  setSelectedRecipient(null);
                } else if (event.target.value === "client") {
                  formik.setFieldValue("recipient", null);
                  formik.setFieldValue("round", "");
                  setSelectedRecipient(null);
                } else if (event.target.value === "recipient") {
                  formik.setFieldValue("client", null);
                  formik.setFieldValue("round", "");
                  setSelectedClient(null);
                } else if (event.target.value === "round") {
                  formik.setFieldValue("client", null);
                  formik.setFieldValue("recipient", null);
                  setSelectedClient(null);
                  setSelectedRecipient(null);
                }
              }}
            >
              <FormControlLabel value="all" control={<Radio />} label="Tout" />
              <FormControlLabel
                value="client"
                control={<Radio />}
                label="Client"
              />
              <FormControlLabel
                value="recipient"
                control={<Radio />}
                label="Convive"
              />
              <FormControlLabel
                value="round"
                control={<Radio />}
                label="Tournée"
              />
            </RadioGroup>
          </FormControl>

          {formik.values.mode === "client" ? (
            <Autocomplete
              fullWidth
              disabled={formik.values.all}
              options={props.clients.sort(
                (a, b) => -b.filtre.localeCompare(a.filtre)
              )}
              isOptionEqualToValue={(option, value) =>
                option.client.id === value.client.id
              }
              groupBy={(value) => value.filtre}
              getOptionLabel={(option) => {
                if (!option.client) return "";
                return option.client.id + " - " + option.client.name;
              }}
              value={selectedClient}
              onChange={(event, value) => {
                setSelectedClient(value);

                value !== null
                  ? formik.setFieldValue("client", value.client.id)
                  : formik.setFieldValue("client", null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  error={formik.touched.client && Boolean(formik.errors.client)}
                  helperText={
                    formik.errors.client &&
                    formik.touched.client &&
                    formik.errors.client
                  }
                />
              )}
            />
          ) : formik.values.mode === "recipient" ? (
            <Autocomplete
              fullWidth
              options={props.recipients.sort(
                (a, b) => -b.filtre.localeCompare(a.filtre)
              )}
              isOptionEqualToValue={(option, value) =>
                option.recipient.id === value.recipient.id
              }
              groupBy={(value) => value.filtre}
              getOptionLabel={(option) => {
                if (!option.recipient) return "";
                return option.recipient.id + " - " + option.recipient.name;
              }}
              value={selectedRecipient}
              onChange={(event, value) => {
                setSelectedRecipient(value);

                value !== null
                  ? formik.setFieldValue("recipient", value.recipient.id)
                  : formik.setFieldValue("recipient", null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Convive"
                  error={
                    formik.touched.recipient && Boolean(formik.errors.recipient)
                  }
                  helperText={
                    formik.errors.recipient &&
                    formik.touched.recipient &&
                    formik.errors.recipient
                  }
                />
              )}
            />
          ) : formik.values.mode === "round" ? (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="round">Tournée</InputLabel>
              <Select
                labelId="round"
                id="round"
                label="Tournée"
                value={formik.values.round}
                onChange={(event) =>
                  formik.setFieldValue("round", event.target.value)
                }
              >
                {props.rounds
                  .sort((a, b) => -b.name.localeCompare(a.name))
                  .map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error>
                {formik.errors.round &&
                  formik.touched.round &&
                  formik.errors.round}
              </FormHelperText>
            </FormControl>
          ) : (
            <></>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={props.loading}
          onClick={props.handleClose}
          color="primary"
        >
          Annuler
        </LoadingButton>
        <LoadingButton
          loading={props.loading}
          onClick={formik.handleSubmit}
          color="primary"
          variant="contained"
          startIcon={<SendRoundedIcon />}
          autoFocus
        >
          Envoyer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const RecipientsList = (props) => {
  const classes = UseStyle();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [confirmSendEmail, setConfirmSendEmail] = useState(false);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Bénéficaire",
      flex: 1,
    },
    {
      field: "organization",
      headerName: "Client",
      flex: 1,
      valueGetter: ({ value }) => value.name,
    },
    {
      headerName: "Dernière facture le",
      flex: 0.7,
      type: "dateTime",
      valueGetter: (params) => {
        let date = "";

        if (params.row.invoices.length > 0) {
          date = new Date(
            params.row.invoices[params.row.invoices.length - 1]?.updatedAt ??
              params.row.invoices[params.row.invoices.length - 1]?.createdAt
          );
        }

        return date;
      },
    },
    {
      field: "email",
      headerName: "État des factures",
      flex: 0.7,
      renderCell: (params) => {
        const totalNotSent = params.row.invoices.filter(
          (invoice) => !invoice.isEmailSent
        );

        return totalNotSent.length > 0
          ? totalNotSent.length + " factures non envoyées"
          : "";
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Afficher les factures">
              <DescriptionIcon />
            </Tooltip>
          }
          onClick={props.toggleDrawer(
            true,
            params.row.id,
            "invoice_recipients",
            "recipient"
          )}
          label="Afficher"
        />,
      ],
    },
  ];
  const handleClose = () => {
    setIsGenerating(false);
    setIsExporting(false);
    setConfirmSendEmail(false);
  };

  const clients = props.clients.map((client) => {
    const filtre = client.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: client,
    };
  });
  const recipients = props.recipients.map((recipient) => {
    const filtre = recipient.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      recipient: recipient,
    };
  });

  return (
    <Stack spacing={2} marginBottom={2}>
      {isAdmin(props.userData) && (
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Stack direction={"row"} spacing={1}>
              <Tooltip title="Générer les factures d'un mois sélectionné">
                <LoadingButton
                  loading={props.loading}
                  color="secondary"
                  variant="contained"
                  startIcon={<UpdateIcon />}
                  onClick={() => setIsGenerating(true)}
                >
                  Génération
                </LoadingButton>
              </Tooltip>

              <Tooltip title="Envoyer toutes les factures par mail">
                <LoadingButton
                  loading={props.loading}
                  color="secondary"
                  variant="contained"
                  startIcon={<SendRoundedIcon />}
                  onClick={() => setConfirmSendEmail(true)}
                >
                  Mail
                </LoadingButton>
              </Tooltip>
            </Stack>
          </Grid>

          <Grid item>
            <Stack direction={"row"} spacing={1}>
              <Button disabled>Import</Button>

              <Button onClick={() => setIsExporting(true)}>Export</Button>
            </Stack>
          </Grid>
        </Grid>
      )}

      <Filters
        userData={props.userData}
        types={["client", "recipient", "month", "bool"]}
        boolName="Est envoyé par email ?"
        from={props.from}
        recipients={props.recipients}
        clients={props.clients}
        handleSubmitFilters={props.handleSubmitFilters}
        loading={props.loading}
      />

      <Paper className={classes.paper} elevation={6}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={props.recipientsList}
          pageSize={30}
          getRowHeight={() => "auto"}
          disableSelectionOnClick
          pagination
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          loading={props.loading}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
        />
      </Paper>

      <Drawer
        anchor={"right"}
        open={props.open}
        recipient={props.recipient}
        onClose={props.toggleDrawer(false, null, null)}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <DrawerInformations
          {...props}
          data={props.recipient}
          invoices={props.invoices}
        />
      </Drawer>

      <GenerateInvoicesDialog
        loading={props.loading}
        isGenerating={isGenerating}
        handleClose={handleClose}
        handleGenerate={props.handleGenerate}
      />

      <ExportDialog
        loading={props.loading}
        isOpen={isExporting}
        handleClose={handleClose}
        validation={props.exportInvoices}
        isFileDownloaded={props.isFileDownloaded}
        clients={clients}
      />

      <EmailDialog
        loading={props.loading}
        isOpen={confirmSendEmail}
        handleSendEmails={props.handleSendEmails}
        handleClose={handleClose}
        userData={props.userData}
        clients={clients}
        recipients={recipients}
        rounds={props.rounds}
      />
    </Stack>
  );
};

export default RecipientsList;
