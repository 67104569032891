import React, { useEffect, useState } from "react";
import { requests } from "../../../agent";
import { format } from "date-fns";
import { ORDER_STATUS, DELIVERY_STATUS } from "../Common/Enums/Enums";
import { Snackbar, Alert } from "@mui/material";
import List from "./List/List";
import _superagent from "superagent";
import superagentPromise from "superagent-promise";
import { ENTRYPOINT } from "../../../config/entrypoint";
import { useSelector } from "react-redux";
import { isAdmin } from "../../../utils";

const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = (response) => response.body;
let token = null;
const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) request.set("Authorization", `Bearer ${token}`);
  };
};

const DeliveryContainer = (props) => {
  const userData = useSelector((state) => state.auth.userData);

  const [rowCount, setRowCount] = useState(undefined);
  const [deliveries, setDeliveries] = useState([]);
  const [delivery, setDelivery] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [diets, setDiets] = useState([]);
  const [clients, setClients] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setIsLoading(true);
    const promises = Promise.all([
      requests.get("/deliveries?order[date]=desc", true),
      requests.get("/recipients", true),
      requests.get("/regimes", true),
      requests.get("/organizations/lists", true),
      requests.get("/rounds", true),
    ]);

    promises
      .then((results) => {
        setDeliveries(results[0]["hydra:member"]);
        setRowCount(results[0]["hydra:totalItems"]);
        setRecipients(results[1]["hydra:member"]);
        setDiets(results[2]["hydra:member"]);
        setClients(results[3]["hydra:member"]);
        setRounds(
          isAdmin(userData)
            ? results[4]["hydra:member"]
            : userData.organization.rounds
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleDrawer = (hasClick, id, callback) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(true);
    setIsLoading(true);
    if (hasClick) {
      requests
        .get(`/deliveries/${id}`, true)
        .then((result) => {
          setDelivery(result);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsOpen(false);
          setIsLoading(false);
          setAlert({
            active: true,
            type: "error",
            message: err.message,
          });
        });
    } else {
      setIsOpen(false);
      setIsLoading(false);
      callback();
    }
  };
  const handleUpdateOrder = (event, id) => {
    event.preventDefault();
    setIsLoading(true);
    const orderStatus = { statut: ORDER_STATUS.PREPARED.status };

    requests
      .patch(`/commandes/${id}`, orderStatus, true)
      .then((response) => {
        // Mise à jour du state des commande de la livraison en question
        const updatedOrders = delivery.commandes.map((order) => {
          if (order.id !== id) return order;
          return response;
        });
        const updatedDelivery = { ...delivery, commandes: updatedOrders };

        // Mise à jour du state de la liste de toutes les livraisons
        const updatedDeliveries = deliveries.map((d) => {
          if (d.id !== updatedDelivery.id) return d;
          return updatedDelivery;
        });

        setDeliveries(updatedDeliveries);
        setDelivery(updatedDelivery);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "La commande est prête pour la livraison !",
        });
      })
      .catch((err) => {
        setIsOpen(false);
        setDelivery(null);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleUpdateOrders = (event, ids) => {
    event.preventDefault();
    setIsLoading(true);
    const iris = ids.map((id) => `/commandes/${id}`);
    const orders = {
      commandes: iris,
    };
    requests
      .post("/commandes/status", orders, true)
      .then((results) => {
        let updatedDelivery = {};
        let updatedDeliveries = [];
        // Mise à jour du state des commande de la livraison en question
        const updatedOrders = delivery.commandes.map((order) => {
          let updatedOrder = null;

          if (
            (updatedOrder = results["hydra:member"].find(
              (item) => item.id === order.id
            ))
          ) {
            return updatedOrder;
          }
          return order;
        });
        updatedDelivery = { ...delivery, commandes: updatedOrders };

        // Mise à jour du state de la liste de toutes les livraisons
        updatedDeliveries = deliveries.map((d) => {
          if (d.id !== updatedDelivery.id) return d;
          return updatedDelivery;
        });

        setDelivery(updatedDelivery);
        setDeliveries(updatedDeliveries);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les commandes est prête pour la livraison !",
        });
      })
      .catch((err) => {
        setIsOpen(false);
        setDelivery(null);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleUpdateDelivery = (id, data) => {
    setIsLoading(true);

    requests
      .patch(`/deliveries/${id}`, data, true)
      .then((response) => {
        const updatedDeliveries = deliveries.map((d) => {
          if (d.id !== response.id) return d;
          return response;
        });

        setDelivery(response);
        setDeliveries(updatedDeliveries);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le statut de la livraison a bien été modifié !",
        });
      })
      .catch((err) => {
        setIsOpen(false);
        setDelivery(null);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDownloadDeliveriesNote = (mode, date, clientId, all) => {
    setIsLoading(true);

    let url = "/deliveries/receipt";
    if (mode === "onfleet") url = "/deliveries/export/onfleet";
    else if (mode === "summary") url = "/deliveries/export/summary";

    superagent
      .get(
        `${ENTRYPOINT}${url}?date=${format(
          date,
          "yyyy-MM-dd"
        )}&status[]=pending_prep&status[]=pending_deliv&status[]=delivering&order[round.name]=asc&order[recipient.rank]=asc${
          all ? "" : "&organization.id=" + clientId
        }`
      )
      .use(tokenPlugin(true))
      .responseType("blob")
      .then(responseBody)
      .then((results) => {
        const fileUrl = URL.createObjectURL(results);
        const modePrefix =
          mode === "onfleet"
            ? "onfleet"
            : mode === "summary"
            ? "recap_prep"
            : "bons_livraison";
        const filename = `${modePrefix}_hulpo_${format(date, "yyyyMMdd")}.${
          mode === "onfleet" || mode === "summary" ? "xlsx" : "pdf"
        }`;

        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(fileUrl);
        setIsLoading(false);
      })
      .catch((error) => {
        let message = "";
        if (error.response) {
          // Si vous avez une réponse de serveur avec un statut d'erreur
          console.error(
            "Erreur de requête avec le statut :",
            error.response.status
          );
          console.error("Détail de l'erreur :", error.response.body);

          if (error.response.status === 500) {
            message = "Une erreur est survenue côté serveur.";
          }
        } else {
          // Si une erreur s'est produite lors de l'envoi de la requête
          console.error(
            "Erreur lors de l'envoi de la requête :",
            error.message
          );
          message = error.message;
        }
        setAlert({
          active: true,
          type: "error",
          message: message,
        });
        setIsLoading(false);
      });
  };
  const handleDownloadDeliveryNote = (id) => {
    setIsLoading(true);
    superagent
      .get(`${ENTRYPOINT}/deliveries/${id}/receipt`)
      .use(tokenPlugin(true))
      .responseType("blob")
      .then(responseBody)
      .then((results) => {
        const fileUrl = URL.createObjectURL(results);
        const filename = "bon_livraison.pdf";

        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(fileUrl);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const fetchDeliveries = ({ page, pageSize, filters }) => {
    setIsLoading(true);
    let dataUrl = "";
    if (filters.id && filters.id !== "") dataUrl += `id=${filters.id}&`;
    if (filters.recipient && filters.recipient !== null)
      dataUrl += `recipient.id=${filters.recipient}&`;
    if (filters.client && filters.client !== null)
      dataUrl += `organization.id=${filters.client}&`;
    if (filters.status && filters.status.length > 0)
      dataUrl += `status=${filters.status}&`;
    if (filters.date && filters.date !== null)
      dataUrl += `date=${format(filters.date, "yyyy-MM-dd")}&`;
    if (filters.round && filters.round !== "")
      dataUrl += `round.id=${filters.round}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(
        `/deliveries?order[date]=desc&${dataUrl}&page=${page}&pageSize=${pageSize}`,
        true
      )
      .then((result) => {
        setDeliveries(result["hydra:member"]);
        setRowCount(result["hydra:totalItems"]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const handleSubmitPreparedDeliveries = (
    date,
    status,
    clientId,
    all,
    callback
  ) => {
    setIsLoading(true);

    const data = {
      date: format(date, "yyyy-MM-dd"),
      state: status,
      client: clientId ? `/organizations/${clientId}` : null,
      all: all,
    };
    requests
      .post("/deliveries/multiple", data, true)
      .then((results) => {
        callback();
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les livraisons ont été mises à jours",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };

  return (
    <>
      <List
        userData={userData}
        isOpen={isOpen}
        isLoading={isLoading}
        deliveries={deliveries}
        rowCount={rowCount}
        delivery={delivery}
        recipients={recipients}
        diets={diets}
        clients={clients}
        rounds={rounds}
        toggleDrawer={toggleDrawer}
        handleUpdateOrder={handleUpdateOrder}
        handleUpdateOrders={handleUpdateOrders}
        handleUpdateDelivery={handleUpdateDelivery}
        handleDownloadDeliveriesNote={handleDownloadDeliveriesNote}
        handleDownloadDeliveryNote={handleDownloadDeliveryNote}
        fetchDeliveries={fetchDeliveries}
        handleSubmitPreparedDeliveries={handleSubmitPreparedDeliveries}
      />

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          sx={{ zIndex: "snackbar" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default DeliveryContainer;
