import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import SpaIcon from "@mui/icons-material/Spa";
import React from "react";
import one from "../../../../../assets/img/home/one.svg";
import two from "../../../../../assets/img/home/two.svg";
import seniors from "../../../../../assets/img/home/old.jpg";
import hamza from "../../../../../assets/img/home/Basil-5.jpg";
import plat from "../../../../../assets/img/home/dishes/carottes.jpg";
import "../feature.css";

const First = (props) => {
  const items = [
    "Fait maison par nos chefs dans nos cuisines",
    "Produits locaux & sélection bio",
    "Race à viande & pêche durable",
    "Menus établis avec une diététicienne",
    "Vous pouvez faire vos difficiles, Basil s'adapte aux régimes spécifiques",
  ];

  return (
    <div>
      <Grid container flexDirection="column" justifyContent="center">
        <Grid item container>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" color="primary" gutterBottom>
              <strong>Des plats aux petits oignons</strong>
            </Typography>

            <Typography variant="body1" fontSize={18}>
              Avec Basil, mangez sain et gourmand. Sans hésiter.
            </Typography>

            <Typography variant="body1" fontSize={18} gutterBottom>
              Les repas industriels qui n'ont pas de goût, non merci !
            </Typography>

            <List disablePadding>
              {items.map((item, i) => (
                <ListItem key={i} alignItems="center">
                  <ListItemIcon>
                    <SpaIcon sx={{ color: "#74BE5C" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontSize={18}>
                        <strong>{item}</strong>
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item xs={12} md={6} container padding={4}>
            <Grid item xs={6} md={4}>
              <img src={hamza} alt="chef" className="worker cover" />
            </Grid>
            <Grid item xs={6} md={8}>
              <img src={seniors} alt="seniors" className="seniors cover" />
              <img src={plat} alt="plat de carrotes" className="plat cover" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container justifyContent="center">
          <div className="steps">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={3}
              padding={4}
            >
              <Grid item xs={12} md={4}>
                <Typography variant="h6" textAlign="center">
                  <strong>COMMENT</strong>
                  <br />
                  <strong>ÇA MARCHE ?</strong>
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                container
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <img src={one} alt="one" className="icon img" />
                </Grid>
                <Grid item>
                  <Typography variant="body1" textAlign="center">
                    Commandez vos {props.selected === 1 ? "menus" : "plats"}
                    <br /> au 04 86 94 09 37
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                container
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <img src={two} alt="two" className="icon img" />
                </Grid>
                <Grid item>
                  <Typography variant="body1" textAlign="center">
                    {props.selected === 1
                      ? "Basil vous livre les lundi, mercredi et vendredi !"
                      : "Basil vous livre selon votre planning !"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default First;
