import {
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import plat1 from "../../../../assets/img/home/dishes/312.jpg";
import plat2 from "../../../../assets/img/home/dishes/boulettefalafelrizpilafetsaucetomate312.jpg";
import plat3 from "../../../../assets/img/home/dishes/couscousl_gumes.jpg";
import plat4 from "../../../../assets/img/home/dishes/L1100552.jpg";
import plat5 from "../../../../assets/img/home/dishes/L1120651.jpg";
import plat6 from "../../../../assets/img/home/dishes/saladem_cheroquefort312.jpg";
import plat7 from "../../../../assets/img/home/dishes/lentilles-truite-fumee-basil-marseille.jpg";
import plat8 from "../../../../assets/img/home/dishes/L1120718.jpeg";
import plat9 from "../../../../assets/img/home/dishes/Mousse-choco-basil-marseille.jpg";
import plat10 from "../../../../assets/img/home/dishes/Muffin-speculoos-marseille-basil.jpg";
import plat11 from "../../../../assets/img/home/dishes/rigatonisaucecitron.jpg";
import plat12 from "../../../../assets/img/home/dishes/saumonpo_l_.jpg";

const Gallery = () => {
  const items = [
    plat1,
    plat2,
    plat3,
    plat4,
    plat5,
    plat6,
    plat7,
    plat8,
    plat9,
    plat10,
    plat11,
    plat12,
  ];
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <ImageList
        sx={{ width: "inherit", height: "inherit" }}
        cols={matchDownMd ? 2 : 4}
        rowHeight={"auto"}
      >
        {items.map((item, i) => (
          <ImageListItem key={i}>
            <img src={item} srcSet={item} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default Gallery;
