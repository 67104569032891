import React from "react";
import {
  Card,
  CardHeader,
  CircularProgress,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { withFormik } from "formik";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <>
      <form>
        <Stack spacing={2}>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <LoadingButton
              loading={props.loading}
              variant={"contained"}
              sx={{ mb: 3 }}
              onClick={props.toggleList}
              color="secondary"
              startIcon={<ArrowBackIcon />}
            >
              Retour
            </LoadingButton>
          </Stack>

          <Card className={classes.card} sx={{ mb: 3 }}>
            <CardHeader
              subheader="Ces informations peuvent être modifier."
              title="Création d'un régime"
            />
          </Card>

          <Paper className={classes.paper} elevation={6}>
            <Stack spacing={2}>
              <TextField
                error={props.errors.nom && props.touched.nom ? true : false}
                {...(props.errors.nom &&
                  props.touched.nom && { helperText: props.errors.nom })}
                fullWidth
                name={"nom"}
                label={"Nom du régime"}
                variant="outlined"
                type={"text"}
                value={props.values.nom}
                className={classes.inputField}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />

              <TextField
                error={props.errors.color && props.touched.color ? true : false}
                {...(props.errors.color &&
                  props.touched.color && { helperText: props.errors.color })}
                fullWidth
                name={"color"}
                label={"Couleur associé aux bons de livraisons"}
                variant="outlined"
                type={"text"}
                value={props.values.color}
                className={classes.inputField}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  ),
                }}
              />

              <Typography
                variant="subtitle2"
                sx={{ color: "GrayText", fontStyle: "italic" }}
              >
                • La couleur doit être au format hexadécimal (ex: #000000)
                <br />
                Vous pouvez utiliser ce site pour choisir votre couleur :{" "}
                <Link href="https://www.color-hex.com/color/def5db">
                  Color-Hex
                </Link>
              </Typography>

              <Stack direction={"row"} justifyContent={"flex-end"}>
                <LoadingButton
                  loading={props.loading}
                  color="primary"
                  onClick={props.handleSubmit}
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  Modifier le régime
                </LoadingButton>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </form>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    nom: "",
    color: "",
  }),
  validationSchema: Yup.object().shape({
    nom: Yup.string().min(3).max(100).required(),
    color: Yup.string()
      .min(6, "Une couleur hexadecimal contient au minimum 6 caractères.")
      .max(6, "Une couleur hexadecimal contient au maximum 6 caractères.")
      .nullable(),
  }),
  handleSubmit: (values, { props }) => {
    const item = {
      nom: values.nom,
      color: values.color !== "" ? values.color.toUpperCase() : null,
    };
    props.validation(item);
  },
})(Add);
