import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import { Button, Grid, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";

const FormPassword = (props) => {
  return (
    <form>
      <Grid container spacing={2} alignItems={"center"} paddingTop={3}>
        <Grid item lg={6} md={12}>
          <TextField
            required
            error={
              props.touched.oldPassword && props.errors.oldPassword
                ? true
                : false
            }
            {...(props.touched.oldPassword &&
              props.errors.oldPassword && {
                helperText: props.errors.oldPassword,
              })}
            fullWidth
            type={"password"}
            name={"oldPassword"}
            label={"Ancien mot de passe"}
            value={props.values.oldPassword}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>

        <Grid item lg={6} md={12} />

        <Grid item lg={6} md={12}>
          <TextField
            required
            error={
              props.touched.newPassword && props.errors.newPassword
                ? true
                : false
            }
            {...(props.touched.newPassword &&
              props.errors.newPassword && {
                helperText: props.errors.newPassword,
              })}
            fullWidth
            type={"password"}
            name={"newPassword"}
            label={"Nouveau mot de passe"}
            value={props.values.newPassword}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>

        <Grid item lg={6} md={12}>
          <TextField
            required
            error={
              props.touched.newPassword && props.errors.confirmationNewPassword
                ? true
                : false
            }
            {...(props.touched.newPassword &&
              props.errors.confirmationNewPassword && {
                helperText: props.errors.confirmationNewPassword,
              })}
            fullWidth
            name={"confirmationNewPassword"}
            label={"Confirmer le nouveau mot de passe"}
            type={"password"}
            value={props.values.confirmationNewPassword}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>
      </Grid>

      <Stack direction={"row"} justifyContent={"flex-end"} paddingTop={2}>
        <Button
          type="submit"
          onClick={props.handleSubmit}
          variant="contained"
          endIcon={<SendIcon />}
        >
          Modifier mon mot de passe
        </Button>
      </Stack>
    </form>
  );
};

export default compose(
  withFormik({
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().min(
        6,
        "L'ancien mot de passe possède au minimum 6 caratères."
      ),
      newPassword: Yup.string()
        .min(6, "Le nouveau mot de passe doit posséder au minimum 6 caratères.")
        .test(
          "same-passwords",
          "Le nouveau mot de passe ne peut être identique à l'ancien",
          function (value) {
            return this.parent.oldPassword !== value;
          }
        ),
      confirmationNewPassword: Yup.string()
        .min(6)
        .test(
          "passwords-match",
          "Les mots de passe doivent être identiques",
          function (value) {
            return this.parent.newPassword === value;
          }
        ),
    }),
    handleSubmit: (values, { props }) => {
      const data = {
        id: props.user.id,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };

      props.validation(data);
    },
  })
)(FormPassword);
