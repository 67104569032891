export const ORDER_STATUS = {
  PENDING: { status: "pending", description: "En attente" },
  VALIDATED: { status: "validated", description: "Validée" },
  PREPARED: { status: "prepared", description: "Prête" },
  DELIVERING: { status: "delivering", description: "En livraison" },
  DELIVERED: { status: "delivered", description: "Livrée" },
  CANCELLED: { status: "cancelled", description: "Annulée" },
};

export const DELIVERY_STATUS = {
  PENDING_PREP: {
    status: "pending_prep",
    description: "En attente de préparation",
  },
  PENDING_DELIV: {
    status: "pending_deliv",
    description: "En attente de livraison",
  },
  DELIVERING: { status: "delivering", description: "En livraison" },
  DELIVERED: { status: "delivered", description: "Livrée" },
  CANCELLED: { status: "cancelled", description: "Annulée" },
};

export const RECIPIENT_STATE = {
  ACTIVE: { status: "active", description: "Active" },
  CANCELLED: { status: "cancelled", description: "Résiliée" },
  PAUSED: { status: "paused", description: "En pause" },
};

export const RECIPIENT_POSITION = {
  LEAD: { status: "lead", description: "Lead" },
  NEW: { status: "new", description: "Nouveau client" },
  ACTIVE: { status: "active", description: "Actif" },
};

export const ROLES = {
  SUPER_ADMIN: {
    role: "ROLE_SUPER_ADMIN",
    description: "Super Administrateur",
    isAdmin: true,
  },
  ADMIN: {
    role: "ROLE_ADMIN",
    description: "Administrateur",
    isAdmin: true,
  },
  FINANCE_ADMIN: {
    role: "ROLE_FINANCE_ADMIN",
    description: "Administrateur Financier",
    isAdmin: true,
  },
  SALES_ADMIN: {
    role: "ROLE_COMMERCIAL_ADMIN",
    description: "Administrateur Commercial",
    isAdmin: true,
  },
  PROD_ADMIN: {
    role: "ROLE_PRODUCTION_ADMIN",
    description: "Administrateur Production",
    isAdmin: true,
  },
  OWNER: {
    role: "ROLE_OWNER",
    description: "Client",
    isAdmin: true,
  },
  FINANCE: { role: "ROLE_FINANCE", description: "Financier", isAdmin: false },
  SALES: { role: "ROLE_COMMERCIAL", description: "Commercial", isAdmin: false },
  PROD: { role: "ROLE_PRODUCTION", description: "Production", isAdmin: false },
};
