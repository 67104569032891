import React, { useEffect, useState } from "react";
import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { ENTRYPOINT } from "../../../config/entrypoint";
import { requests } from "../../../agent";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { Snackbar, Alert } from "@mui/material";
import { useSelector } from "react-redux";

const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = (response) => response.body;
let token = null;
const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) request.set("Authorization", `Bearer ${token}`);
  };
};

const OgranizationContainer = (props) => {
  const userData = useSelector((state) => state.auth.userData);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [creditors, setCreditors] = useState([]);
  const [diets, setDiets] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [formules, setFormules] = useState([]);
  const [extraCategories, setExtraCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showImportModal, setShowImportModal] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    const promises = Promise.all([
      requests.get("/organizations/lists", true),
      requests.get("/origins", true),
      requests.get("/creanciers", true),
      requests.get("/regimes", true),
      requests.get("/rounds", true),
      requests.get("/formules", true),
      requests.get("/extra_categories", true),
    ]);

    promises
      .then((results) => {
        setClients(results[0]["hydra:member"]);
        setClientsList(results[0]["hydra:member"]);
        setOrigins(results[1]["hydra:member"]);
        setCreditors(results[2]["hydra:member"]);
        setDiets(results[3]["hydra:member"]);
        setRounds(results[4]["hydra:member"]);
        setFormules(results[5]["hydra:member"]);
        setExtraCategories(results[6]["hydra:member"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (id) => {
    setLoading(true);
    requests
      .get(`/organizations/${id}`, true)
      .then((result) => {
        setLoading(false);
        setClient(result);
        setEdit(!edit);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setClient(null);
  };
  const toggleImportModal = () => {
    setShowImportModal(!showImportModal);
  };
  const handleSubmit = (client) => {
    setLoading(true);
    requests
      .post("/organizations", client, true)
      .then((response) => {
        const newListeUser = [...clients];
        newListeUser.unshift(response);
        setLoading(false);
        setClients(newListeUser);
        setAdd(false);
        setAlert({
          active: true,
          type: "success",
          message: "L'utilisateur a bien été créé !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitEdit = (client) => {
    setLoading(true);
    requests
      .put(`/organizations/${client.id}`, client, true)
      .then((response) => {
        const newListeClients = clients.map((c) => {
          if (c.id !== response.id) {
            return c;
          }

          return response;
        });
        setLoading(false);
        setClients(newListeClients);
        setEdit(false);
        setClient(null);
        setAlert({
          active: true,
          type: "success",
          message: "Le client a bien été modifié !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitFilters = (data, from) => {
    setLoading(true);
    let dataUrl = "";
    if (data.id !== "" || data.client !== "")
      dataUrl += `id=${data.id || data.client}&`;
    if (data.email !== "") dataUrl += `email=${data.email}&`;
    if (data.phone !== "") dataUrl += `phone=${data.phone}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setClients(result["hydra:member"]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const sendDataExport = (all, start, end) => {
    setLoading(true);
    let dataUrl = "";
    if (!all) {
      if (start !== null) dataUrl += `createdAt[before]=${start}&`;
      if (end !== null) dataUrl += `createdAt[after]=${end}&`;
    }
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    superagent
      .get(`${ENTRYPOINT}/organizations/export?${dataUrl}`, true)
      .use(tokenPlugin(true))
      .responseType("blob")
      .then(responseBody)
      .then((results) => {
        const fileUrl = URL.createObjectURL(results);
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = "clients_hulpo.xlsx";

        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(fileUrl);

        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les données ont été exportées !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const importUsers = (file) => {
    setLoading(true);
    let url = `/users/import`;
    superagent
      .post(`${ENTRYPOINT}${url}`)
      .use(tokenPlugin(true))
      .attach("file", file)
      .then((response) => {
        const newClients = response.body["users"]["hydra:member"];
        const newClientsList = [...clients, ...newClients];
        setClients(newClientsList);
        setShowImportModal(false);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Les données ont été importées !",
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      {add && (
        <Add
          userData={userData}
          validation={handleSubmit}
          add={add}
          clients={clients}
          origins={origins}
          creditors={creditors}
          diets={diets}
          rounds={rounds}
          formules={formules}
          extraCategories={extraCategories}
          toggleList={toggleList}
          loading={loading}
        />
      )}

      {edit && (
        <Edit
          userData={userData}
          validation={handleSubmitEdit}
          edit={edit}
          clients={clients}
          client={client}
          origins={origins}
          creditors={creditors}
          diets={diets}
          rounds={rounds}
          formules={formules}
          extraCategories={extraCategories}
          toggleList={toggleList}
          loading={loading}
        />
      )}

      {!edit && !add && (
        <List
          userData={userData}
          edit={edit}
          add={add}
          clients={clients}
          clientsList={clientsList}
          loading={loading}
          toggleEdit={toggleEdit}
          toggleAdd={toggleAdd}
          handleSubmitFilters={handleSubmitFilters}
          sendDataExport={sendDataExport}
          importUsers={importUsers}
          toggleImportModal={toggleImportModal}
          showImportModal={showImportModal}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default OgranizationContainer;
