import React from "react";
import Add from "./Add/Add";
import List from "./List/List";
import Edit from "./Edit/Edit";
import { Snackbar, Alert } from "@mui/material";
import { requests } from "../../../agent";

class RegimeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regimes: [],
      add: false,
      edit: null,
      isPageLoading: false,
      loading: false,
      dataRegimeEdit: null,
      alert: {
        active: false,
        type: null,
        message: null,
      },
    };
  }

  componentDidMount() {
    this.setState({ isPageLoading: true });

    requests
      .get("/regimes", true)
      .then((response) => {
        this.setState({
          regimes: response["hydra:member"],
          isPageLoading: false,
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  }

  handleSubmit = (item) => {
    this.setState({ loading: true });

    requests
      .post("/regimes", item, true)
      .then((response) => {
        this.setState({ loading: false });
        const newRow = response;
        const newListRows = [...this.state.regimes];
        newListRows.unshift(newRow);
        this.setState({ loading: false });
        this.setState((oldState) => {
          return {
            regimes: newListRows,
            add: false,
            alert: {
              active: true,
              type: "success",
              message: "Opération effectuée avec succès",
            },
          };
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  handleSubmitEdit = (id, item) => {
    this.setState({ loading: true });

    requests
      .patch(`/regimes/${id}`, item, true)
      .then((response) => {
        const caseRegime = this.state.regimes.findIndex((u) => {
          return u.id === id;
        });
        const updatedRegime = response;
        const newListeRegimes = [...this.state.regimes];
        newListeRegimes[caseRegime] = updatedRegime;
        this.setState((oldState) => {
          return {
            regimes: newListeRegimes,
            edit: null,
            alert: {
              active: true,
              type: "success",
              message: "Le régime a bien été modifié !",
            },
            loading: false,
          };
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  toggleAdd = () => {
    this.setState((oldState) => {
      return {
        add: !oldState.add,
      };
    });
  };

  toggleEdit = (regimeId) => {
    const caseRegime = this.state.regimes.findIndex((l) => {
      return l.id === regimeId;
    });

    this.setState(() => {
      return {
        edit: regimeId,
        dataRegimeEdit: this.state.regimes[caseRegime],
      };
    });
  };

  toggleListFromEdit = () => {
    this.setState(() => {
      return {
        edit: null,
        dataRegimeEdit: null,
      };
    });
  };

  handleSubmitFilters = (data, from) => {
    this.setState({
      loading: true,
    });

    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.lastname !== "") dataUrl += `nom=${data.lastname}&`;

    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        this.setState({
          regimes: result["hydra:member"],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  render() {
    const handleCloseSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      this.setState({
        alert: {
          active: false,
          type: null,
          message: null,
        },
      });
    };

    const { add, edit, alert } = this.state;
    return (
      <>
        {add && (
          <Add
            {...this.state}
            validation={this.handleSubmit}
            toggleList={this.toggleAdd}
          />
        )}

        {edit && (
          <Edit
            {...this.state}
            validation={this.handleSubmitEdit}
            toggleList={this.toggleListFromEdit}
          />
        )}

        {!add && !edit && (
          <List
            {...this.state}
            toggleAdd={this.toggleAdd}
            toggleEdit={this.toggleEdit}
            handleSubmitFilters={this.handleSubmitFilters}
          />
        )}

        {alert.active && (
          <Snackbar
            open={alert.active}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              variant={"filled"}
              severity={alert.type}
            >
              {alert.message}
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }
}

export default RegimeContainer;
