import React, { useState, useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
import TransferListProduit from "../Common/TransfertList/TransferList";
import TransfertListSupplement from "../Common/TransfertList/TransfertListSupplement";
import { ORDER_STATUS } from "../Common/Enums/Enums";
import { locale, mask } from "../../../localisation";
import {
  Divider,
  Grid,
  TextField,
  Typography,
  Alert,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AlertTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Stack,
  Chip,
  Card,
  List,
  ListItem,
  Autocomplete,
  FormGroup,
  CircularProgress,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import UseStyle from "../Common/StyledComponent/UseStyle";
import { isAnyAdmin } from "../../../utils";

const mod = (n, m) => {
  return ((n % m) + m) % m;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isDayNotDelivered",
})(({ theme, isDayNotDelivered }) => ({
  ...(isDayNotDelivered && {
    color: "red",
  }),
}));

const OrderForm = (props) => {
  const classes = UseStyle();
  const [recipientDiets, setRecipientDiets] = useState([]);
  const [recipientAllergens, setRecipientAllergens] = useState([]);
  const [menus, setMenus] = useState([]);

  const recipients = props.user.recipients.map((recipient) => {
    const filtre = recipient.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      recipient: recipient,
    };
  });
  const [selectedRecipient, setSelectedRecipient] = useState(
    props.values.recipient
      ? recipients.find((r) => r.recipient.id === props.values.recipient)
      : null
  );

  useEffect(() => {
    if (selectedRecipient) {
      const recipient = props.user.recipients.find(
        (r) => r.id === selectedRecipient.recipient.id
      );
      setRecipientDiets(recipient.diets);
      setRecipientAllergens(recipient.allergens);
      props.setFieldValue("round", recipient.round.id);
      props.setFieldValue("name", recipient.name);
      props.setFieldValue("lunchMenu", recipient.lunchMenu.id);
      recipient.dinnerMenu
        ? props.setFieldValue("dinnerMenu", recipient.dinnerMenu.id)
        : props.setFieldValue("dinnerMenu", "Aucune");
    } else {
      const diet = props.diets.find((d) => d.nom === props.values.name);
      diet && setRecipientDiets([diet]);
    }
  }, [selectedRecipient]);

  const callbackFetchMenus = (menus) => {
    setMenus(menus);

    if (menus.length > 0) {
      props.setFieldValue("menu", menus[0]);
    }
  };

  useEffect(() => {
    if (props.values.menu) {
      const round = props.rounds.find((r) => r.id === props.values.round);
      const deliveryDay = round.deliveryDays.findIndex((deliveryDay) =>
        deliveryDay.repasDu.includes(props.values.date.getDay())
      );
      const deliveryDate = new Date(props.values.date);

      if (deliveryDay === props.values.date.getDay()) {
        props.setFieldValue("deliveryDate", deliveryDate);
      } else {
        deliveryDate.setDate(
          deliveryDate.getDate() - mod(deliveryDate.getDay() - deliveryDay, 7)
        );

        props.setFieldValue("deliveryDate", deliveryDate);
      }
    } else {
      props.setFieldValue("menu", null);
      props.setFieldValue("deliveryDate", "");
    }
  }, [props.values.menu]);

  useEffect(() => {
    if (
      props.values.date !== null &&
      props.values.date instanceof Date &&
      !isNaN(props.values.date)
    ) {
      props.fetchMenus(
        props.values.date,
        props.values.round,
        callbackFetchMenus
      );
    }
  }, [props.values.date, props.values.round]);

  const colors = [
    "#E57373",
    "#F8BBD0",
    "#9FA8DA",
    "#A5D6A7",
    "#FFF59D",
    "#FFAB91",
    "#4DD0E1",
    "#283593",
    "#2E7D32",
  ];
  const categories = props.categories.map((categorie, index) => ({
    ...categorie,
    color: colors[index],
  }));
  const isDayNotDelivered = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const round = props.rounds.find(
        (round) => round.id === props.values.round
      );
      return !round.deliveryDays.find(
        (deliveryDay, index) => index === date.getDay()
      ).estActif;
    } else {
      return false;
    }
  };

  const renderProduitsDejeuner = () => {
    const handleSelectedProducts = (produits) => {
      props.setFieldValue("produitsDejeuner", produits);
    };
    const handleSelectedIncludedExtras = (items) => {
      props.setFieldValue(
        "supplementsDejeunerInclus",
        items.map((item) => ({ ...item, isMenuIncluded: true }))
      );
    };
    const handleSelectedExtras = (items) => {
      props.setFieldValue(
        "supplementsDejeuner",
        items.map((item) => ({ ...item, isMenuIncluded: false }))
      );
    };

    return (
      <>
        <Grid item md={8} lg={9}>
          <Typography
            variant="h6"
            component="h6"
            a="true"
            align={"center"}
            sx={{ mb: 1 }}
          >
            Produits
          </Typography>

          <TransferListProduit
            left={
              props.values.produitsDejeuner.length > 0
                ? isAnyAdmin(props.userData)
                  ? props.products.ids.filter(
                      (id) =>
                        !props.values.produitsDejeuner
                          .map((item) => item.id)
                          .includes(id)
                    )
                  : props.values.menu.lunchProducts
                      .map((l) => l.id)
                      .filter(
                        (id) =>
                          !props.values.produitsDejeuner
                            .map((item) => item.id)
                            .includes(id)
                      )
                : isAnyAdmin(props.userData)
                ? props.products.ids
                : props.values.menu.lunchProducts.map((l) => l.produit.id)
            }
            right={props.values.produitsDejeuner}
            chosen={handleSelectedProducts}
            items={props.products.datas}
            labels={props.products.labels}
            categories={categories}
            regimes={props.diets}
            error={
              props.errors.produitsDejeuner && props.touched.produitsDejeuner
                ? true
                : false
            }
            helperText={
              props.errors.produitsDejeuner &&
              props.touched.produitsDejeuner &&
              props.errors.produitsDejeuner
            }
            commandeList={true}
          />
        </Grid>

        <Grid item md={4} lg={3}>
          <Card className={classes.card}>
            <FormControl
              fullWidth
              size="small"
              sx={{ padding: 1, marginTop: 1 }}
            >
              <InputLabel>Menus disponibles</InputLabel>
              <Select
                error={props.touched.menu && Boolean(props.errors.menu)}
                helperText={
                  props.errors.menu && props.touched.menu && props.errors.menu
                }
                label="Menu disponible"
                name="menu"
                value={props.values.menu}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              >
                {menus.map((menu) => {
                  return (
                    <MenuItem key={menu.id} value={menu}>
                      {menu.name} (
                      {menu.diets.length > 0
                        ? menu.diets.map((diet) => diet.nom).join(", ")
                        : "Normal"}
                      )
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Divider />

            <List disablePadding={true}>
              {props.values.menu.lunchProducts
                .sort((a, b) => a.categorie.priorite - b.categorie.priorite)
                .map((item, index) => {
                  const color = categories.find(
                    (c) => c.id === item.categorie.id
                  ).color;

                  return (
                    <>
                      <ListItem
                        key={index}
                        component="div"
                        sx={{
                          backgroundColor: color,
                          p: 1,
                        }}
                      >
                        <Stack direction="row" spacing={3}>
                          <Typography variant="body1" component="div">
                            {item.nom}
                          </Typography>
                          {item.estPrioritaire && <StarIcon color="primary" />}
                        </Stack>
                      </ListItem>

                      <Divider
                        variant="fullWidth"
                        light={true}
                        component="li"
                      />
                    </>
                  );
                })}
            </List>
          </Card>
        </Grid>

        <Grid item md={8} lg={9}>
          <Typography
            variant="h6"
            component="h6"
            align={"center"}
            sx={{ mb: 1 }}
          >
            Suppléments inclus dans la formule
          </Typography>

          <TransfertListSupplement
            left={
              props.values.supplementsDejeunerInclus.length > 0
                ? isAnyAdmin(props.userData)
                  ? props.extras.ids.filter(
                      (id) =>
                        !props.values.supplementsDejeuner
                          .map((item) => item.supplement)
                          .includes(id)
                    )
                  : props.values.menu.lunchExtras
                      .map((l) => l.id)
                      .filter(
                        (id) =>
                          !props.values.supplementsDejeuner
                            .map((item) => item.supplement)
                            .includes(id)
                      )
                : isAnyAdmin(props.userData)
                ? props.extras.ids
                : props.values.menu.lunchExtras.map((s) => s.id)
            }
            labels={props.extras.labels}
            right={props.values.supplementsDejeunerInclus}
            chosen={handleSelectedIncludedExtras}
          />
        </Grid>

        <Grid item xs={3}>
          <Card className={classes.card}>
            <Typography variant="h6" component="div" padding={2}>
              Suppléments du menu déjeuner
            </Typography>

            <Divider />

            <List disablePadding={true}>
              {props.values.menu.lunchExtras.length > 0 ? (
                props.values.menu.lunchExtras.map((item, index) => {
                  return (
                    <>
                      <ListItem
                        key={index}
                        component="div"
                        sx={{ p: 1 }}
                        alignItems="center"
                      >
                        <Typography variant="body1" component="div">
                          {item.nom}
                        </Typography>
                      </ListItem>
                      <Divider variant="fullWidth" component="li" />
                    </>
                  );
                })
              ) : (
                <ListItem component="div" sx={{ p: 1 }}>
                  <Typography variant="body1" component="div">
                    Aucun suppléments.
                  </Typography>
                </ListItem>
              )}
            </List>
          </Card>
        </Grid>

        <Grid item md={8} lg={9}>
          <Typography
            variant="h6"
            component="h6"
            align={"center"}
            sx={{ mb: 1 }}
          >
            Suppléments hors formule
          </Typography>

          <TransfertListSupplement
            left={
              props.values.supplementsDejeuner.length > 0
                ? isAnyAdmin(props.userData)
                  ? props.extras.ids.filter(
                      (id) =>
                        !props.values.supplementsDejeuner
                          .map((item) => item.supplement)
                          .includes(id)
                    )
                  : props.values.menu.lunchExtras
                      .map((l) => l.id)
                      .filter(
                        (id) =>
                          !props.values.supplementsDejeuner
                            .map((item) => item.supplement)
                            .includes(id)
                      )
                : isAnyAdmin(props.userData)
                ? props.extras.ids
                : props.values.menu.lunchExtras.map((s) => s.id)
            }
            labels={props.extras.labels}
            right={props.values.supplementsDejeuner.filter(
              (item) => !item.isMenuIncluded
            )}
            chosen={handleSelectedExtras}
          />
        </Grid>
      </>
    );
  };
  const renderProduitsDiner = () => {
    const handleSelectedProducts = (products) => {
      props.setFieldValue("produitsDiner", products);
    };
    const handleSelectedIncludedExtras = (items) => {
      props.setFieldValue(
        "supplementsDinerInclus",
        items.map((item) => ({ ...item, isMenuIncluded: true }))
      );
    };
    const handleSelectedExtras = (items) => {
      props.setFieldValue(
        "supplementsDiner",
        items.map((item) => ({ ...item, isMenuIncluded: false }))
      );
    };

    return (
      <>
        <Grid item xs={9}>
          <Typography variant="h6" component="h6" sx={{ mb: 1 }}>
            Produits
          </Typography>
          <TransferListProduit
            left={
              props.values.produitsDiner.length > 0
                ? isAnyAdmin(props.userData)
                  ? props.products.ids.filter(
                      (id) =>
                        !props.values.produitsDiner
                          .map((item) => item.id)
                          .includes(id)
                    )
                  : props.values.menu.dinnerProducts
                      .map((l) => l.id)
                      .filter(
                        (id) =>
                          !props.values.produitsDiner
                            .map((item) => item.id)
                            .includes(id)
                      )
                : isAnyAdmin(props.userData)
                ? props.products.ids
                : props.values.menu.dinnerProducts.map((l) => l.id)
            }
            right={props.values.produitsDiner}
            chosen={handleSelectedProducts}
            items={props.products.datas}
            labels={props.products.labels}
            categories={categories}
            regimes={props.diets}
            commandeList={true}
          />
        </Grid>

        <Grid item xs={3}>
          <Card className={classes.card}>
            <FormControl
              fullWidth
              size="small"
              sx={{ padding: 1, marginTop: 1 }}
            >
              <InputLabel>Menus disponibles</InputLabel>
              <Select
                error={props.touched.menu && Boolean(props.errors.menu)}
                helperText={
                  props.errors.menu && props.touched.menu && props.errors.menu
                }
                label="Menu disponible"
                name="menu"
                value={props.values.menu}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              >
                {menus.map((menu) => {
                  return (
                    <MenuItem key={menu.id} value={menu}>
                      {menu.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Divider />

            <List disablePadding={true}>
              {props.values.menu.dinnerProducts
                .sort((a, b) => a.categorie.priorite - b.categorie.priorite)
                .map((item, index) => {
                  const color = categories.find(
                    (c) => c.id === item.categorie.id
                  ).color;

                  return (
                    <>
                      <ListItem
                        key={index}
                        component="div"
                        sx={{ backgroundColor: color, p: 1 }}
                      >
                        <Stack direction={"row"} spacing={3}>
                          <Typography variant="body1" component="div">
                            {item.nom}
                          </Typography>
                          {item.estPrioritaire && <StarIcon color="primary" />}
                        </Stack>
                      </ListItem>

                      <Divider
                        variant="fullWidth"
                        component="li"
                        light={true}
                      />
                    </>
                  );
                })}
            </List>
          </Card>
        </Grid>

        <Grid item xs={9}>
          <Typography
            variant="h6"
            component="h6"
            align={"center"}
            sx={{ mb: 1 }}
          >
            Suppléments inclus dans la formule
          </Typography>

          <TransfertListSupplement
            left={
              props.values.supplementsDinerInclus.length > 0
                ? isAnyAdmin(props.userData)
                  ? props.extras.ids.filter(
                      (id) =>
                        !props.values.supplementsDiner
                          .map((item) => item.supplement)
                          .includes(id)
                    )
                  : props.values.menu.dinnerExtras
                      .map((l) => l.id)
                      .filter(
                        (id) =>
                          !props.values.supplementsDiner
                            .map((item) => item.supplement)
                            .includes(id)
                      )
                : isAnyAdmin(props.userData)
                ? props.extras.ids
                : props.values.menu.dinnerExtras.map((s) => s.id)
            }
            labels={props.extras.labels}
            right={props.values.supplementsDinerInclus}
            chosen={handleSelectedIncludedExtras}
          />
        </Grid>

        <Grid item xs={3}>
          <Card className={classes.card}>
            <Typography variant="h6" component="div" padding={2}>
              Suppléments du menu dîner
            </Typography>

            <Divider />

            <List disablePadding={true}>
              {props.values.menu.dinnerExtras.length > 0 ? (
                props.values.menu.dinnerExtras.map((item, index) => {
                  return (
                    <>
                      <ListItem key={index} component="div" sx={{ p: 1 }}>
                        <Typography variant="body1" component="div">
                          {item.nom}
                        </Typography>
                      </ListItem>
                      <Divider variant="fullWidth" component="li" />
                    </>
                  );
                })
              ) : (
                <ListItem component="div" sx={{ p: 1 }} alignItems="center">
                  <Typography variant="body1" component="div">
                    Aucun suppléments.
                  </Typography>
                </ListItem>
              )}
            </List>
          </Card>
        </Grid>

        <Grid item xs={9}>
          <Typography
            variant="h6"
            component="h6"
            align={"center"}
            sx={{ mb: 1 }}
          >
            Suppléments hors formule
          </Typography>

          <TransfertListSupplement
            left={
              props.values.supplementsDiner.length > 0
                ? isAnyAdmin(props.userData)
                  ? props.extras.ids.filter(
                      (id) =>
                        !props.values.supplementsDiner
                          .map((item) => item.supplement)
                          .includes(id)
                    )
                  : props.values.menu.dinnerExtras
                      .map((l) => l.id)
                      .filter(
                        (id) =>
                          !props.values.supplementsDiner
                            .map((item) => item.supplement)
                            .includes(id)
                      )
                : isAnyAdmin(props.userData)
                ? props.extras.ids
                : props.values.menu.dinnerExtras.map((s) => s.id)
            }
            labels={props.extras.labels}
            right={props.values.supplementsDiner}
            chosen={handleSelectedExtras}
          />
        </Grid>
      </>
    );
  };

  let isPassed = false;
  const today = new Date();

  if (props.orderEdit) {
    const orderDate = new Date(props.orderEdit.date);

    if (orderDate.getMonth() < today.getMonth()) {
      isPassed = true;
    }
  }

  return (
    <form>
      <Grid container spacing={2} alignItems={"center"}>
        {isAnyAdmin(props.userData) && props.user.mode === "diets" && (
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Quantité"
              type="number"
              value={props.values.quantity}
              onChange={(event) => {
                const min = 1;
                if (event.target.value === "") {
                  props.setFieldValue("quantity", min);
                  return;
                }
                const value = +event.target.value;
                if (value < min) {
                  props.setFieldValue("quantity", min);
                } else {
                  props.setFieldValue("quantity", value);
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        )}

        <Grid
          item
          xs={isAnyAdmin(props.userData) ? 8 : 10}
          md={props.user.mode === "diets" ? 8 : 10}
        >
          <FormControl variant="outlined" fullWidth required>
            <Autocomplete
              disabled={props.values.other}
              sx={{ width: "100%" }}
              options={recipients.sort(
                (a, b) => -b.filtre.localeCompare(a.filtre)
              )}
              isOptionEqualToValue={(option, value) =>
                option.recipient.id === value.recipient.id
              }
              groupBy={(value) => value.filtre}
              getOptionLabel={(option) => {
                if (!option.recipient) return "";
                return option.recipient.id + " - " + option.recipient.name;
              }}
              value={selectedRecipient}
              onChange={(event, value) => {
                setSelectedRecipient(value);

                value !== null
                  ? props.setFieldValue("recipient", value.recipient.id)
                  : props.setFieldValue("recipient", null);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Convive" />
              )}
            />
            <FormHelperText error>
              {props.errors.recipient &&
                props.touched.recipient &&
                props.errors.recipient}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Autre"
              checked={props.values.other}
              onChange={(event) => {
                props.setFieldValue("other", event.target.checked);
                if (event.target.checked)
                  props.setFieldValue("recipient", null);
              }}
            />
          </FormGroup>
        </Grid>

        {props.values.other && (
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth required>
              <TextField
                required
                fullWidth
                name={"name"}
                label={"Nom du convive"}
                type={"text"}
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.errors.name && props.touched.name ? true : false}
                {...(props.errors.name &&
                  props.touched.name && { helperText: props.errors.name })}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel>Formule déjeuner</InputLabel>
            <Select
              name={"lunchMenu"}
              label="Formule déjeuner"
              value={props.values.lunchMenu}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={
                props.errors.lunchMenu && props.touched.lunchMenu ? true : false
              }
              {...(props.errors.lunchMenu &&
                props.touched.lunchMenu && {
                  helperText: props.errors.lunchMenu,
                })}
            >
              {props.formules
                .filter((formule) => !formule.estDiner)
                .map((formule, i) => {
                  return (
                    <MenuItem value={formule.id} key={i}>
                      {formule.name}
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText error>
              {props.errors.lunchMenu &&
                props.touched.lunchMenu &&
                props.errors.lunchMenu}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Formule dîner</InputLabel>
            <Select
              name={"dinnerMenu"}
              label="Formule dîner"
              value={props.values.dinnerMenu}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            >
              <MenuItem value="Aucune">
                <i>Aucune</i>
              </MenuItem>
              {props.formules
                .filter((formule) => formule.estDiner)
                .map((formule, i) => (
                  <MenuItem value={formule.id} key={i}>
                    {formule.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            {...(props.values.recipient && { disabled: true })}
          >
            <InputLabel>Tournée</InputLabel>
            <Select
              name={"round"}
              label="Tournée"
              value={props.values.round}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.errors.round && props.touched.round ? true : false}
            >
              {props.rounds.map((round, i) => {
                return (
                  <MenuItem value={round.id} key={i}>
                    {round.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText error>
              {props.errors.round && props.touched.round && props.errors.round}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth required>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale["fr"]}
            >
              <DatePicker
                mask={mask["fr"]}
                label="Date du menu"
                inputFormat="dd/MM/yyyy"
                value={props.values.date}
                onChange={(value) => {
                  props.setFieldValue("date", value);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>

            <FormHelperText error>
              {props.errors.date && props.touched.date && props.errors.date}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth required>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale["fr"]}
            >
              <DatePicker
                mask={mask["fr"]}
                label="Date de livraison"
                inputFormat="dd/MM/yyyy"
                value={props.values.deliveryDate}
                onChange={(value) => {
                  props.setFieldValue("deliveryDate", value);
                }}
                renderDay={(date, selectedDates, pickersDayProps) => (
                  <CustomPickersDay
                    {...pickersDayProps}
                    disableMargin
                    isDayNotDelivered={isDayNotDelivered(date)}
                  />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    {...(props.values.deliveryDate &&
                      isDayNotDelivered(props.values.deliveryDate) && {
                        error: true,
                        helperText:
                          "Attention ! Ce jour n'est habituellement pas livré",
                      })}
                  />
                )}
              />
            </LocalizationProvider>

            <FormHelperText error>
              {props.errors.deliveryDate &&
                props.touched.deliveryDate &&
                props.errors.deliveryDate}
            </FormHelperText>
          </FormControl>
        </Grid>

        {isAnyAdmin(props.userData) && (
          <>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel>Statut</InputLabel>
                <Select
                  name={"statut"}
                  label="Statut"
                  value={props.values.statut}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.errors.statut && props.touched.statut ? true : false
                  }
                  {...(props.errors.statut &&
                    props.touched.statut && {
                      helperText: props.errors.statut,
                    })}
                >
                  {Object.values(ORDER_STATUS).map((state, i) => {
                    return (
                      <MenuItem value={state.status} key={i}>
                        {state.description}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error>
                  {props.errors.statut &&
                    props.touched.statut &&
                    props.errors.statut}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                value={props.values.estOffert}
                checked={props.values.estOffert}
                onChange={(event) =>
                  props.setFieldValue(`estOffert`, event.target.checked)
                }
                control={<Checkbox />}
                label="Commande à offrir"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} marginY={2}>
          <Divider />
        </Grid>

        <Grid item xs={12} container spacing={2} justifyContent={"center"}>
          {props.loading ? (
            <Grid>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {menus.length > 0 ? (
                <>
                  {!props.values.other && (
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Typography variant="body1" gutterBottom>
                            Régimes du convive :
                          </Typography>
                          {recipientDiets.length > 0 ? (
                            recipientDiets.map((d, i) => (
                              <Chip key={i} label={d.nom} />
                            ))
                          ) : (
                            <Chip label="Aucun régime" />
                          )}
                        </Stack>

                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Typography variant="body1" gutterBottom>
                            Allergènes du convive :
                          </Typography>
                          {recipientAllergens.length > 0 ? (
                            recipientAllergens.map((a, i) => (
                              <Chip key={i} label={a.nom} />
                            ))
                          ) : (
                            <Chip label="Aucun allergène" />
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                  )}

                  {props.values.menu && (
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignContent={"center"}
                            justifyContent={"center"}
                          >
                            <WbSunnyIcon />
                            <Typography>Produits du déjeuner</Typography>
                            {!Array.isArray(
                              props.values.estCompleteDejeuner.products
                            ) && (
                              <Alert severity="warning">
                                Produits manquants au déjeuner :{" "}
                                {Object.entries(
                                  props.values.estCompleteDejeuner.products
                                )
                                  .map(([key, value]) => value + " " + key)
                                  .join(", ")}
                              </Alert>
                            )}
                            {!Array.isArray(
                              props.values.estCompleteDejeuner.extras
                            ) && (
                              <Alert severity="warning">
                                Suppléments manquants au déjeuner :{" "}
                                {Object.entries(
                                  props.values.estCompleteDejeuner.extras
                                )
                                  .map(([key, value]) => value + " " + key)
                                  .join(", ")}
                              </Alert>
                            )}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={3} alignItems={"center"}>
                            {renderProduitsDejeuner()}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      {props.values.dinnerMenu !== "Aucune" && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Stack
                              direction="row"
                              spacing={2}
                              alignContent={"center"}
                              justifyContent={"center"}
                            >
                              <BedtimeIcon />
                              <Typography>Produits du dîner</Typography>
                              {!Array.isArray(
                                props.values.estCompleteDiner.products
                              ) && (
                                <Alert severity="warning">
                                  Produits manquants au déjeuner :{" "}
                                  {Object.entries(
                                    props.values.estCompleteDiner.products
                                  )
                                    .map(([key, value]) => value + " " + key)
                                    .join(", ")}
                                </Alert>
                              )}
                              {!Array.isArray(
                                props.values.estCompleteDiner.extras
                              ) && (
                                <Alert severity="warning">
                                  Suppléments manquants au déjeuner :{" "}
                                  {Object.entries(
                                    props.values.estCompleteDiner.extras
                                  )
                                    .map(([key, value]) => value + " " + key)
                                    .join(", ")}
                                </Alert>
                              )}
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={3} alignItems={"center"}>
                              {renderProduitsDiner()}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item>
                  <Alert severity="warning" sx={{ textAlign: "center" }}>
                    <AlertTitle>Attention</AlertTitle>
                    Il n'y a pas de menu à cette date !{" "}
                    {isAnyAdmin(props.userData) && (
                      <strong>
                        <Link href="/admin/menus">Cliquez ici</Link> pour créer
                        un nouveau menu
                      </strong>
                    )}
                  </Alert>
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Grid item xs={12} container justifyContent={"flex-end"}>
          <LoadingButton
            loading={props.loading}
            disabled={isPassed}
            color="primary"
            onClick={props.handleSubmit}
            variant="contained"
            endIcon={<SendIcon />}
          >
            {props.orderEdit ? "Modifier" : "Créer"} la commande
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    menu: props?.orderEdit?.menu ?? null,
    date: props.orderEdit ? new Date(props.orderEdit.date) : null,
    recipient: props?.orderEdit?.recipient?.id ?? null,
    other: props.add ? false : props?.orderEdit?.recipient?.id ? false : true,
    name: props?.orderEdit?.name ?? "",
    statut: props?.orderEdit?.statut ?? ORDER_STATUS.PENDING.status,
    lunchMenu: props?.orderEdit?.lunchMenu?.id ?? "",
    dinnerMenu: props?.orderEdit?.dinnerMenu?.id ?? "Aucune",
    produitsDejeuner: props?.orderEdit?.produitsDejeuner ?? [],
    supplementsDejeunerInclus:
      props?.orderEdit?.supplementsDejeuner
        .map((supp) => ({
          ...supp,
          supplement: supp.supplement.id,
        }))
        .filter((item) => item.isMenuIncluded) ?? [],
    supplementsDejeuner:
      props?.orderEdit?.supplementsDejeuner
        .map((supp) => ({
          ...supp,
          supplement: supp.supplement.id,
        }))
        .filter((item) => !item.isMenuIncluded) ?? [],
    produitsDiner: props?.orderEdit?.produitsDiner ?? [],
    supplementsDinerInclus:
      props?.orderEdit?.supplementsDiner
        .map((supp) => ({
          ...supp,
          supplement: supp.supplement.id,
        }))
        .filter((item) => item.isMenuIncluded) ?? [],
    supplementsDiner:
      props?.orderEdit?.supplementsDiner
        .map((supp) => ({
          ...supp,
          supplement: supp.supplement.id,
        }))
        .filter((item) => !item.isMenuIncluded) ?? [],
    deliveryDate:
      props.orderEdit && props.orderEdit.delivery
        ? new Date(props.orderEdit.delivery.date)
        : null,
    estOffert: props?.orderEdit?.estOffert ?? false,
    round: props?.orderEdit?.round?.id ?? props.rounds[0].id,
    estCompleteDejeuner: {
      products: props?.orderEdit?.missingLunchProducts?.products ?? [],
      extras: props?.orderEdit?.missingLunchProducts?.extras ?? [],
    },
    estCompleteDiner: {
      products: props?.orderEdit?.missingDinnerProducts?.products ?? [],
      extras: props?.orderEdit?.missingDinnerProducts?.extra ?? [],
    },
    quantity: props?.orderEdit?.quantity ?? 1,
  }),
  validationSchema: Yup.object().shape({
    date: Yup.date().typeError("Date invalide"),
    name: Yup.string().required("Le nom du convive est obligatoire."),
    statut: Yup.string().required(),
    lunchMenu: Yup.number().required("Champs obligatoire"),
    produitsDejeuner: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().positive(),
        })
      )
      .min(1, "La commande nécessite au moins un produit au déjeuner."),
    produitsDiner: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().positive(),
      })
    ),
    supplementsDejeuner: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().positive(),
      })
    ),
    supplementsDiner: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().positive(),
      })
    ),
    deliveryDate: Yup.date()
      .required("Champs obligatoire")
      .typeError("Date invalide"),
    round: Yup.number().positive().required("Champs obligatoire"),
  }),
  handleSubmit: (values, { props }) => {
    const includedLunchExtras = values.supplementsDejeunerInclus.map(
      (item) => ({
        supplement: `/supplements/${item.supplement}`,
        qte: item.qte,
        isMenuIncluded: item.isMenuIncluded,
      })
    );
    const notIncludedLunchExtras = values.supplementsDejeuner.map((item) => ({
      supplement: `/supplements/${item.supplement}`,
      qte: item.qte,
      isMenuIncluded: item.isMenuIncluded,
    }));
    const lunchExtras = includedLunchExtras.concat(notIncludedLunchExtras);

    const includedDinnerExtras = values.supplementsDinerInclus.map((item) => ({
      supplement: `/supplements/${item.supplement}`,
      qte: item.qte,
      isMenuIncluded: item.isMenuIncluded,
    }));
    const notIncludedDinnerExtras = values.supplementsDiner.map((item) => ({
      supplement: `/supplements/${item.supplement}`,
      qte: item.qte,
      isMenuIncluded: item.isMenuIncluded,
    }));
    const dinnerExtras = includedDinnerExtras.concat(notIncludedDinnerExtras);

    const commande = {
      ...(props?.orderEdit ?? null),
      produitsDejeuner: values.produitsDejeuner.map(
        (item) => `/produits/${item.id}`
      ),
      produitsDiner: values.produitsDiner.map((item) => `/produits/${item.id}`),
      setMenu: `/menus/${values.menu.id}`,
      deliveryDate: format(values.deliveryDate, "yyyy-MM-dd"),
      statut: values.statut,
      supplementsDejeuner: lunchExtras,
      supplementsDiner: dinnerExtras,
      lunchMenu: `/formules/${values.lunchMenu}`,
      dinnerMenu:
        values.dinnerMenu !== "Aucune"
          ? `/formules/${values.dinnerMenu}`
          : null,
      estOffert: values.estOffert,
      name: values.name,
      recipient: values.other ? null : `/recipients/${values.recipient}`,
      round: `/rounds/${values.round}`,
      organization: `/organizations/${props.user.id}`,
      quantity: values.quantity,
      date: format(values.date, "yyyy-MM-dd"),
    };
    props.validation(commande);
  },
})(OrderForm);
