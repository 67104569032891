import { Paper, Stack, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import React from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { DELIVERY_STATUS } from "../../Common/Enums/Enums";
import UseStyle from "../../Common/StyledComponent/UseStyle";

const DeliveriesChart = (props) => {
  const classes = UseStyle();
  const data = [
    {
      name: "Livrées",
      value: props.deliveries.filter(
        (delivery) => delivery.status === DELIVERY_STATUS.DELIVERED.status
      ).length,
    },
    {
      name: "En attente de préparations",
      value: props.deliveries.filter(
        (delivery) => delivery.status === DELIVERY_STATUS.PENDING_PREP.status
      ).length,
    },
    {
      name: "En attente de livraisons",
      value: props.deliveries.filter(
        (delivery) => delivery.status === DELIVERY_STATUS.PENDING_DELIV.status
      ).length,
    },
    {
      name: "En livraisons",
      value: props.deliveries.filter(
        (delivery) => delivery.status === DELIVERY_STATUS.DELIVERED.status
      ).length,
    },
    {
      name: "Annulées",
      value: props.deliveries.filter(
        (delivery) => delivery.status === DELIVERY_STATUS.CANCELLED.status
      ).length,
    },
  ];
  const COLORS = [green[700], "#ed6c02", "#e84f36", "#0288d1", red[900]];
  const values = data.map((item) => item.value);
  const sum = values.reduce((acc, value) => {
    return acc + value;
  });

  return (
    <Paper className={classes.paper} elevation={3}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography variant="h6" textAlign="center">
          Livraisons du jour par statut
        </Typography>

        <PieChart width={120} height={120}>
          {sum === 0 ? (
            <Pie
              data={[{ name: "No Data", value: 1 }]}
              fill="#eeeeee"
              innerRadius={40}
              outerRadius={60}
              labelLine={false}
            />
          ) : (
            <Pie
              data={data}
              dataKey="value"
              fill="#8884d8"
              innerRadius={40}
              outerRadius={60}
              labelLine={false}
              paddingAngle={5}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          )}

          {sum !== 0 && <Tooltip animationEasing="ease" />}
        </PieChart>

        {sum === 0 && (
          <Typography variant="body1">
            <i>Pas de livraisons</i>
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default DeliveriesChart;
