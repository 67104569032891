import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  List,
  Card,
  CardHeader,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  FormControlLabel,
  Switch,
  Collapse,
  IconButton,
  TextField,
  Typography,
  Pagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  Stack,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReplayIcon from "@mui/icons-material/Replay";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { grey, red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { isAnyAdmin } from "../../../../utils";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {
  const userData = useSelector((state) => state.auth.userData);
  const initializeRight = (products) => {
    return products.map((product) => ({
      id: product.id,
      produit: product,
      qte: 1,
    }));
  };

  const [checkedSwitch, setCheckedSwitch] = useState(false);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(props.left);
  const [right, setRight] = useState(
    props.commandeList ? initializeRight(props.right) : props.right
  );
  const [categorieSelectionnee, setCategorieSelectionnee] = useState(null);
  const [recherche, setRecherche] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [regimeSelectionne, setRegimeSelectionne] = useState(null);

  const pageSize = 20;
  const [pageCount, setPageCount] = React.useState(
    Math.ceil(left.length / pageSize)
  );
  const [page, setPage] = React.useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const paginate = (array, pageSize, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };
  const sort = function (items) {
    const categoryRetriever = (item) => {
      return item?.produit?.categorie.nom ?? item?.categorie.nom;
    };
    items.sort((a, b) => {
      var a = categoryRetriever(a);
      var b = categoryRetriever(b);
      const noms = props.categories.map((categorie) => categorie.nom);
      return noms.indexOf(a) - noms.indexOf(b);
    });
  };

  useEffect(() => {
    setPageCount(Math.ceil(left.length / pageSize));
  }, [left]);

  useEffect(() => {
    if (props.commandeList || props.menuList) {
      sort(right);
    }
  }, [right]);

  const colors = [
    "#E57373",
    "#F8BBD0",
    "#9FA8DA",
    "#A5D6A7",
    "#FFF59D",
    "#FFAB91",
    "#4DD0E1",
    "#283593",
    "#2E7D32",
  ];
  const useStyles = makeStyles({
    root: {
      "&:hover": {
        border: 1,
        borderColor: "black",
      },
    },
    selected: {},
  });
  const classes = useStyles();
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(
    checked,
    right.map((item) => item.id)
  );
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const numberOfChecked = (items) => intersection(checked, items).length;
  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  const handleCheckedLeft = () => {
    const notRightChecked = not(
      right.map((item) => item.id),
      rightChecked
    );

    setLeft(left.concat(rightChecked));
    setRight(right.filter((item) => notRightChecked.includes(item.id)));
    props.chosen(right.filter((item) => notRightChecked.includes(item.id)));
    setChecked(not(checked, rightChecked));
  };
  const handleCheckedRight = () => {
    setRight(
      right.concat(
        leftChecked.map((item) => ({
          id: item,
          produit: props.items.find((value) => value.id === item),
          qte: 1,
        }))
      )
    );
    setLeft(not(left, leftChecked));
    props.chosen(
      right.concat(
        leftChecked.map((item) => ({
          id: item,
          produit: props.items.find((value) => value.id === item),
          qte: 1,
        }))
      )
    );
    setChecked(not(checked, leftChecked));
  };
  const handleSwitch = () => {
    setCheckedSwitch((prev) => !prev);
  };
  const filtreItems = (categorieId, regimeId) => {
    let itemsFiltres = [];

    if (categorieId && regimeId === null) {
      itemsFiltres = props.items.filter(
        (item) => item.categorie.id === categorieId
      );
    } else if (categorieId === null && regimeId) {
      props.items.forEach((item) =>
        Object.values(item.regimesAdaptes).forEach((regime) => {
          if (regime.id === regimeId) {
            itemsFiltres.push(item);
          }
        })
      );
    } else if (categorieId && regimeId) {
      props.items.forEach((item) => {
        Object.values(item.regimesAdaptes).forEach((regime) => {
          if (item.categorie.id === categorieId && regime.id === regimeId) {
            itemsFiltres.push(item);
          }
        });
      });
    }

    let ItemsIdsFiltres = itemsFiltres.map((item) => item.id);

    setCategorieSelectionnee(categorieId);
    setRegimeSelectionne(regimeId);
    setLeft(
      not(
        ItemsIdsFiltres,
        right.map((item) => item.id)
      )
    );
  };
  const searchItem = (event) => {
    let recherche = event.target.value;
    if (recherche !== "") {
      let newLeft = [];
      props.items.forEach((item) => {
        item.nom.toLowerCase().includes(recherche.toLowerCase()) &&
          newLeft.push(item.id);
      });
      setLeft(newLeft);
    }
    setRecherche(recherche);
  };
  const findId = (item) => {
    return item.produit ? item.produit.id : item.id;
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const categories = props.categories.map((categorie, index) => ({
    ...categorie,
    couleur: colors[index],
  }));
  const customListLeft = (title, items, highlighted) => (
    <Stack direction={"row"} spacing={1} justifyContent={"center"}>
      <Collapse orientation="horizontal" in={checkedSwitch}>
        <Card>
          <CardHeader
            title="Filtres"
            titleTypographyProps={{
              fontSize: 16,
            }}
            subheader={""}
            sx={{ px: 2, py: 1.7 }}
            action={
              <IconButton
                aria-label="reinitialise"
                onClick={() => {
                  setLeft(props.left);
                  setCategorieSelectionnee(null);
                  setRegimeSelectionne(null);
                  setRecherche("");
                }}
              >
                <ReplayIcon />
              </IconButton>
            }
          />
          <Accordion
            expanded={expanded === "fitlreCat"}
            onChange={handleChange("fitlreCat")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="fitlreCatbh-content"
              id="fitlreCatbh-header"
            >
              <Typography>Catégories</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  bgcolor: "background.paper",
                  overflow: "auto",
                  height: { md: 135, lg: 165 },
                  width: { md: 120, lg: 160 },
                }}
                dense
                component="div"
                role="list"
              >
                {categories.map((categorie, index) => (
                  <ListItemButton
                    key={categorie.id}
                    onClick={() => {
                      filtreItems(categorie.id, regimeSelectionne);
                      setRecherche("");
                    }}
                    {...(categorieSelectionnee &&
                      categorieSelectionnee === categorie.id && {
                        classes: {
                          root: classes.root,
                          selected: classes.selected,
                        },
                        selected: true,
                      })}
                    sx={{ backgroundColor: categorie.couleur }}
                  >
                    {categorie.nom}
                  </ListItemButton>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "filtreReg"}
            onChange={handleChange("filtreReg")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filtreRegbh-content"
              id="filtreRegbh-header"
            >
              <Typography>Régimes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  bgcolor: "background.paper",
                  overflow: "auto",
                  height: { md: 150, lg: 180 },
                  width: { md: 120, lg: 160 },
                }}
                dense
                component="div"
                role="list"
              >
                {props.regimes.length > 0 &&
                  props.regimes.map((regime) => (
                    <ListItemButton
                      key={regime.id}
                      onClick={() => {
                        filtreItems(categorieSelectionnee, regime.id);
                        setRecherche("");
                      }}
                      {...(regimeSelectionne &&
                        regimeSelectionne === regime.id && {
                          classes: {
                            root: classes.root,
                            selected: classes.selected,
                          },
                          selected: true,
                        })}
                    >
                      {regime.nom}
                    </ListItemButton>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Collapse>

      <Card sx={{ width: { md: 300, lg: 350 } }}>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} sélectionné(s)`}
          {...(isAnyAdmin(userData) && {
            action: (
              <FormControlLabel
                control={
                  <Switch checked={checkedSwitch} onChange={handleSwitch} />
                }
                label=""
              />
            ),
          })}
        />

        {isAnyAdmin(userData) && (
          <>
            <Divider />
            <TextField
              size="small"
              fullWidth
              label="Rechercher un produit"
              variant="standard"
              value={recherche}
              onChange={(event) => searchItem(event)}
            />
          </>
        )}

        <List
          sx={{
            height: { md: 220, lg: 240 },
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
          disablePadding={true}
        >
          {items.map((item, index) => {
            const labelId = `transfer-list-all-item-${item}-label`;
            let produit = props.items.find((value) => value.id === item);
            let categorie = null;

            if (props.categories.length > 0 && produit) {
              categorie = categories.find((c) => c.id === produit.categorie.id);
            }

            return (
              <ListItemButton
                key={index}
                onClick={handleToggle(item)}
                {...(highlighted &&
                  highlighted.includes(item) && {
                    classes: {
                      root: classes.root,
                      selected: classes.selected,
                    },
                    selected: true,
                  })}
                sx={{
                  backgroundColor: categorie && categorie.couleur,
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                    value={item}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${produit && produit.nom}`}
                />
              </ListItemButton>
            );
          })}
        </List>

        <Divider />

        <Box sx={{ m: 1, display: "center", justifyContent: "center" }}>
          <Pagination
            count={pageCount}
            color="primary"
            showFirstButton
            showLastButton
            page={page}
            onChange={handleChangePage}
            size="small"
          />
        </Box>
      </Card>
    </Stack>
  );
  const customListRight = (title, items) => {
    return (
      <Stack direction={"row"} spacing={1} justifyContent={"center"}>
        <Card
          sx={{
            width: { md: 300, lg: 350 },
            border: props.error && 1,
            borderColor: props.error && red[900],
          }}
        >
          <CardHeader
            sx={{ px: 2, py: 1 }}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items.map((item) => item.id))}
                checked={
                  numberOfChecked(items.map((item) => item.id)) ===
                    items.length && items.length !== 0
                }
                indeterminate={
                  numberOfChecked(items.map((item) => item.id)) !==
                    items.length &&
                  numberOfChecked(items.map((item) => item.id)) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                  "aria-label": "all items selected",
                }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${
              items.length
            } sélectionné(s)`}
          />
          <Divider />
          <List
            sx={{
              height: {
                md: isAnyAdmin(userData) ? 240 : 185,
                lg: isAnyAdmin(userData) ? 340 : 285,
              },
              bgcolor: "background.paper",
              overflow: "auto",
            }}
            dense
            component="div"
            role="list"
            disablePadding={true}
          >
            {items.map((item) => {
              const labelId = `transfer-list-all-item-${item.id}-label`;
              const id = item.produit ? item.produit.id : item.id;
              let produit = props.items.find((produit) => produit.id === id);
              let categorie = null;

              if (props.categories.length > 0 && produit) {
                categorie = categories.find(
                  (c) => c.id === produit.categorie.id
                );
              }
              return (
                <ListItemButton
                  key={item.id}
                  onClick={handleToggle(item.id)}
                  sx={{
                    backgroundColor: categorie && categorie.couleur,
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(item.id) !== -1}
                      onChange={handleToggle(item.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      value={item.id}
                    />
                  </ListItemIcon>

                  <ListItemText
                    id={labelId}
                    primary={`${
                      props.items.find((value) => value.id === findId(item)).nom
                    }`}
                  />

                  {props.qte && (
                    <TextField
                      label="Qté"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "80px" }}
                      size="small"
                      value={item.qte}
                      onChange={(event) => {
                        const qte = +event.target.value;
                        if (qte >= 0) {
                          setRight(
                            right.map((value) =>
                              item.id === value.id
                                ? { ...value, qte: qte }
                                : value
                            )
                          );
                          props.chosen(
                            right.map((value) =>
                              item.id === value.id
                                ? { ...value, qte: qte }
                                : value
                            )
                          );
                        }
                      }}
                    />
                  )}
                </ListItemButton>
              );
            })}
          </List>
        </Card>
      </Stack>
    );
  };

  return (
    <FormControl fullWidth>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={"auto"}>
          {customListLeft(
            "Choix",
            paginate(left, pageSize, page),
            props.highlighted
          )}
        </Grid>

        <Grid item container direction="column" alignItems="center" xs={"auto"}>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>

        <Grid item xs={"auto"}>
          {customListRight("Sélectionnés", right)}
        </Grid>
      </Grid>

      <FormHelperText error sx={{ textAlign: "center" }}>
        {props.error && props.helperText}
      </FormHelperText>
    </FormControl>
  );
}
