import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import UseStyle from "../Common/StyledComponent/UseStyle";

const PlanForm = (props) => {
  const classes = UseStyle();

  return (
    <Paper className={classes.paper} elevation={6}>
      <form>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label="Nom"
              value={props.values.name}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.name && Boolean(props.errors.name)}
              helperText={
                props.errors.name && props.touched.name && props.errors.name
              }
            />
          </Grid>

          <Grid container item justifyContent="flex-end" xs={12}>
            <LoadingButton
              loading={props.isLoading}
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={props.handleSubmit}
            >
              {props.edit ? "Sauvegarder" : "Créer"}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default PlanForm;
