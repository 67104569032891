import React, { useEffect, useState } from "react";
import { requests } from "../../../agent";
import { Snackbar, Alert } from "@mui/material";
import Profile from "./Profile";
import { useSelector } from "react-redux";

const ProfileContainer = (props) => {
  const userData = useSelector((state) => state.auth.userData);

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, []);

  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const handleSubmitInfos = (user) => {
    setIsLoading(true);
    requests
      .patch(`/users/${user.id}`, user, true)
      .then((response) => {
        setIsLoading(false);
        setUser(response);
        setAlert({
          active: true,
          type: "success",
          message: "Vos informations ont bien été mise à jour !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };

  const handleSubmitPassword = (data) => {
    setIsLoading(true);
    requests
      .patch(`/users/${user.id}/change/password`, data, true)
      .then((response) => {
        setIsLoading(false);
        setUser(response);
        setAlert({
          active: true,
          type: "success",
          message: "Vos informations ont bien été mise à jour !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };

  return (
    <>
      {user && (
        <Profile
          user={user}
          handleSubmitInfos={handleSubmitInfos}
          handleSubmitPassword={handleSubmitPassword}
          isLoading={isLoading}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          sx={{ zIndex: "snackbar" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ProfileContainer;
