import { makeStyles } from "@mui/styles";

const UseStyle = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "15px",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  inputField: {
    borderRadius: "15px",
  },
  card: {
    borderRadius: "15px",
  },
}));

export default UseStyle;
