import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { format } from "date-fns";

const MonthView = (props) => {
  return (
    <Stack direction="row" flex={1} height="350px">
      {Object.values(props.week).map((day, i) => {
        const menu = props.menus.find(
          (menu) =>
            format(new Date(menu.date), "yyyy-MM-dd") ===
            format(day.date, "yyyy-MM-dd")
        );

        return (
          <Stack key={i} flex={1} marginBottom={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                borderBottom: 1,
                borderBottomColor: "divider",
              }}
            >
              <Typography variant="body2" marginRight={2}>
                {day.day} {day.dayNumber}
              </Typography>
            </Box>

            {menu ? (
              <Stack
                padding={2}
                spacing={2}
                height="100%"
                sx={{
                  borderLeft: 1,
                  borderRight: i + 1 === props.week.length ? 1 : 0,
                  borderColor: "divider",
                }}
              >
                <Stack
                  padding={2}
                  sx={{
                    height: "100%",
                    overflow: "auto",
                    background: "rgba(238, 175, 97, 0.4)",
                    borderRadius: "10px",
                  }}
                >
                  {menu.lunchProducts
                    .sort((a, b) => a.categorie.priorite - b.categorie.priorite)
                    .map((mp, i) => (
                      <Typography key={i} variant="body2" textAlign="left">
                        • {mp.nom}
                      </Typography>
                    ))}

                  {menu.lunchExtras.length > 0 &&
                    menu.lunchExtras.map((extra, i) => (
                      <Typography key={i} variant="body2" textAlign="left">
                        • {extra.nom}
                      </Typography>
                    ))}
                </Stack>

                {menu.dinnerProducts.length > 0 && (
                  <Stack
                    padding={2}
                    sx={{
                      height: "100%",
                      overflow: "auto",
                      background: "rgba(106, 13, 131, 0.4)",
                      borderRadius: "10px",
                    }}
                  >
                    {menu.dinnerProducts
                      .sort(
                        (a, b) =>
                          a.produit.categorie.priorite -
                          b.produit.categorie.priorite
                      )
                      .map((mp, i) => (
                        <Typography key={i} variant="body2" textAlign="left">
                          • {mp.produit.nom}
                        </Typography>
                      ))}

                    {menu.dinnerExtras.length > 0 &&
                      menu.dinnerExtras.map((item, i) => (
                        <Typography key={i} variant="body2" textAlign="left">
                          • {item.nom}
                        </Typography>
                      ))}
                  </Stack>
                )}
              </Stack>
            ) : (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                height="100%"
                sx={{
                  borderLeft: 1,
                  borderRight: i + 1 === props.week.length ? 1 : 0,
                  borderColor: "divider",
                }}
              >
                <Tooltip title="Aucun menu">
                  <CancelIcon fontSize="medium" color="disabled" />
                </Tooltip>
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default MonthView;
