import React from "react";
import {
  Card,
  CardHeader,
  CircularProgress,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";
import { withFormik } from "formik";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <>
      <form>
        <Stack spacing={2}>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Button
              variant={"contained"}
              onClick={props.toggleList}
              color="secondary"
              startIcon={<ArrowBackIcon />}
            >
              Retour
            </Button>
          </Stack>

          <Card className={classes.card}>
            <CardHeader
              subheader="Ces informations peuvent être modifier."
              title="Modification d'un allergène"
            />
          </Card>

          <Paper className={classes.paper} elevation={6}>
            <TextField
              error={props.errors.nom && props.touched.nom ? true : false}
              {...(props.errors.nom &&
                props.touched.nom && { helperText: props.errors.nom })}
              fullWidth
              name={"nom"}
              label={"Nom de l'allergène"}
              variant="outlined"
              type={"text"}
              value={props.values.nom}
              className={classes.inputField}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />

            <Stack direction={"row"} justifyContent={"flex-end"} marginTop={2}>
              {props.loading ? (
                <CircularProgress color="secondary" />
              ) : (
                <Button
                  color="primary"
                  onClick={props.handleSubmit}
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  Modifier l'allergène
                </Button>
              )}
            </Stack>
          </Paper>
        </Stack>
      </form>
    </>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    nom: props.dataAllergeneEdit.nom,
  }),
  validationSchema: Yup.object().shape({
    nom: Yup.string().min(3).max(100).required(),
  }),
  handleSubmit: (values, { props }) => {
    props.validation(props.dataAllergeneEdit.id, values.nom);
  },
})(Edit);
