import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Sun from "../../../../../assets/img/sun.png";
import Moon from "../../../../../assets/img/moon.png";

const OrderBag = ({ formule, products, extras, isDinner }) => {
  return (
    <Paper
      sx={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${
          !isDinner ? Sun : Moon
        })`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        bgcolor: !isDinner ? "#FFE699" : "#C996CC",
      }}
    >
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{formule.name}</strong>
              </TableCell>
              <TableCell>{isDinner ? "Dîner" : "Déjeuner"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products &&
              products
                .sort((a, b) => a.categorie.priorite - b.categorie.priorite)
                .map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>{product.nom}</TableCell>
                    <TableCell component="th" scope="row">
                      1
                    </TableCell>
                  </TableRow>
                ))}
            {extras.length > 0 &&
              extras.map((orderExtra) => (
                <TableRow key={orderExtra.id}>
                  <TableCell>{orderExtra.supplement.nom}</TableCell>
                  <TableCell component="th" scope="row">
                    {orderExtra.qte}
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell sx={{ fontSize: 22 }} colSpan={1} align="right">
                <strong>Nombre total de produits :</strong>
              </TableCell>
              <TableCell sx={{ fontSize: 22 }}>
                {products && extras && (
                  <strong>{products.length + extras.length}</strong>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OrderBag;
