import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import SubscriptionForm from "../SubscriptionForm";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'une souscription"
        />
      </Card>

      <SubscriptionForm {...props} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    organization: null,
    plan: null,
    addOns: [],
  }),
  validationSchema: Yup.object().shape({
    organization: Yup.number()
      .positive()
      .required("Ce champs est obligatoire.")
      .typeError("Ce champs est obligatoire."),
    plan: Yup.number()
      .positive()
      .required("Ce champs est obligatoire.")
      .typeError("Ce champs est obligatoire."),
    addOns: Yup.array().of(Yup.number().positive()),
  }),
  handleSubmit: (values, { props }) => {
    const newItem = {
      organization: `/organizations/${values.organization}`,
      plan: `/plans/${values.plan}`,
      addOns: values.addOns.map((addon) => `/add_ons/${addon}`),
    };

    props.validation(newItem);
  },
})(Add);
