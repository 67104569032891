import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Button, LinearProgress, Paper, Stack, Tooltip } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import React from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import Filters from "../../Common/Filters";

const List = (props) => {
  const classes = UseStyle();
  let columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "priceIncludingTax",
      headerName: "Prix TTC (€)",
      flex: 1,
    },
    {
      field: "priceExcludingTax",
      headerName: "Prix HT (€)",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Créé le",
      flex: 1,
      type: "date",
      valueGetter: ({ value }) => new Date(value),
    },
    {
      field: "isEnabled",
      headerName: "Statut",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.value ? (
          <Tooltip title="Actif">
            <CheckCircleOutlineRoundedIcon color="success" />
          </Tooltip>
        ) : (
          <Tooltip title="Inactif">
            <HighlightOffRoundedIcon color="error" />
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
        <GridActionsCellItem
          disabled={!params.row.isEnabled}
          color="error"
          icon={
            <Tooltip title="Désactiver">
              <DisabledByDefaultRoundedIcon />
            </Tooltip>
          }
          onClick={() => props.handleDisable(params.id)}
          label="Désactiver"
        />,
      ],
    },
  ];

  return (
    <Stack spacing={2} marginBottom={2}>
      <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>
        <LoadingButton
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
          loading={props.isLoading}
        >
          Offre
        </LoadingButton>
      </Stack>

      <Filters
        userData={props.userData}
        types={["fullName"]}
        from="plans"
        handleSubmitFilters={props.handleSubmitFilters}
        loading={props.isLoading}
      />

      <Paper className={classes.paper} elevation={6}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={props.plans}
          pageSize={30}
          density="comfortable"
          disableSelectionOnClick
          pagination
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          loading={props.isLoading}
          experimentalFeatures={{ newEditingApi: true }}
          getRowHeight={() => "auto"}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </Paper>
    </Stack>
  );
};

export default List;
