import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import UserForm from "../UserForm";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { isAdmin } from "../../../../utils";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          loading={props.isLoading}
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </LoadingButton>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Modification d'un utilisateur"
        />
      </Card>

      <UserForm {...props} isEdit={true} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    client: isAdmin(props.userData)
      ? props.user.organization.id
      : props.userData.organization.id,
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    roles: props.user.roles,
    email: props.user.email,
    phone: props.user.telephone,
    password: "",
    confirmPassword: "",
  }),
  validationSchema: Yup.object().shape({
    client: Yup.string()
      .required()
      .typeError("Veuillez sélectionner un client"),
    firstName: Yup.string().required("Veuillez saisir votre prénom"),
    lastName: Yup.string().required("Veuillez saisir votre nom"),
    email: Yup.string()
      .email("L'email est invalide.")
      .required("L'email est obligatoire. Ex: nom@exemple.fr"),
    phone: Yup.string()
      .required("Un numéro de téléphone est requis")
      .matches(
        "[0]{1}[1-7]{1}[0-9]{8}",
        "Veuillez saisir un numéro de téléphone valide."
      ),
    password: Yup.string().min(
      6,
      "Votre nouveau mot de passe doit contenir au minimum 6 caractères."
    ),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().required("Veuillez confirmer votre mot de passe."),
      })
      .test(
        "passwords-match",
        "Les mots de passe doivent être identiques",
        function (value) {
          return this.parent.password === value;
        }
      ),
    roles: Yup.array()
      .required()
      .min(1, "Veuillez sélectionner au moins un rôle"),
  }),
  handleSubmit: (values, { props }) => {
    let newUser = {
      id: props.user.id,
      firstName:
        values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1),
      lastName: values.lastName.toUpperCase(),
      email: values.email,
      telephone: values.phone,
      roles: values.roles,
    };

    if (values.password.length > 0) {
      newUser.password = values.password;
      newUser.plainPassword = values.confirmPassword;
    }

    props.validation(newUser);
  },
})(Edit);
