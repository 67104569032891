import React, { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { ORDER_STATUS } from "../../Common/Enums/Enums";
import { locale, mask } from "../../../../localisation";
import LoadingButton from "@mui/lab/LoadingButton";
import TabList from "@mui/lab/TabList/TabList";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import TabContext from "@mui/lab/TabContext/TabContext";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  RadioGroup,
  Radio,
  Select,
  TextField,
  Tooltip,
  DialogContentText,
  Stack,
  Autocomplete,
  Chip,
  Drawer,
  Tab,
  Input,
  FormGroup,
  Checkbox,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GridActionsCellItem, useGridApiContext } from "@mui/x-data-grid";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import WarningIcon from "@mui/icons-material/Warning";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DoneIcon from "@mui/icons-material/Done";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import * as Yup from "yup";
import { useFormik } from "formik";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { isAdmin, isAnyAdmin } from "../../../../utils";
import DeliveryInfos from "../Delivery/DeliveryInfos";
import Calendar from "./Calendar";
import Table from "./Table";

const StateChangerDialog = ({
  loading,
  isChangingState,
  handleClose,
  handleSubmitOrdersState,
  fetchOrders,
  queryOptions,
  clients,
  userData,
}) => {
  const [selectedClient, setSelectedClient] = useState(
    !isAdmin(userData) ? { client: userData } : null
  );
  const formik = useFormik({
    initialValues: {
      date: new Date(),
      state: "",
      client: null,
      all: false,
    },
    validationSchema: Yup.object({
      date: Yup.date().required().typeError("Date invalide"),
      state: Yup.string().required("Sélectionner un statut à appliquer."),
      client: Yup.number()
        .nullable()
        .when("all", {
          is: false,
          then: Yup.number().required().typeError("Champs obligatoire"),
        }),
      all: Yup.boolean().required(),
    }),
    onSubmit: (values, actions) => {
      handleSubmitOrdersState(
        values.date,
        values.state,
        values.client,
        values.all,
        () => fetchOrders(queryOptions)
      );
      handleClose();
      actions.resetForm();
      setSelectedClient(null);
    },
  });

  return (
    <Dialog
      open={isChangingState}
      onClose={() => {
        handleClose();
        formik.handleReset();
        setSelectedClient(null);
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        Changer le statut des commandes.
        <DialogContentText>
          <i>Chaque champs est obligatoire.</i>
        </DialogContentText>
        <DialogContentText>
          <i>
            <u>
              Toutes vos commandes doivent être complète afin de pouvoir valider
              !
            </u>
          </i>
        </DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={formik.errors.date && formik.touched.date ? true : false}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  label="Date"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  renderInput={(params) => (
                    <TextField fullWidth size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              size="small"
              error={formik.errors.state && formik.touched.state ? true : false}
            >
              <InputLabel id="status">Statut</InputLabel>
              <Select
                labelId="status"
                id="status"
                label="Statut"
                value={formik.values.state}
                onChange={(event) =>
                  formik.setFieldValue("state", event.target.value)
                }
              >
                {Object.values(ORDER_STATUS).map((value) => (
                  <MenuItem key={value.status} value={value.status}>
                    {value.description}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {formik.errors.state &&
                  formik.touched.state &&
                  formik.errors.state}
              </FormHelperText>
            </FormControl>

            <Autocomplete
              fullWidth
              disabled={formik.values.all}
              options={clients.sort(
                (a, b) => -b.filtre.localeCompare(a.filtre)
              )}
              isOptionEqualToValue={(option, value) =>
                option.client.id === value.client.id
              }
              groupBy={(value) => value.filtre}
              getOptionLabel={(option) => {
                if (!option.client) return "";
                return option.client.id + " - " + option.client.name;
              }}
              value={selectedClient}
              onChange={(event, value) => {
                setSelectedClient(value);

                value !== null
                  ? formik.setFieldValue("client", value.client.id)
                  : formik.setFieldValue("client", null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  size="small"
                  error={formik.touched.client && Boolean(formik.errors.client)}
                  helperText={
                    formik.errors.client &&
                    formik.touched.client &&
                    formik.errors.client
                  }
                />
              )}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.all}
                    onChange={(event) => {
                      formik.setFieldValue("all", event.target.checked);
                      if (event.target.checked) {
                        setSelectedClient(null);
                        formik.setFieldValue("client", null);
                      }
                    }}
                    name="all"
                  />
                }
                label="Tout les clients"
              />
              <FormHelperText error>
                {formik.errors.all && formik.touched.all && formik.errors.all}
              </FormHelperText>
            </FormGroup>
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          loading={loading}
          onClick={() => {
            handleClose();
            formik.handleReset();
            setSelectedClient(null);
          }}
        >
          Annuler
        </LoadingButton>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          start={<DoneIcon />}
          onClick={formik.handleSubmit}
        >
          Valider
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const GenerateDialog = ({
  loading,
  isGenerating,
  handleClose,
  handleGenerate,
  clients,
  rounds,
  userData,
  fetchOrders,
  queryOptions,
}) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const formik = useFormik({
    initialValues: {
      start: "",
      end: "",
      round: null,
      client: null,
    },
    validationSchema: Yup.object({
      start: Yup.date().typeError("Date invalide").nullable(),
      end: Yup.date().typeError("Date invalide").nullable(),
    }),
    onSubmit: (values, actions) => {
      const data = {
        start: values.start,
        end: values.end,
        round: values.round,
        client: isAnyAdmin(userData) ? values.client : userData.organization.id,
      };
      handleGenerate(data, () => fetchOrders(queryOptions));
      handleClose();
      actions.resetForm();
      setSelectedClient(null);
    },
  });

  return (
    <Dialog
      open={isGenerating}
      onClose={() => {
        handleClose();
        formik.handleReset();
        setSelectedClient(null);
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        Générer des commandes
        <DialogContentText>
          <i>Chaque champs n'est pas obligatoire.</i>
        </DialogContentText>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="De"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.start}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("start", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={
                          formik.touched.start && Boolean(formik.errors.start)
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.start &&
                    formik.touched.start &&
                    formik.errors.start}
                </FormHelperText>
              </FormControl>

              <ArrowRightAltIcon color="disabled" fontSize="large" />

              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="À"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.end}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("end", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={formik.touched.end && Boolean(formik.errors.end)}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.end && formik.touched.end && formik.errors.end}
                </FormHelperText>
              </FormControl>
            </Stack>
          </Grid>

          {isAnyAdmin(userData) && (
            <Grid item container justifyContent="center" alignItems="center">
              <FormControl fullWidth>
                <Autocomplete
                  options={clients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.client.id === value.client.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.client) return "";
                    return option.client.id + " - " + option.client.name;
                  }}
                  value={selectedClient}
                  onChange={(event, value) => {
                    setSelectedClient(value);

                    value !== null
                      ? formik.setFieldValue("client", value.client.id)
                      : formik.setFieldValue("client", null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Client" />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <Grid item container justifyContent="center" alignItems="center">
            <FormControl fullWidth size="small">
              <InputLabel id="round">Tournées</InputLabel>
              <Select
                labelId="round"
                id="round"
                value={formik.values.round}
                label="Tournées"
                name="round"
                onChange={(event) => {
                  formik.setFieldValue(
                    "round",
                    event.target.value !== "none" ? event.target.value : null
                  );
                }}
              >
                <MenuItem value="none">
                  <i>Toutes</i>
                </MenuItem>

                {rounds
                  .sort((a, b) => -b.name.localeCompare(a.name))
                  .map((round) => (
                    <MenuItem key={round.id} value={round.id}>
                      {round.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            formik.handleReset();
            setSelectedClient(null);
          }}
        >
          Annuler
        </Button>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          startIcon={<CachedIcon />}
          onClick={formik.handleSubmit}
        >
          Générer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const ExportDialog = ({
  loading,
  isExporting,
  handleClose,
  sendDataExport,
}) => {
  const formik = useFormik({
    initialValues: {
      mode: "",
      startDate: new Date(),
      endDate: null,
    },
    validationSchema: Yup.object({
      mode: Yup.string().required("Un mode pour l'export est obligatoire."),
      startDate: Yup.date().required().typeError("Date invalide"),
      endState: Yup.date().typeError("Date invalide"),
    }),
    onSubmit: (values, actions) => {
      sendDataExport(values.mode, values.startDate, values.endDate);
      actions.resetForm({
        values: {
          mode: "",
          startDate: new Date(),
          endDate: null,
        },
      });
      handleClose();
    },
  });

  return (
    <Dialog open={isExporting} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        Exporter des commandes
        <DialogContentText>
          <i>Chaque champs est obligatoire.</i>
        </DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        <form>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              item
              xs={6}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de début"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.startDate}
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue("startDate", value)
                    }
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.startDate &&
                    formik.touched.startDate &&
                    formik.errors.startDate}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FormControl
                variant="outlined"
                fullWidth
                required
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de fin"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.endDate}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("endDate", value)}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.endDate &&
                    formik.touched.endDate &&
                    formik.errors.endDate}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <FormControl>
                <RadioGroup
                  value={formik.values.mode}
                  onChange={(event) =>
                    formik.setFieldValue("mode", event.target.value)
                  }
                >
                  <FormControlLabel
                    value="commandes"
                    control={<Radio />}
                    label="Commandes"
                  />
                  <FormControlLabel
                    value="production"
                    control={<Radio />}
                    label="Production"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <LoadingButton loading={loading} onClick={handleClose}>
          Annuler
        </LoadingButton>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          startIcon={<DownloadRoundedIcon />}
          onClick={formik.handleSubmit}
        >
          Exporter
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const ImportDialog = (props) => {
  const [selectedClient, setSelectedClient] = useState(
    !isAdmin(props.userData) ? { client: props.userData } : null
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const handleResetForm = () => {
    setSelectedFile(null);
    setSelectedClient(null);
  };
  const formik = useFormik({
    initialValues: {
      file: "",
      startDate: null,
      endDate: null,
      client: null,
      round: null,
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required("Champs obligatoire"),
      startDate: Yup.date().required().typeError("Date invalide"),
      endDate: Yup.date().required().typeError("Date invalide"),
      client: Yup.number().required().typeError("Champs obligatoire"),
      round: Yup.number().nullable(),
    }),
    onSubmit: (values, { actions }) => {
      props.handleImport(
        values.file,
        selectedClient,
        format(values.startDate, "yyyy-MM-dd"),
        format(values.endDate, "yyyy-MM-dd"),
        () => props.fetchOrders(props.queryOptions)
      );
    },
  });
  const clients = props.clients.map((client) => {
    const filtre = client.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: client,
    };
  });
  return (
    <Dialog
      open={props.isImporting}
      onClose={() => {
        handleResetForm();
        setSelectedClient(null);
        props.handleClose();
      }}
      fullWidth
    >
      <DialogTitle>
        Importer les choix de menus des convives
        <DialogContentText>
          <i>La tournée n'est pas obligatoire.</i>
        </DialogContentText>
      </DialogTitle>

      <DialogContent>
        <form>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
              marginTop={1}
            >
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de début"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.startDate}
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue("startDate", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={
                          formik.touched.startDate &&
                          Boolean(formik.errors.startDate)
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.startDate &&
                    formik.touched.startDate &&
                    formik.errors.startDate}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
              container
              justifyContent={"center"}
              alignItems={"center"}
              marginTop={1}
            >
              <FormControl variant="outlined" fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locale["fr"]}
                >
                  <DatePicker
                    mask={mask["fr"]}
                    label="Date de fin"
                    inputFormat="dd/MM/yyyy"
                    value={formik.values.endDate}
                    onBlur={formik.handleBlur}
                    onChange={(value) => formik.setFieldValue("endDate", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={
                          formik.touched.endDate &&
                          Boolean(formik.errors.endDate)
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText error>
                  {formik.errors.endDate &&
                    formik.touched.endDate &&
                    formik.errors.endDate}
                </FormHelperText>
              </FormControl>
            </Grid>

            {isAdmin(props.userData) && (
              <Grid item xs={12}>
                <Autocomplete
                  options={clients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.client.id === value.client.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.client) return "";
                    return option.client.id + " - " + option.client.name;
                  }}
                  value={selectedClient}
                  onChange={(event, value) => {
                    setSelectedClient(value);

                    value !== null
                      ? formik.setFieldValue("client", value.client.id)
                      : formik.setFieldValue("client", null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      size="small"
                      error={
                        formik.touched.client && Boolean(formik.errors.client)
                      }
                      helperText={
                        formik.errors.client &&
                        formik.touched.client &&
                        formik.errors.client
                      }
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item container justifyContent="center" alignItems="center">
              <FormControl fullWidth size="small">
                <InputLabel id="round">Tournée</InputLabel>
                <Select
                  labelId="round"
                  id="round"
                  value={formik.values.round}
                  label="Tournées"
                  name="round"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "round",
                      event.target.value !== "none" ? event.target.value : null
                    );
                  }}
                >
                  <MenuItem value="none">
                    <i>Toutes</i>
                  </MenuItem>

                  {props.rounds
                    .sort((a, b) => -b.name.localeCompare(a.name))
                    .filter((round) => {
                      if (selectedClient) {
                        let clientRounds = selectedClient.client.rounds.map(
                          (r) => r.id
                        );
                        return clientRounds.includes(round.id);
                      }
                    })
                    .map((round) => (
                      <MenuItem key={round.id} value={round.id}>
                        {round.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <label htmlFor="contained-button-file">
                <Input
                  inputProps={{
                    accept: ".csv,.xls,.xlsx",
                  }}
                  id="contained-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    formik.setFieldValue("file", e.target.files[0]);
                  }}
                />
                <LoadingButton
                  loading={props.loading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<UploadRoundedIcon />}
                >
                  Importer un fichier
                </LoadingButton>
              </label>
            </Grid>

            <Grid item xs={6}>
              <LoadingButton
                disabled={
                  !(
                    formik.values.startDate &&
                    formik.values.endDate &&
                    formik.values.client
                  )
                }
                fullWidth
                loading={props.loading}
                color="primary"
                variant="contained"
                startIcon={<DownloadRoundedIcon />}
                onClick={() =>
                  props.downloadImportTemplate(
                    selectedClient,
                    format(formik.values.startDate, "yyyy-MM-dd"),
                    format(formik.values.endDate, "yyyy-MM-dd"),
                    formik.values.round
                  )
                }
              >
                Télécharger le modèle
              </LoadingButton>
            </Grid>

            <Grid item xs={12}>
              {selectedFile ? (
                <Typography variant="body2">{selectedFile.name}</Typography>
              ) : (
                <Typography
                  variant="body2"
                  color={
                    formik.touched.file &&
                    Boolean(formik.errors.file) &&
                    "error"
                  }
                >
                  Aucun fichier sélectionné.
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                <i>
                  Informations concernant le modèle d'import :
                  <br />• Sélectionnez un client et les dates de début et de fin
                  afin de télécharger le modèle.
                  <br />• Vous ne pourrez modifier que les cases de choix de
                  menu sous les colonnes de dates.
                  <br />
                  <br /> Les données générées dans le modèle d'import dépendent
                  :
                  <br />• Des convives actifs du client sélectionné.
                  <br />• De leurs absences et jours de repas.
                  <br />• Des menus créé avec les tournée correspondant à chaque
                  convives.
                  <br />
                  <br />{" "}
                  <strong>
                    Une fois l'import effectué, cela générera toutes les
                    commandes pour chaque convive sur toutes les dates ou un
                    choix de menu a été renseigné.
                  </strong>
                </i>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={() => {
            props.handleClose();
            handleResetForm();
            setSelectedClient(null);
          }}
        >
          Annuler
        </LoadingButton>
        <LoadingButton
          loading={props.loading}
          color="primary"
          variant="contained"
          startIcon={<UploadRoundedIcon />}
          onClick={formik.handleSubmit}
        >
          Importer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const List = (props) => {
  const classes = UseStyle();
  const [tab, setTab] = useState("list");
  const [isFilteredOnRecipient, setIsFilteredOnRecipient] = useState(false);
  const filterFormik = useFormik({
    initialValues: props.filters,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      date: Yup.date().nullable().default(null),
    }),
    onSubmit: (values) => {
      props.setFilters({
        ...values,
        client: values.client?.client.id,
        recipient: values.recipient?.recipient.id,
      });
      setIsFilteredOnRecipient(Boolean(values.recipient));
    },
  });
  const recipients = props.recipients.map((recipient) => {
    const filtre = recipient.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      recipient: recipient,
    };
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const rowCount = props.rowCount;
  const queryOptions = useMemo(
    () => ({
      page: page + 1,
      pageSize,
      filters: { ...props.filters },
    }),
    [page, pageSize, props.filters]
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  const resetFilters = () => {
    filterFormik.setValues({ ...props.filterInitialValues });
    props.setFilters({ ...props.filterInitialValues });
    setIsFilteredOnRecipient(false);
  };

  useEffect(() => {
    props.fetchOrders(queryOptions);
  }, [queryOptions]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (tab === "calendar")
      props.fetchCalendar(queryOptions, () =>
        handleChangeTab(null, "calendar")
      );
  }, [tab]);

  const [confirmMultipleDelete, setConfirmMultipleDelete] = useState(false);
  const [isChangingState, setIsChangingState] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleConfirmMultipleDelete = () => {
    setConfirmMultipleDelete(true);
  };
  const handleClose = () => {
    setConfirmMultipleDelete(false);
    setIsChangingState(false);
    setIsGenerating(false);
    setIsExporting(false);
    setIsImporting(false);
  };
  const renderState = (params) => {
    let state = [];

    if (params.value === ORDER_STATUS.DELIVERED.status) {
      state.push(
        <Chip
          size="small"
          label={ORDER_STATUS.DELIVERED.description}
          color="success"
          variant="outlined"
        />
      );
    } else if (params.value === ORDER_STATUS.DELIVERING.status) {
      state.push(
        <Chip
          size="small"
          label={ORDER_STATUS.DELIVERING.description}
          color="info"
          variant="outlined"
        />
      );
    } else if (params.value === ORDER_STATUS.PREPARED.status) {
      state.push(
        <Chip
          size="small"
          label={ORDER_STATUS.PREPARED.description}
          color="primary"
          variant="outlined"
        />
      );
    } else if (params.value === ORDER_STATUS.VALIDATED.status) {
      state.push(
        <Chip
          size="small"
          label={ORDER_STATUS.VALIDATED.description}
          color="secondary"
          variant="outlined"
        />
      );
    } else if (params.value === ORDER_STATUS.CANCELLED.status) {
      state.push(
        <Chip
          size="small"
          label={ORDER_STATUS.CANCELLED.description}
          color="error"
          variant="outlined"
        />
      );
    } else {
      state.push(
        <Chip
          size="small"
          label={ORDER_STATUS.PENDING.description}
          color="warning"
          variant="outlined"
        />
      );
    }

    return (
      <Stack direction={"row"} spacing={1}>
        {state}
      </Stack>
    );
  };
  const renderIconEstComplete = (params) => {
    if (
      !params.row.isLunchComplete ||
      !params.row.isDinnerComplete ||
      params.row.estOffert ||
      params.row?.pilote
    ) {
      let title = "";

      if (!params.row.isLunchComplete) {
        if (!Array.isArray(params.row.missingLunchProducts.products)) {
          title +=
            "Produits manquants au déjeuner : " +
            Object.entries(params.row.missingLunchProducts.products)
              .map(([key, value]) => value + " " + key)
              .join(", ") +
            "\n";
        }

        if (!Array.isArray(params.row.missingLunchProducts.extras)) {
          title +=
            "Suppléments manquants au déjeuner : " +
            Object.entries(params.row.missingLunchProducts.extras)
              .map(([key, value]) => value + " " + key)
              .join(", ") +
            "\n";
        }
      }

      if (!params.row.isDinnerComplete) {
        if (!Array.isArray(params.row.missingDinnerProducts.products)) {
          title +=
            "Produits manquants au dîner : " +
            Object.entries(params.row.missingDinnerProducts.products)
              .map(([key, value]) => value + " " + key)
              .join(", ") +
            "\n";
        }

        if (!Array.isArray(params.row.missingDinnerProducts.extras)) {
          title +=
            "Suppléments manquants au dîner : " +
            Object.entries(params.row.missingDinnerProducts.extras)
              .map(([key, value]) => value + " " + key)
              .join(", ") +
            "\n";
        }
      }

      return (
        <Stack direction={"row"} spacing={1}>
          {(!Array.isArray(params.row.missingLunchProducts.products) ||
            !Array.isArray(params.row.missingLunchProducts.extras) ||
            !Array.isArray(params.row.missingDinnerProducts.products) ||
            !Array.isArray(params.row.missingDinnerProducts.extras)) && (
            <Tooltip
              title={<span style={{ whiteSpace: "pre-line" }}>{title}</span>}
            >
              <WarningIcon color="warning" />
            </Tooltip>
          )}

          {params.row.estOffert && (
            <Tooltip title="Offert">
              <CardGiftcardIcon color="success" />
            </Tooltip>
          )}

          {params.row?.pilote && (
            <Tooltip title="Pilote">
              <WorkOutlineIcon color="success" />
            </Tooltip>
          )}
        </Stack>
      );
    } else {
      return "";
    }
  };
  const renderSelectState = (params) => {
    return <SelectStateInputCell {...params} />;
  };
  const SelectStateInputCell = (params) => {
    const { id, value, field } = params;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
      props.handleSubmitStateFromList(params.row, event.target.value);
    };

    return (
      <Select fullWidth value={value} onChange={handleChange}>
        {Object.values(ORDER_STATUS).map((state, i) => {
          return (
            <MenuItem value={state.status} key={i}>
              {state.description}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  let actions = (params) => {
    // On ne souhaite plus modifier les commandes du mois précédent ou plus
    let disabled = false;
    const orderDate = new Date(params.row.date);
    const today = new Date();

    if (orderDate.getMonth() < today.getMonth()) {
      disabled = true;
    }

    let array = [
      <GridActionsCellItem
        color="primary"
        icon={
          <Tooltip title="Modifier">
            <EditIcon />
          </Tooltip>
        }
        onClick={() => props.toggleEdit(params.id)}
        label="Modifier"
      />,
      <GridActionsCellItem
        disabled={disabled}
        color="error"
        icon={
          <Tooltip title="Supprimer les infos de la livraison">
            <DeleteIcon />
          </Tooltip>
        }
        onClick={() => props.handleDelete(params.id)}
        label="Supprimer"
      />,
    ];

    if (!params.row.delivery) {
      array.unshift(
        <GridActionsCellItem
          color="error"
          icon={
            <Tooltip title="Aucune livraison !">
              <ErrorOutlineIcon />
            </Tooltip>
          }
          label="Erreur"
        />
      );
    } else {
      array.unshift(
        <GridActionsCellItem
          color="warning"
          icon={
            <Tooltip title="Afficher">
              <DeliveryDiningIcon />
            </Tooltip>
          }
          onClick={props.toggleDelivery(true, params.row.delivery.id)}
          label="Afficher"
        />
      );
    }

    return array;
  };
  let columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "statut",
      headerName: "Statut",
      headerAlign: "center",
      align: "center",
      flex: 1,
      editable: true,
      renderCell: renderState,
      renderEditCell: renderSelectState,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? format(new Date(params.value), "dd/MM/yyyy") : "",
    },
    {
      field: "recipient",
      headerName: "Convive",
      flex: 2,
      valueGetter: (params) =>
        params.row.qty ? params.value + ` (x${params.row.qty})` : params.value,
    },
    {
      field: "lunchMenu",
      headerName: "Formules",
      flex: 2,
      valueGetter: (params) =>
        `${params.row.lunchMenu} ${
          params.row.dinnerMenu ? " + " + params.row.dinnerMenu : ""
        }`,
    },
    {
      field: "setMenu",
      headerName: "Menu",
    },
    {
      field: "estComplete",
      headerName: "Infos",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: renderIconEstComplete,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => actions(params),
    },
  ];
  const clientColumn = { field: "name", headerName: "Client", flex: 2 };
  if (isAnyAdmin(props.userData)) columns.splice(3, 0, clientColumn);

  const rows = props.orders.map((order, index) => ({
    key: index,
    id: order.id,
    date: order.date,
    name: order.organization.name,
    recipient: order?.recipient?.name ?? order.name,
    lunchMenu: order.lunchMenu.name,
    dinnerMenu: order?.dinnerMenu?.name ?? null,
    statut: order.statut,
    estOffert: order.estOffert,
    pilote: order?.pilote,
    estComplete: "",
    isLunchComplete: order.isLunchComplete,
    isDinnerComplete: order.isDinnerComplete,
    missingLunchProducts: order.missingLunchProducts,
    missingDinnerProducts: order.missingDinnerProducts,
    delivery: order.delivery,
    qty: order?.quantity ?? null,
    setMenu: order?.setMenu?.name ?? null,
  }));
  const clients = props.clients.map((client) => {
    const filtre = client.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: client,
    };
  });
  const today = new Date();
  const handleChangeSelectedMonth = (date) => {
    props.setFilters({
      ...props.filters,
      selectedMonth: date,
    });
  };

  return (
    <>
      <Stack spacing={2} marginBottom={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item container spacing={1} flex={1}>
            <Grid item>
              <LoadingButton
                loading={props.loading}
                {...(selectedOrders.length === 0 ? { disabled: true } : {})}
                color="error"
                variant="contained"
                onClick={handleConfirmMultipleDelete}
                startIcon={<DeleteIcon />}
              >
                Suppression
              </LoadingButton>
            </Grid>

            {isAnyAdmin(props.userData) && (
              <Grid item>
                <Tooltip title="Changer le statut de plusieurs commandes à la fois">
                  <LoadingButton
                    loading={props.loading}
                    color="secondary"
                    variant="contained"
                    startIcon={<ChangeCircleOutlinedIcon />}
                    onClick={() => setIsChangingState(true)}
                  >
                    Statut
                  </LoadingButton>
                </Tooltip>
              </Grid>
            )}

            <Grid item>
              <Tooltip title="Générer/Mettre à jour des commandes">
                <LoadingButton
                  loading={props.loading}
                  color="secondary"
                  variant="contained"
                  startIcon={<UpdateIcon />}
                  onClick={() => setIsGenerating(true)}
                >
                  Génération
                </LoadingButton>
              </Tooltip>
            </Grid>

            {props.userData.organization.mode === "repertory" && (
              <Grid item>
                <Tooltip title="Modifier le choix du plat principal des convives">
                  <LoadingButton
                    loading={props.loading}
                    color="secondary"
                    variant="contained"
                    startIcon={<RestaurantRoundedIcon />}
                    onClick={props.toggleChoices}
                  >
                    Choix des Plats
                  </LoadingButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Grid item container justifyContent="flex-end" spacing={1} flex={1}>
            {isAnyAdmin(props.userData) && (
              <>
                <Grid item>
                  <LoadingButton
                    loading={props.loading}
                    onClick={() => setIsImporting(true)}
                  >
                    Import
                  </LoadingButton>
                </Grid>

                <Grid item>
                  <LoadingButton
                    loading={props.loading}
                    onClick={() => setIsExporting(true)}
                  >
                    Export
                  </LoadingButton>
                </Grid>
              </>
            )}

            <Grid item>
              <LoadingButton
                loading={props.loading}
                color="secondary"
                variant="contained"
                onClick={props.toggleAdd}
                startIcon={<AddIcon />}
              >
                Commande
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>

        <Paper className={classes.paper} elevation={6}>
          <form onSubmit={filterFormik.handleSubmit}>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item container spacing={2} xs={12} md={12} lg={9}>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    disabled={props.loading}
                    size="small"
                    fullWidth
                    name={"id"}
                    label="ID"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={filterFormik.handleChange}
                    onBlur={filterFormik.handleBlur}
                    value={filterFormik.values.id}
                    error={
                      filterFormik.touched.id && Boolean(filterFormik.errors.id)
                    }
                    helperText={
                      filterFormik.touched.id && filterFormik.errors.id
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={locale["fr"]}
                    >
                      <DatePicker
                        disabled={props.loading}
                        mask={mask["fr"]}
                        label="Date"
                        inputFormat="dd/MM/yyyy"
                        onChange={(value) =>
                          filterFormik.setFieldValue("date", value)
                        }
                        onBlur={filterFormik.handleBlur}
                        value={filterFormik.values.date}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            disabled={props.loading}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText error>
                      {filterFormik.errors.date && filterFormik.touched.date
                        ? "Date invalide"
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled={props.loading}
                  >
                    <InputLabel id="status">Statut</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      label="Statut"
                      value={filterFormik.values.status}
                      onChange={(event) =>
                        filterFormik.setFieldValue(
                          "status",
                          event.target.value === "none"
                            ? ""
                            : event.target.value
                        )
                      }
                      disabled={props.loading}
                    >
                      <MenuItem value="none">
                        <i>Aucun</i>
                      </MenuItem>

                      {Object.values(ORDER_STATUS).map((value) => (
                        <MenuItem key={value.status} value={value.status}>
                          {value.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                  <Autocomplete
                    value={filterFormik.values.recipient}
                    options={recipients.sort(
                      (a, b) => -b.filtre.localeCompare(a.filtre)
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.recipient.id === (value?.recipient?.id ?? value)
                    }
                    groupBy={(value) => value.filtre}
                    getOptionLabel={(option) => {
                      if (!option.recipient) return "";
                      return (
                        option.recipient.id + " - " + option.recipient.name
                      );
                    }}
                    onChange={(event, value) =>
                      filterFormik.setFieldValue("recipient", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Convive"
                        size="small"
                        disabled={props.loading}
                      />
                    )}
                  />
                </Grid>

                {isAnyAdmin(props.userData) && (
                  <Grid item xs={12} md={6} lg={3}>
                    <Autocomplete
                      value={filterFormik.values.client}
                      options={clients.sort(
                        (a, b) => -b.filtre.localeCompare(a.filtre)
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.client.id === value.client.id
                      }
                      groupBy={(value) => value.filtre}
                      getOptionLabel={(option) => {
                        if (!option.client) return "";
                        return option.client.id + " - " + option.client.name;
                      }}
                      onChange={(event, value) =>
                        filterFormik.setFieldValue("client", value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client"
                          size="small"
                          disabled={props.loading}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6} lg={3}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled={props.loading}
                  >
                    <InputLabel id="orders">
                      Commandes complètes/incomplètes
                    </InputLabel>
                    <Select
                      labelId="orders"
                      id="orders"
                      label="Commandes complètes/incomplètes"
                      value={filterFormik.values.completedOrders}
                      onChange={(event) =>
                        filterFormik.setFieldValue(
                          "completedOrders",
                          event.target.value
                        )
                      }
                      disabled={props.loading}
                    >
                      <MenuItem value="all">Toutes les commandes</MenuItem>
                      <MenuItem value="incomplete">Incomplètes</MenuItem>
                      <MenuItem value="complete">Complètes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container spacing={1} xs={12} md={12} lg={3}>
                <Grid item xs md lg>
                  <LoadingButton
                    loading={props.loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="small"
                  >
                    Valider
                  </LoadingButton>
                </Grid>

                <Grid item xs md lg>
                  <LoadingButton
                    loading={props.loading}
                    fullWidth
                    variant="text"
                    size="small"
                    onClick={resetFilters}
                  >
                    Réinitialiser
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <Paper className={classes.paper} elevation={6}>
          <TabContext value={tab}>
            <TabList
              onChange={(event, value) => {
                if (value === "calendar")
                  return props.fetchCalendar(queryOptions, () =>
                    handleChangeTab(null, "calendar")
                  );
                else return handleChangeTab(event, value);
              }}
              variant="fullWidth"
              centered
            >
              <Tab value="list" label="Liste" />
              <Tab value="calendar" label="Calendrier" />
            </TabList>

            <TabPanel value="list">
              <Table
                loading={props.loading}
                columns={columns}
                rows={rows}
                rowCount={rowCountState}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
              />
            </TabPanel>

            <TabPanel value="calendar">
              <Calendar
                loading={props.loading}
                orders={props.orders}
                today={today}
                isFilteredOnRecipients={isFilteredOnRecipient}
                data={props.dataCalendar}
                handleChangeSelectedMonth={handleChangeSelectedMonth}
              />
            </TabPanel>
          </TabContext>
        </Paper>
      </Stack>

      <GenerateDialog
        loading={props.loading}
        isGenerating={isGenerating}
        handleClose={handleClose}
        handleGenerate={props.generateOrders}
        clients={clients}
        rounds={props.rounds}
        userData={props.userData}
        fetchOrders={props.fetchOrders}
        queryOptions={queryOptions}
      />

      <ExportDialog
        loading={props.loading}
        isExporting={isExporting}
        handleClose={handleClose}
        sendDataExport={props.sendDataExport}
      />

      <ImportDialog
        {...props}
        isImporting={isImporting}
        handleClose={handleClose}
        queryOptions={queryOptions}
      />

      <StateChangerDialog
        loading={props.loading}
        isChangingState={isChangingState}
        handleClose={handleClose}
        handleSubmitOrdersState={props.handleSubmitOrdersState}
        fetchOrders={props.fetchOrders}
        queryOptions={queryOptions}
        clients={clients}
        userData={props.userData}
      />

      <Dialog
        open={confirmMultipleDelete}
        onClose={handleClose}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Êtes-vous sûr(e) de vouloir supprimer les commandes sélectionnées ?{" "}
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              props.handleMultipleDelete(
                selectedOrders,
                props.fetchOrders(queryOptions)
              );
              setSelectedOrders([]);
              handleClose();
            }}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer
        anchor="right"
        open={props.isOpen}
        delivery={props.delivery}
        onClose={props.toggleDelivery(false, null)}
        sx={{
          width: "80%",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        className={classes.drawer}
      >
        {props.loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", padding: 3 }}
            fontSize="large"
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <DeliveryInfos {...props} />
        )}
      </Drawer>
    </>
  );
};

export default List;
