import React, { Component } from "react";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { Snackbar, Alert } from "@mui/material";
import { requests } from "../../../agent";
import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { ENTRYPOINT } from "../../../config/entrypoint";

const superagent = superagentPromise(_superagent, global.Promise);
let token = null;
const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) request.set("Authorization", `Bearer ${token}`);
  };
};

class ProduitContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produits: [],
      allergenes: [],
      regimes: [],
      add: false,
      edit: null,
      loading: false,
      dataProductEdit: null,
      displayAlert: {
        active: false,
        type: "",
        message: null,
      },
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const promises = Promise.all([
      requests.get("/produits?&order[nom]=asc", true),
      requests.get("/allergenes", true),
      requests.get("/categories", true),
      requests.get("/regimes", true),
    ]);

    promises
      .then((results) => {
        this.setState({
          produits: results[0]["hydra:member"],
          allergenes: results[1]["hydra:member"],
          categories: results[2]["hydra:member"],
          regimes: results[3]["hydra:member"],
          loading: false,
        });
      })
      .catch((err) => {
        this.isMountedVal = 1;
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  }

  handleDelete = (produitId) => {
    this.setState({ loading: true });
    requests
      .del(`/produits/${produitId}`, true)
      .then((response) => {
        this.setState({ loading: false });
        this.setState({
          produits: this.state.produits.filter(
            (produit) => produitId !== produit.id
          ),
        });
        this.setState({
          displayAlert: {
            active: true,
            type: "success",
            message: "Le produit a été supprimé !",
          },
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  toggleImportModal = () => {
    this.setState({ showImportModal: !this.state.showImportModal });
  };

  handleToggleEdit = (productId) => {
    this.setState({ loading: true });

    requests
      .get(`/produits/${productId}`, true)
      .then((result) => {
        this.setState({
          dataProductEdit: result,
          loading: false,
          edit: true,
        });
      })

      .catch((err) => {
        this.setState({
          loading: false,
          displayAlert: {
            active: true,
            type: "error",
            message: err.message,
          },
        });
      });
  };

  handleToggleAdd = () => {
    this.setState((oldState) => {
      return {
        add: !oldState.add,
      };
    });
  };

  handleToggleEditForm = () => {
    this.setState(() => {
      return {
        edit: null,
        dataProductEdit: null,
      };
    });
  };

  handleSubmit = (
    nom,
    description,
    ingredients,
    preparation,
    allergenes,
    estMicroOndable,
    categorie,
    image,
    estRemplacement,
    regimesNonAdaptes
  ) => {
    this.setState({ loading: true });

    let dataAllergenes = [];
    let dataRegimes = [];
    let url = `/produits`;

    allergenes.map((allergene) =>
      dataAllergenes.push(`/allergenes/${allergene}`)
    );
    regimesNonAdaptes.map((regime) => dataRegimes.push(`/regimes/${regime}`));

    superagent
      .post(`${ENTRYPOINT}${url}`)
      .use(tokenPlugin(true))
      .field("nom", nom)
      .field("description", description)
      .field("ingredients", ingredients)
      .field("preparation", preparation)
      .field("estMicroOndable", estMicroOndable)
      .field("categorie", "/categories/" + categorie)
      .field("estRemplacement", estRemplacement)
      .field("allergenes", JSON.stringify(dataAllergenes))
      .field("regimesNonAdaptes", JSON.stringify(dataRegimes))
      .attach("file", image)
      .then((response) => {
        const newRow = response.body;
        const newListRows = [...this.state.produits];
        newListRows.unshift(newRow);
        this.setState({ loading: false });
        this.setState((oldState) => {
          return {
            produits: newListRows,
            add: false,
            displayAlert: {
              active: true,
              type: "success",
              message: "Le produit a bien été créé !",
            },
          };
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  handleSubmitEdit = (
    id,
    nom,
    description,
    ingredients,
    preparation,
    allergenes,
    estMicroOndable,
    categorie,
    estRemplacement,
    regimesNonAdaptes
  ) => {
    this.setState({ loading: true });

    let dataAllergenes = [];
    let dataRegimes = [];
    let url = `/produits/${id}`;

    allergenes.map((allergene) =>
      dataAllergenes.push(`/allergenes/${allergene}`)
    );
    regimesNonAdaptes.map((regime) => dataRegimes.push(`/regimes/${regime}`));

    const data = {
      nom: nom,
      description: description,
      ingredients,
      preparation,
      allergenes: dataAllergenes,
      estMicroOndable,
      categorie: "/categories/" + categorie,
      estRemplacement,
      regimesNonAdaptes: dataRegimes,
    };

    requests
      .patch(url, data, true)
      .then((response) => {
        const newListeProduits = this.state.produits.map((produit) => {
          if (produit.id !== id) {
            return produit;
          }
          return response;
        });
        this.setState({
          produits: newListeProduits,
          edit: null,
          displayAlert: {
            active: true,
            type: "success",
            message: "Le produit a bien été modifié !",
          },
          loading: false,
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  updateImage = (id, image) => {
    this.setState({ loading: true });

    let url = `/produits/image`;

    superagent
      .post(`${ENTRYPOINT}${url}`)
      .use(tokenPlugin(true))
      .field("id", id)
      .attach("file", image)
      .then((response) => {
        const newListeProduits = this.state.produits.map((produit) => {
          if (produit.id !== id) {
            return produit;
          }
          return response.body;
        });
        this.setState({ loading: false });
        this.setState((oldState) => {
          return {
            produits: newListeProduits,
            edit: null,
            displayAlert: {
              active: true,
              type: "success",
              message: "L'image du produit a été mise à jour !",
            },
          };
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  importProducts = (file) => {
    this.setState({ loading: true });

    let url = `/produits/import`;
    superagent
      .post(`${ENTRYPOINT}${url}`)
      .use(tokenPlugin(true))
      .attach("file", file)
      .then((response) => {
        const body = response.body;
        const newProduits = body["produits"]["hydra:member"];
        const newListeProduits = [...this.state.produits, ...newProduits];
        this.setState({
          produits: newListeProduits,
          showImportModal: false,
          loading: false,
          displayAlert: {
            active: true,
            type: "success",
            message: "Les données ont été importées !",
          },
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  handleSubmitFilters = (data, from) => {
    this.setState({
      loading: true,
    });

    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.lastname !== "") dataUrl += `nom=${data.lastname}&`;

    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        this.setState({
          produits: result["hydra:member"],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  render() {
    const handleCloseSnackbar = (reason) => {
      if (reason === "clickaway") {
        return;
      }

      this.setState({
        displayAlert: {
          active: false,
          type: "",
          message: "",
        },
      });
    };

    const { add, edit, displayAlert } = this.state;
    return (
      <>
        {add && (
          <Add
            {...this.state}
            validation={this.handleSubmit}
            toggleForm={this.handleToggleAdd}
          />
        )}

        {edit && (
          <Edit
            {...this.state}
            validation={this.handleSubmitEdit}
            toggleForm={this.handleToggleEditForm}
            updateImage={this.updateImage}
          />
        )}

        {!add && !edit && (
          <List
            {...this.state}
            toggleAdd={this.handleToggleAdd}
            toggleEdit={this.handleToggleEdit}
            handleDelete={this.handleDelete}
            handleSubmitFilters={this.handleSubmitFilters}
            toggleImportModal={this.toggleImportModal}
            importProducts={this.importProducts}
          />
        )}

        {displayAlert.active && (
          <Snackbar
            open={displayAlert.active}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              variant={"filled"}
              severity={displayAlert.type}
            >
              {displayAlert.message}
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }
}

export default ProduitContainer;
