import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import UseStyle from "../Common/StyledComponent/UseStyle";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { locale, mask } from "../../../localisation";

const CreditNoteForm = (props) => {
  const classes = UseStyle();
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [formules, setFormules] = useState([]);
  const recipients = props.recipients.map((recipient) => {
    const filtre = recipient.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      recipient: recipient,
    };
  });

  useEffect(() => {
    if (props.edit) {
      const lunchMenu = {
        id: props.values.order.lunchMenu.id,
        name: props.values.order.lunchMenu.name,
        selected: props.values.formules.includes(
          props.values.order.lunchMenu.id
        ),
      };
      const dinnerMenu = props.values.order.dinnerMenu
        ? {
            id: props.values.order.dinnerMenu.id,
            name: props.values.order.dinnerMenu.name,
            selected: props.values.formules.includes(
              props.values.order.dinnerMenu.id
            ),
          }
        : null;
      const formules = [lunchMenu, dinnerMenu].filter(Boolean);
      setFormules(formules);
    }
  }, []);

  useEffect(() => {
    if (selectedOrder) {
      const order = selectedOrder.order;
      const lunchMenu = {
        id: order.lunchMenu.id,
        name: order.lunchMenu.name,
        selected: true,
      };
      const dinnerMenu = order.dinnerMenu
        ? {
            id: order.dinnerMenu.id,
            name: order.dinnerMenu.name,
            selected: true,
          }
        : null;

      const formules = [lunchMenu, dinnerMenu].filter(Boolean);
      setFormules(formules);
      props.setFieldValue(
        "formules",
        formules.map((formule) => formule.id)
      );
    }
  }, [selectedOrder]);

  return (
    <Paper className={classes.paper} elevation={6}>
      <form>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            {props.edit ? (
              <FormControl fullWidth>
                <InputLabel htmlFor="recipient">
                  Convive (non modifiable)
                </InputLabel>
                <OutlinedInput
                  readOnly
                  name="recipient"
                  value={props.values.recipient.name}
                  label="Convive  (non modifiable)"
                />
              </FormControl>
            ) : (
              <FormControl fullWidth required>
                <Autocomplete
                  disabled={props.edit}
                  options={recipients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.recipient.id === value.recipient.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.recipient) return "";
                    return option.recipient.id + " - " + option.recipient.name;
                  }}
                  value={selectedRecipient}
                  onChange={(event, value) => {
                    setSelectedRecipient(value);
                    value !== null
                      ? props.setFieldValue("recipient", value.recipient.id)
                      : props.setFieldValue("recipient", null);
                    setSelectedOrder(null);
                    props.setFieldValue("formules", []);
                    setFormules([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Convive"
                      error={
                        props.touched.recipient &&
                        Boolean(props.errors.recipient)
                      }
                    />
                  )}
                />
                <FormHelperText error>
                  {props.errors.recipient &&
                    props.touched.recipient &&
                    props.errors.recipient}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  views={["month", "year"]}
                  label="Pour le mois de"
                  inputFormat="MM/yyyy"
                  value={props.values.date}
                  onBlur={props.handleBlur}
                  onChange={(value) => props.setFieldValue("date", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={props.touched.date && Boolean(props.errors.date)}
                    />
                  )}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {props.errors.date && props.touched.date && props.errors.date}
              </FormHelperText>
            </FormControl>
          </Grid>

          {props.isLoadingData && (
            <Grid item xs={1} container justifyContent="center">
              <CircularProgress color="secondary" />
            </Grid>
          )}

          <Grid item xs={props.isLoadingData ? 11 : 12}>
            {props.edit ? (
              <FormControl fullWidth>
                <InputLabel htmlFor="order">
                  Commande du (non modifiable)
                </InputLabel>
                <OutlinedInput
                  readOnly
                  name="order"
                  value={format(
                    new Date(props.values.order.date),
                    "dd/MM/yyyy"
                  )}
                  label="Commande du (non modifiable)"
                />
              </FormControl>
            ) : (
              <Autocomplete
                fullWidth
                disabled={props.edit}
                options={props.orders.sort(
                  (a, b) => -b.filtre.localeCompare(a.filtre)
                )}
                isOptionEqualToValue={(option, value) =>
                  option.order.id === value.order.id
                }
                groupBy={(value) => value.filtre}
                getOptionLabel={(option) => {
                  if (!option.order) return "";
                  return (
                    option.order.id +
                    " - " +
                    option.order.name +
                    " - " +
                    format(new Date(option.order.date), "dd/MM/yyyy")
                  );
                }}
                value={selectedOrder}
                onChange={(event, value) => {
                  props.setFieldValue("formules", []);
                  setFormules([]);
                  setSelectedOrder(value);

                  value !== null
                    ? props.setFieldValue("order", value.order.id)
                    : props.setFieldValue("order", null);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Commandes" />
                )}
              />
            )}
          </Grid>

          {formules.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Inclure les formules :</Typography>
              </Grid>

              {formules.map((formule, index) => (
                <Grid
                  item
                  xs={12}
                  container
                  key={formule.id}
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      checked={formule.selected}
                      onChange={(event) => {
                        setFormules(
                          formules.map((formule, i) => {
                            if (i === index) {
                              return {
                                ...formule,
                                selected: event.target.checked,
                              };
                            }
                            return formule;
                          })
                        );

                        props.setFieldValue(
                          "formules",
                          event.target.checked
                            ? [...props.values.formules, formule.id]
                            : props.values.formules.filter(
                                (id) => id !== formule.id
                              )
                        );
                      }}
                      control={<Checkbox />}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1">{formule.name}</Typography>
                  </Grid>
                </Grid>
              ))}
            </>
          )}

          <Grid item container justifyContent="flex-end">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={props.isLoading}
              onClick={props.handleSubmit}
              startIcon={<SaveRoundedIcon />}
            >
              {props.edit ? "Sauvegarder" : "Créer"}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CreditNoteForm;
