import {
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import OrderCard from "./OrderCard";
import { DELIVERY_STATUS } from "../../Common/Enums/Enums";

const DeliveryInfos = (props) => {
  const classes = UseStyle();
  const organizationMode = props.delivery.organization.mode;
  const organizationNote = props.delivery.organization.organizationNote;
  const organizationAdminNote = props.delivery.organization.adminNote;
  const recipientNote = props.delivery?.recipient?.recipientNote;
  const recipientAdminNote = props.delivery?.recipient?.adminNote;
  let color = null;

  if (
    props.delivery.status === DELIVERY_STATUS.PENDING_PREP.status ||
    props.delivery.status === DELIVERY_STATUS.DELIVERED.status
  ) {
    color = "#DEF5DB";
  } else if (props.delivery.status === DELIVERY_STATUS.CANCELLED.status) {
    color = "#FBE4E9";
  }

  return (
    <Grid
      container
      spacing={2}
      direction={"column"}
      alignItems="stretch"
      sx={{ padding: 3, bgcolor: color }}
    >
      <Grid item>
        <Paper className={classes.paper} elevation={6}>
          <Grid container spacing={1}>
            <Grid item container spacing={1}>
              <Grid item xs={8}>
                <Typography variant="body1">
                  <strong>Client :</strong> {props.delivery.organization.name}
                </Typography>

                {props.delivery.organization.mode === "diets" ? (
                  <Typography variant="body1" gutterBottom>
                    <strong>Téléphone :</strong>{" "}
                    {props.delivery.organization.phone}
                  </Typography>
                ) : (
                  props.delivery.recipient &&
                  props.delivery.recipient.contacts.map((c, i) => (
                    <Typography key={i} variant="body1" gutterBottom>
                      <strong>Contact #{i + 1} : </strong>
                      {c.nomContact} / {c.telephoneContact}
                    </Typography>
                  ))
                )}

                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Typography variant="body1">
                    <strong>Adresse : </strong>
                  </Typography>

                  <Typography variant="body1">
                    {props.delivery.address.unparsed}
                  </Typography>
                </Stack>

                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Typography variant="body1">
                    <strong>Compléments d'adresse : </strong>
                  </Typography>

                  <Typography variant="body1">
                    {props.delivery.address.notes}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body1">
                  <strong>Livraison du : </strong>
                  {format(new Date(props.delivery.date), "dd/MM/yyyy")}
                </Typography>

                <Typography variant="body1">
                  <strong>Tournée : </strong> {props.delivery.round.name}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container spacing={1}>
              {organizationMode === "diets" && recipientNote && (
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Note client :</strong> <br />
                    {recipientNote}
                  </Typography>
                </Grid>
              )}

              {!organizationMode === "diets" && recipientAdminNote && (
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Note admin :</strong> <br />
                    {recipientAdminNote}
                  </Typography>
                </Grid>
              )}

              {!recipientNote && organizationNote && (
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Note client :</strong> <br />
                    {organizationNote}
                  </Typography>
                </Grid>
              )}

              {!recipientAdminNote && organizationAdminNote && (
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Note admin :</strong> <br />
                    {organizationAdminNote}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item container spacing={3} direction={"column"}>
        {props.delivery.commandes
          .sort((a, b) => a.id - b.id)
          .map((order, i) => (
            <OrderCard
              key={i}
              index={order.id}
              order={order}
              diets={order?.recipient?.diets ?? []}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default DeliveryInfos;
