import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import InvoiceCard from "./InvoiceCard";

const UserInvoices = (props) => {
  const datas = props.clients.find(
    (c) => c.id === props.userData.organization.id
  );

  return (
    <Stack spacing={2} padding={2}>
      <Typography variant="h3" gutterBottom>
        Mes factures
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {datas && datas.invoices.length > 0 ? (
            datas.invoices.map((invoice, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <InvoiceCard
                    invoice={invoice}
                    user={props.userData}
                    handleDownloadInvoice={props.handleDownloadInvoice}
                    loading={props.loadingList}
                  />
                </Grid>
              );
            })
          ) : (
            <Typography variant="body1" display="block" gutterBottom>
              Vous n'avez pas encore de facture..
            </Typography>
          )}
        </Grid>
      </Box>
    </Stack>
  );
};

export default UserInvoices;
