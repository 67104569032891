import React, { useEffect, useState } from "react";
import { requests } from "../../../agent";
import Add from "./Add/Add";
import { Alert, LinearProgress, Snackbar } from "@mui/material";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { useSelector } from "react-redux";

const UserContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [clients, setClients] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    const promises = Promise.all([
      requests.get("/users", true),
      requests.get("/organizations", true),
    ]);

    promises
      .then((responses) => {
        setUsers(responses[0]["hydra:member"]);
        setUsersList(responses[0]["hydra:member"]);
        setClients(responses[1]["hydra:member"]);
        setIsPageLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (userId) => {
    const user = users.find((user) => user.id === userId);
    if (!user) return;
    setUser(user);
    setEdit(true);
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setUser(null);
  };
  const handleSubmit = (data) => {
    setIsLoading(true);
    requests
      .post("/users", data, true)
      .then((response) => {
        setUsers((prevUsers) => [response, ...prevUsers]);
        setIsLoading(false);
        toggleList();
        setAlert({
          active: true,
          type: "success",
          message: "L'utilisateur a bien été ajouté !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleUpdate = (data) => {
    setIsLoading(true);
    requests
      .patch(`/users/${data.id}`, data, true)
      .then((response) => {
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id === response.id ? response : u))
        );
        setIsLoading(false);
        toggleList();
        setAlert({
          active: true,
          type: "success",
          message: "L'utilisateur a bien été modifié !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") return;
    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const handleSubmitFilters = (data, from) => {
    setIsLoading(true);
    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.lastname !== "") dataUrl += `lastName=${data.lastname}&`;
    if (data.client !== null) dataUrl += `organization.id=${data.client}&`;
    if (data.phone !== "") dataUrl += `telephone=${data.phone}&`;
    if (data.email !== "") dataUrl += `email=${data.email}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setUsers(result["hydra:member"]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDelete = (userId) => {
    setIsLoading(true);
    requests
      .del(`/users/${userId}`, true)
      .then(() => {
        setUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId));
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "L'utilisateur a bien été supprimé !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };

  return isPageLoading ? (
    <LinearProgress />
  ) : (
    <>
      {add && (
        <Add
          isLoading={isLoading}
          add={add}
          clients={clients}
          toggleList={toggleList}
          validation={handleSubmit}
          userData={userData}
        />
      )}
      {edit && (
        <Edit
          isLoading={isLoading}
          edit={edit}
          clients={clients}
          user={user}
          toggleList={toggleList}
          validation={handleUpdate}
          userData={userData}
        />
      )}
      {!add && !edit && (
        <List
          isLoading={isLoading}
          users={users.filter((user) => user.id !== userData.id)}
          usersList={usersList}
          clients={clients}
          add={add}
          toggleAdd={toggleAdd}
          toggleEdit={toggleEdit}
          edit={edit}
          userData={userData}
          handleSubmitFilters={handleSubmitFilters}
          handleDelete={handleDelete}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default UserContainer;
