import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import ServiceForm from "../ServiceForm";
import { isAdmin } from "../../../../utils";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Modification d'un service"
        />
      </Card>

      <ServiceForm {...props} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    organization: isAdmin(props.userData)
      ? props.service.organization.id
      : props.userData.organization.id,
    organizationName: props.service.organization.name,
    name: props.service.name,
    description: props.service.description,
    isEnabled: props.service.isEnabled,
    tax: props.service.tax.id,
    priceIncludingTax: parseFloat(props.service.priceIncludingTax),
    priceExcludingTax: parseFloat(props.service.priceExcludingTax),
  }),
  validationSchema: Yup.object().shape({
    organization: Yup.number().positive().required(),
    name: Yup.string()
      .required("Champs obligatoire")
      .min(5, "Minimum 5 caractères"),
    isEnabled: Yup.boolean(),
    tax: Yup.number().positive().required("Ce champs est obligatoire"),
    priceIncludingTax: Yup.number()
      .positive("Le prix doit être supérieur à 0")
      .required("Champs obligatoire"),
    priceExcludingTax: Yup.number().positive().required("Champs obligatoire"),
  }),
  handleSubmit: (values, { props }) => {
    const updatedItem = {
      id: props.service.id,
      organization: `/organizations/${values.organization}`,
      name: values.name,
      description: values.description,
      isEnabled: values.isEnabled,
      tax: `/taxes/${values.tax}`,
      priceIncludingTax: values.priceIncludingTax.toFixed(4),
      priceExcludingTax: values.priceExcludingTax,
    };

    props.validation(updatedItem);
  },
})(Edit);
