import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import quote from "../../../../assets/img/home/quote.svg";

const items = [
  [
    {
      author: "Nathalie P.",
      comment: "Ma maman se régale, nous sommes ravis.",
    },
    {
      author: "Jeannine A.",
      comment:
        "C'est toujours un plaisir de manger vos repas et de parler avec vous.",
    },
    {
      author: "Marie Antoinette",
      comment: "Vous faites des contenants en carton, ça c'est vraiment bien !",
    },
  ],
  [
    {
      author: "François",
      comment: "Les repas sont toujours copieux et généreux",
    },
    {
      author: "Christiane",
      comment:
        "C'est toujours très bon et ça change toujours, c'est très varié",
    },

    {
      author: "Guy",
      comment: "Les indications et ingrédients sont clairs, c'est bien.",
    },
  ],
  [
    {
      author: "Georgia",
      comment:
        "Les conditionnements en carton sont adaptés et résistants, c'est pratique.",
    },
  ],
];

function Item(props) {
  return props.array.map((item, i) => (
    <Grid key={i} item xs={12} md={3} padding={1}>
      <Paper
        sx={{
          // width: "310px",
          padding: 2,
          boxShadow: "0 5px 10px rgb(232 79 54 / 0.3)",
          borderRadius: "21px",
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <img src={quote} alt="quote" />
          </Grid>

          <Grid item>
            <Typography variant="h6" textAlign="center">
              "{item.comment}"
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="body1"
              color="text.secondary"
              textAlign="center"
              padding={1}
            >
              <i>{item.author}</i>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  ));
}

const Testimonials = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography
          variant="h6"
          gutterBottom
          color="primary"
          textAlign="center"
        >
          <strong>LES MOTS DOUX</strong>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Carousel animation="slide">
          {items.map((array, i) => (
            <Grid
              key={i}
              container
              spacing={2}
              padding={2}
              justifyContent="space-around"
            >
              <Item key={i} array={array} />
            </Grid>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default Testimonials;
