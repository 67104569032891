import { CardContent, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import "./formules.css";
import Particulier from "../../particulier/Formules/Formules";
import Professionnel from "../../professionnel/Formules/Formules";

export const CardContentNoPadding = styled(CardContent)(`
  height: 160px;
  padding: 0;
  display: flex;
  align-items: center;
  &:last-child {
    padding-bottom: 0;
  }
`);

const Formules = (props) => {
  const colors = [
    { hex: "#FAD086", rgb: "250 208 134" },
    { hex: "#8ACBB6", rgb: "138 203 182" },
    { hex: "#C1E488", rgb: "193 228 136" },
    { hex: "#ADE9CC", rgb: "173 233 204" },
  ];

  return (
    <div className="formules">
      <Stack alignItems="center" marginBottom={3}>
        <Typography variant="h4" color="primary" textAlign="center">
          {props.selected === 1
            ? "Les formules Basil"
            : "Composez vos formules"}
        </Typography>

        <Typography variant="body1" textAlign="center">
          {props.selected === 1
            ? "Sans engagement, à partir de 2 repas par semaine"
            : "Exemples de composition :"}
        </Typography>
      </Stack>

      {props.selected === 1 ? (
        <Particulier {...props} colors={colors} />
      ) : (
        <Professionnel colors={colors} />
      )}
    </div>
  );
};

export default Formules;
