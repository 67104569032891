import React, { useState } from "react";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Filters from "../../Common/Filters";
import UseStyle from "../../Common/StyledComponent/UseStyle";

const List = (props) => {
  const classes = UseStyle();
  const [open, setOpen] = useState(false);
  const [selectedFormule, setSelectedFormule] = useState(null);

  const handleClickOpen = (formuleId) => {
    setOpen(true);
    setSelectedFormule(formuleId);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFormule(null);
  };

  const renderCellChecked = (params) => {
    if (params.value) {
      return <CheckIcon />;
    } else {
      return <CloseIcon />;
    }
  };
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "nom",
      headerName: "Nom",
      flex: 0.1,
    },
    {
      field: "diner",
      headerName: "Formule dîner",
      headerAlign: "center",
      align: "center",
      renderCell: renderCellChecked,
      flex: 0.05,
    },
    {
      field: "ttc",
      headerName: "Prix TTC (€)",
      flex: 0.05,
    },
    {
      field: "ht",
      headerName: "Prix HT (€)",
      flex: 0.05,
    },
    {
      field: "composition",
      headerName: "Composition",
      flex: 0.1,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
        <GridActionsCellItem
          color="error"
          icon={
            <Tooltip title="Supprimer">
              <DeleteIcon />
            </Tooltip>
          }
          onClick={() => handleClickOpen(params.id)}
          label="Supprimer"
        />,
      ],
    },
  ];
  const rows =
    props.formules &&
    props.formules.map((formule) => ({
      id: formule.id,
      nom: formule.name,
      diner: formule.estDiner,
      ttc: parseFloat(formule.priceIncludingTax).toFixed(4),
      ht: parseFloat(formule.priceExcludingTax).toFixed(4),
      composition: formule.composition
        .map((c) =>
          c.qte > 1 ? c.qte + " " + c.categorie.nom + "s" : c.categorie.nom
        )
        .join(", "),
    }));

  return props.isPageLoading ? (
    <LinearProgress />
  ) : (
    <>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        spacing={1}
        paddingBottom={2}
      >
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Formule
        </Button>
      </Stack>

      <Stack spacing={2}>
        <Filters
          types={["lastname"]}
          from="formules"
          handleSubmitFilters={props.handleSubmitFilters}
          loading={props.loading}
        />

        <Paper className={classes.paper} elevation={6}>
          <Grid container sx={{ height: "70vh", width: "100%" }}>
            <DataGrid
              columns={columns}
              rows={rows}
              density="comfortable"
              disableSelectionOnClick
              autoPageSize
              pagination
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              loading={props.loading}
            />
          </Grid>
        </Paper>
      </Stack>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Supprimer cette formule ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Toute suppression sera définitive et entrainera la perte de ces
            données. Êtes-vous sûr de vouloir supprimer cette formule ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Annuler
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              props.handleDelete(selectedFormule);
              handleClose();
            }}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default List;
