import React from "react";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Filters from "../../Common/Filters";
import UseStyle from "../../Common/StyledComponent/UseStyle";

const List = (props) => {
  const classes = UseStyle();
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "nom",
      headerName: "Nom",
      flex: 2,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
      ],
    },
  ];
  const rows =
    props.allergenes &&
    props.allergenes.map((allergene) => ({
      id: allergene.id,
      nom: allergene.nom,
    }));

  return props.isPageLoading ? (
    <LinearProgress />
  ) : (
    <>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        spacing={1}
        paddingBottom={2}
      >
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Allergènes
        </Button>
      </Stack>

      <Stack spacing={2}>
        <Filters
          types={["lastname"]}
          from="allergenes"
          handleSubmitFilters={props.handleSubmitFilters}
          loading={props.loading}
        />

        <Paper className={classes.paper} elevation={6}>
          <Grid container sx={{ height: "70vh", width: "100%" }}>
            <DataGrid
              columns={columns}
              rows={rows}
              density="comfortable"
              disableSelectionOnClick
              autoPageSize
              pagination
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              loading={props.loading}
            />
          </Grid>
        </Paper>
      </Stack>
    </>
  );
};

export default List;
