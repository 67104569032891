import {
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { locale, mask } from "../../../../localisation";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import MonthView from "./MonthView";
import { getDatesOfMonth } from "../../Orders/List/Calendar";
import WeekView from "./WeekView";

const MenuCalendar = (props) => {
  const [date, setDate] = useState(new Date());
  const [selectedView, setSelectedView] = useState(1);
  const classes = UseStyle();
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <Paper className={classes.paper} elevation={6} sx={{ mb: 3 }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Calendrier des menus
            </Typography>
          </Grid>

          <Grid item>
            <ButtonGroup size="large">
              <Button
                color="primary"
                variant={selectedView === 1 ? "contained" : "outlined"}
                onClick={() => setSelectedView(1)}
              >
                Mois
              </Button>
              <Button
                color="primary"
                variant={selectedView === 2 ? "contained" : "outlined"}
                onClick={() => setSelectedView(2)}
              >
                Semaine
              </Button>
            </ButtonGroup>
          </Grid>

          <Grid item>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale["fr"]}
            >
              <DatePicker
                mask={mask["fr"]}
                {...(selectedView === 1
                  ? { views: ["month", "year"], inputFormat: "MMMM yyyy" }
                  : { inputFormat: "dd/MM/yyyy" })}
                value={date}
                onChange={(value) => setDate(value)}
                renderInput={(params) => (
                  <TextField {...params} onKeyDown={onKeyDown} disabled />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        {selectedView === 1 ? (
          getDatesOfMonth(date).map((week, i) => (
            <Grid key={i} item xs={12}>
              <MonthView week={week} date={date} menus={props.menus} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <WeekView date={date} menus={props.menus} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default MenuCalendar;
