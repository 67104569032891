import {
  AppBar,
  Avatar,
  Button,
  Slide,
  Stack,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const MenuBar = (props) => {
  const trigger = useScrollTrigger();
  let navigate = useNavigate();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar>
        <Toolbar variant="dense">
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            width={"100%"}
          >
            <Avatar
              alt="Basil Ainés"
              srcSet={props.logo}
              sx={{
                backgroundColor: "primary.contrastText",
                ".MuiAvatar-img": {
                  objectFit: "contain",
                },
              }}
            />

            <Button
              variant="outlined"
              sx={{
                color: "primary.contrastText",
                borderColor: "primary.contrastText",
                ":hover": {
                  backgroundColor: "primary.contrastText",
                  color: "#E84F36",
                },
              }}
              onClick={() => navigate("/login")}
            >
              Se connecter
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default MenuBar;
