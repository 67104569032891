import {
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SendIcon from "@mui/icons-material/Send";
import React from "react";
import "./footer.css";
import logo from "../../../../assets/img/home/basil_aines_footer.svg";

const Footer = () => {
  const links = [
    {
      title: "Militantisme",
      href: "https://basil.fr/militantisme",
    },
    {
      title: "Frigo connecté",
      href: "https://basil.fr/frigo-connecte",
    },
    {
      title: "Zone Livraison",
      href: "https://basil.fr/militantisme#mapV3",
    },
    {
      title: "Conditions générales de vente",
      href: "https://basil.fr/conditions-generales-de-Vente",
    },
  ];
  const links2 = [
    {
      title: "Mentions légales",
      href: "https://basil.fr/mentions-legales",
    },
  ];
  const social = [
    {
      title: "facebook",
      href: "https://www.facebook.com/Basil.Fraismaison/",
      icon: <FacebookIcon />,
    },
    {
      title: "instagram",
      href: "https://www.instagram.com/basilfrance_/",
      icon: <InstagramIcon />,
    },
    {
      title: "linkedin",
      href: "https://www.linkedin.com/company/basilpournosaines/mycompany/",
      icon: <LinkedInIcon />,
    },
  ];

  return (
    <Grid container className="footer" marginTop={3}>
      <Grid
        item
        xs={12}
        md={4}
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        padding={2}
      >
        <Grid item>
          <img src={logo} alt="logo basil aines" className="logo" />
        </Grid>
        <Grid item>
          <Typography variant="body1" textAlign="center">
            Parce que bien manger
            <br />
            ne devrait jamais être une option.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6">
            <strong>INDEX</strong>
          </Typography>
        </Grid>

        <Grid item>
          {links.map((link, i) => (
            <Link
              key={i}
              href={link.href}
              underline="hover"
              color="white"
              marginBottom={0.5}
            >
              <Typography variant="body1" textAlign="center">
                {link.title}
              </Typography>
            </Link>
          ))}
        </Grid>

        <Grid item>
          {links2.map((link, i) => (
            <Link
              key={i}
              href={link.href}
              underline="hover"
              color="white"
              marginBottom={0.5}
            >
              <Typography variant="body1">{link.title}</Typography>
            </Link>
          ))}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        padding={2}
      >
        <Grid item>
          <Typography variant="body1">SUIVRE LA BANDE À BASIL</Typography>
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {social.map((item, i) => (
            <Grid key={i} item>
              <IconButton
                aria-label={item.title}
                sx={{ color: "white" }}
                href={item.href}
              >
                {item.icon}
              </IconButton>
            </Grid>
          ))}
        </Grid>

        <Grid item>
          <Typography variant="body1">
            <strong>BASIL DAILY</strong>
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            label="Email"
            variant="filled"
            color="infos"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SendIcon sx={{ color: "white" }} />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
