import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { useSelector } from "react-redux";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { requests } from "../../../../agent";
import { ENTRYPOINT } from "../../../../config/entrypoint";

const superagent = superagentPromise(_superagent, global.Promise);
let token = null;
const tokenPlugin = (secured) => {
  return (request) => {
    if (token && secured) request.set("Authorization", `Bearer ${token}`);
  };
};

const CustomDeliveryNoteContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [edit, setEdit] = useState(false);
  const [customDeliveryNote, setCustomDeliveryNote] = useState(null);
  const [customDeliveriesNotes, setCustomDeliveriesNote] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setIsLoading(true);
    const promises = Promise.all([
      requests.get("/custom_delivery_notes", true),
      requests.get("/organizations/lists", true),
    ]);

    promises
      .then((responses) => {
        setCustomDeliveriesNote(responses[0]["hydra:member"]);
        setOrganizations(responses[1]["hydra:member"]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleEdit = (organizationId) => {
    setIsLoading(true);

    requests
      .get(`/custom_delivery_notes/${organizationId}`, true)
      .then((result) => {
        setCustomDeliveryNote(result);
        setEdit(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const toggleList = () => {
    setEdit(false);
    setCustomDeliveryNote(null);
  };
  const handleUpdate = async (data) => {
    setIsLoading(true);

    superagent
      .post(`${ENTRYPOINT}/custom_delivery_notes/${data.id}`)
      .use(tokenPlugin(true))
      .field("title", data.title)
      .field("footer", data.footer)
      .field("showAddressNotes", data.showAddressNotes)
      .field("bannerColor", data.bannerColor)
      .attach("file", data.file ? data.file : null)
      .then((response) => {
        setIsLoading(false);
        setEdit(false);
        setAlert({
          active: true,
          type: "success",
          message: "Votre bon de livraison a été mise à jour !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") return;
    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };
  const handleSubmitFilters = (data, from) => {
    setIsLoading(true);
    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.client !== null) dataUrl += `organization.id=${data.client}&`;
    if (data.fullName !== "") dataUrl += `name=${data.fullName}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setCustomDeliveriesNote(result["hydra:member"]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };

  return (
    <>
      {edit ? (
        <Edit
          userData={userData}
          isLoading={isLoading}
          edit={edit}
          customDeliveryNote={customDeliveryNote}
          organizations={organizations}
          toggleList={toggleList}
          validation={handleUpdate}
        />
      ) : (
        <List
          userData={userData}
          isLoading={isLoading}
          edit={edit}
          customDeliveriesNotes={customDeliveriesNotes}
          organizations={organizations}
          toggleEdit={toggleEdit}
          handleSubmitFilters={handleSubmitFilters}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CustomDeliveryNoteContainer;
