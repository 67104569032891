import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UseStyle from "../Common/StyledComponent/UseStyle";
import { isAdmin } from "../../../utils";

const SubscriptionForm = (props) => {
  const classes = UseStyle();
  const [selectedClient, setSelectedClient] = useState(null);
  const clients = props.organizations.map((o) => {
    const filtre = o.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: o,
    };
  });

  return (
    <Paper className={classes.paper} elevation={6}>
      <form>
        <Grid container spacing={2} alignItems="center">
          {isAdmin(props.userData) && (
            <Grid item xs={12}>
              {props.edit ? (
                <TextField
                  fullWidth
                  name={"client"}
                  label={"Client (non modifiable)"}
                  type={"text"}
                  value={props.values.organizationName}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Autocomplete
                  fullWidth
                  options={clients.sort(
                    (a, b) => -b.filtre.localeCompare(a.filtre)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.client.id === value.client.id
                  }
                  groupBy={(value) => value.filtre}
                  getOptionLabel={(option) => {
                    if (!option.client) return "";
                    return option.client.id + " - " + option.client.name;
                  }}
                  value={selectedClient}
                  onChange={(event, value) => {
                    setSelectedClient(value);

                    if (value !== null)
                      props.setFieldValue("organization", value.client.id);
                    else props.setFieldValue("organization", null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      error={
                        props.touched.organization &&
                        Boolean(props.errors.organization)
                      }
                      helperText={
                        props.touched.organization &&
                        props.errors.organization &&
                        props.errors.organization
                      }
                    />
                  )}
                />
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={props.touched.plan && Boolean(props.errors.plan)}
            >
              <InputLabel>Offres</InputLabel>
              <Select
                label="Offres"
                name="plan"
                value={props.values.plan}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              >
                {props.plans.map((plan) => {
                  return (
                    <MenuItem key={plan.id} value={plan.id}>
                      {plan.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {props.errors.plan && props.touched.plan && props.errors.plan}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={props.touched.addOns && Boolean(props.errors.addOns)}
            >
              <InputLabel>Extensions</InputLabel>
              <Select
                multiple
                label="Extensions"
                name="addOns"
                value={props.values.addOns}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) => props.addOns.find((addOn) => addOn.id === id).name
                    )
                    .join(", ")
                }
              >
                {props.addOns.map((addOn) => {
                  return (
                    <MenuItem key={addOn.id} value={addOn.id}>
                      <Checkbox
                        checked={props.values.addOns.indexOf(addOn.id) > -1}
                      />
                      <ListItemText primary={addOn.name} />
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {props.errors.addOns &&
                  props.touched.addOns &&
                  props.errors.addOns}
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label="Nom"
              value={props.values.name}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.name && Boolean(props.errors.name)}
              helperText={
                props.errors.name && props.touched.name && props.errors.name
              }
            />
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Taxe</InputLabel>
              <Select
                error={props.touched.tax && Boolean(props.errors.tax)}
                helperText={
                  props.errors.tax && props.touched.tax && props.errors.tax
                }
                label="Taxe"
                name="tax"
                value={props.values.tax}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              >
                {props.taxes.map((taxe) => {
                  return (
                    <MenuItem key={taxe.id} value={taxe.id}>
                      {taxe.nom}%
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="priceIncludingTax">Prix TTC</InputLabel>
              <OutlinedInput
                id="priceIncludingTax"
                value={props.values.priceIncludingTax}
                onChange={props.handleChange}
                startAdornment={
                  <InputAdornment position="start">€</InputAdornment>
                }
                label="Prix TTC"
                type="number"
                error={
                  props.touched.priceIncludingTax &&
                  Boolean(props.errors.priceIncludingTax)
                }
              />
              <FormHelperText>
                {props.errors.priceIncludingTax &&
                  props.touched.priceIncludingTax &&
                  props.errors.priceIncludingTax}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <Tooltip title="Le prix HT est calculé automatiquement">
              <FormControl fullWidth>
                <InputLabel htmlFor="priceExcludingTax">Prix HT</InputLabel>
                <OutlinedInput
                  readOnly
                  id="priceExcludingTax"
                  value={props.values.priceExcludingTax}
                  startAdornment={
                    <InputAdornment position="start">€</InputAdornment>
                  }
                  label="Prix HT"
                />
              </FormControl>
            </Tooltip>
          </Grid> */}

          <Grid container item justifyContent="flex-end" xs={12}>
            <LoadingButton
              loading={props.isLoading}
              variant="contained"
              color="primary"
              startIcon={<SendIcon />}
              onClick={props.handleSubmit}
            >
              {props.edit ? "Sauvegarder" : "Créer"}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SubscriptionForm;
