import React from "react";
import { CircularProgress } from "@mui/material";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import SearchLocationInput from "./SearchLocationInput";

const PlacesAutocomplete = (props) => {
  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <CircularProgress color="secondary" />;
      case Status.FAILURE:
        return <div>Erreur</div>;
      case Status.SUCCESS:
        return <SearchLocationInput {...props} />;
    }
  };

  return (
    <Wrapper
      apiKey={"AIzaSyD2UZ0AnaPjZ_Nx0SrQBK-Hbczjo6dIIaY"}
      render={render}
      libraries={["places"]}
    />
  );
};

export default PlacesAutocomplete;
