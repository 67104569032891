import React from "react";
import TransferList from "../Common/TransfertList/TransferList";
import UseStyle from "../Common/StyledComponent/UseStyle";
import { locale, mask } from "../../../localisation";
import {
  Grid,
  Stack,
  Paper,
  TextField,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Divider,
  Checkbox,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import InfoIcon from '@mui/icons-material/Info';

const MenuForm = (props) => {
  const classes = UseStyle();

  const handleSelectedProductDejeuner = (produits) => {
    props.setFieldValue("lunchProducts", produits);
  };
  const handleSelectedProductDiner = (produits) => {
    props.setFieldValue("dinnerProducts", produits);
  };
  const handleSelectedLunchExtras = (extras) => {
    props.setFieldValue("lunchExtras", extras);
  };
  const handleSelectedDinnerExtras = (extras) => {
    props.setFieldValue("dinnerExtras", extras);
  };

  return (
    <form>
      <Paper className={classes.paper} elevation={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="name"
              label="Nom"
              value={props.values.name}
              onChange={props.handleChange}
              error={props.touched.name && Boolean(props.errors.name)}
              helperText={
                props.errors.name && props.touched.name && props.errors.name
              }
            />
          </Grid>

          <Grid item xs={4}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale["fr"]}
            >
              <DatePicker
                mask={mask["fr"]}
                label="Date du menu"
                inputFormat="dd/MM/yyyy"
                value={props.values.date}
                onChange={(value) => props.setFieldValue("date", value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    name="date"
                    {...params}
                    error={props.touched.date && Boolean(props.errors.date)}
                    helperText={
                      props.errors.date &&
                      props.touched.date &&
                      props.errors.date
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <Stack direction={"row"} spacing={1} alignItems="center">
              <TextField
                fullWidth
                name="priorityIndex"
                label="Priorité"
                type="number"
                value={props.values.priorityIndex}
                onChange={props.handleChange}
                error={props.touched.priorityIndex && Boolean(props.errors.priorityIndex)}
                InputProps={{ inputProps: { min: -2000000, max: 2000000 } }}
                helperText={
                  props.errors.priorityIndex && props.touched.priorityIndex && props.errors.priorityIndex
                }
              />
              <Tooltip title="De -2 000 000 (priorité basse) à 2 000 000 (priorité haute)">
                <InfoIcon fontSize="medium" sx={{ color: "gray" }}/>
              </Tooltip>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              name="description"
              label="Description"
              value={props.values.description}
              onChange={props.handleChange}
              error={
                props.touched.description && Boolean(props.errors.description)
              }
              helperText={
                props.errors.description &&
                props.touched.description &&
                props.errors.description
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel>Tournées</InputLabel>
              <Select
                multiple
                name="rounds"
                label="Tournées"
                value={props.values.rounds}
                onChange={props.handleChange}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) => props.rounds.find((round) => round.id === id).name
                    )
                    .join(", ")
                }
                error={props.touched.rounds && Boolean(props.errors.rounds)}
              >
                {props.rounds.map((round, i) => {
                  return (
                    <MenuItem value={round.id} key={i}>
                      <Checkbox
                        checked={props.values.rounds.indexOf(round.id) > -1}
                      />
                      <ListItemText primary={round.name} />
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {props.errors.round &&
                  props.touched.round &&
                  props.errors.round}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel>Régimes adaptés</InputLabel>
              <Select
                multiple
                name="diets"
                label="Régimes adaptés"
                value={props.values.diets}
                onChange={props.handleChange}
                renderValue={(selected) =>
                  selected
                    .map((id) => props.diets.find((item) => item.id === id).nom)
                    .join(", ")
                }
                error={props.touched.diets && Boolean(props.errors.diets)}
              >
                {props.diets.map((diet, i) => {
                  return (
                    <MenuItem value={diet.id} key={i}>
                      <Checkbox
                        checked={props.values.diets.indexOf(diet.id) > -1}
                      />
                      <ListItemText primary={diet.nom} />
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>
                {props.errors.diets &&
                  props.touched.diets &&
                  props.errors.diets}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h6"
              align={"left"}
              sx={{ mb: 1 }}
            >
              Produits du déjeuner
            </Typography>
            <TransferList
              menuList={true}
              left={
                props.values.lunchProducts.length > 0
                  ? props.products.ids.filter(
                      (id) =>
                        !props.values.lunchProducts
                          .map((product) => product.id)
                          .includes(id)
                    )
                  : props.products.ids
              }
              right={props.values.lunchProducts}
              chosen={handleSelectedProductDejeuner}
              items={props.products.datas}
              categories={props.categories}
              regimes={props.diets}
              error={
                props.touched.lunchProducts &&
                Boolean(props.errors.lunchProducts)
              }
              helperText={
                props.errors.lunchProducts &&
                props.touched.lunchProducts &&
                props.errors.lunchProducts
              }
            />
            <input type="hidden" values={props.values.lunchProducts} />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h6"
              align={"left"}
              sx={{ mb: 1 }}
            >
              Suppléments du déjeuner
            </Typography>
            <TransferList
              left={
                props.values.lunchExtras.length > 0
                  ? props.extras.ids.filter(
                      (id) =>
                        !props.values.lunchExtras
                          .map((item) => item.id)
                          .includes(id)
                    )
                  : props.extras.ids
              }
              right={props.values.lunchExtras}
              chosen={handleSelectedLunchExtras}
              items={props.extras.datas}
              categories={[]}
              regimes={[]}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h6"
              align={"left"}
              sx={{ mb: 1 }}
            >
              Produits du dîner
            </Typography>
            <TransferList
              menuList={true}
              left={
                props.values.dinnerProducts.length > 0
                  ? props.products.ids.filter(
                      (id) =>
                        !props.values.dinnerProducts
                          .map((product) => product.id)
                          .includes(id)
                    )
                  : props.products.ids
              }
              right={props.values.dinnerProducts}
              chosen={handleSelectedProductDiner}
              items={props.products.datas}
              categories={props.categories}
              regimes={props.diets}
              error={
                props.touched.dinnerProducts &&
                Boolean(props.errors.dinnerProducts)
              }
              helperText={
                props.errors.dinnerProducts &&
                props.touched.dinnerProducts &&
                props.errors.dinnerProducts
              }
            />
            <input type="hidden" values={props.values.dinnerProducts} />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h6"
              align={"left"}
              sx={{ mb: 1 }}
            >
              Suppléments du dîner
            </Typography>
            <TransferList
              left={
                props.values.dinnerExtras.length > 0
                  ? props.extras.ids.filter(
                      (id) =>
                        !props.values.dinnerExtras
                          .map((item) => item.id)
                          .includes(id)
                    )
                  : props.extras.ids
              }
              right={props.values.dinnerExtras}
              chosen={handleSelectedDinnerExtras}
              items={props.extras.datas}
              categories={[]}
              regimes={[]}
            />
          </Grid>

          <Grid item xs={12} container justifyContent="flex-end">
            <LoadingButton
              loading={props.isLoading}
              color="primary"
              onClick={props.handleSubmit}
              variant="contained"
              startIcon={<SaveRoundedIcon />}
            >
              {props.edit ? "Sauvegarder" : "Créer"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default MenuForm;
