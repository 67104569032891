import React, { useEffect, useState } from "react";
import Add from "./Add/Add";
import { Alert, LinearProgress, Snackbar } from "@mui/material";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { requests } from "../../../agent";

const CreditNoteContainer = () => {
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [creditNote, setCreditNote] = useState(null);
  const [creditNotes, setCreditNotes] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    const promises = Promise.all([
      requests.get("/credit_notes", true),
      requests.get("/recipients?discriminator[]=unit", true),
    ]);

    promises
      .then((responses) => {
        setCreditNotes(responses[0]["hydra:member"]);
        setRecipients(responses[1]["hydra:member"]);
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
    setIsPageLoading(false);
  }, []);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (id) => {
    // Find the taxe to edit
    const creditNote = creditNotes.find((cn) => cn.id === id);
    if (!creditNote) return;

    setCreditNote(creditNote);
    setEdit(true);
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setCreditNote(null);
  };
  const handleSubmit = async (data) => {
    setIsLoading(true);
    requests
      .post("/credit_notes", data, true)
      .then((response) => {
        setCreditNotes((prevCreditNotes) => [response, ...prevCreditNotes]);
        setIsLoading(false);
        toggleList();
        setAlert({
          active: true,
          type: "success",
          message: "L'avoir a bien été créé !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleUpdate = async (data) => {
    setIsLoading(true);
    requests
      .patch(`/credit_notes/${data.id}`, data, true)
      .then((response) => {
        setCreditNotes((prevCreditNotes) =>
          prevCreditNotes.map((cn) => (cn.id === response.id ? response : cn))
        );
        setIsLoading(false);
        toggleList();
        setAlert({
          active: true,
          type: "success",
          message: "L'avoir a bien été modifié !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleDelete = (id) => {
    setIsLoading(true);
    requests
      .del(`/credit_notes/${id}`, true)
      .then((response) => {
        setCreditNotes((prevCreditNotes) =>
          prevCreditNotes.filter((cn) => cn.id !== id)
        );
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "L'avoir a bien été supprimé !",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") return;
    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };

  return isPageLoading ? (
    <LinearProgress />
  ) : (
    <>
      {add && (
        <Add
          isLoading={isLoading}
          add={add}
          edit={edit}
          toggleList={toggleList}
          validation={handleSubmit}
          recipients={recipients}
        />
      )}
      {edit && (
        <Edit
          isLoading={isLoading}
          edit={edit}
          creditNote={creditNote}
          toggleList={toggleList}
          validation={handleUpdate}
          recipients={recipients}
        />
      )}
      {!add && !edit && (
        <List
          isLoading={isLoading}
          creditNotes={creditNotes}
          add={add}
          toggleAdd={toggleAdd}
          toggleEdit={toggleEdit}
          edit={edit}
          recipients={recipients}
          handleDelete={handleDelete}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CreditNoteContainer;
