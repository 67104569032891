import React, { useState } from "react";
import { format } from "date-fns";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Input,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Filters from "../../Common/Filters";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const List = (props) => {
  const classes = UseStyle();
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleClickOpen = (produitId) => {
    setOpen(true);
    setSelectedProduct(produitId);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const renderCellChecked = (params) => {
    if (params.value) {
      return <CheckIcon />;
    } else {
      return <CloseIcon />;
    }
  };
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "photo",
      headerName: "Photo",
      valueGetter: (params) => "",
    },
    {
      field: "nom",
      headerName: "Nom",
      flex: 0.2,
    },
    {
      field: "categorie",
      headerName: "Catégorie",
      flex: 0.1,
      valueGetter: (params) => params.value.nom,
    },
    {
      field: "estMicroOndable",
      headerName: "Micro-ondable",
      renderCell: renderCellChecked,
      flex: 0.05,
    },
    {
      field: "estRemplacement",
      headerName: "Produit de remplacement",
      renderCell: renderCellChecked,
      flex: 0.05,
    },
    {
      field: "dateMaj",
      headerName: "MàJ le",
      flex: 0.07,
      valueGetter: (params) => {
        if (params.value) {
          return format(new Date(params.value), "dd/MM/yyyy HH:mm");
        } else {
          return format(new Date(params.row.dateCreation), "dd/MM/yyyy");
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
        <GridActionsCellItem
          color="error"
          icon={
            <Tooltip title="Supprimer">
              <DeleteIcon />
            </Tooltip>
          }
          onClick={() => handleClickOpen(params.id)}
          label="Supprimer"
        />,
      ],
    },
  ];

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        spacing={1}
        paddingBottom={2}
      >
        <LoadingButton
          loading={props.loading}
          onClick={() => {
            props.toggleImportModal();
            setSelectedFile(null);
          }}
        >
          Import
        </LoadingButton>
        <Button disabled>Export</Button>

        <LoadingButton
          loading={props.loading}
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Produit
        </LoadingButton>
      </Stack>

      <Stack spacing={2}>
        <Filters
          types={["lastname"]}
          from="produits"
          handleSubmitFilters={props.handleSubmitFilters}
          loading={props.loading}
        />

        <Paper className={classes.paper} elevation={6}>
          <Grid container sx={{ height: "70vh", width: "100%" }}>
            <DataGrid
              columns={columns}
              rows={props.produits}
              density="comfortable"
              disableSelectionOnClick
              pagination
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              loading={props.loading}
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
            />
          </Grid>
        </Paper>
      </Stack>

      <Dialog
        open={props.showImportModal}
        onClose={props.toggleImportModal}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>Importer des produits</DialogTitle>
        <DialogContent dividers>
          {props.loading ? (
            <Grid container justifyContent={"center"}>
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                xs={6}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  variant="contained"
                  component="span"
                  endIcon={<FileDownloadIcon />}
                >
                  Télécharger le modèle
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                {selectedFile && (
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    direction={"column"}
                  >
                    <Grid item>
                      <FilePresentIcon />
                    </Grid>
                    <Grid item>{selectedFile.name}</Grid>
                  </Grid>
                )}

                <Grid
                  container
                  item
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      inputProps={{
                        accept:
                          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      }}
                      id="contained-button-file"
                      type="file"
                      sx={{ display: "none" }}
                      onChange={(e) => {
                        setSelectedFile(e.target.files[0]);
                      }}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      endIcon={<FileUploadIcon />}
                    >
                      Choisir un fichier
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.toggleImportModal} disabled={props.loading}>
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={() => props.importProducts(selectedFile)}
            disabled={props.loading || !selectedFile}
          >
            Importer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Supprimer ce produit ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Toute suppression sera définitive et entrainera la perte de ces
            données. Êtes-vous sûr de vouloir supprimer ce produit ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Annuler
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              props.handleDelete(selectedProduct);
              handleClose();
            }}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default List;
