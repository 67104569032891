import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import AddOnForm from "../AddOnForm";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'une extension"
        />
      </Card>

      <AddOnForm {...props} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    name: "",
    tax: props.taxes?.[0]?.id ?? null,
    priceIncludingTax: 0,
    priceExcludingTax: 0,
    isEnabled: true,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Champs obligatoire")
      .min(3, "Minimum 3 caractères"),
    isEnabled: Yup.boolean(),
    tax: Yup.number().positive().required("Ce champs est obligatoire"),
    priceIncludingTax: Yup.number()
      .positive("Le prix doit être supérieur à 0")
      .required("Champs obligatoire"),
    priceExcludingTax: Yup.number().positive().required("Champs obligatoire"),
  }),
  handleSubmit: (values, { props }) => {
    const newItem = {
      name: values.name,
      isEnabled: values.isEnabled,
      tax: `/taxes/${values.tax}`,
      priceIncludingTax: values.priceIncludingTax.toFixed(4),
      priceExcludingTax: values.priceExcludingTax,
    };

    props.validation(newItem);
  },
})(Add);
