import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import RecipientForm from "../RecipientForm";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import { format } from "date-fns";
import { isAdmin } from "../../../../utils";

const EditUnit = (props) => {
  const classes = UseStyle();

  return (
    <form>
      <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Button
            variant={"contained"}
            onClick={props.toggleList}
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Retour
          </Button>
        </Stack>

        <Card className={classes.card}>
          <CardHeader
            subheader="Ces informations peuvent être modifier."
            title="Modification d'un convive"
          />
        </Card>

        {isAdmin(props.userData) && (
          <Paper className={classes.paper} elevation={6}>
            <TextField
              fullWidth
              name={"client"}
              label={"Client (non modifiable)"}
              type={"text"}
              value={props.values.clientFullname}
              InputProps={{
                readOnly: true,
              }}
            />
          </Paper>
        )}

        <Paper className={classes.paper} elevation={6}>
          <RecipientForm {...props} client={props.recipientEdit.organization} />

          <Stack direction={"row"} justifyContent={"flex-end"} paddingTop={2}>
            {props.loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                onClick={props.handleSubmit}
                variant="contained"
                startIcon={<SaveRoundedIcon />}
              >
                Sauvegarder
              </Button>
            )}
          </Stack>
        </Paper>
      </Stack>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    clientFullname: props.recipientEdit.organization.name,
    gender: props.recipientEdit.gender,
    name: props.recipientEdit.name,
    email: props.recipientEdit.email,
    address: props.recipientEdit.address,
    birthdate: props.recipientEdit.birthdate
      ? new Date(props.recipientEdit.birthdate)
      : null,
    contacts: props.recipientEdit.contacts,
    startedAt: new Date(props.recipientEdit.startedAt),
    cancelledAt: props.recipientEdit.cancelledAt
      ? new Date(props.recipientEdit.cancelledAt)
      : null,
    recipientNote: props.recipientEdit.recipientNote,
    adminNote: props.recipientEdit.adminNote,
    origin: props.recipientEdit?.origin?.id ?? "none",
    state: props.recipientEdit.state,
    position: props.recipientEdit.position,
    round: props.recipientEdit.round.id,
    lunchMenu: props.recipientEdit.lunchMenu.id,
    dinnerMenu: props.recipientEdit?.dinnerMenu?.id ?? "none",
    mealDays: props.recipientEdit.mealDays,
    diets: props.recipientEdit.diets.map((d) => d.id),
    dietsNote: props.recipientEdit.dietsNote,
    allergens: props.recipientEdit.allergens.map((a) => a.id),
    hasNewsletterSubscribed: props.recipientEdit.hasNewsletterSubscribed,
    lunchExtras: props.recipientEdit.lunchExtras.map((e) => e.extra.id),
    dinnerExtras: props.recipientEdit.dinnerExtras.map((e) => e.extra.id),
    billingAddress: props.recipientEdit?.billingAddress ?? {
      location: [0.0, 0.0],
      address: {
        unparsed: "",
      },
      notes: "",
    },
    billingEmail: props.recipientEdit.billingEmail,
    rank: props.recipientEdit.rank,
    wantsInvoicesByEmail: props.recipientEdit.wantsInvoicesByEmail,
  }),
  validationSchema: Yup.object().shape({
    gender: Yup.string().length(1).required("Champs obligatoire"),
    name: Yup.string().required("Champs obligatoire"),
    email: Yup.string()
      .email("Veuillez saisir une email valide. Ex: hello@touva.fr ")
      .required("Champs obligatoire"),
    birthdate: Yup.date().nullable(),
    address: Yup.object()
      .shape({
        latitude: Yup.number().required("Champs obligatoire"),
        longitude: Yup.number().required("Champs obligatoire"),
        number: Yup.string().nullable(),
        street: Yup.string().required(),
        city: Yup.string().required(),
        locality: Yup.string().required(),
        country: Yup.string().required(),
        postalCode: Yup.string().nullable(),
        unparsed: Yup.string().required(
          "Sélectionner une adresse dans la liste déroulante."
        ),
        latitude: Yup.number().required(),
        longitude: Yup.number().required(),
        notes: Yup.string().nullable(),
      })
      .required("Champs obligatoire"),
    contacts: Yup.array().of(
      Yup.object().shape({
        nomContact: Yup.string().required("Champs obligatoire").matches(/^[^:]+$/, "Le nom du contact ne doit pas contenir le caractère :"),
        telephoneContact: Yup.string()
          .required("Champs obligatoire")
          .test(
            "len",
            "Veuillez saisir un numéro de téléphone valide",
            (tel) => tel && tel.toString().length === 10
          ),
      })
    ),
    startedAt: Yup.date(),
    cancelledAt: Yup.date().notRequired().nullable(),
    round: Yup.number().positive().required("Champs obligatoire"),
    lunchMenu: Yup.number().positive().required("Champs obligatoire"),
    mealDays: Yup.array().of(Yup.number()),
    billingAddress: Yup.object()
      .shape({
        latitude: Yup.number().required("Champs obligatoire"),
        longitude: Yup.number().required("Champs obligatoire"),
        number: Yup.string().nullable(),
        street: Yup.string().required(),
        city: Yup.string().required(),
        locality: Yup.string().required(),
        country: Yup.string().required(),
        postalCode: Yup.string().nullable(),
        unparsed: Yup.string().required(
          "Sélectionner une adresse dans la liste déroulante."
        ),
        latitude: Yup.number().required(),
        longitude: Yup.number().required(),
        notes: Yup.string().nullable(),
      })
      .required("Champs obligatoire"),
    billingEmail: Yup.string()
      .email("Veuillez saisir une email valide. Ex: hello@touva.fr ")
      .required("Champs obligatoire"),
    rank: Yup.number().positive().nullable(),
    wantsInvoicesByEmail: Yup.boolean(),
  }),
  handleSubmit: (values, { props }) => {
    const recipient = {
      id: props.recipientEdit.id,
      gender: values.gender ? values.gender : null,
      name: values.name,
      email: values.email,
      address: {
        ...values.address,
        latitude: parseFloat(values.address.latitude),
        longitude: parseFloat(values.address.longitude)
      },
      birthdate: values.birthdate
        ? format(values.birthdate, "yyyy-MM-dd")
        : null,
      contacts: values.contacts,
      startedAt: format(values.startedAt, "yyyy-MM-dd"),
      cancelledAt: values.cancelledAt
        ? format(values.cancelledAt, "yyyy-MM-dd")
        : null,
      recipientNote: values.recipientNote ? values.recipientNote : null,
      adminNote: values.adminNote ? values.adminNote : null,
      origin: values.origin !== "none" ? `/origins/${values.origin}` : null,
      state: values.state,
      position: values.position,
      round: `/rounds/${values.round}`,
      lunchMenu: `/formules/${values.lunchMenu}`,
      dinnerMenu:
        values.dinnerMenu !== "none" ? `/formules/${values.dinnerMenu}` : null,
      mealDays: values.mealDays,
      diets: values.diets.map((d) => `/regimes/${d}`),
      dietsNote: values.dietsNote ? values.dietsNote : null,
      allergens: values.allergens.map((a) => `/allergenes/${a}`),
      hasNewsletterSubscribed: values.hasNewsletterSubscribed,
      lunchExtras: values.lunchExtras.map((extra) => ({
        extra: `/extra_categories/${extra}`,
        quantity: 1,
      })),
      dinnerExtras: values.dinnerExtras.map((extra) => ({
        extra: `/extra_categories/${extra}`,
        quantity: 1,
      })),
      billingAddress: values.billingAddress,
      billingEmail: values.billingEmail,
      rank: values.rank !== "" ? values.rank : null,
      wantsInvoicesByEmail: values.wantsInvoicesByEmail,
    };

    props.validation(recipient);
  },
})(EditUnit);
