import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { requests } from "../../../../agent";

const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(null);
  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required("Un numéro de téléphone est requis")
        .matches(
          "[0]{1}[1-7]{1}[0-9]{8}",
          "Veuillez saisir un numéro de téléphone valide."
        ),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      requests
        .post("/users/contact/individual", { phone: values.phone }, true)
        .then((response) => {
          setIsLoading(false);
          setIsSubmit(true);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsSubmit(false);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        borderRadius={3}
        padding={2}
      >
        <TextField
          sx={{ width: "33ch" }}
          label="Votre numéro de téléphone"
          size="small"
          value={formik.values.phone}
          name="phone"
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />

        <ReCAPTCHA
          sitekey="6Lemk8ckAAAAAPdLsajjt9sOWs3p8UvnrxXnM5fI"
          onChange={(token) => setIsCaptchaValid(token)}
        />

        <LoadingButton
          {...(!isCaptchaValid && { disabled: true })}
          type="submit"
          variant="contained"
          color="primary"
          loading={isLoading}
        >
          Recevoir un basil pour nos ainés
        </LoadingButton>

        {isSubmit && (
          <Alert severity="success">
            Nous avons bien reçu votre demande, vous serez prochainement
            contacté par téléphone.
          </Alert>
        )}
      </Stack>
    </form>
  );
};

export default Form;
