import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Layout from "./components/admin/Layout";
import { fetchUserData } from "./slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const userData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserData({ id: userData.id }));
  }, []);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default App;
