// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/home/formule.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".formules {\n    position: relative;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left\n        bottom #fff;\n    display: flex;\n    flex-direction: column;\n    padding: 2rem;\n}\n", ""]);
// Exports
module.exports = exports;
