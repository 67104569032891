import React, { useEffect, useState } from "react";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import {
  Grid,
  Paper,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Card,
  CardHeader,
  Button,
  OutlinedInput,
  Chip,
  ListItemText,
  Input,
  FormHelperText,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SendIcon from "@mui/icons-material/Send";
import { withFormik } from "formik";
import * as Yup from "yup";
import { ENTRYPOINT } from "../../../../config/entrypoint";

const Edit = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const classes = UseStyle();

  const handleSelectedAllergenes = (allergenes) => {
    props.setFieldValue("allergenes", allergenes);
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  return (
    <form>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button
          variant={"contained"}
          sx={{ mb: 3 }}
          onClick={props.toggleForm}
          color="secondary"
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Stack>

      <Card className={classes.card} sx={{ mb: 3 }}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Modification d'un produit"
        />
      </Card>

      <Paper className={classes.paper} elevation={6}>
        <Grid container spacing={5}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={props.errors.nom && props.touched.nom ? true : false}
                {...(props.errors.nom &&
                  props.touched.nom && { helperText: props.errors.nom })}
                fullWidth
                name={"nom"}
                label={"Nom du produit"}
                variant="outlined"
                type={"text"}
                value={props.values.nom}
                className={classes.inputField}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={
                  props.errors.description && props.touched.description
                    ? true
                    : false
                }
                {...(props.errors.description &&
                  props.touched.description && {
                    helperText: props.errors.description,
                  })}
                fullWidth
                name={"description"}
                label={"Sous-titre du produit"}
                variant="outlined"
                type={"text"}
                value={props.values.description}
                className={classes.inputField}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel>Catégories</InputLabel>
                <Select
                  error={
                    props.errors.categorie && props.touched.categorie
                      ? true
                      : false
                  }
                  style={{ width: "100%", textAlign: "left" }}
                  label="Catégories"
                  name={"categorie"}
                  value={props.values.categorie}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                >
                  {props.categories.length > 0 &&
                    props.categories.map((categorie) => {
                      return (
                        <MenuItem value={categorie.id}>
                          {categorie.nom}
                        </MenuItem>
                      );
                    })}
                </Select>
                {props.errors.categorie && props.touched.categorie && (
                  <FormHelperText error>
                    {props.errors.categorie}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormGroup row>
                <FormControlLabel
                  onChange={props.handleChange}
                  checked={props.values.estMicroOndable}
                  name="estMicroOndable"
                  onBlur={props.handleBlur}
                  control={<Checkbox />}
                  label="Micro-ondable"
                />

                <FormControlLabel
                  onChange={props.handleChange}
                  checked={props.values.estRemplacement}
                  name="estRemplacement"
                  onBlur={props.handleBlur}
                  control={<Checkbox />}
                  label="Produit de remplacement"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={props.values.ingredients}
                multiline
                rows={4}
                fullWidth
                name={"ingredients"}
                label={"Ingrédients"}
                variant="outlined"
                type={"text"}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={props.values.preparation}
                multiline
                rows={4}
                fullWidth
                name={"preparation"}
                label={"Préparation"}
                variant="outlined"
                type={"text"}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel>Régimes non adaptés</InputLabel>
                <Select
                  multiple
                  value={props.values.regimesNonAdaptes}
                  onChange={(event) => {
                    props.setFieldValue(
                      `regimesNonAdaptes`,
                      event.target.value
                    );
                  }}
                  style={{ width: "100%" }}
                  input={<OutlinedInput label="Régimes non adaptés" />}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((id) => (
                          <Chip
                            key={id}
                            label={
                              props.regimes.find((regime) => regime.id === id)
                                .nom
                            }
                          />
                        ))}
                      </Box>
                    );
                  }}
                  label="Régimes non adaptés au produit"
                  name={"regimesNonAdaptes"}
                >
                  {props.regimes.map((regime, i) => {
                    return (
                      <MenuItem value={regime.id} key={i}>
                        <Checkbox
                          checked={
                            props.values.regimesNonAdaptes.indexOf(regime.id) >
                            -1
                          }
                        />
                        <ListItemText primary={regime.nom} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel>Allergènes</InputLabel>
                <Select
                  multiple
                  value={props.values.allergenes}
                  onChange={(event) => {
                    props.setFieldValue(`allergenes`, event.target.value);
                  }}
                  style={{ width: "100%" }}
                  input={<OutlinedInput label="Allergènes" />}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((id) => (
                          <Chip
                            key={id}
                            label={
                              props.allergenes.find(
                                (allergene) => allergene.id === id
                              ).nom
                            }
                          />
                        ))}
                      </Box>
                    );
                  }}
                  label="Allergènes du produit"
                  name={"allergenes"}
                >
                  {props.allergenes.map((allergene, i) => {
                    return (
                      <MenuItem value={allergene.id} key={i}>
                        <Checkbox
                          checked={
                            props.values.allergenes.indexOf(allergene.id) > -1
                          }
                        />
                        <ListItemText primary={allergene.nom} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item container justifyContent={"flex-end"}>
              {props.loading ? (
                <CircularProgress color="secondary" />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={props.handleSubmit}
                  endIcon={<SendIcon />}
                >
                  Modifier le produit
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              Image du produit
            </Typography>

            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {(props.dataProductEdit.contentUrl ||
                (imageUrl && selectedImage)) && (
                <img
                  src={
                    imageUrl
                      ? imageUrl
                      : `${ENTRYPOINT}${props.dataProductEdit.contentUrl}`
                  }
                  alt={
                    imageUrl ? selectedImage.name : props.dataProductEdit.nom
                  }
                  style={{ width: 300, height: 220 }}
                />
              )}

              <Stack spacing={2}>
                <label htmlFor="contained-button-file">
                  <Input
                    inputProps={{
                      accept: "image/*",
                    }}
                    id="contained-button-file"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                      props.setFieldValue("image", e.target.files[0]);
                    }}
                  />
                  <Button variant="contained" component="span">
                    Sélectionner une image
                  </Button>
                </label>

                {props.loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Button
                    variant="contained"
                    endIcon={<FileUploadIcon />}
                    disabled={!(imageUrl && selectedImage)}
                    onClick={() =>
                      props.updateImage(
                        props.dataProductEdit.id,
                        props.values.image
                      )
                    }
                  >
                    Mettre à jour l'image
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    nom: props.dataProductEdit.nom,
    description: props.dataProductEdit.description,
    preparation: props.dataProductEdit.preparation,
    ingredients: props.dataProductEdit.ingredients,
    allergenes: props.dataProductEdit.allergenes.map((item) => item.id),
    categorie: props.dataProductEdit.categorie.id,
    estMicroOndable: props.dataProductEdit.estMicroOndable,
    image: "",
    estRemplacement: props.dataProductEdit.estRemplacement,
    regimesNonAdaptes: props.dataProductEdit.regimesNonAdaptes.map(
      (item) => item.id
    ),
  }),
  validationSchema: Yup.object().shape({
    nom: Yup.string()
      .min(3)
      .max(100)
      .required("Un nom est nécessaire pour le produit."),
    categorie: Yup.number().required(
      "Une catégorie est nécessaire pour le produit."
    ),
  }),
  handleSubmit: (values, { props }) => {
    props.validation(
      props.dataProductEdit.id,
      values.nom,
      values.description,
      values.ingredients,
      values.preparation,
      values.allergenes,
      values.estMicroOndable,
      values.categorie,
      values.estRemplacement,
      values.regimesNonAdaptes
    );
  },
})(Edit);
