import React, { Component } from "react";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { requests } from "../../../agent";
import { Snackbar, Alert } from "@mui/material";

class SupplementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplements: [],
      taxes: [],
      allergenes: [],
      regimes: [],
      categories: [],
      add: false,
      edit: null,
      loading: false,
      isPageLoading: false,
      dataSupplementEdit: null,
      displayAlert: {
        active: false,
        message: null,
      },
    };
  }

  componentDidMount() {
    this.setState({ isPageLoading: true });

    const promises = Promise.all([
      requests.get("/supplements", true),
      requests.get("/taxes", true),
      requests.get("/allergenes", true),
      requests.get("/extra_categories", true),
      requests.get("/regimes", true),
    ]);

    promises
      .then((results) => {
        this.setState({
          supplements: results[0]["hydra:member"],
          taxes: results[1]["hydra:member"],
          allergenes: results[2]["hydra:member"],
          categories: results[3]["hydra:member"],
          regimes: results[4]["hydra:member"],
          isPageLoading: false,
        });
      })
      .catch((err) => {
        this.isMountedVal = 1;
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  }

  handleToggleEdit = (supplementId) => {
    const caseSupplement = this.state.supplements.findIndex((l) => {
      return l.id === supplementId;
    });
    this.setState(() => {
      return {
        edit: supplementId,
        dataSupplementEdit: this.state.supplements[caseSupplement],
      };
    });
  };

  handleToggleAdd = () => {
    this.setState((oldState) => {
      return {
        add: !oldState.add,
      };
    });
  };

  handleToggleListFromEdit = () => {
    this.setState(() => {
      return {
        edit: null,
        dataSupplementEdit: null,
      };
    });
  };

  handleSubmit = (
    nom,
    category,
    description,
    taxe,
    prixTtc,
    prixHt,
    serviceTtc,
    serviceHt,
    repasTtc,
    repasHt,
    allergenes,
    regimesNonAdaptes
  ) => {
    this.setState({ loading: true });

    let dataAllergenes = [];
    let dataRegimes = [];
    let url = `/supplements`;

    allergenes.map((allergene) =>
      dataAllergenes.push(`/allergenes/${allergene}`)
    );
    regimesNonAdaptes.map((regime) => dataRegimes.push(`/regimes/${regime}`));

    const data = {
      nom,
      category: `/extra_categories/${category}`,
      description,
      taxe: `/taxes/${taxe}`,
      prixTtc,
      prixHt,
      serviceTtc,
      serviceHt,
      repasTtc,
      repasHt,
      allergenes: dataAllergenes,
      regimesNonAdaptes: dataRegimes,
    };

    requests
      .post(url, data, true)
      .then((response) => {
        const newSupplement = response;
        const newListSupplement = [...this.state.supplements];
        newListSupplement.unshift(newSupplement);
        this.setState({ loading: false });
        this.setState((oldState) => {
          return {
            supplements: newListSupplement,
            add: false,
            displayAlert: {
              active: true,
              type: "success",
              message: "Le supplément a bien été créé !",
            },
          };
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  handleSubmitEdit = (
    id,
    nom,
    category,
    description,
    taxe,
    prixTtc,
    prixHt,
    serviceTtc,
    serviceHt,
    repasTtc,
    repasHt,
    allergenes,
    regimesNonAdaptes
  ) => {
    this.setState({ loading: true });

    let dataAllergenes = [];
    let dataRegimes = [];
    let url = `/supplements/${id}`;

    allergenes.map((allergene) =>
      dataAllergenes.push(`/allergenes/${allergene}`)
    );
    regimesNonAdaptes.map((regime) => dataRegimes.push(`/regimes/${regime}`));

    const data = {
      nom,
      description,
      category: `/extra_categories/${category}`,
      taxe: `/taxes/${taxe}`,
      prixTtc,
      prixHt,
      serviceTtc,
      serviceHt,
      repasTtc,
      repasHt,
      allergenes: dataAllergenes,
      regimesNonAdaptes: dataRegimes,
    };

    requests
      .patch(url, data, true)
      .then((response) => {
        const caseSupplement = this.state.supplements.findIndex((u) => {
          return u.id === id;
        });
        const updatedSupplement = response;
        const newListSupplement = [...this.state.supplements];
        newListSupplement[caseSupplement] = updatedSupplement;
        this.setState({ loading: false });
        this.setState((oldState) => {
          return {
            supplements: newListSupplement,
            edit: null,
            displayAlert: {
              active: true,
              type: "success",
              message: "Le supplément a bien été modifié !",
            },
            loading: false,
          };
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  handleSubmitFilters = (data, from) => {
    this.setState({
      loading: true,
    });

    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.lastname !== "") dataUrl += `nom=${data.lastname}&`;

    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        this.setState({
          supplements: result["hydra:member"],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState(() => {
          return {
            loading: false,
            alert: {
              active: true,
              type: "error",
              message: err.message,
            },
          };
        });
      });
  };

  render() {
    const handleCloseSnackbar = (reason) => {
      if (reason === "clickaway") {
        return;
      }

      this.setState({
        displayAlert: {
          active: false,
          type: "",
          message: "",
        },
      });
    };

    const { add, edit, displayAlert } = this.state;
    return (
      <>
        {add && (
          <Add
            {...this.state}
            validation={this.handleSubmit}
            toggleList={this.handleToggleAdd}
          />
        )}

        {edit && (
          <Edit
            {...this.state}
            validation={this.handleSubmitEdit}
            toggleList={this.handleToggleListFromEdit}
          />
        )}

        {!add && !edit && (
          <List
            {...this.state}
            toggleAdd={this.handleToggleAdd}
            toggleEdit={this.handleToggleEdit}
            handleSubmitFilters={this.handleSubmitFilters}
          />
        )}

        {displayAlert.active && (
          <Snackbar
            open={displayAlert.active}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              variant={"filled"}
              severity={displayAlert.type}
            >
              {displayAlert.message}
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }
}

export default SupplementContainer;
