import { Paper, Stack, Typography } from "@mui/material";
import { blue, green, purple, red } from "@mui/material/colors";
import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { RECIPIENT_STATE } from "../../Common/Enums/Enums";
import UseStyle from "../../Common/StyledComponent/UseStyle";

const RecipientsChart = (props) => {
  const classes = UseStyle();
  let actives = props.recipients.filter(
    (recipient) => recipient.state === RECIPIENT_STATE.ACTIVE.status
  ).length;
  let paused = props.recipients.filter(
    (recipient) => recipient.state === RECIPIENT_STATE.PAUSED.status
  ).length;
  let cancelled = props.recipients.filter(
    (recipient) => recipient.state === RECIPIENT_STATE.CANCELLED.status
  ).length;

  const data = [
    { name: "Actifs", value: actives },
    { name: "En pause", value: paused },
    { name: "Résiliés", value: cancelled },
  ];
  const COLORS = [blue[400], purple[300], green[700], "#febd2f", red[900]];
  const values = data.map((item) => item.value);
  const sum = values.reduce((acc, value) => {
    return acc + value;
  });

  return (
    <Paper className={classes.paper} elevation={3}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography variant="h6" textAlign="center">
          Nombre de convives par statut
        </Typography>

        <PieChart width={120} height={120}>
          {sum === 0 ? (
            <Pie
              data={[{ name: "No Data", value: 1 }]}
              fill="#eeeeee"
              innerRadius={40}
              outerRadius={60}
              labelLine={false}
            />
          ) : (
            <Pie
              data={data}
              dataKey="value"
              fill="#8884d8"
              innerRadius={40}
              outerRadius={60}
              labelLine={false}
              paddingAngle={5}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          )}

          {sum !== 0 && <Tooltip animationEasing="ease" />}
        </PieChart>

        {sum === 0 && (
          <Typography variant="body1">
            <i>Aucun convive</i>
          </Typography>
        )}
        <Stack></Stack>
      </Stack>
    </Paper>
  );
};

export default RecipientsChart;
