import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  CardHeader,
  List,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import UseStyle from "../../Common/StyledComponent/UseStyle";
import CreditorForm from "../CreditorForm";
import { isAdmin } from "../../../../utils";

const Edit = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </Button>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Modification d'une taxe"
        />
      </Card>

      <CreditorForm {...props} isEdit={true} />

      {isAdmin(props.user) && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Clients associés</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.creditor.users.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{user.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    name: props.creditor.nom,
    email: props.creditor.email,
    address: {
      location: [0.0, 0.0],
      address: {
        unparsed: props.creditor.adresse,
      },
      notes: "",
    },
    capital: props.creditor.capital,
    siren: props.creditor.siren,
    tva: props.creditor.tva,
    sap: props.creditor.sap,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .min(1)
      .max(100)
      .required("Un nom est obligatoire. Ex: Basil"),
    email: Yup.string()
      .email("L'email est invalide.")
      .required("L'email est obligatoire. Ex: hello@touva.fr"),
    address: Yup.object().nullable().required("Champs obligatoire"),
    capital: Yup.number().required("Le capital est obligatoire. Ex: 1000"),
    siren: Yup.string()
      .min(9, "Un numéro de SIREN est composé d'au minimum 9 caratères.")
      .max(9, "Un numéro de SIREN est composé d'au maximum 9 caratères.")
      .required("Le SIREN est obligatoire. Ex: 123456789"),
    tva: Yup.string()
      .min(11, "Un numéro de TVA est composé d'au minimum 11 caratères.")
      .max(11, "Un numéro de TVA est composé d'au maximum 11 caratères.")
      .required("Le TVA est obligatoire. Ex: 12345678901"),
    sap: Yup.string().required("Le TVA est obligatoire. Ex: 123456789"),
  }),
  handleSubmit: (values, { props }) => {
    const updatedCreditor = {
      nom: values.name,
      email: values.email,
      adresse: values.address.address.unparsed,
      capital: values.capital,
      siren: values.siren,
      tva: values.tva,
      sap: values.sap,
    };

    props.validation(props.creditor.id, updatedCreditor);
  },
})(Edit);
