import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { requests } from "../../../agent";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import List from "./List/List";

const ExtraCategoryContainer = () => {
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [extraCategories, setExtraCategories] = useState([]);
  const [extraCategoryEdit, setExtraCategoryEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    requests
      .get("/extra_categories", true)
      .then((result) => {
        setExtraCategories(result["hydra:member"]);
        setIsPageLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (extraCategoryId) => {
    const extraCategory = extraCategories.find((c) => c.id === extraCategoryId);
    setExtraCategoryEdit(extraCategory);
    setEdit(!edit);
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setExtraCategoryEdit(null);
  };
  const handleSubmit = (newValue) => {
    setIsLoading(true);

    requests
      .post("/extra_categories", newValue, true)
      .then((response) => {
        const newCategoriesList = [...extraCategories];
        newCategoriesList.unshift(response);
        setExtraCategories(newCategoriesList);
        setAdd(false);
        setAlert({
          active: true,
          type: "success",
          message: "La catégorie a bien été créé !",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setAdd(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
        setIsLoading(false);
      });
  };
  const handleSubmitEdit = (id, name) => {
    setIsLoading(true);

    requests
      .patch(`/extra_categories/${id}`, { name }, true)
      .then((response) => {
        const newCategoriesList = extraCategories.map((c) => {
          if (c.id !== response.id) {
            return c;
          }

          return response;
        });
        setExtraCategories(newCategoriesList);
        setEdit(false);
        setExtraCategoryEdit(null);
        setAlert({
          active: true,
          type: "success",
          message: "La catégorie a bien été modifié !",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleSubmitFilters = (data, from) => {
    setIsLoading(true);
    let dataUrl = "";
    if (data.id !== "") dataUrl += `id=${data.id}&`;
    if (data.lastname !== "") dataUrl += `name=${data.lastname}&`;
    dataUrl = dataUrl.slice(0, -1); // Pour supprimer le dernier & en trop

    requests
      .get(`/${from}?${dataUrl}`, true)
      .then((result) => {
        setExtraCategories(result["hydra:member"]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };

  return (
    <>
      {add && (
        <Add
          add={add}
          validation={handleSubmit}
          toggleList={toggleList}
          isLoading={isLoading}
          alert={alert}
        />
      )}

      {edit && (
        <Edit
          edit={edit}
          extraCategoryEdit={extraCategoryEdit}
          validation={handleSubmitEdit}
          toggleList={toggleList}
          isLoading={isLoading}
          alert={alert}
        />
      )}

      {!edit && !add && (
        <List
          edit={edit}
          add={add}
          extraCategories={extraCategories}
          isLoading={isLoading}
          isPageLoading={isPageLoading}
          alert={alert}
          toggleEdit={toggleEdit}
          toggleAdd={toggleAdd}
          handleSubmitFilters={handleSubmitFilters}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant={"filled"}
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ExtraCategoryContainer;
