import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormInformations from "./FormInformations";
import FormPassword from "./FormPassword";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
}));

const Profile = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {props.isLoading && <LinearProgress sx={{ mb: 2 }} />}

      <Paper className={classes.paper} elevation={6}>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          paddingBottom={3}
        >
          <Avatar>
            {props.user.lastName[0]}
            {props.user.firstName[0]}
          </Avatar>

          <Typography variant="h4" gutterBottom>
            {props.user.lastName.toUpperCase()} {props.user.firstName}
          </Typography>
        </Stack>

        <Accordion
          expanded={expanded === "infos"}
          onChange={handleChange("infos")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="infosbh-content"
            id="infosbh-header"
          >
            <Typography variant="h6">Mes informations personnelles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormInformations
              user={props.user}
              validation={props.handleSubmitInfos}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "password"}
          onChange={handleChange("password")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="passwordbh-content"
            id="passwordbh-header"
          >
            <Typography variant="h6">Mon mot de passe</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormPassword
              user={props.user}
              validation={props.handleSubmitPassword}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
};

export default Profile;
