import React, { useState } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import InvoiceCard from "./InvoiceCard";
import { locale, mask } from "../../../localisation";
import { isAdmin } from "../../../utils";
import UseStyle from "../Common/StyledComponent/UseStyle";

const ConfirmDialog = (props) => {
  const formik = useFormik({
    initialValues: {
      date: new Date(),
      comments: null,
    },
    validationSchema: Yup.object({
      date: Yup.date()
        .required("Le mois de facturation est obligatoire.")
        .typeError("Date invalide"),
    }),
    onSubmit: (values, { actions }) => {
      props.validation(props.data.id, values.date, values.comments, props.from);
      props.handleClose();
      actions.resetForm();
    },
  });

  return (
    <Dialog
      open={props.isCreating}
      onClose={props.handleClose}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>Créer une facture</DialogTitle>

      <DialogContent dividers>
        <form>
          <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <FormControl
              variant="outlined"
              fullWidth
              required
              error={formik.touched.date && Boolean(formik.errors.date)}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={locale["fr"]}
              >
                <DatePicker
                  mask={mask["fr"]}
                  views={["month", "year"]}
                  label="Choississez le mois"
                  inputFormat="MM/yyyy"
                  value={formik.values.date}
                  onBlur={formik.handleBlur}
                  onChange={(value) => formik.setFieldValue("date", value)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <FormHelperText error>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </FormHelperText>
            </FormControl>

            <FormControl variant="outlined" fullWidth>
              <TextField
                fullWidth
                multiline
                rows={3}
                name={"comments"}
                label={"Commentaires"}
                value={formik.values.comments}
                type={"text"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormControl>
          </Stack>
        </form>
      </DialogContent>

      <DialogContent>
        <DialogContentText>
          Client : <u>{props.data.name}</u>
        </DialogContentText>
        <DialogContentText>
          Mois de la facture :{" "}
          <u>
            {formik.values.date instanceof Date &&
            !isNaN(formik.values.date) ? (
              format(formik.values.date, "MMMM yyyy", {
                locale: fr,
              }).toUpperCase()
            ) : (
              <i>Date invalide !</i>
            )}
          </u>
        </DialogContentText>
        <DialogContentText>
          Vous pourrez accéder aux détails de la facture en la téléchargeant.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {props.loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <Button onClick={props.handleClose}>Annuler</Button>
            <Button
              {...(formik.values.date instanceof Date &&
              !isNaN(formik.values.date)
                ? { disabled: false }
                : { disabled: true })}
              color="primary"
              variant="contained"
              onClick={formik.handleSubmit}
              startIcon={<SaveRoundedIcon />}
            >
              Créer
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};

const DrawerInformations = (props) => {
  const [isCreating, setIsCreating] = useState(false);
  const classes = UseStyle();

  const handleClose = () => {
    setIsCreating(false);
  };

  return props.loadingList ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", padding: 3 }}
      fontSize="large"
    >
      <CircularProgress color="secondary" />
    </Grid>
  ) : (
    props.data !== null && (
      <Grid
        container
        spacing={3}
        direction={"column"}
        alignItems={"stretch"}
        sx={{ padding: 3 }}
      >
        <Grid item>
          <Paper className={classes.paper} variant="outlined">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={2}
            >
              <Typography component="div" variant="h4" paddingY={1}>
                <i>{props.data.name}</i>
              </Typography>

              {isAdmin(props.userData) && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setIsCreating(!isCreating)}
                  startIcon={<AddIcon />}
                >
                  Facture
                </Button>
              )}
            </Stack>
          </Paper>
        </Grid>

        {props.invoices.length > 0 ? (
          props.invoices.map((invoice, i) => (
            <Grid key={i} item>
              <InvoiceCard
                invoice={invoice}
                user={props.userData}
                handleDownloadInvoice={props.handleDownloadInvoice}
                handleInvoiceDunning={props.handleInvoiceDunning}
                loading={props.loading}
                from={props.from}
              />
            </Grid>
          ))
        ) : (
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            sx={{ fontStyle: "italic", color: "#B1B1B1" }}
          >
            <h3>Cet utilisateur n'a pas encore de facture.</h3>
          </Grid>
        )}

        <ConfirmDialog
          {...props}
          isCreating={isCreating}
          handleClose={handleClose}
        />
      </Grid>
    )
  );
};

export default DrawerInformations;
