import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import PlacesAutocomplete from "../Common/PlacesAutocomplete/PlacesAutocomplete";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const Profile = (props) => {
  return (
    <form>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item lg={6} md={12}>
          <TextField
            required
            error={props.errors.nom && props.touched.nom ? true : false}
            {...(props.errors.nom &&
              props.touched.nom && { helperText: props.errors.nom })}
            fullWidth
            name={"nom"}
            label={"Nom"}
            value={props.values.nom}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>

        <Grid item lg={6} md={12}>
          <TextField
            required
            error={props.errors.prenom && props.touched.prenom ? true : false}
            {...(props.errors.prenom &&
              props.touched.prenom && { helperText: props.errors.prenom })}
            fullWidth
            name={"prenom"}
            label={"Prénom"}
            type={"text"}
            value={props.values.prenom}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>

        <Grid item lg={6} md={12}>
          <TextField
            required
            error={
              props.errors.telephone && props.touched.telephone ? true : false
            }
            {...(props.errors.telephone &&
              props.touched.telephone && {
                helperText: props.errors.telephone,
              })}
            fullWidth
            name={"telephone"}
            label={"Téléphone"}
            type={"tel"}
            value={props.values.telephone}
            onChange={(event) =>
              props.setFieldValue("telephone", event.target.value.toString())
            }
            onBlur={props.handleBlur}
          />
        </Grid>

        <Grid item lg={6} md={12}>
          <TextField
            required
            error={props.errors.email && props.touched.email ? true : false}
            {...(props.errors.email &&
              props.touched.email && { helperText: props.errors.email })}
            fullWidth
            name={"email"}
            label={"Adresse email"}
            value={props.values.email}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Grid>
      </Grid>

      <Stack direction={"row"} justifyContent={"flex-end"} paddingTop={2}>
        <Button
          onClick={props.handleSubmit}
          variant="contained"
          endIcon={<SendIcon />}
        >
          Modifier mes Infos
        </Button>
      </Stack>
    </form>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      return {
        prenom: props.user.firstName,
        nom: props.user.lastName,
        telephone: props.user.telephone,
        email: props.user.email,
      };
    },
    validationSchema: Yup.object().shape({
      prenom: Yup.string().required("Champs obligatoire"),
      nom: Yup.string().required("Champs obligatoire"),
      telephone: Yup.string()
        .required("Champs obligatoire")
        .test(
          "len",
          "Veuillez saisir un numéro de téléphone valide",
          (tel) => tel && tel.toString().length === 10
        ),
      email: Yup.string().email().required("Champs obligatoire"),
    }),
    handleSubmit: (values, { props }) => {
      const user = {
        id: props.user.id,
        firstName: values.prenom,
        lastName: values.nom.toUpperCase(),
        email: values.email.toLowerCase(),
        telephone: values.telephone,
      };

      props.validation(user);
    },
  })
)(Profile);
