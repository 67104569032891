import React from "react";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import {
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";

const RecipientsListMacro = (props) => {
  const classes = UseStyle();

  let inputs = (dietId, day) => {
    return (
      <Stack spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <Tooltip title="Total jour/Total déjeuner">
            <TextField
              size="small"
              variant="standard"
              label="Déj"
              value={props.recipientsMacro[dietId][day].lunch}
              InputProps={{
                readOnly: true,
              }}
            />
          </Tooltip>

          <Tooltip title="Total dîner">
            <TextField
              size="small"
              variant="standard"
              label="Dîn"
              value={props.recipientsMacro[dietId][day].dinner}
              InputProps={{
                readOnly: true,
              }}
            />
          </Tooltip>
        </Stack>
      </Stack>
    );
  };
  const createData = (
    diet,
    dimanche,
    lundi,
    mardi,
    mercredi,
    jeudi,
    vendredi,
    samedi
  ) => {
    return {
      diet,
      dimanche,
      lundi,
      mardi,
      mercredi,
      jeudi,
      vendredi,
      samedi,
    };
  };
  let rows = props.diets.map((d, i) =>
    createData(
      d.id,
      inputs(d.id, 0),
      inputs(d.id, 1),
      inputs(d.id, 2),
      inputs(d.id, 3),
      inputs(d.id, 4),
      inputs(d.id, 5),
      inputs(d.id, 6)
    )
  );
  rows.unshift(
    createData(
      "Sans régime",
      inputs(0, 0),
      inputs(0, 1),
      inputs(0, 2),
      inputs(0, 3),
      inputs(0, 4),
      inputs(0, 5),
      inputs(0, 6)
    )
  );

  return (
    <TableContainer>
      {props.loading && <LinearProgress />}
      <Table className={classes.table} aria-label="table-tournees">
        <TableHead>
          <TableRow>
            <TableCell>
              <Stack direction={"row"}>
                <Button disabled startIcon={<ArrowDropDownOutlinedIcon />}>
                  Régimes
                </Button>
                <Button disabled endIcon={<ArrowRightOutlinedIcon />}>
                  Jours
                </Button>
              </Stack>
            </TableCell>
            <TableCell>Lundi</TableCell>
            <TableCell>Mardi</TableCell>
            <TableCell>Mercredi</TableCell>
            <TableCell>Jeudi</TableCell>
            <TableCell>Vendredi</TableCell>
            <TableCell>Samedi</TableCell>
            <TableCell>Dimanche</TableCell>
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.diet}>
              <TableCell>
                {index === 0
                  ? "Sans régime"
                  : props.diets.find((d) => d.id === row.diet).nom}
              </TableCell>
              <TableCell>{row.lundi}</TableCell>
              <TableCell>{row.mardi}</TableCell>
              <TableCell>{row.mercredi}</TableCell>
              <TableCell>{row.jeudi}</TableCell>
              <TableCell>{row.vendredi}</TableCell>
              <TableCell>{row.samedi}</TableCell>
              <TableCell>{row.dimanche}</TableCell>
              <TableCell>
                <Tooltip title="Total de convives avec le régime de la ligne">
                  <TextField
                    size="small"
                    value={
                      index === 0
                        ? props.recipientsMacro[0][7].total
                        : props.recipientsMacro[row.diet][7].total
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // the change is here
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RecipientsListMacro;
