import React from "react";
import { format } from "date-fns";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Filters from "../../Common/Filters";
import UseStyle from "../../Common/StyledComponent/UseStyle";

const List = (props) => {
  const classes = UseStyle();
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "photo",
      headerName: "Photo",
    },
    {
      field: "nom",
      headerName: "Nom",
      flex: 0.2,
    },
    {
      field: "ttc",
      headerName: "Prix TTC (€)",
      flex: 0.05,
    },
    {
      field: "ht",
      headerName: "Prix HT (€)",
      flex: 0.05,
    },
    {
      field: "dateMaj",
      headerName: "MàJ le",
      flex: 0.07,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
      ],
    },
  ];
  const rows =
    props.supplements &&
    props.supplements.map((supplement) => ({
      id: supplement.id,
      photo: "",
      nom: supplement.nom,
      ttc: parseFloat(supplement.prixTtc).toFixed(2),
      ht: parseFloat(supplement.prixHt).toFixed(2),
      dateMaj:
        supplement.dateMaj &&
        format(new Date(supplement.dateMaj), "dd/MM/yyyy HH:mm"),
    }));

  return props.isPageLoading ? (
    <LinearProgress />
  ) : (
    <>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        spacing={1}
        paddingBottom={2}
      >
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Supplément
        </Button>
      </Stack>

      <Stack spacing={2}>
        <Filters
          types={["lastname"]}
          from="supplements"
          handleSubmitFilters={props.handleSubmitFilters}
          loading={props.loading}
        />

        <Paper className={classes.paper} elevation={6}>
          <Grid container sx={{ height: "70vh", width: "100%" }}>
            <DataGrid
              columns={columns}
              rows={rows}
              density="comfortable"
              disableSelectionOnClick
              pagination
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              loading={props.loading}
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
            />
          </Grid>
        </Paper>
      </Stack>
    </>
  );
};

export default List;
