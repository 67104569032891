import React, { useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Button,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

const Add = (props) => {
  const classes = UseStyle();

  useEffect(() => {
    let prixHt = 0;
    let prixTtc = 0;
    let repasHt = 0;
    let serviceHt = 0;
    let tva = props.taxes.find((t) => t.id === props.values.taxe).taux;

    if (props.values.repasTtc !== "" && props.values.serviceTtc !== "") {
      repasHt = parseFloat(props.values.repasTtc) / (1 + tva);
      serviceHt = parseFloat(props.values.serviceTtc) / (1 + tva);
      prixHt = repasHt + serviceHt;
      prixTtc =
        parseFloat(props.values.repasTtc) + parseFloat(props.values.serviceTtc);
    }
    props.setFieldValue("repasHt", repasHt);
    props.setFieldValue("serviceHt", serviceHt);
    props.setFieldValue("prixHt", prixHt);
    props.setFieldValue("prixTtc", prixTtc);
  }, [props.values.taxe, props.values.repasTtc, props.values.serviceTtc]);

  return (
    <>
      <form>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant={"contained"}
              sx={{ mb: 3 }}
              onClick={props.toggleList}
              color="secondary"
              startIcon={<ArrowBackIcon />}
            >
              Retour
            </Button>
          </Box>
        </Box>

        <Card className={classes.card} sx={{ mb: 3 }}>
          <CardHeader
            subheader="Ces informations peuvent être modifier."
            title="Création d'un supplément"
          />
        </Card>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    error={props.errors.nom && props.touched.nom ? true : false}
                    {...(props.errors.nom &&
                      props.touched.nom && { helperText: props.errors.nom })}
                    fullWidth
                    name={"nom"}
                    label={"Nom"}
                    value={props.values.nom}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="category">Catégorie</InputLabel>
                    <Select
                      labelId="category"
                      id="category"
                      label="Catégorie"
                      value={props.values.category}
                      onChange={(event) => {
                        props.setFieldValue("category", event.target.value);
                      }}
                      error={
                        props.errors.category && props.touched.category
                          ? true
                          : false
                      }
                    >
                      {props.categories.map((c) => (
                        <MenuItem key={c.id} value={c.id}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {props.errors.category && props.touched.category && (
                      <FormHelperText error>
                        {props.errors.category}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={props.values.description}
                    multiline
                    rows={4}
                    fullWidth
                    name={"description"}
                    label={"Description"}
                    variant="outlined"
                    type={"text"}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Taxes</InputLabel>
                    <Select
                      error={
                        props.errors.taxe && props.touched.taxe ? true : false
                      }
                      {...(props.errors.taxe &&
                        props.touched.taxe && {
                          helperText: props.errors.taxe,
                        })}
                      style={{ width: "100%" }}
                      label="Taxes"
                      name={"taxe"}
                      value={props.values.taxe}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      {props.taxes.length > 0 &&
                        props.taxes.map((taxe) => (
                          <MenuItem key={taxe.id} value={taxe.id}>
                            {taxe.nom}%
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="repasTtc">Repas TTC</InputLabel>
                    <OutlinedInput
                      id="repasTtc"
                      value={props.values.repasTtc}
                      onChange={(event) =>
                        props.setFieldValue("repasTtc", event.target.value)
                      }
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      label="Repas TTC"
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="repasHt">Repas HT</InputLabel>
                    <OutlinedInput
                      readOnly
                      id="repasHt"
                      value={props.values.repasHt}
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      label="Repas HT"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="serviceTtc">Service TTC</InputLabel>
                    <OutlinedInput
                      id="serviceTtc"
                      value={props.values.serviceTtc}
                      onChange={(event) =>
                        props.setFieldValue("serviceTtc", event.target.value)
                      }
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      label="Service TTC"
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="serviceHt">Service HT</InputLabel>
                    <OutlinedInput
                      readOnly
                      id="serviceHt"
                      value={props.values.serviceHt}
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      label="Service HT"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="totalTtc">Total TTC</InputLabel>
                    <OutlinedInput
                      readOnly
                      id="prixTtc"
                      value={props.values.prixTtc}
                      onChange={(event) =>
                        props.setFieldValue("prixTtc", event.target.value)
                      }
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      label="Prix TTC"
                      type="number"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="totalHt">Total HT</InputLabel>
                    <OutlinedInput
                      readOnly
                      id="prixHt"
                      value={props.values.prixHt}
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      label="Prix HT"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>Régimes non adaptés</InputLabel>
                    <Select
                      multiple
                      value={props.values.regimesNonAdaptes}
                      onChange={(event) => {
                        props.setFieldValue(
                          `regimesNonAdaptes`,
                          event.target.value
                        );
                      }}
                      style={{ width: "100%" }}
                      input={<OutlinedInput label="Régimes non adaptés" />}
                      renderValue={(selected) => {
                        return (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((id) => (
                              <Chip
                                key={id}
                                label={
                                  props.regimes.find(
                                    (regime) => regime.id === id
                                  ).nom
                                }
                              />
                            ))}
                          </Box>
                        );
                      }}
                      label="Régimes non adaptés au produit"
                      name={"regimesNonAdaptes"}
                    >
                      {props.regimes.map((regime, i) => {
                        return (
                          <MenuItem value={regime.id} key={i}>
                            <Checkbox
                              checked={
                                props.values.regimesNonAdaptes.indexOf(
                                  regime.id
                                ) > -1
                              }
                            />
                            <ListItemText primary={regime.nom} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel>Allergènes</InputLabel>
                    <Select
                      multiple
                      value={props.values.allergenes}
                      onChange={(event) => {
                        props.setFieldValue(`allergenes`, event.target.value);
                      }}
                      style={{ width: "100%" }}
                      input={<OutlinedInput label="Allergènes" />}
                      renderValue={(selected) => {
                        return (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((id) => (
                              <Chip
                                key={id}
                                label={
                                  props.allergenes.find(
                                    (allergene) => allergene.id === id
                                  ).nom
                                }
                              />
                            ))}
                          </Box>
                        );
                      }}
                      label="Allergènes du produit"
                      name={"allergenes"}
                    >
                      {props.allergenes.map((allergene, i) => {
                        return (
                          <MenuItem value={allergene.id} key={i}>
                            <Checkbox
                              checked={
                                props.values.allergenes.indexOf(allergene.id) >
                                -1
                              }
                            />
                            <ListItemText primary={allergene.nom} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {props.loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                color="primary"
                onClick={props.handleSubmit}
                variant="contained"
                endIcon={<SendIcon />}
              >
                Créer le supplément
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    nom: "",
    category: "",
    description: "",
    taxe: props.taxes[0] ? props.taxes[0].id : "",
    prixTtc: 0,
    prixHt: 0,
    serviceTtc: 0,
    serviceHt: 0,
    repasTtc: 0,
    repasHt: 0,
    allergenes: [],
    regimesNonAdaptes: [],
  }),
  validationSchema: Yup.object().shape({
    nom: Yup.string().required("Le nom est obligatoire"),
    category: Yup.string().required("La catégorie est obligatoire"),
    description: Yup.string(),
    taxe: Yup.number().positive().required(),
    prixTtc: Yup.number().required("Le prix TTC est obligatoire"),
    prixHt: Yup.number().required("Le prix HT est obligatoire"),
    serviceTtc: Yup.string().required("Ce champs est obligatoire"),
    serviceHt: Yup.string().required(),
    repasTtc: Yup.string().required("Ce champs est obligatoire"),
    repasHt: Yup.string().required(),
    allergenes: Yup.array(),
    regimesNonAdaptes: Yup.array(),
  }),
  handleSubmit: (values, { props }) => {
    props.validation(
      values.nom,
      values.category,
      values.description,
      values.taxe,
      values.prixTtc.toString(),
      values.prixHt.toString(),
      values.serviceTtc.toString(),
      values.serviceHt.toString(),
      values.repasTtc.toString(),
      values.repasHt.toString(),
      values.allergenes,
      values.regimesNonAdaptes
    );
  },
})(Add);
