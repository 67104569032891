import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import UseStyle from "../Common/StyledComponent/UseStyle";

const TaxForm = (props) => {
  const classes = UseStyle();

  return (
    <Paper className={classes.paper} elevation={6}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="Nom"
            name="name"
            onChange={props.handleChange}
            value={props.values.name}
            variant="outlined"
            error={props.touched.name && Boolean(props.errors.name)}
            helperText={
              props.touched.name && props.errors.name && props.errors.name
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Taux"
            name="rate"
            type="number"
            onChange={props.handleChange}
            required
            value={props.values.rate}
            variant="outlined"
            error={props.touched.rate && Boolean(props.errors.rate)}
            helperText={
              props.touched.rate && props.errors.rate && props.errors.rate
            }
          />
        </Grid>

        <Grid item container justifyContent="flex-end">
          <LoadingButton
            loading={props.isLoading}
            variant="contained"
            onClick={props.handleSubmit}
          >
            {props.isEdit ? "Modifier" : "Créer"} la taxe
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TaxForm;
