import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { requests } from "../../agent";
import { useDispatch, useSelector } from "react-redux";
import { show } from "../../slices/alertSlice";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Votre nouveau mot de passe doit contenir au minimum 6 caractères.")
    .required("Veuillez entrer votre nouveau mot de passe"),
  confirmationPassword: Yup.string()
    .required("Veuillez confirmer votre nouveau mot de passe")
    .test(
      "passwords-match",
      "Les mots de passe doivent être identiques",
      function (value) {
        return this.parent.password === value;
      }
    ),
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const alert = {
    isActive: useSelector((state) => state.alert.active),
    message: useSelector((state) => state.alert.message),
    type: useSelector((state) => state.alert.type),
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");

  useEffect(() => {
    requests
      .post("/reset/password/check", { token }, true)
      .then((response) => {
        setIsLoading(false);
      })
      .catch((err) => navigate("/login"));
  }, [token]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmationPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        token: token,
        plainPassword: values.password,
      };

      requests
        .post("/reset/password/change", data, true)
        .then((response) => {
          navigate("/login");
        })
        .catch((err) => {
          dispatch(
            show({
              active: true,
              message: err.message,
              type: "error",
            })
          );
        });
    },
  });

  return (
    <>
      <Container maxWidth="sm">
        {isLoading ? (
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            height={"100vh"}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Stack
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100vh"}
            >
              <Typography variant="h3" gutterBottom textAlign={"center"}>
                Réinitialisation de votre mot de passe
              </Typography>

              <TextField
                required
                type="password"
                id="password"
                name="password"
                label="Nouveau mot de passe"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
              />

              <TextField
                required
                type="password"
                id="confirmationPassword"
                name="confirmationPassword"
                label="Répétez le mot de passe"
                value={formik.values.confirmationPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmationPassword &&
                  Boolean(formik.errors.confirmationPassword)
                }
                helperText={
                  formik.touched.confirmationPassword &&
                  formik.errors.confirmationPassword
                }
                fullWidth
              />

              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                Enregistrer
              </Button>
            </Stack>
          </form>
        )}
      </Container>

      {alert.isActive && (
        <Snackbar
          open={alert.isActive}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
        >
          <Alert variant={"filled"} severity={alert.type}>
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ResetPassword;
