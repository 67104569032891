import React, { useEffect, useState } from "react";
import { requests } from "../../../agent";
import Add from "./Add/Add";
import { Alert, LinearProgress, Snackbar } from "@mui/material";
import Edit from "./Edit/Edit";
import List from "./List/List";
import { isAdmin } from "../../../utils";
import { useSelector } from "react-redux";

const CreditorContainer = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [creditor, setCreditor] = useState(null);
  const [creditors, setCreditors] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    if (isAdmin(userData)) {
      requests
        .get("/creanciers", true)
        .then((res) => {
          setCreditors(res["hydra:member"]);
          setIsPageLoading(false);
        })
        .catch((err) => {
          setIsPageLoading(false);
          setAlert({
            active: true,
            type: "error",
            message: err.message,
          });
        });
    } else {
      setCreditors(userData?.organization.creditors ?? []);
      setIsPageLoading(false);
    }
  }, []);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (taxeId) => {
    // Find the taxe to edit
    const creditor = creditors.find((creditor) => creditor.id === taxeId);
    if (!creditor) return;

    setCreditor(creditor);
    setEdit(true);
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setCreditor(null);
  };
  const handleSubmit = (data) => {
    setIsLoading(true);
    requests
      .post("/creanciers", data, true)
      .then((res) => {
        setCreditors([...creditors, res]);
        setAdd(false);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le créancier a été crée avec succès",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleUpdate = (id, data) => {
    setIsLoading(true);
    requests
      .patch(`/creanciers/${id}`, data, true)
      .then((res) => {
        const updatedCreditor = creditors.map((creditor) => {
          if (creditor.id === id) {
            return res;
          }
          return creditor;
        });
        setCreditors(updatedCreditor);
        setEdit(false);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Le créancier a été modifiée avec succès",
        });
      })

      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") return;
    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };

  return isPageLoading ? (
    <LinearProgress />
  ) : (
    <>
      {add && (
        <Add
          isLoading={isLoading}
          add={add}
          toggleList={toggleList}
          validation={handleSubmit}
          user={userData}
        />
      )}
      {edit && (
        <Edit
          isLoading={isLoading}
          edit={edit}
          creditor={creditor}
          toggleList={toggleList}
          validation={handleUpdate}
          user={userData}
        />
      )}
      {!add && !edit && (
        <List
          isLoading={isLoading}
          creditors={creditors}
          add={add}
          toggleAdd={toggleAdd}
          toggleEdit={toggleEdit}
          edit={edit}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default CreditorContainer;
