import React, { useEffect, useState } from "react";
import { requests } from "../../../agent";
import Add from "./Add/Add";
import { Alert, LinearProgress, Snackbar } from "@mui/material";
import Edit from "./Edit/Edit";
import List from "./List/List";

const TaxContainer = () => {
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tax, setTax] = useState(null);
  const [taxes, setTaxes] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    requests
      .get("/taxes", true)
      .then((res) => {
        setTaxes(res["hydra:member"]);
        setIsPageLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  const toggleAdd = () => {
    setAdd(true);
  };
  const toggleEdit = (taxeId) => {
    // Find the taxe to edit
    const tax = taxes.find((tax) => tax.id === taxeId);
    if (!tax) return;

    setTax(tax);
    setEdit(true);
  };
  const toggleList = () => {
    setAdd(false);
    setEdit(false);
    setTax(null);
  };
  const handleSubmit = (data) => {
    setIsLoading(true);
    requests
      .post("/taxes", data, true)
      .then((res) => {
        setTaxes([...taxes, res]);
        setAdd(false);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Taxe ajoutée avec succès",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleUpdate = (id, data) => {
    setIsLoading(true);
    requests
      .patch(`/taxes/${id}`, data, true)
      .then((res) => {
        const newTaxes = taxes.map((tax) => {
          if (tax.id === id) {
            return res;
          }
          return tax;
        });
        setTaxes(newTaxes);
        setEdit(false);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Taxe modifiée avec succès",
        });
      })

      .catch((err) => {
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") return;
    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };

  return isPageLoading ? (
    <LinearProgress />
  ) : (
    <>
      {add && (
        <Add
          isLoading={isLoading}
          add={add}
          toggleList={toggleList}
          validation={handleSubmit}
        />
      )}
      {edit && (
        <Edit
          isLoading={isLoading}
          edit={edit}
          tax={tax}
          toggleList={toggleList}
          validation={handleUpdate}
        />
      )}
      {!add && !edit && (
        <List
          isLoading={isLoading}
          taxes={taxes}
          add={add}
          toggleAdd={toggleAdd}
          toggleEdit={toggleEdit}
          edit={edit}
        />
      )}

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default TaxContainer;
