import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Card, CardHeader, Stack } from "@mui/material";
import { withFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import UserForm from "../UserForm";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { isAdmin } from "../../../../utils";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <Stack spacing={2} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          loading={props.isLoading}
          color="secondary"
          variant="contained"
          onClick={props.toggleList}
          startIcon={<ArrowBackIcon />}
        >
          Retour
        </LoadingButton>
      </Box>

      <Card className={classes.card}>
        <CardHeader
          subheader="Ces informations peuvent être modifier."
          title="Création d'un utilisateur"
        />
      </Card>

      <UserForm {...props} isEdit={false} />
    </Stack>
  );
};

export default withFormik({
  mapPropsToValues: (props) => ({
    client: isAdmin(props.userData) ? null : props.userData.organization.id,
    firstName: "",
    lastName: "",
    roles: [],
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  }),
  validationSchema: Yup.object().shape({
    client: Yup.string()
      .required()
      .typeError("Veuillez sélectionner un client"),
    firstName: Yup.string().required("Veuillez saisir votre prénom"),
    lastName: Yup.string().required("Veuillez saisir votre nom"),
    email: Yup.string()
      .email("L'email est invalide.")
      .required("L'email est obligatoire. Ex: nom@exemple.fr"),
    phone: Yup.string()
      .required("Un numéro de téléphone est requis")
      .matches(
        "[0]{1}[1-7]{1}[0-9]{8}",
        "Veuillez saisir un numéro de téléphone valide."
      ),
    password: Yup.string()
      .min(
        6,
        "Votre nouveau mot de passe doit contenir au minimum 6 caractères."
      )
      .required("Veuillez entrer votre nouveau mot de passe"),
    confirmPassword: Yup.string()
      .required("Veuillez confirmer votre nouveau mot de passe")
      .test(
        "passwords-match",
        "Les mots de passe doivent être identiques",
        function (value) {
          return this.parent.password === value;
        }
      ),
    roles: Yup.array()
      .required()
      .min(1, "Veuillez sélectionner au moins un rôle"),
  }),
  handleSubmit: (values, { props }) => {
    const newUser = {
      organization: `/organizations/${values.client}`,
      firstName:
        values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1),
      lastName: values.lastName.toUpperCase(),
      email: values.email,
      telephone: values.phone,
      password: values.password,
      plainPassword: values.confirmPassword,
      roles: values.roles,
    };

    props.validation(newUser);
  },
})(Add);
