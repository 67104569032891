import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import UseStyle from "../Common/StyledComponent/UseStyle";
import { isAdmin } from "../../../utils";
import { ROLES } from "../Common/Enums/Enums";

const UserForm = (props) => {
  const classes = UseStyle();
  const [selectedClient, setSelectedClient] = useState(
    props.isEdit
      ? {
          client: props.clients.find(
            (client) => client.id === props.values.client
          ),
        }
      : isAdmin(props.userData)
      ? null
      : {
          client: props.clients.find(
            (client) => client.id === props.userData.organization.id
          ),
        }
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const clients = props.clients.map((client) => {
    const filtre = client.name[0].toUpperCase();
    return {
      filtre: /[0-9]/.test(filtre) ? "0-9" : filtre,
      client: client,
    };
  });

  return (
    <Paper className={classes.paper} elevation={6}>
      <form className="form">
        <Grid container spacing={2}>
          {isAdmin(props.userData) && (
            <Grid item xs={12}>
              <Autocomplete
                disabled={props.isEdit}
                options={clients.sort(
                  (a, b) => -b.filtre.localeCompare(a.filtre)
                )}
                isOptionEqualToValue={(option, value) =>
                  option.client.id === value.client.id
                }
                groupBy={(value) => value.filtre}
                getOptionLabel={(option) => {
                  if (!option.client) return "";
                  return option.client.id + " - " + option.client.name;
                }}
                value={selectedClient}
                onChange={(event, value) => {
                  setSelectedClient(value);

                  value !== null
                    ? props.setFieldValue("client", value.client.id)
                    : props.setFieldValue("client", null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    error={props.touched.client && Boolean(props.errors.client)}
                    helperText={props.touched.client && props.errors.client}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              autoComplete="lastname"
              label="Nom"
              name="lastName"
              value={props.values.lastName}
              onChange={props.handleChange}
              error={props.touched.lastName && Boolean(props.errors.lastName)}
              helperText={props.touched.lastName && props.errors.lastName}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              autoComplete="firstname"
              label="Prénom"
              name="firstName"
              value={props.values.firstName}
              onChange={props.handleChange}
              error={props.touched.firstName && Boolean(props.errors.firstName)}
              helperText={props.touched.firstName && props.errors.firstName}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              required
              fullWidth
              autoComplete="email"
              label="Email de connexion"
              name="email"
              value={props.values.email}
              onChange={props.handleChange}
              error={props.touched.email && Boolean(props.errors.email)}
              helperText={props.touched.email && props.errors.email}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              fullWidth
              autoComplete="phone"
              name="phone"
              label="Téléphone"
              type="tel"
              value={props.values.phone}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.phone && Boolean(props.errors.phone)}
              helperText={
                props.errors.phone && props.touched.phone && props.errors.phone
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              autoComplete="current-password"
              label="Mot de passe"
              name="password"
              type={showPassword ? "text" : "password"}
              value={props.values.password}
              onChange={props.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={props.touched.password && Boolean(props.errors.password)}
              helperText={props.touched.password && props.errors.password}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              autoComplete="confirmPassword"
              label="Confirmer le mot de passe"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              value={props.values.confirmPassword}
              onChange={props.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                props.touched.confirmPassword &&
                Boolean(props.errors.confirmPassword)
              }
              helperText={
                props.touched.confirmPassword && props.errors.confirmPassword
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel>Rôles</InputLabel>
              <Select
                multiple
                name="roles"
                label="Rôles"
                value={props.values.roles}
                onChange={props.handleChange}
                renderValue={(selected) =>
                  selected
                    .map((role) =>
                      props.values.roles.find((value) => value === role)
                    )
                    .join(", ")
                }
                error={props.touched.roles && Boolean(props.errors.roles)}
              >
                {Object.values(ROLES)
                  .filter((r) => {
                    if (!isAdmin(props.userData)) {
                      return !r.isAdmin;
                    }
                    return true;
                  })
                  .map((r, i) => {
                    return (
                      <MenuItem value={r.role} key={i}>
                        <Checkbox
                          checked={props.values.roles.indexOf(r.role) > -1}
                        />
                        <ListItemText primary={r.description} />
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText error>
                {props.errors.roles &&
                  props.touched.roles &&
                  props.errors.roles}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item container justifyContent="flex-end" xs={12}>
            <LoadingButton
              loading={props.isLoading}
              variant="contained"
              color="primary"
              onClick={props.handleSubmit}
              startIcon={<SaveRoundedIcon />}
            >
              Créer
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default UserForm;
