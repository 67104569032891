import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import React from "react";
import Filters from "../../Common/Filters";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import CustomNoRowsOverlay from "../../Common/CustomNoRowsOverlay";
import { format } from "date-fns";

const List = (props) => {
  const classes = UseStyle();
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Créé le",
      flex: 0.2,
      renderCell: (params) =>
        format(new Date(params.value), "dd/MM/yyyy HH:mm"),
    },
    {
      field: "updatedAt",
      headerName: "Modifié le",
      flex: 0.2,
      renderCell: (params) =>
        params.value ? format(new Date(params.value), "dd/MM/yyyy HH:mm") : "",
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          color="primary"
          icon={
            <Tooltip title="Modifier">
              <EditIcon />
            </Tooltip>
          }
          onClick={() => props.toggleEdit(params.id)}
          label="Modifier"
        />,
      ],
    },
  ];
  return props.isPageLoading ? (
    <LinearProgress />
  ) : (
    <Stack spacing={2} paddingBottom={3}>
      <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
        <Button disabled>Import</Button>
        <Button disabled>Export</Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={props.toggleAdd}
          startIcon={<AddIcon />}
        >
          Catégorie
        </Button>
      </Stack>

      <Filters
        types={["lastname"]}
        from="extra_categories"
        handleSubmitFilters={props.handleSubmitFilters}
        loading={props.isLoading}
      />

      <Paper className={classes.paper} elevation={6}>
        <Grid container sx={{ height: "70vh", width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={props.extraCategories}
            density="comfortable"
            disableSelectionOnClick
            pagination
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={props.isLoading}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
          />
        </Grid>
      </Paper>
    </Stack>
  );
};

export default List;
