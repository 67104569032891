import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import UseStyle from "../../Common/StyledComponent/UseStyle";

export const colors = [
  "#005f73",
  "#0a9396",
  "#94d2bd",
  "#e9d8a6",
  "#ee9b00",
  "#ca6702",
  "#bb3e03",
  "#ae2012",
  "#9b2226",
  "#d9ed92",
  "#b5e48c",
  "#99d98c",
  "#76c893",
  "#52b69a",
  "#34a0a4",
  "#168aad",
  "#1a759f",
  "#1e6091",
  "#184e77",
  "#001219",
];

const FormulesChart = (props) => {
  const classes = UseStyle();
  return (
    <Paper className={classes.paper} elevation={3}>
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Nombre de formules par convives actifs
        </Typography>

        <BarChart
          width={props.isOpen ? 390 : 560}
          height={280}
          data={props.data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis width={30} />
          <Tooltip />
          <Legend />
          {props.formules.map((formule, i) => (
            <Bar key={i} dataKey={formule} stackId="a" fill={colors[i]} />
          ))}
        </BarChart>
      </Stack>
    </Paper>
  );
};

export default FormulesChart;
