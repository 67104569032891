import { Alert, Box, Fab, Grid, LinearProgress, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import TuneIcon from "@mui/icons-material/Tune";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { requests } from "../../../agent";
import AverageBasket from "./Charts/AverageBasket";
import DeliveriesChart from "./Charts/DeliveriesChart";
import FormulesChart from "./Charts/FormulesChart";
import RecipientsChart from "./Charts/RecipientsChart";
import Turnover from "./Charts/Turnover";
import DrawerFilters from "./DrawerFilters";
import { isAdmin } from "../../../utils";

const drawerWidth = 240;

const Main = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const Dashboard = () => {
  const userData = useSelector((state) => state.auth.userData);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [turnover, setTurnover] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [dataFormules, setDataFormules] = useState([]);
  const [dataBasket, setDataBasket] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedFormules, setSelectedFormules] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });
  const [filters, setFilters] = useState(null);
  const clientsList = useRef([]);

  useEffect(() => {
    requests
      .get("/organizations", true)
      .then((result) => {
        clientsList.current = result["hydra:member"];
        setFilters({
          clients: clientsList.current.map((client) => client["@id"]),
          type: "mensuel",
          month: new Date(),
        });
      })
      .catch((err) => {
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, []);

  useEffect(() => {
    const formatDate = format(new Date(), "yyyy-MM-dd");
    if (!filters) return;

    let parameters = "";
    if (filters.clients.length === 1) {
      const id = filters.clients[0].split("/").pop();
      parameters = `&organizations.id=${id}`;
    }

    const clients = filters.clients.map((organization) => {
      const foundedOrga = clientsList.current.find(
        (u) => u["@id"] === organization
      );
      return foundedOrga;
    });
    setSelectedClients(clients);

    const clientsPackages = clients.map((user) =>
      user.packages.map((formule) => formule.name)
    );
    const formules = clientsPackages.flat();
    const formulesUnique = [...new Set(formules)];
    setSelectedFormules(formulesUnique);

    const promises = Promise.all([
      requests.post("/commandes/turnover", filters, true),
      requests.post(`/organizations/formules`, filters, true),
      requests.post(`/organizations/average-basket`, filters, true),
      requests.get(`/recipients?properties[]=state${parameters}`, true),
      requests.get(
        `/deliveries?properties[]=status&date=${formatDate}${parameters}`,
        true
      ),
    ]);

    promises
      .then((results) => {
        setTurnover(results[0]["hydra:member"]);
        setDataFormules(results[1]["hydra:member"]);
        setDataBasket(results[2]["hydra:member"]);
        setRecipients(results[3]["hydra:member"]);
        setDeliveries(results[4]["hydra:member"]);
        setIsPageLoading(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
        setIsLoading(false);
        setAlert({
          active: true,
          type: "error",
          message: err.message,
        });
      });
  }, [filters]);

  const handleDrawerOpen = () => {
    setIsOpen(true);
  };
  const handleDrawerClose = () => {
    setIsOpen(false);
  };
  const handleSubmitFilters = (data) => {
    setIsLoading(true);
    setFilters(data);
  };
  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsLoading(false);
    setAlert({
      active: false,
      type: "",
      message: "",
    });
  };

  return isPageLoading ? (
    <LinearProgress />
  ) : (
    <>
      <Box
        onClick={handleDrawerOpen}
        role="presentation"
        sx={{ position: "fixed", top: "80px" + 16, right: 16 }}
      >
        <Fab color="primary" size="medium" aria-label="filtres">
          <TuneIcon />
        </Fab>
      </Box>

      <Main open={isOpen}>
        <Grid container spacing={2} marginBottom={3} alignItems="center">
          <Grid item xs={3}>
            <DeliveriesChart deliveries={deliveries} />
          </Grid>

          <Grid item xs={6}>
            <Turnover
              userData={userData}
              data={turnover}
              isOpen={isOpen}
              filters={filters}
            />
          </Grid>

          <Grid item xs={3}>
            <RecipientsChart userData={userData} recipients={recipients} />
          </Grid>

          <Grid item xs={6}>
            <FormulesChart
              isOpen={isOpen}
              data={dataFormules}
              formules={selectedFormules}
            />
          </Grid>

          <Grid item xs={6}>
            <AverageBasket
              isOpen={isOpen}
              data={dataBasket}
              selectedClients={selectedClients}
              filters={filters}
            />
          </Grid>
        </Grid>
      </Main>

      <DrawerFilters
        isAdmin={isAdmin(userData)}
        drawerWidth={drawerWidth}
        isOpen={isOpen}
        isLoading={isLoading}
        clients={clientsList.current}
        handleClose={handleDrawerClose}
        handleSubmitFilters={handleSubmitFilters}
      />

      {alert.active && (
        <Snackbar
          open={alert.active}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            variant="filled"
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Dashboard;
