import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import { CardHeader, Card, Button, Paper, Stack, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import UseStyle from "../../Common/StyledComponent/UseStyle";
import Informations from "../Forms/Informations";
import Pilote from "../Forms/Pilote";

const Add = (props) => {
  const classes = UseStyle();

  return (
    <>
      <form>
        <Stack spacing={2} sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent={"flex-end"}>
            <Button
              variant={"contained"}
              onClick={props.toggleList}
              color="secondary"
              startIcon={<ArrowBackIcon />}
            >
              Retour
            </Button>
          </Stack>

          <Card className={classes.card}>
            <CardHeader
              subheader="Ces informations peuvent être modifier."
              title="Création d'une fiche client"
            />
          </Card>

          <Paper className={classes.paper} elevation={6}>
            <Stack spacing={2}>
              <Box>
                <Informations {...props} />
              </Box>

              {props.values.mode === "diets" && (
                <Box>
                  <Pilote {...props} />
                </Box>
              )}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  loading={props.loading}
                  onClick={props.handleSubmit}
                  variant="contained"
                  startIcon={<SaveRoundedIcon />}
                >
                  Créer
                </LoadingButton>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </form>
    </>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => ({
      name: "",
      mode: "repertory",
      email: "",
      phone: "",
      address: {
        latitude: 0.0,
        longitude: 0.0,
        number: "",
        street: "",
        city: "",
        locality: "",
        country: "",
        postalCode: "",
        unparsed: "",
        notes: "",
      },
      newsletter: true,
      organizationNote: "",
      adminNote: "",
      origin: "none",
      creditors: [],
      pilotes: [],
      packages: [],
      rounds: [],
      billingAddress: {
        latitude: 0.0,
        longitude: 0.0,
        number: "",
        street: "",
        city: "",
        locality: "",
        country: "",
        postalCode: "",
        unparsed: "",
        notes: "",
      },
      billingEmail: "",
      wantMenus: true,
      wantMenusBy: "paper",
      wantInvoices: true,
      wantInvoicesBy: "paper",
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Champs obligatoire"),
      mode: Yup.string().required("Champs obligatoire"),
      address: Yup.object()
        .shape({
          latitude: Yup.number().required("Champs obligatoire"),
          longitude: Yup.number().required("Champs obligatoire"),
          number: Yup.string().nullable(),
          street: Yup.string().required(),
          city: Yup.string().required(),
          locality: Yup.string().required(),
          country: Yup.string().required(),
          postalCode: Yup.string().nullable(),
          unparsed: Yup.string().required(
            "Sélectionner une adresse dans la liste déroulante."
          ),
          latitude: Yup.number().required(),
          longitude: Yup.number().required(),
          notes: Yup.string().nullable(),
        })
        .required("Champs obligatoire"),
      email: Yup.string()
        .email("Veuillez saisir une email valide. Ex: hello@touva.fr ")
        .required("Champs obligatoire"),
      phone: Yup.string()
        .required("Champs obligatoire")
        .matches(
          "[0]{1}[1-7]{1}[0-9]{8}",
          "Veuillez saisir un numéro de téléphone valide."
        ),
      newsletter: Yup.boolean(),
      creditors: Yup.array()
        .of(Yup.number().positive().required("Champs obligatoire"))
        .min(1, "Au minimum 1 créancier est requis."),
      pilotes: Yup.array().of(
        Yup.object()
          .shape({
            round: Yup.number().positive().required("Champs obligatoire"),
            lunchMenu: Yup.number().positive().required("Champs obligatoire"),
            qty: Yup.number().positive().required("Champs obligatoire"),
          })
          .notRequired()
      ),
      packages: Yup.array().of(Yup.number().positive()),
      rounds: Yup.array().of(Yup.number().positive()),
      billingAddress: Yup.object()
        .shape({
          latitude: Yup.number().required("Champs obligatoire"),
          longitude: Yup.number().required("Champs obligatoire"),
          number: Yup.string().nullable(),
          street: Yup.string().required(),
          city: Yup.string().required(),
          locality: Yup.string().required(),
          country: Yup.string().required(),
          postalCode: Yup.string().nullable(),
          unparsed: Yup.string().required(),
          latitude: Yup.number().required(),
          longitude: Yup.number().required(),
          notes: Yup.string().nullable(),
        })
        .required("Champs obligatoire"),
      billingEmail: Yup.string()
        .email("Veuillez saisir une email valide. Ex: hello@touva.fr ")
        .required("Champs obligatoire"),
    }),
    handleSubmit: (values, { props }) => {
      const newClient = {
        name: values.name,
        mode: values.mode,
        email: values.email.toLowerCase(),
        phone: values.phone,
        address: values.address,
        newsletter: values.newsletter,
        origin: values.origin !== "none" ? `/origins/${values.origin}` : null,
        organizationNote: values.organizationNote,
        adminNote: values.adminNote,
        creditors: values.creditors.map((c) => `/creanciers/${c}`),
        pilotes: values.pilotes.map((p) => ({
          round: `/rounds/${p.round}`,
          lunchMenu: `/formules/${p.lunchMenu}`,
          dinnerMenu:
            p.dinnerMenu !== "none" ? `/formules/${p.dinnerMenu}` : null,
          diet: p.diet !== "none" ? `/regimes/${p.diet}` : null,
          qty: p.qty,
          lunchExtras: p.lunchExtras.map((e) => `/extra_categories/${e}`),
          dinnerExtras: p.dinnerExtras.map((e) => `/extra_categories/${e}`),
        })),
        packages: values.packages.map((p) => `/formules/${p}`),
        rounds: values.rounds.map((p) => `/rounds/${p}`),
        billingAddress: values.billingAddress,
        billingEmail: values.billingEmail,
        wantMenus: values.wantMenus,
        wantMenusBy: values.wantMenusBy,
        wantInvoices: values.wantInvoices,
        wantInvoicesBy: values.wantInvoicesBy,
      };

      props.validation(newClient);
    },
  })
)(Add);
