import { Grid, Stack, Typography } from "@mui/material";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import React from "react";

const Policy = () => {
  const items = [
    {
      text: "Solution toute intégrée de gestion du service de portage de repas",
      icon: <HomeRepairServiceIcon fontSize="large" color="primary" />,
    },
    {
      text: "Gestion fine du répertoire des convives",
      icon: <MenuBookIcon fontSize="large" color="primary" />,
    },
    {
      text: "Automatisation des créations de commande en fonction des spécificités de chaque convive",
      icon: <AutoAwesomeIcon fontSize="large" color="primary" />,
    },
    {
      text: "Prise en compte des régimes alimentaires et allergènes pour chaque convive",
      icon: <SentimentSatisfiedAltIcon fontSize="large" color="primary" />,
    },
    {
      text: "Accès en avance au planning des menus",
      icon: <RestaurantIcon fontSize="large" color="primary" />,
    },
    {
      text: "Un service client à votre écoute et proche de vous",
      icon: <HeadsetMicIcon fontSize="large" color="primary" />,
    },
  ];
  return (
    <Grid
      container
      justifyContent="center"
      border={1}
      borderColor="#E6E6E6"
      borderRadius={3}
    >
      {items.map((item, i) => (
        <Grid key={i} item xs={6} md={4}>
          <Stack
            key={i}
            spacing={3}
            justifyContent="center"
            alignItems="center"
            paddingX={2}
            paddingY={5}
            {...(i % 2 === 0 && {
              sx: {
                background:
                  "linear-gradient(180deg, rgba(232, 79, 54, 0.08) 0%, rgba(232, 79, 54, 0) 100%);",
              },
            })}
          >
            {item.icon}

            <Typography variant="body1" textAlign="center" color="#474747">
              {item.text}
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default Policy;
